import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Platform, Log } from '../util';

import './style/index.less';

export default class Slider extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        handleStyle: PropTypes.object,
        trackStyle: PropTypes.object,
        railStyle: PropTypes.object,
        handle: PropTypes.any,
        track: PropTypes.any,
        rail: PropTypes.any,
        value: PropTypes.number,
        min: PropTypes.number,
        max: PropTypes.number,
        onChange: PropTypes.func,
        onAfterChange: PropTypes.func,
        vertical: PropTypes.bool,
        loading: PropTypes.bool,
    };

    static defaultProps = {
        prefixCls: 'tm-slider',
        value: 0,
        min: 0,
        max: 100,
        vertical: false,
        loading: false,
    };

    rail = null;
    track = null;
    handle = null;

    constructor(props) {
        super(props);

        this.state = {
            dragging: false,
        }
    }

    onRailClick = e => {
        if (!this.rail) {
            return;
        }

        const { onChange, onAfterChange } = this.props;

        let value = this.calculateValue(e);
        if (null === value) return;

        onChange && onChange(value);
        onAfterChange && onAfterChange(value);
    }

    matchDOM = (node, target) => {
        let targetNode = target;
        while (targetNode) {
            if (targetNode === node) {
                return true;
            }

            targetNode = targetNode.parentNode;
        }
        return false;
    }

    onTouchBegin = e => {
        Log.verbose('onTouchBegin');
        if (!this.rail || !this.handle) {
            return;
        }

        if (this.matchDOM(this.handle, e.target)) {
            document.addEventListener(Platform.isMobile() ? 'touchmove' : 'mousemove', this.onTouchMove);
            document.addEventListener(Platform.isMobile() ? 'touchend' : 'mouseup', this.onTouchEnd);
            this.setState({
                dragging: true,
            });
        }
    }

    onTouchMove = e => {
        Log.verbose('onTouchMove');
        if (!this.state.dragging) {
            return;
        }

        const { onChange } = this.props;
        let value = this.calculateValue(e);
        if (null === value) return;
        onChange && onChange(value);
    }

    onTouchEnd = e => {
        Log.verbose('onTouchEnd');
        if (!this.state.dragging) {
            return;
        }

        document.removeEventListener(Platform.isMobile() ? 'touchmove' : 'mousemove', this.onTouchMove);
        document.removeEventListener(Platform.isMobile() ? 'touchend' : 'mouseup', this.onTouchEnd);

        this.setState({
            dragging: false,
        });

        const { onAfterChange, onChange } = this.props;
        let value = this.calculateValue(e);
        if (null === value) return;
        onChange && onChange(value);
        onAfterChange && onAfterChange(value);
    }

    calculateValue(e) {
        if (!this.rail) {
            return null;
        }

        const { vertical, min, max } = this.props;

        let rect = this.rail.getBoundingClientRect();
        let pos = vertical ? rect.top : rect.left;
        let currentPos = 0;
        if (e.type === 'touchstart') {
            currentPos = vertical ? e.touches[0].clientY : e.touches[0].clientX;
        } else if (e.type === 'touchmove' || e.type === 'touchend') {
            currentPos = vertical ? e.changedTouches[0].clientY : e.changedTouches[0].clientX;
        } else {
            currentPos = vertical ? e.clientY : e.clientX;
        }

        let offset = currentPos - pos;
        offset = offset > 0 ? offset : 0;

        let percent = Math.round(offset * 100 / this.rail.clientWidth) / 100;
        if (percent > 1) percent = 1;
        let value = (max - min) * percent + min;
        value = Math.min(Math.max(min, value), max);
        return value;
    }

    calculatePercentValue() {
        const { value, min, max } = this.props;

        let tmpVal = value - min;
        tmpVal = Math.min(Math.max(tmpVal, 0), max - min);
        let percent = tmpVal * 100 / (max - min);
        return percent.toFixed(2);
    }

    calculateHandlePosition() {
        if (!this.rail || !this.handle) {
            return;
        }

        const { value, min, max } = this.props;

        let tmpVal = value - min;
        tmpVal = Math.min(Math.max(tmpVal, 0), max - min);

        let percent = tmpVal / (max - min);

        let width = this.rail.clientWidth * percent - this.handle.clientWidth / 2;
        return width.toFixed(2);
    }

    render() {
        const {
            className,
            prefixCls,
            value,
            min,
            max,
            style = {},
            trackStyle = {},
            railStyle = {},
            handleStyle = {},
            handle,
            track,
            rail,
            loading,
        } = this.props;

        const sliderCls = classnames(`${prefixCls}`, className);
        const trackCls = classnames(`${prefixCls}-track`);
        const railCls = classnames(`${prefixCls}-rail`);
        const handleCls = classnames(`${prefixCls}-handle`);
        const activeTrackStyle = {
            width: this.calculatePercentValue() + '%',
        };
        const activeHandleStyle = {
            left: this.calculateHandlePosition() + 'px',
        }

        const renderHandle = () => {
            if (handle) {
                const props = {
                    ref: el => this.handle = ReactDOM.findDOMNode(el),
                    style: {...activeHandleStyle},
                    onMouseDown: this.onTouchBegin,
                    onTouchStart: this.onTouchBegin,
                }
                return React.cloneElement(handle, props);
            } else {
                return (
                    <div
                        ref={el => this.handle = el}
                        className={handleCls}
                        style={{...handleStyle, ...activeHandleStyle}}
                        onMouseDown={this.onTouchBegin}
                        onTouchStart={this.onTouchBegin}
                    />
                );
            }
        }

        const renderLoading = () => {
            if (!loading) {
                return null;
            }

            return <div className={`${prefixCls}-loading`} />
        }

        const renderSlider = () => {
            if (rail && track) {
                const railProps = {
                    ref: el => this.rail = ReactDOM.findDOMNode(el),
                    onClick: e => this.onRailClick(e),
                };
                const trackProps = {
                    style: {...activeTrackStyle},
                    key: 'track'
                };

                const trackElem = React.cloneElement(track, trackProps);

                return React.cloneElement(rail, railProps, [trackElem]);
            } else {
                return (
                    <div
                        ref={el => this.rail = el}
                        className={railCls}
                        style={railStyle}
                        onClick={e => this.onRailClick(e)}
                    >   
                        <div 
                            className={trackCls}
                            style={{...trackStyle, ...activeTrackStyle}}
                        />
                    </div>
                );
            }
        }

        return (
            <div
                className={sliderCls}
                style={style}
                role="slider"
                aria-valuenow={value}
                aria-valuemin={min}
                aria-valuemax={max}
            >
                {renderSlider()}
                {renderLoading()}
                {renderHandle()}
            </div>
        );
    }
}