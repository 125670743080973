import React from 'react';
import ReactDOM from 'react-dom';
import ContextMenu from './ContextMenu';
import Item from './Item';

import { Log } from '../util';

ContextMenu.Item = Item;

ContextMenu.show = (menus, onSelect, point, offset) => {
    if (Object.prototype.toString.call(menus) !== '[object Array]' ||
    menus.length === 0 || !point || !point.x || !point.y) {
        Log.error('invalid arguments!');
        return;
    }
    const menu = document.createElement('div');
    document.body.appendChild(menu);

    const close = () => {
        ReactDOM.unmountComponentAtNode(menu);
        if (menu && menu.parentNode) {
            menu.parentNode.removeChild(menu);
        }
    };

    ReactDOM.render(
        <ContextMenu
            menus={menus}
            point={point}
            offset={offset}
            onSelect={onSelect}
            onClose={close}
        />
    , menu);
}

export default ContextMenu;