import axios from 'axios'
import { parseUrl } from 'src/Utils'

export async function post(
  url,
  data,
  {
    useToken = true,
  } = {},
) {
  const headers = {}
  if (useToken) {
    const urlParam = parseUrl()
    const token = urlParam.token
    headers.Authorization = token
  }

  const ret = await axios({
    method: 'post',
    url: url,
    data,
    headers,
  })

  if (ret.status === 200 && ret.data.code === 0) {
    return ret.data.data
  }

  throw new Error(ret.data.msg)
}

export async function get(
  url,
  params,
  {
    useToken = true,
  } = {}
) {
  const headers = {}
  if (useToken) {
    const urlParam = parseUrl()
    const token = urlParam.token
    headers.Authorization = token
  }

  const ret = await axios({
    method: 'get',
    url: url,
    params,
    headers,
  })

  if (ret.status === 200 && ret.data.code === 0) {
    return ret.data.data
  }

  throw new Error(ret.data.msg)
}
