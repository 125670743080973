import React from 'react';
import ReactDOM from 'react-dom';
import Gallery from './Gallery';

function show(images, index, onDownload) {
    let gallery = document.createElement('div');
    document.body.appendChild(gallery);
    const close = () => {
        ReactDOM.unmountComponentAtNode(gallery);
        if (gallery && gallery.parentNode) {
            gallery.parentNode.removeChild(gallery);
        }
    };

    ReactDOM.render(
        <Gallery 
            source={images} 
            index={index || 0} 
            onClose={close}
            onDownload={onDownload}
        />, 
        gallery
    );
}

export default { show, Gallery }