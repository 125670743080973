import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

class Preload extends React.PureComponent {
    
    static propTypes = {
        source: PropTypes.string,
        onPreload: PropTypes.func,
        onClose: PropTypes.func,
    };

    componentDidMount() {
        if (this.audio) {
            this.audio.muted = true;
            this.audio.play();
        }
    }

    onDurationChange = e => {
        const { onPreload, onClose } = this.props;

        if (this.audio && this.audio.duration !== Infinity && this.audio.duration > 0) {
            this.audio.pause();
            onPreload && onPreload(this.audio.duration);
            onClose && onClose();
        }
    }

    render() {
        return (
            <audio
                ref={el => this.audio = el}
                src={this.props.source}
                onDurationChange={this.onDurationChange}
            />
        );
    }
}

const load = (source, onPreload) => {
    const preload = document.createElement('div');
    document.body.appendChild(preload);

    const close = () => {
        ReactDOM.unmountComponentAtNode(preload);
        if (preload && preload.parentNode) {
            preload.parentNode.removeChild(preload);
        }
    }

    ReactDOM.render(
        <Preload
            source={source}
            onPreload={onPreload}
            onClose={close}
        />,
        preload
    );
}

export default { load }