import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import View from '../view';
import Text from '../text';
import { Loading } from '../indicator';

import Strings from '../strings';

import './style/index.less';

const LOADER_HEIGHT = 48;

export default class Loader extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        prefixCls: PropTypes.string,
        onLoad: PropTypes.func,
        hasMore: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-listview-loader',
        hasMore: () => true,
    };

    state = {
        status: 'idle', // idle/loading/finish
        height: 0,
    }

    get loading() {
        return this.state.status === 'loading';
    }
    set loading(loading) {
        const status = loading ? 'loading' : 'finish';
        if (status === this.state.status || (!this.props.hasMore() && this.state.status === 'idle')) return;
        let state = {status: status};
        if (status === 'loading') {
            state['height'] = LOADER_HEIGHT;
            this.props.onLoad && this.props.onLoad();
        }

        this.setState(state, () => {
            if (this.state.status === 'finish') {
                if(this._idle_timer) clearTimeout(this._idle_timer)
                this._idle_timer = setTimeout(() => {
                    this.setState({
                        status: 'idle',
                        height: this.props.hasMore() ? 0 : LOADER_HEIGHT,
                    });
                }, 1000);
            }
        });
    }

    componentWillUnmount() {
        if(this._idle_timer) clearTimeout(this._idle_timer)
    }

    render() {
        const {
            prefixCls,
            className,
            hasMore,
        } = this.props;
        const renderChild = () => {
            if (this.state.height === 0) return null;
            
            if (this.state.status === 'finish' || !hasMore()) {
                const text = hasMore() ? Strings.load_finish : Strings.no_more_data;
                return (
                    <Text className={`${prefixCls}-text`}>{text}</Text>
                );
            } else {
                return (
                    <Loading 
                        className={`${prefixCls}-indicator`}
                        animate={true}
                    />
                );
            }
        }

        const idle = this.state.status === 'idle';
        const height = idle ? ((hasMore && hasMore()) ?  0 : LOADER_HEIGHT) : this.state.height

        return (
            <View 
                className={classnames(
                    `${prefixCls}`, 
                    className, {
                        [`${prefixCls}-animate`]: !idle,
                    }
                )}
                style={{height: height + 'px'}}
            >
                {renderChild()}
            </View>
        );
    }
}