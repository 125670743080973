import './css/index.less';
import Tabs from './Tabs';
export default Tabs;

/*
tabs使用说明

    相关属性：

        tabs                    说明tabs相关信息
        options                 tabs相关选项
        activeKey               当前活动的key
        bSlide                  是否左右滑动
        defaultActiveKey        默认Key
        className               指定整个tabs对应类名
        onChange                当tabs选择发生变化时调用
        onTabHeightChange       当tabs大小发生变化时调用
        onPagesLayout           当左右滑动式，页面大小确定后调用

        tabs结构数组：
            tabs=[
                {
                    name:string,            //描述tab名称
                    key:string,             //描述tab对应的key
                    //以下各项可选
                    disabled：boolean，     //描述tab是否可点击                 默认 false
                    color:string,           //指定该tab字体颜色                 默认options指定
                    activeColor:string,     //指定该tab激活字体颜色             默认options指定
                    disableColor:string,    //tab标题字体disable颜色           默认options指定
                    fontsize:int,           //指定该tab字体大小  单位：px       默认options指定
                    fontWeight:int,         //字体重量                         默认options指定
                },
                ...
            ]

        options选项
            options={
                tabHeight:int,              //tab标题高度 单位：px              默认60px
                tabWidth:int,               //每个tab固定宽度，不设置将自动根据内容计算     默认80px
                autoFit:boolean,            //是否自适应大小  true将触发onTabHeightChange调用
                contentHeight:int,          //内容高度 单位：px                 默认460px
                fontSize:int,               //tab标题字体大小 单位：px          默认48px
                color:string,               //tab标题字体颜色                   默认#000000
                fontWeight:int,             //字体重量                         默认500
                tabTopGap:int,              //tab离上边间隔    单位：px         默认20 
                tabBottomGap:int,           //tab离下边间隔    单位：px         默认20
                tabGap:int,                 //tab之间间隔   单位：px            默认20
                align:string,               //tab在水平方向上对齐方式           默认space-around     flex-start | flex-end | center | space-between | space-around
                tabMargin:int,              //最左边和最右边tab距离边框间隔      默认0
                activeColor:string,         //tab标题字体选中颜色               默认#0000FF
                disableColor:string,        //tab标题字体disable颜色            默认#0000FF
                background:string,          //tab标题背景色                     默认#FFFFFF
                interval:int,               //tab标题下是否绘制分割线大小单位：px    默认0不绘制                 
                intervalColor:string,       //tab标题下绘制分割线颜色 单位：px    默认gray
                inkSize:int,                //tab选中时是否出现选中线大小 单位：px    默认4px
                inkColor:string,            //tab选中时选中线颜色                   默认blue
            }



*/
