/**
 * DrawingMode interface
 * @class
 * @param {string} name - drawing mode name
 * @ignore
 */
class DrawingMode {
  constructor(name) {
    this.name = name;
  }
  /**
   * Get this drawing mode name;
   * @returns {string} drawing mode name
   */
  getName() {
    return this.name;
  }

  /**
   * start this drawing mode
   * @param {Object} options - drawing mode options
   * @abstract
   */
  start() {
    console.log('Error: UN_IMPLEMENTATION start()');
  }

  /**
   * stop this drawing mode
   * @abstract
   */
  stop() {
    console.log('Error: UN_IMPLEMENTATION stop()');
  }
}

export default DrawingMode;
