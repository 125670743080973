import React from 'react'
import { ListView } from 'thanos'
import { apiGetMatchRank, apiBatchUsers } from 'src/Api'
import { parseUrl } from 'src/Utils'
import BgDiv from 'Components/BgDiv'
import classnames from 'classnames'
import './index.less'

const PAGE_SIZE = 50
const PREFIX_CLS = 'promotion-list'

class PromotionList extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      list: [],
      pageIndex: 1,
      hasMore: false,
    }

    this.urlParam = parseUrl()
  }

  componentDidMount() {
    this.getData(1)
  }

  getData = async (pageIndex) => {
    try {
      const matchId = this.urlParam.get('matchId', 'Number')

      const data = await apiGetMatchRank(matchId, pageIndex, PAGE_SIZE)

      const userIdList = [122]
      for (let i = 0; i < data.length; ++i) {
        userIdList.push(data[i].rank.userId)
      }

      const ret = await apiBatchUsers(userIdList)
      const userInfoList = ret.data || []

      this.setState({
        list: userInfoList,
        pageIndex: pageIndex + 1,
        hasMore: data.length < PAGE_SIZE,
      })

      if (this.refList) {
        this.refList.stopRefreshing()
        this.refList.stopLoading()
      }
    } catch (err) {
      console.log('eeeeeeeeeeeeeee', err.message)
    }
  }

  handleRefresh = () => {
    this.getData(1)
  }

  handleLoadMore = () => {
    this.getData(this.state.pageIndex)
  }

  hasMore = () => {
    return this.state.hasMore
  }

  getPhone = (phone) => {
    const str = phone.toString()
    if (str.length !== 11) {
      return str
    }

    return str.substring(0, 3) + '****' + str.substring(7)
  }

  renderRow = (index, data) => {
    return (
      <ul className={classnames(`${PREFIX_CLS}-item`, { even: index % 2 === 0 })}>
        <li className={`${PREFIX_CLS}-no`}>{index + 1}</li>
        <li className={`${PREFIX_CLS}-name`}>{data.name}</li>
        <li className={`${PREFIX_CLS}-phone`}>{this.getPhone(data.mobile)}</li>
        <li className={`${PREFIX_CLS}-area`}>{data.provinceName}{data.cityName}</li>
      </ul>
    )
  }

  render() {
    const { list } = this.state

    return (
      <BgDiv
        className={PREFIX_CLS}
        style={{ height: '100%' }}
        src={require('Images/bg.png').default}
      >
        <ul className={`${PREFIX_CLS}-title`}>
          <li className={`${PREFIX_CLS}-no`}>序号</li>
          <li className={`${PREFIX_CLS}-name`}>姓名</li>
          <li className={`${PREFIX_CLS}-phone`}>手机号</li>
          <li className={`${PREFIX_CLS}-area`}>地区</li>
        </ul>
        <ListView
          ref={el => this.refList = el}
          className={`${PREFIX_CLS}-list`}
          dataSource={list}
          renderRow={this.renderRow}
          onRefresh={this.handleRefresh}
          onLoad={this.handleLoadMore}
          hasMore={this.hasMore}
        />
      </BgDiv>
    )
  }
}

export default PromotionList
