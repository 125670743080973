import React from 'react';
import { Image } from 'thanos';

const CSS = 'gamelist';

export const Hx = React.memo((props) => {
  const onClick = () => {
    props.gotoMatch(props.data)
  }

  return (
    <div className={`${CSS}-hx`}>
      <Image source={require('Images/bg-box1.png').default} className={`${CSS}-item-bg`} />
      <div className={`${CSS}-content`}>
        <div className='title'>{props.data.matchName}（{props.data.sT + '-' + props.data.eT}日）</div>
        <div className='center'>
          <div className='people-count'>
            <div className='count'>{props.data.joinUserNumber}</div>
            <div className='txt'>报名人数</div>
          </div>
          <div className='message'>
            <div className='tips'>距开{props.data.matchName}始还有{props.data.days}天</div>
            <div className='ctrl'>
              <span className='state'>未开始</span>
              <span className='btn' onClick={onClick}>进入{'>>'}</span>
            </div>
          </div>
        </div>
        <div className='time-box'>
          <div>网络答题</div>
          <div>时间：{props.data.startT + '-' + props.data.endT}</div>
        </div>
      </div>
    </div>
  )
})