import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import View from '../view';
import Text from '../text';
import { Platform } from '../util';

const [DRAG_IDLE, DRAG_BEGIN, DRAG_ACCEPT] = [0, 1, 2];

export default class PickerItem extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        prefixCls: PropTypes.string,
        style: PropTypes.object,
        data: PropTypes.object.isRequired,
        rows: PropTypes.oneOf([3, 5]),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-picker',
        data: {},
    };

    isMobile = Platform.isMobile();
    scroller = null;
    itemHeight = 0;
    touchStart = 0;
    timestamp = 0;
    velocity = 0;

    position = 0;

    innerIndex = 0;
    innerId = -1;

    dragState = DRAG_IDLE;

    constructor(props) {
        super(props);
        this.state = {
            index: props.data.index,
            data: props.data.source,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ('data' in nextProps) {
            console.log('UNSAFE_componentWillReceiveProps')
            this.setTransitionDuration(0);
            let index = nextProps.data.index;
            const source = nextProps.data.source;
            for (let i = 0; i < source.length; i++) {
                if (source[i].id === this.innerId) {
                    index = i;
                    break;
                }
            }

            let shouldChange = false;
            if (this.props.onChange && index !== this.state.index) {
                shouldChange = true;
            }

            this.setState({
                index: index,
                data: source,
            }, () => {
                this.setPosition(-index * this.itemHeight);
                if (shouldChange) {
                    this.props.onChange(this.state.index, this.state.data[this.state.index]);
                }
            });
        }
    }

    componentDidMount() {
        this.setPosition(-this.state.index * this.itemHeight);
        if (!Platform.isMobile()) {
            document.addEventListener('mouseup', this.onMouseUp);
        }

        if (this.scroller) {
            const scroller = this.scroller.firstElementChild;
            scroller.addEventListener('webkitTransitionEnd', this.onTransitionEnd);
        }
    }

    componentWillUnmount() {
        if (!Platform.isMobile()) {
            document.removeEventListener('mouseup', this.onMouseUp);
        }

        if (this.scroller) {
            const scroller = this.scroller.firstElementChild;
            scroller.removeEventListener('webkitTransitionEnd', this.onTransitionEnd);
        }
    }

    setTransitionDuration = time => {
        if (this.scroller) {
            const scroller = this.scroller.firstElementChild;
            scroller.style.transitionDuration = time + 'ms';
        }
    }

    setPosition(position) {
        this.position = position;
        if (this.content) {
            this.content.style.transform = `translate3d(0, ${this.position}px, 0)`;
            this.content.style.webkitTransform = `translate3d(0, ${this.position}px, 0)`;
        }
    }

    getItemAttribute = el => {
        if (this.itemHeight <= 0) {
            const item = ReactDOM.findDOMNode(el);
            this.itemHeight = item.offsetHeight;
        }
    }

    calculateIndex = (position, scrollUp) => {
        const { data } = this.state;
        let index = Math.floor(-position / this.itemHeight);
        if (index < 0) {
            return 0;
        } else if (index >= data.length) {
            return data.length - 1;
        }

        let delta = 0;
        
        if (scrollUp) {
            delta =  Math.abs(position) - index * this.itemHeight;
            index = delta > (this.itemHeight / 2) ? index + 1 : index;
        } else if (!scrollUp) {
            delta =  Math.abs(position) - index * this.itemHeight;
            index = delta > (this.itemHeight / 2) ? index + 1 : index;
        }

        return index > data.length - 1 ? data.length - 1 : index;
    }

    onTransitionEnd = e => {
        if (this.scroller) {
            const scroller = this.scroller.firstElementChild;
            if (scroller === e.target) {
                this.scrolling = false;
                if (!!this.shouldScroll) {
                    this.shouldScroll = false;
                    this.setState({
                        index: this.innerIndex,
                    }, () => {
                        if (this.props.onChange) {
                            this.props.onChange(this.state.index, this.state.data[this.state.index]);
                        }
                    });
                }
            }
        }
    }

    onMouseUp = e => {
        if (!!this.shouldUpdateCloseableState) {
            this.shouldUpdateCloseableState = false;
            setTimeout(() => {
                this.props.onCloseableChange && this.props.onCloseableChange(true);
            }, 300);
        }
    }

    onTouchBegin = e => {
        if (this.state.data.length === 0 || this.scrolling) return;
        if (!this.isMobile) {
            e.preventDefault();
        }
        this.setTransitionDuration(0);
        
        this.dragState = DRAG_BEGIN;

        const touches = (this.isMobile && e.changedTouches) ? e.changedTouches[0] : e;
        this.touchStart = touches.clientY;
        this.velocity = 0;
        this.timestamp = Date.now();
    }

    onTouchMove = e => {
        if (this.dragState !== DRAG_IDLE) {
            if (this.state.data.length === 0) return;
            const touches = this.isMobile ? e.changedTouches[0] : e;
            const delta = touches.clientY - this.touchStart;
            if (this.dragState !== DRAG_ACCEPT && Math.abs(delta) > 15) {
                this.dragState = DRAG_ACCEPT;
                this.props.onCloseableChange && this.props.onCloseableChange(false);
            }
            
            if (this.dragState === DRAG_ACCEPT) {
                const position = this.position + (touches.clientY - this.touchStart);
                const index = this.calculateIndex(position, delta < 0);
                this.setPosition(position);
                let shouldChange = false;
                if (this.props.onChange && this.state.index !== index) {
                    shouldChange = true;
                }
                this.setState({
                    index: index,
                }, () => {
                    if (shouldChange) {
                        this.props.onChange(this.state.index, this.state.data[this.state.index]);
                    }
                });
                this.touchStart = touches.clientY;
                const timestamp = Date.now();
                const duration = timestamp - this.timestamp;
                this.timestamp = timestamp;
                if (duration > 0) {
                    this.velocity = delta / duration;
                } else {
                    this.velocity = 0;
                }
            }
        }
    }

    onTouchEnd = e => {
        if (this.dragState === DRAG_ACCEPT) {
            
            if (e.type !== 'mouseleave') {
                setTimeout(() => {
                    this.props.onCloseableChange && this.props.onCloseableChange(true);
                }, 300);
            } else {
                this.shouldUpdateCloseableState = true;
            }

            this.shouldScroll = false;
            this.innerId = -1;
            this.setTransitionDuration(200);

            const data = this.state.data;
            const length = data.length;
            if (length === 0) {
                return;
            }

            const decelerator = 0.02;
            const duration = Math.abs(this.velocity / decelerator);
            const bounce = decelerator * duration * duration / 2 * (this.velocity > 0 ? 1 : -1);
            let position = -this.state.index * this.itemHeight + bounce;
            const index = this.calculateIndex(position, this.velocity < 0);
            position = -index * this.itemHeight;
            this.scrolling = position !== this.position;
            this.setPosition(position);
            setTimeout(() => this.scrolling = false, 300);
            let shouldChange = false;
            if (this.props.onChange && this.state.index !== index) {
                shouldChange = true;    
            }
            this.setState({
                index: index,
            }, () => {
                if (shouldChange) {
                    this.props.onChange(this.state.index, data[this.state.index]);
                }
            });
            this.touchStart = 0;
        }
        this.dragState = DRAG_IDLE;
    }

    onTrigger = index => {
        if (this.scrolling) return;
        const delta = index - this.state.index - (this.props.rows === 5 ? 2 : 1);
        let position = this.position;
        if (delta < 0) {
            // Scroll up
            position -= this.itemHeight;
        } else if (delta > 0) {
            //Scroll down
            position += this.itemHeight;
        } else {
            return;
        }

        this.innerIndex = this.calculateIndex(position, true);
        this.innerId = this.state.data[this.innerIndex].id;
        this.shouldScroll = true;
        
        position = -this.innerIndex * this.itemHeight;
        this.scrolling = position !== this.position;
        this.setTransitionDuration(200);
        this.setPosition(position);
        setTimeout(() => this.scrolling = false, 300);
    }

    renderItems = () => {
        const { prefixCls, rows } = this.props;
        const emptyItem = {id: -1, label: ' '};
        let data = [emptyItem, ...this.state.data, emptyItem];
        if (rows === 5) {
            data = [emptyItem, ...data, emptyItem];
        }

        return data.map((item, index) => {
            const delta = index - this.state.index - (rows === 5 ? 2 : 1);
            const wrapCls = classnames({
                [`${prefixCls}-scroller-item`]: true,
                [`${prefixCls}-scroller-item-deactive`]: delta !== 0,
                [`${prefixCls}-scroller-item-upper`]: delta === -1,
                [`${prefixCls}-scroller-item-lower`]: delta === 1,
                [`${prefixCls}-scroller-item-upper`]: delta === -2 && rows === 5,
                [`${prefixCls}-scroller-item-lower`]: delta === 2 && rows === 5,
            });  
            return (
                <Text
                    ref={this.getItemAttribute}
                    key={index}
                    className={wrapCls}
                    onClick={e => this.onTrigger(index)}
                >
                    {item.label}
                </Text>
            );
        });
    }

    render() {
        const {
            prefixCls,
            className,
            style,
            rows,
        } = this.props;

        const wrapCls = classnames({
            [`${prefixCls}-scroller-row-3`]: rows === 3,
            [`${prefixCls}-scroller-row-5`]: rows === 5,
        }, `${prefixCls}-scroller`, className);
        // Log.verbose('Render picker item - ', this.props.column);

        return (
            <View 
                ref={el => this.scroller = ReactDOM.findDOMNode(el)}
                className={wrapCls}
                style={style}
                onMouseDown={this.onTouchBegin}
                onMouseMove={this.onTouchMove}
                onMouseUp={this.onTouchEnd}
                onMouseLeave={this.onTouchEnd}
                onTouchStart={this.onTouchBegin}
                onTouchMove={this.onTouchMove}
                onTouchEnd={this.onTouchEnd}
            >
                <View 
                    ref={el => this.content = ReactDOM.findDOMNode(el)}
                    className={`${prefixCls}-scroller-content`}
                    // style={contentStyle}
                >
                    {this.renderItems()}
                </View>
            </View>
        );
    }
}