import _ from 'lodash';
export const User_Group = [
  { label: '小学组', value: 1 },
  { label: '初中组', value: 2 },
  { label: '高中组', value: 3 },
  { label: '高校组', value: 4 },
  { label: '社会组', value: 5 },
];

export const User_Grade = [
  { label: '一年级', value: 1 },
  { label: '二年级', value: 2 },
  { label: '三年级', value: 3 },
  { label: '四年级', value: 4 },
  { label: '五年级', value: 5 },
  { label: '六年级', value: 6 },
];

export const Location = () => {
  let antdDistrict = [];
  let districtData = require('../../Constants/location.json');
  Object.keys(districtData).forEach((index) => {
    let itemLevel1 = {};
    let itemLevel2 = {};
    itemLevel1.value = districtData[index].code;
    itemLevel1.label = districtData[index].name;
    itemLevel1.children = [];
    let data = districtData[index].cities;
    Object.keys(data).forEach((index) => {
      itemLevel2.value = data[index].code;
      itemLevel2.label = data[index].name;
      itemLevel2.children = [];
      let data2 = data[index].districts;
      let itemLevel3 = {};
      itemLevel3.children = [];
      Object.keys(data2).forEach((index) => {
        itemLevel3.value = index;
        itemLevel3.label = data2[index];
        itemLevel2.children.push(itemLevel3);
        itemLevel3 = {};
      });
      itemLevel1.children.push(itemLevel2);
      itemLevel2 = {};
    });
    antdDistrict.push(itemLevel1);
  });

  //排序 '-洛阳
  let henan = _.find(antdDistrict, { value: '410000' });
  let luoyang = _.find(henan.children, { value: '410300' });
  let luoyangidx = _.findIndex(henan.children, { value: '410300' });
  henan.children.splice(luoyangidx, 1);
  henan.children.unshift(luoyang);
  let henanidx = _.findIndex(antdDistrict, { value: '410000' });
  antdDistrict.splice(henanidx, 1);
  antdDistrict.unshift(henan);

  //排序 河北省、山东、山西、陕西
  let citys = ['130000', '370000', '140000', '610000'];
  for (let i = 0; i < citys.length; i++) {
    let obj = _.find(antdDistrict, { value: citys[i] });
    let idx = _.findIndex(antdDistrict, { value: citys[i] });
    antdDistrict.splice(idx, 1);
    antdDistrict.push(obj);
  }

  return antdDistrict;
};
