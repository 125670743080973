import React from 'react';
import consts from './lib/consts';
import Graphics from './graphics';
import Invoker from './invoker';
import commandFactory from './factory/command';
import util from './lib/util';
import Toast from '../../toast';

import './command/addObject';
import './command/removeObject';
import './command/addText';
import './command/changeText';
import './command/changeTextStyle';
import './command/addIcon';
import './command/changeIconStyle';
import './command/rotate';

const { componentNames, rejectMessages, drawingModes } = consts;
const events = consts.eventNames;
const commands = consts.commandNames;
const { IMAGE_LOADER } = componentNames;
const {
  OBJECT_MOVED,
  OBJECT_SCALED,
  OBJECT_ACTIVATED,
  OBJECT_ROTATED,
  ADD_TEXT,
  ADD_OBJECT,
  ADD_OBJECT_AFTER,
  REMOVE_OBJECT,
  TEXT_EDITING,
  TEXT_CHANGED,
  SELECTION_CLEARED,
  SELECTION_CREATED,
  DRAWING_MODE_CHANGED,
  UNDO_STACK_CHANGED,
  REDO_STACK_CHANGED,
  LOADED,
} = events;

// polyfill
const reqAnimationFrame = (function () {
  return (
    window[window.Hammer.prefixed(window, 'requestAnimationFrame')] ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60);
    }
  );
})();

class FabricCanvas extends React.Component {
  constructor(props) {
    super(props);

    /**
     * Event handler list
     * @type {Object}
     * @private
     */
    this._handlers = {
      objectActivated: this._onObjectActivated.bind(this),
      objectMoved: this._onObjectMoved.bind(this),
      objectScaled: this._onObjectScaled.bind(this),
      objectRotated: this._onObjectRotated.bind(this),
      addText: this._onAddText.bind(this),
      addObject: this._onAddObject.bind(this),
      addObjectAfter: this._onAddObjectAfter.bind(this),
      removeObject: this._onRemoveObject.bind(this),
      textEditing: this._onTextEditing.bind(this),
      textChanged: this._onTextChanged.bind(this),
      selectionCleared: this._selectionCleared.bind(this),
      selectionCreated: this._selectionCreated.bind(this),
      drawingModeChanged: this._drawingModeChanged.bind(this),
      undoStackChanged: this._onUndoStackChanged.bind(this),
      redoStackChanged: this._onRedoStackChanged.bind(this),
    };
  }

  componentDidMount() {
    const { cssMaxWidth, cssMaxHeight, name, source } = this.props;
    /**
     * Graphics instance
     * @type {Graphics}
     * @private
     */
    this._graphics = new Graphics(this._element, {
      containerClass: 'canvas-container',
      cssMaxWidth: cssMaxWidth,
      cssMaxHeight: cssMaxHeight,
      useItext: true,
      useDragAddIcon: true,
    });

    /**
     * Invoker
     * @type {Invoker}
     * @private
     */
    this._invoker = new Invoker(this._graphics);

    this._attachGraphicsEvents();
    this._attachInvokerEvents();

    /**
     * 初始化dom
     */
    this._deviceScreenEl = document.querySelector('#device-screen');
    this._canvasContainerEl = document.querySelector('#canvas-container');

    /**
     * 全屏区域
     */
    this._maxWidth = this._deviceScreenEl.offsetWidth;
    this._maxHeight = this._deviceScreenEl.offsetHeight;

    this._ticking = false;
    this._transform = null;

    this._initPoint = {};

    this._initScale = 1;
    this._initCenter = {};

    /**
     * 鼠标、手势事件
     */
    const mc = new window.Hammer.Manager(this._canvasContainerEl);

    mc.add(new window.Hammer.Pan({ threshold: 0, pointers: 0 }));
    mc.add(new window.Hammer.Rotate({ threshold: 0 })).recognizeWith(mc.get('pan'));
    mc.add(new window.Hammer.Pinch({ threshold: 0 })).recognizeWith([mc.get('pan'), mc.get('rotate')]);
    mc.add(new window.Hammer.Tap({ event: 'doubletap', taps: 2 }));
    mc.add(new window.Hammer.Tap());

    mc.on('panstart panmove', this._onPan.bind(this));
    mc.on('pinchstart pinchmove', this._onPinch.bind(this));
    mc.on('hammer.input', function (ev) {
      // console.log('FabricCanvas -> _attachHammerEvents -> ev', ev);
      if (ev.isFinal) {
        // resetElement();
      }
    });

    /**
     * 鼠标滚轮事件
     */
    this._canvasContainerEl.addEventListener('mousewheel', this._onWheel.bind(this));

    this.load(source, name);
  }

  shouldComponentUpdate(nextProps, nextState) {
    return false;
  }

  componentWillUnmount() {
    this.destroy();
  }

  load(source, name) {
    if (source && name) {
      const toast = Toast.loading();
      this.loadImageFromURL(source, name).then(() => {
        toast.close();
        this._onLoaded();
      });
    } else {
      this._onLoaded();
    }
  }

  clear() {
    this._graphics.clear();
    this.clearUndoStack();
    this.clearRedoStack();
  }

  /**
   * Attach canvas events
   */
  _attachGraphicsEvents() {
    this._graphics.on({
      [OBJECT_MOVED]: this._handlers.objectMoved,
      [OBJECT_SCALED]: this._handlers.objectScaled,
      [OBJECT_ROTATED]: this._handlers.objectRotated,
      [OBJECT_ACTIVATED]: this._handlers.objectActivated,
      [ADD_TEXT]: this._handlers.addText,
      [ADD_OBJECT]: this._handlers.addObject,
      [TEXT_EDITING]: this._handlers.textEditing,
      [TEXT_CHANGED]: this._handlers.textChanged,
      [REMOVE_OBJECT]: this._handlers.removeObject,
      [SELECTION_CLEARED]: this._handlers.selectionCleared,
      [SELECTION_CREATED]: this._handlers.selectionCreated,
      [ADD_OBJECT_AFTER]: this._handlers.addObjectAfter,
      [DRAWING_MODE_CHANGED]: this._handlers.drawingModeChanged,
    });
  }

  /**
   * Attach invoker events
   * @private
   */
  _attachInvokerEvents() {
    this._invoker.on({
      [UNDO_STACK_CHANGED]: this._handlers.undoStackChanged,
      [REDO_STACK_CHANGED]: this._handlers.redoStackChanged,
    });
  }

  _resetElement() {
    this._originWidth = this._canvasContainerEl.offsetWidth;
    this._originHeight = this._canvasContainerEl.offsetHeight;

    var START_X = Math.round((this._maxWidth - this._originWidth) / 2);
    var START_Y = Math.round((this._maxHeight - this._originHeight) / 2);

    this._canvasContainerEl.className = 'animate';
    this._transform = {
      left: START_X,
      top: START_Y,
      width: this._originWidth,
      height: this._originHeight,
      scale: 1,
    };
    this._requestElementUpdate();
  }

  _requestElementUpdate() {
    if (!this._ticking) {
      reqAnimationFrame(this._updateElementTransform.bind(this));
      this._ticking = true;
    }
  }

  _updateElementTransform() {
    let { left, top, width, height } = this._transform;

    this._graphics.setCanvasCssDimension({
      width: `${width}px`,
      height: `${height}px`,
    });

    if (width < this._maxWidth) {
      left = Math.round((this._maxWidth - width) / 2);
    } else {
      let minLeft = 0;
      if (left > minLeft) {
        left = minLeft;
      }

      let maxLeft = this._maxWidth - width;
      if (left < maxLeft) {
        left = maxLeft;
      }
    }
    this._transform.left = left;

    if (height < this._maxHeight - 120) {
      top = Math.round((this._maxHeight - height) / 2);
    } else {
      let minTop = 0 + 60;
      if (top > minTop) {
        top = minTop;
      }
      let maxTop = this._maxHeight - height - 60;
      if (top < maxTop) {
        top = maxTop;
      }
    }
    this._transform.top = top;

    this._canvasContainerEl.style.left = `${left}px`;
    this._canvasContainerEl.style.top = `${top}px`;

    this._ticking = false;
  }

  adjustCanvasViewport() {
    // 画布范围
    const { width, height } = this.getCanvasSize();
    // canvas transform
    const [zoom, , , , x, y] = this._canvas.viewportTransform;
    // 图片范围
    const [imageWidth, imageHeight] = [zoom * width, zoom * height];
    // 原点范围
    const [pointWidth, pointHeight] = [imageWidth - width, imageHeight - height];
    // 校正原点，图片范围不能超出画布范围
    const pointer = new window.fabric.Point(
      x > 0 ? 0 : -x > pointWidth ? pointWidth : -x,
      y > 0 ? 0 : -y > pointHeight ? pointHeight : -y
    );
    this._canvas.absolutePan(pointer);
  }

  _onPan(e) {
    const { pointerType } = e;
    const drawingMode = this.getDrawingMode();

    if (
      drawingMode === drawingModes.FREE_DRAWING ||
      drawingMode === drawingModes.DELETION ||
      drawingMode === drawingModes.PATH ||
      drawingMode === drawingModes.TEXT ||
      drawingMode === drawingModes.ICON
    ) {
      if (pointerType === 'touch') {
        if (e.maxPointers !== 2) return;
      } else {
        return;
      }
    }

    if (e.type === 'panstart') {
      if (drawingMode === drawingModes.FREE_DRAWING) {
        this._graphics.cancelCurrentlyDrawing();
      }

      this._initPoint = {
        x: this._transform.left,
        y: this._transform.top,
      };
    }

    this._transform.left = this._initPoint.x + e.deltaX;
    this._transform.top = this._initPoint.y + e.deltaY;

    this._requestElementUpdate();
  }

  _onPinch(e) {
    if (e.type === 'pinchstart') {
      this._initScale = this._transform.scale;
      this._initCenter = e.center;
    }

    let scale2 = this._initScale * e.scale;
    if (scale2 > 20) scale2 = 20;
    if (scale2 < 1) scale2 = 1;

    const { left, top } = this._transform;

    const width = this._originWidth * this._initScale;
    const height = this._originHeight * this._initScale;

    const offsetX = this._initCenter.x - left;
    const offsetY = this._initCenter.y - top;

    const rateX = offsetX / width;
    const rateY = offsetY / height;

    const width2 = this._originWidth * scale2;
    const height2 = this._originHeight * scale2;

    const offsetX2 = width2 * rateX;
    const offsetY2 = height2 * rateY;

    const diffX = offsetX2 - offsetX;
    const diffY = offsetY2 - offsetY;

    const left2 = left - diffX;
    const top2 = top - diffY;

    this._transform = {
      left: left2,
      top: top2,
      width: width2,
      height: height2,
      scale: scale2,
    };

    this._requestElementUpdate();
  }

  _onWheel(e) {
    const { scale } = this._transform;
    // 以鼠标位置点，缩放
    this._zoom({ x: e.offsetX, y: e.offsetY }, scale + e.deltaY / 300)
  }

  _zoom(zoomPoint, scale) {
    const { left, top, width, height } = this._transform;
    const { x: offsetX, y: offsetY } = zoomPoint;

    let scale2 = scale;
    if (scale2 > 20) scale2 = 20;
    if (scale2 < 1) scale2 = 1;

    const rateX = offsetX / width;
    const rateY = offsetY / height;

    const width2 = this._originWidth * scale2;
    const height2 = this._originHeight * scale2;

    const offsetX2 = width2 * rateX;
    const offsetY2 = height2 * rateY;

    const diffX = offsetX2 - offsetX;
    const diffY = offsetY2 - offsetY;

    const left2 = left - diffX;
    const top2 = top - diffY;

    this._transform = {
      left: left2,
      top: top2,
      width: width2,
      height: height2,
      scale: scale2,
    };

    this._requestElementUpdate();
  }

  zoomIn() {
    const { left, top, scale } = this._transform;
    // 以屏幕中心点，缩放
    this._zoom({
      x: this._maxWidth / 2 - left,
      y: this._maxHeight / 2 - top
    }, scale + 0.5);
  }

  zoomOut() {
    const { left, top, scale } = this._transform;
    // 以屏幕中心点，缩放
    this._zoom({
      x: this._maxWidth / 2 - left,
      y: this._maxHeight / 2 - top
    }, scale - 0.5);
  }

  restoreZoom() {
    const { left, top } = this._transform;
    // 以屏幕中心点，缩放
    this._zoom({
      x: this._maxWidth / 2 - left,
      y: this._maxHeight / 2 - top
    }, 1); // 减去足够大的值，会回到最小1:1
  }

  _getViewportCenter() {
    const { left, top, width, height } = this._transform;
    const maxWidth = this._maxWidth;
    const maxHeight = this._maxHeight;

    const w = maxWidth; //Math.min(width, maxWidth);
    const h = maxHeight; //Math.min(height, maxHeight);

    let x = w / 2;
    let y = h / 2;

    x -= left;
    y -= top;

    const rateX = x / width;
    const rateY = y / height;

    const { br } = this._graphics.getCanvas().vptCoords;

    x = br.x * rateX;
    y = br.y * rateY;

    return {
      x,
      y,
    };
  }

  _setSelectionStyle(selectionStyle, { applyCropSelectionStyle, applyGroupSelectionStyle }) {
    if (selectionStyle) {
      this._graphics.setSelectionStyle(selectionStyle);
    }

    if (applyCropSelectionStyle) {
      this._graphics.setCropSelectionStyle(selectionStyle);
    }

    if (applyGroupSelectionStyle) {
      this.on('selectionCreated', (eventTarget) => {
        if (eventTarget.type === 'activeSelection') {
          eventTarget.set(selectionStyle);
        }
      });
    }
  }

  /**
   * Set position
   */
  // _setPositions(options) {
  //   const centerPosition = this._graphics.getViewportCenter();

  //   if (util.isUndefined(options.left)) {
  //     options.left = centerPosition.left;
  //   }

  //   if (util.isUndefined(options.top)) {
  //     options.top = centerPosition.top;
  //   }
  // }

  _setPositions(options) {
    const { x, y } = this._getViewportCenter();

    if (util.isUndefined(options.left)) {
      options.left = x;
    }
    if (util.isUndefined(options.top)) {
      options.top = y;
    }
  }

  getGraphics() {
    return this._graphics;
  }

  /**
   * Get image name
   */
  getImageName() {
    return this._graphics.getImageName();
  }

  /**
   * Get the canvas size
   */
  getCanvasSize() {
    return this._graphics.getCanvasSize();
  }

  /**
   * Get current drawing mode
   */
  getDrawingMode() {
    return this._graphics.getDrawingMode();
  }

  /**
   * Start a drawing mode. If the current mode is not 'NORMAL', 'stopDrawingMode()' will be called first.
   */
  startDrawingMode(mode, option) {
    if (!this._graphics) return;
    return this._graphics.startDrawingMode(mode, option);
  }

  /**
   * Stop the current drawing mode and back to the 'NORMAL' mode
   */
  stopDrawingMode() {
    if (!this._graphics) return;
    this._graphics.stopDrawingMode();
  }

  /**
   * Remove Active Object
   */
  removeActiveObject() {
    const activeObjectId = this._graphics.getActiveObjectIdForRemove();
    this.removeObject(activeObjectId);
  }

  /**
   * Clear all objects
   */
  clearObjects() {
    this._graphics.removeAll(true);
  }

  /**
   * Deactivate all objects
   */
  deactivateAll() {
    this._graphics.deactivateAll();
    this._graphics.renderAll();
  }

  /**
   * discard selction
   */
  discardSelection() {
    this._graphics.discardSelection();
  }

  /**
   * selectable status change
   */
  changeSelectableAll(selectable) {
    this._graphics.changeSelectableAll(selectable);
  }

  /**
   * Load image from file
   */
  loadImageFromFile(imgFile, imageName) {
    if (!imgFile) {
      return Promise.reject(rejectMessages.invalidParameters);
    }

    const imgUrl = URL.createObjectURL(imgFile);
    imageName = imageName || imgFile.name;

    return this.loadImageFromURL(imgUrl, imageName).then((value) => {
      URL.revokeObjectURL(imgFile);
      return value;
    });
  }

  /**
   * Load image from url
   */
  loadImageFromURL(url, imageName) {
    if (!imageName || !url) {
      return Promise.reject(rejectMessages.invalidParameters);
    }

    const loader = this._graphics.getComponent(IMAGE_LOADER);
    return loader.load(imageName, url).then((newImage) => ({
      newWidth: newImage.width,
      newHeight: newImage.height,
    }));
  }

  /**
   * Get data url
   */
  toDataURL() {
    return new Promise((resolve, reject) => {
      const dataURL = this._graphics.toDataURL();
      if (dataURL && util.validDataURL(dataURL)) {
        resolve(dataURL);
      } else {
        reject(new Error('生成图片失败'));
      }
    });
  }

  /**
   * Get data blob
   */
  toBlob(options) {
    const dataURL = this._graphics.toDataURL(options);
    return util.base64ToBlob(dataURL);
  }

  /**
   * Set drawing brush
   */
  setBrush(option) {
    this._graphics.setBrush(option);
  }

  /**
   * @param {string} type - 'rotate' or 'setAngle'
   * @param {number} angle - angle value (degree)
   * @param {boolean} isSilent - is silent execution or not
   * @returns {Promise<RotateStatus, ErrorMsg>}
   * @private
   */
  _rotate(type, angle, isSilent) {
    // this._graphics.restoreZoom();

    let result = null;
    if (isSilent) {
      result = this.executeSilent(commands.ROTATE_IMAGE, type, angle);
    } else {
      result = this.execute(commands.ROTATE_IMAGE, type, angle);
    }

    return result.then(() => {
      this._resetElement();
    });
  }

  /**
   * Rotate image
   */
  rotate(angle, isSilent) {
    return this._rotate('rotate', angle, isSilent);
  }

  /**
   * Set angle
   */
  setAngle(angle, isSilent) {
    return this._rotate('setAngle', angle, isSilent);
  }

  /**
   * Add text on image
   */
  addText(text, options) {
    text = text || '';
    options = options || {};

    this._setPositions(options);

    return this.execute(commands.ADD_TEXT, text, options);
  }

  /**
   * Change contents of selected text object on image
   */
  changeText(id, text) {
    text = text || '';
    return this.execute(commands.CHANGE_TEXT, id, text);
  }

  /**
   * Set style
   */
  changeTextStyle(id, styleObj, isSilent) {
    const executeMethodName = isSilent ? 'executeSilent' : 'execute';
    return this[executeMethodName](commands.CHANGE_TEXT_STYLE, id, styleObj);
  }

  /**
   * Add icon on canvas
   */
  addIcon(type, options) {
    options = options || {};

    this._setPositions(options);

    return this.execute(commands.ADD_ICON, type, options);
  }

  /**
   * Set style
   */
  changeIconStyle(id, styleObj, isSilent) {
    const executeMethodName = isSilent ? 'executeSilent' : 'execute';
    return this[executeMethodName](commands.CHANGE_ICON_STYLE, id, styleObj);
  }

  /**
   * Remove an object or group by id
   */
  removeObject(id) {
    if (id) {
      return this.removeObjects([id]);
    }
    throw new Error('"id" undefined');
  }

  /**
   * Remove object array
   */
  removeObjects(ids) {
    return this.execute(commands.REMOVE_OBJECT, ids);
  }

  /**
   * Undo
   */
  undo() {
    return this._invoker.undo().then(() => {
      this._resetElement();
    });
  }

  /**
   * Redo
   */
  redo() {
    return this._invoker.redo().then(() => {
      this._resetElement();
    });
  }

  /**
   * Clear undoStack
   */
  clearUndoStack() {
    this._invoker.clearUndoStack();
  }

  /**
   * Clear redoStack
   */
  clearRedoStack() {
    this._invoker.clearRedoStack();
  }

  /**
   * Whehter the undo stack is empty or not
   */
  isEmptyUndoStack() {
    return this._invoker.isEmptyUndoStack();
  }

  /**
   * Whehter the redo stack is empty or not
   */
  isEmptyRedoStack() {
    return this._invoker.isEmptyRedoStack();
  }

  /**
   * Resize canvas dimension
   */
  resizeCanvasDimension(dimension) {
    if (!dimension) {
      return Promise.reject(rejectMessages.invalidParameters);
    }

    return this.execute(commands.RESIZE_CANVAS_DIMENSION, dimension);
  }

  /**
   * Set properties of active object
   */
  setObjectProperties(id, keyValue) {
    return this.execute(commands.SET_OBJECT_PROPERTIES, id, keyValue);
  }

  /**
   * Destroy
   */
  destroy() {
    this.stopDrawingMode();
    this._graphics.destroy();
    this._graphics = null;

    util.forEach(
      this,
      (value, key) => {
        this[key] = null;
      },
      this
    );
  }

  /**
   * 画线过程中途取消
   */
  cancelCurrentlyDrawing() {
    this._graphics.cancelCurrentlyDrawing();
  }

  /**
   * Invoke command
   */
  execute(commandName, ...args) {
    // Inject an Graphics instance as first parameter
    const theArgs = [this._graphics].concat(args);

    return this._invoker.execute(commandName, ...theArgs);
  }

  /**
   * Invoke command
   */
  executeSilent(commandName, ...args) {
    // Inject an Graphics instance as first parameter
    const theArgs = [this._graphics].concat(args);

    return this._invoker.executeSilent(commandName, ...theArgs);
  }

  /**
   * Event to React
   */
  _fire(eventName) {
    const eventFnName = 'on' + eventName[0].toUpperCase() + eventName.slice(1);
    const args = Array.prototype.slice.call(arguments, 1);
    const event = this.props[eventFnName];
    if (event) {
      event(...args);
    }
  }

  /////////////////////////////////////////////////////////////////////////////
  /** Event handler begin */

  /**
   * 'loaded' event handler
   */
  _onLoaded() {
    this._resetElement();
    this._fire(LOADED);
  }

  /**
   * 'objectActivated' event handler
   */
  _onObjectActivated(props) {
    this._fire(events.OBJECT_ACTIVATED, props);
  }

  /**
   * 'objectMoved' event handler
   */
  _onObjectMoved(props) {
    this._fire(events.OBJECT_MOVED, props);
  }

  /**
   * 'objectScaled' event handler
   */
  _onObjectScaled(props) {
    this._fire(events.OBJECT_SCALED, props);
  }

  /**
   * 'objectRotated' event handler
   */
  _onObjectRotated(props) {
    this._fire(events.OBJECT_ROTATED, props);
  }

  /**
   * 'textChanged' event handler
   */
  _onTextChanged(objectProps) {
    this.changeText(objectProps.id, objectProps.text);
  }

  /**
   * 'textEditing' event handler
   */
  _onTextEditing() {
    this._fire(events.TEXT_EDITING);
  }

  /**
   * Mousedown event handler in case of 'TEXT' drawing mode
   */
  _onAddText(event) {
    this._fire(events.ADD_TEXT, {
      originPosition: event.originPosition,
      clientPosition: event.clientPosition,
    });
  }

  /**
   * 'addObject' event handler
   */
  _onAddObject(objectProps) {
    const obj = this._graphics.getObject(objectProps.id);
    const command = commandFactory.create(commands.ADD_OBJECT, this._graphics, obj);
    this._invoker.pushUndoStack(command);

    this._fire(events.ADD_OBJECT, obj);
  }

  /**
   * 'addObjectAfter' event handler
   */
  _onAddObjectAfter(objectProps) {
    this._fire(events.ADD_OBJECT_AFTER, objectProps);
  }

  /**
   * 'addObject' event handler
   */
  _onRemoveObject(objs) {
    this._fire(events.REMOVE_OBJECT, objs);
  }

  /**
   * 'undoStackChanged' event handler
   */
  _onUndoStackChanged(num) {
    this._fire(events.UNDO_STACK_CHANGED, num);
  }

  /**
   * 'redoStackChanged' event handler
   */
  _onRedoStackChanged(num) {
    this._fire(events.REDO_STACK_CHANGED, num);
  }

  /**
   * 'selectionCleared' event handler
   */
  _selectionCleared() {
    this._fire(events.SELECTION_CLEARED);
  }

  /**
   * 'selectionCreated' event handler
   */
  _selectionCreated(eventTarget) {
    this._fire(events.SELECTION_CREATED, eventTarget);
  }

  /**
   * 'drawingModeChanged' event handler
   */
  _drawingModeChanged(drawingMode) {
    this._fire(events.DRAWING_MODE_CHANGED, drawingMode);
  }

  /** Event handler end */
  /////////////////////////////////////////////////////////////////////////////

  render() {
    return <div ref={(e) => (this._element = e)} className="fabric-canvas" id="device-screen" />;
  }
}

export default FabricCanvas;
