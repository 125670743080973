import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import TabContent from './TabContent'
import TabBar from './TabBar'
import ReactDOM from 'react-dom'
import Pager from '../pager'

const LEFT = 37;
const UP = 38;
const RIGHT = 39;
const DOWN = 40;

function noop() {
}

function getDefaultActiveKey(props) {
  let activeKey;
  let j = 0;
  let len = props.tabs.length;
  for (j = 0; j<len; j++){
    if (props.tabs[j].key && !props.tabs[j].disabled){
      activeKey = props.tabs[j].key;
      break;
    }
  }
  return activeKey;
}

function activeKeyIsValid(props, key) {
  let j = 0;
  let len = props.tabs.length;
  for (j = 0; j<len; j++){
    if (props.tabs[j].key === key){
      return true;
    }
  }
  return false;
}

export default class Tabs extends React.Component {
  constructor(props) {
    super(props);

    let activeKey;
    if ('activeKey' in props) {
      activeKey = props.activeKey;
    } else if ('defaultActiveKey' in props) {
      activeKey = props.defaultActiveKey;
    } else {
      activeKey = getDefaultActiveKey(props);
    }
    this.state = {
      activeKey,
    };

    this.initContentViews(this.props.tabs)
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.tabs.length !== this.props.tabs.length) {
      this.initContentViews(nextProps.tabs)
    }

    if ('activeKey' in nextProps) {
      this.setState({
        activeKey: nextProps.activeKey,
      });
    } else if (!activeKeyIsValid(nextProps, this.state.activeKey)) {
      this.setState({
        activeKey: getDefaultActiveKey(nextProps),
      });
    }
  }

  initContentViews = (tabs) => {
    this._tabContentViews = [];
    let len = tabs.length;
    for (let nIndex = 0; nIndex < len; nIndex++){
        this._tabContentViews[nIndex] = {
            key:tabs[nIndex].key,
            node:undefined,
        }
    };
  }

  onTabClick = (activeKey, e) => {
    if (this.props.onTabClick){
      this.props.onTabClick(activeKey, e);
    }
    this.setActiveKey(activeKey);
  }

  onNavKeyDown = (e) => {
    const eventKeyCode = e.keyCode;
    if (eventKeyCode === RIGHT || eventKeyCode === DOWN) {
      e.preventDefault();
      const nextKey = this.getNextActiveKey(true);
      this.onTabClick(nextKey);
    } else if (eventKeyCode === LEFT || eventKeyCode === UP) {
      e.preventDefault();
      const previousKey = this.getNextActiveKey(false);
      this.onTabClick(previousKey);
    }
  }

  setActiveKey = (activeKey) => {
    if (this.state.activeKey !== activeKey) {
        if (!('activeKey' in this.props)) {
        this.setState({
            activeKey,
        });
        }
        if (this.props.onChange){
            this.props.onChange(activeKey);
        }
        if (this.pagerNode){
            this.pagerNode.activeIndex = this.getActiveIndex(activeKey);
        }
    }
  }

  onPagerChanged = (index) => {
    if (this.props.tabs){
        this.setActiveKey(this.props.tabs[index].key);
    }
  }

  onPagesLayout = (layout) => {
    if (this.props.onPagesLayout){
        this.props.onPagesLayout(layout);
    }
    for (let nIndex = 0; nIndex < this._tabContentViews.length; nIndex++){
        if (this._tabContentViews[nIndex].childView)
        {
            if (this._tabContentViews[nIndex].childView.onPagesLayout){
                this._tabContentViews[nIndex].childView.onPagesLayout(layout);
            }
        }
    };
  }

  getNextActiveKey = (next) => {
    const activeKey = this.state.activeKey;
    const validTabs = [];
    let j = 0;
    let len = this.props.tabs.length;
    for (j = 0; j<len; j++){
      if (!this.props.tabs[j].disabled){
          if (next) {
            validTabs.push(this.props.tabs[j]);
          } else {
            validTabs.unshift(this.props.tabs[j]);
          }
      }
    }
    const length = validTabs.length;
    let ret = length && validTabs[0].key;
    for (j = 0; j<length; j++){
      if (validTabs[j].key === activeKey) {
        if (j === length - 1) {
          ret = validTabs[0].key;
        } else {
          ret = validTabs[j + 1].key;
        }
      }
    }
    return ret;
  }
  saveRef = (name) => {
    return node => {
      this[name] = node;
    };
  }

  saveContentRef = (key) => {
    return node => {
        for (let nIndex = 0; nIndex < this._tabContentViews.length; nIndex++){
            if (this._tabContentViews[nIndex].key === key)
            {
                this._tabContentViews[nIndex].node = node;
            }
        };
    };
  }

  saveContentChildViewRef = (key) => {
    return node => {
        if (node){
          for (let nIndex = 0; nIndex < this._tabContentViews.length; nIndex++){
              if (this._tabContentViews[nIndex].key === key)
              {
                  this._tabContentViews[nIndex].childView = node;
              }
          };
        }
    };
  }

  updateTabsHeight = () => {
    let tabsFrame = ReactDOM.findDOMNode(this['tabs-frame']);
    let tabsHeader = ReactDOM.findDOMNode(this['tab-header']);
    let nActiveIndex = this.getActiveIndex();
    if (!this._tabContentViews[nActiveIndex].node){
      return;
    }
    let tabContent = ReactDOM.findDOMNode(this._tabContentViews[nActiveIndex].node);
    if (this.props.options && this.props.options.contentHeight){//设置了内容高度
          this.frameHeight = tabsHeader.offsetHeight + tabContent.offsetHeight;
          tabsFrame.style.height=`${ this.frameHeight}px`;
          if (this.pagerNode){
            this.pagerNode.height = tabContent.offsetHeight;
          }
    }
    else{
      if (this.props.options.autoFit){
          let children = tabContent.childNodes;
          let contentHeight = 0;
          if (children.length > 0){
            contentHeight = children[0].offsetHeight + 1;
          }
          tabContent.style.height=`${contentHeight}px`;
          //tabContent.style.overflowY='hidden';
          this.frameHeight = tabsHeader.offsetHeight + contentHeight;
          tabsFrame.style.height=`${ this.frameHeight}px`;
          if (this.pagerNode){
            this.pagerNode.height = contentHeight;
          }
      }
      else{
        this.frameHeight = tabsFrame.offsetHeight;
        let contentHeight = tabsFrame.offsetHeight - tabsHeader.offsetHeight;
        tabContent.style.height=`${contentHeight}px`;
        if (this.pagerNode){
            this.pagerNode.height = contentHeight;
        }
        let children = tabContent.childNodes;
        let nIndex = 0;
        for(nIndex = 0;nIndex < children.length; nIndex++){
          children[nIndex].style.height=`${contentHeight}px`;
        }
      }
    }
    if (this.props.options.autoFit && this.props.onTabHeightChange){
      this.props.onTabHeightChange(this.frameHeight);
    }
  }
  componentDidUpdate(){
    this.updateTabsHeight();
  }
  componentDidMount() {
    this.updateTabsHeight();
  }

  getActiveIndex = (activeKey)=>{
    let tabs = this.props.tabs;
    let len = tabs.length;
    let nIndex = -1;
    for (nIndex = 0; nIndex < len; nIndex++){
      if (tabs[nIndex].key === (activeKey?activeKey:this.state.activeKey)){
          return nIndex;
      }
    };
    return 0;
  }

  getPages = ()=>{
    if (!this.pages){
        const {
          prefixCls,
        } = this.props;
        this.pages = [];
        let tabs = this.props.tabs;
        let nIndex = -1;
        let len = tabs.length;
        for (nIndex = 0; nIndex < len; nIndex++){
            this.pages.push(
                <TabContent
                    prefixCls = {prefixCls}
                    activeKey = {tabs[nIndex].key}
                    tabs = {tabs}
                    refParent = {this}
                    ref={this.saveContentRef(tabs[nIndex].key)}
                    key = {`tabContent${nIndex}`}
                    options = {this.props.options}
                    children = {this.props.children}
                />
            );
        }
    }
    return (
        <Pager
            style = {{height:'100%'}}
            ref = {(node) => {this.pagerNode = node;}}
            pages = {this.pages}
            preloadSiblings = {0}
            activeIndex = {this.getActiveIndex}
            onChange = {this.onPagerChanged}
            onLayout = {this.onPagesLayout}
        />
    );
  }

  render() {
    const props = this.props;
    const {
      prefixCls,
      className,
      style,
    } = props;
    const cls = classnames({
      [prefixCls]: 1,
      [className]: !!className,
    });
    let tabBar = React.cloneElement(<TabBar/>, {
      prefixCls,
      activeKey: this.state.activeKey,
      tabs:props.tabs,
      refParent:this,
      key: 'tabBar',
      options: props.options,
      onKeyDown: this.onNavKeyDown,
      onTabClick: this.onTabClick,
    });
    let tabContent;
    if (this.props.bSlide){
        tabContent = this.getPages();
    } else{
        tabContent = (<TabContent
            prefixCls = {prefixCls}
            activeKey = {this.state.activeKey}
            tabs = {props.tabs}
            refParent = {this}
            cache={this.props.cacheContent}
            ref = {this.saveContentRef(this.state.activeKey)}
            key = {`tabContent`}
            options = {props.options}
            children = {props.children}
            onChange = {this.setActiveKey}
        />);
    }
    return (
      <div className={cls} style={style} ref={this.saveRef('tabs-frame')}>
        {tabBar}
        {tabContent}
      </div>
    );
  }
}

Tabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  onTabHeightChange: PropTypes.func,
  options: PropTypes.object,
  prefixCls: PropTypes.string,
  className: PropTypes.string,
  activeKey: PropTypes.string,
  defaultActiveKey: PropTypes.string,
  children: PropTypes.any,
};

Tabs.defaultProps = {
  prefixCls: 'lj-tabs',
  onChange: noop,
};
