/* eslint-disable no-prototype-builtins */
/* eslint-disable no-use-before-define */
var toString = Object.prototype.toString;

/**
 * Check whether the given variable is existing or not.<br>
 *  If the given variable is not null and not undefined, returns true.
 * @param {*} param - Target for checking
 * @returns {boolean} Is existy?
 * @memberof tui.util
 * @example
 * //-- #1. Get Module --//
 * var util = require('tui-code-snippet'); // node, commonjs
 * var util = tui.util; // distribution file
 *
 * //-- #2. Use property --//
 * util.isExisty(''); //true
 * util.isExisty(0); //true
 * util.isExisty([]); //true
 * util.isExisty({}); //true
 * util.isExisty(null); //false
 * util.isExisty(undefined); //false
 */
function isExisty(param) {
  return !isUndefined(param) && !isNull(param);
}

/**
 * Check whether the given variable is undefined or not.<br>
 *  If the given variable is undefined, returns true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is undefined?
 * @memberof tui.util
 */
function isUndefined(obj) {
  return obj === undefined; // eslint-disable-line no-undefined
}

/**
 * Check whether the given variable is null or not.<br>
 *  If the given variable(arguments[0]) is null, returns true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is null?
 * @memberof tui.util
 */
function isNull(obj) {
  return obj === null;
}

/**
 * Check whether the given variable is truthy or not.<br>
 *  If the given variable is not null or not undefined or not false, returns true.<br>
 *  (It regards 0 as true)
 * @param {*} obj - Target for checking
 * @returns {boolean} Is truthy?
 * @memberof tui.util
 */
function isTruthy(obj) {
  return isExisty(obj) && obj !== false;
}

/**
 * Check whether the given variable is falsy or not.<br>
 *  If the given variable is null or undefined or false, returns true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is falsy?
 * @memberof tui.util
 */
function isFalsy(obj) {
  return !isTruthy(obj);
}

/**
 * Check whether the given variable is an arguments object or not.<br>
 *  If the given variable is an arguments object, return true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is arguments?
 * @memberof tui.util
 */
function isArguments(obj) {
  var result = isExisty(obj) && (toString.call(obj) === '[object Arguments]' || !!obj.callee);

  return result;
}

/**
 * Check whether the given variable is an instance of Array or not.<br>
 *  If the given variable is an instance of Array, return true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is array instance?
 * @memberof tui.util
 */
function isArray(obj) {
  return obj instanceof Array;
}

/**
 * Check whether the given variable is an object or not.<br>
 *  If the given variable is an object, return true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is object?
 * @memberof tui.util
 */
function isObject(obj) {
  return obj === Object(obj);
}

/**
 * Check whether the given variable is a function or not.<br>
 *  If the given variable is a function, return true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is function?
 * @memberof tui.util
 */
function isFunction(obj) {
  return obj instanceof Function;
}

/**
 * Check whether the given variable is a number or not.<br>
 *  If the given variable is a number, return true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is number?
 * @memberof tui.util
 */
function isNumber(obj) {
  return typeof obj === 'number' || obj instanceof Number;
}

/**
 * Check whether the given variable is a string or not.<br>
 *  If the given variable is a string, return true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is string?
 * @memberof tui.util
 */
function isString(obj) {
  return typeof obj === 'string' || obj instanceof String;
}

/**
 * Check whether the given variable is a boolean or not.<br>
 *  If the given variable is a boolean, return true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is boolean?
 * @memberof tui.util
 */
function isBoolean(obj) {
  return typeof obj === 'boolean' || obj instanceof Boolean;
}

/**
 * Check whether the given variable is an instance of Array or not.<br>
 *  If the given variable is an instance of Array, return true.<br>
 *  (It is used for multiple frame environments)
 * @param {*} obj - Target for checking
 * @returns {boolean} Is an instance of array?
 * @memberof tui.util
 */
function isArraySafe(obj) {
  return toString.call(obj) === '[object Array]';
}

/**
 * Check whether the given variable is a function or not.<br>
 *  If the given variable is a function, return true.<br>
 *  (It is used for multiple frame environments)
 * @param {*} obj - Target for checking
 * @returns {boolean} Is a function?
 * @memberof tui.util
 */
function isFunctionSafe(obj) {
  return toString.call(obj) === '[object Function]';
}

/**
 * Check whether the given variable is a number or not.<br>
 *  If the given variable is a number, return true.<br>
 *  (It is used for multiple frame environments)
 * @param {*} obj - Target for checking
 * @returns {boolean} Is a number?
 * @memberof tui.util
 */
function isNumberSafe(obj) {
  return toString.call(obj) === '[object Number]';
}

/**
 * Check whether the given variable is a string or not.<br>
 *  If the given variable is a string, return true.<br>
 *  (It is used for multiple frame environments)
 * @param {*} obj - Target for checking
 * @returns {boolean} Is a string?
 * @memberof tui.util
 */
function isStringSafe(obj) {
  return toString.call(obj) === '[object String]';
}

/**
 * Check whether the given variable is a boolean or not.<br>
 *  If the given variable is a boolean, return true.<br>
 *  (It is used for multiple frame environments)
 * @param {*} obj - Target for checking
 * @returns {boolean} Is a boolean?
 * @memberof tui.util
 */
function isBooleanSafe(obj) {
  return toString.call(obj) === '[object Boolean]';
}

/**
 * Check whether the given variable is a instance of HTMLNode or not.<br>
 *  If the given variables is a instance of HTMLNode, return true.
 * @param {*} html - Target for checking
 * @returns {boolean} Is HTMLNode ?
 * @memberof tui.util
 */
function isHTMLNode(html) {
  if (typeof HTMLElement === 'object') {
    return html && (html instanceof HTMLElement || !!html.nodeType);
  }

  return !!(html && html.nodeType);
}

/**
 * Check whether the given variable is a HTML tag or not.<br>
 *  If the given variables is a HTML tag, return true.
 * @param {*} html - Target for checking
 * @returns {Boolean} Is HTML tag?
 * @memberof tui.util
 */
function isHTMLTag(html) {
  if (typeof HTMLElement === 'object') {
    return html && html instanceof HTMLElement;
  }

  return !!(html && html.nodeType && html.nodeType === 1);
}

/**
 * Check whether the given variable is empty(null, undefined, or empty array, empty object) or not.<br>
 *  If the given variables is empty, return true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is empty?
 * @memberof tui.util
 */
function isEmpty(obj) {
  if (!isExisty(obj) || _isEmptyString(obj)) {
    return true;
  }

  if (isArray(obj) || isArguments(obj)) {
    return obj.length === 0;
  }

  if (isObject(obj) && !isFunction(obj)) {
    return !_hasOwnProperty(obj);
  }

  return true;
}

/**
 * Check whether given argument is empty string
 * @param {*} obj - Target for checking
 * @returns {boolean} whether given argument is empty string
 * @memberof tui.util
 * @private
 */
function _isEmptyString(obj) {
  return isString(obj) && obj === '';
}

/**
 * Check whether given argument has own property
 * @param {Object} obj - Target for checking
 * @returns {boolean} - whether given argument has own property
 * @memberof tui.util
 * @private
 */
function _hasOwnProperty(obj) {
  var key;
  for (key in obj) {
    if (obj.hasOwnProperty(key)) {
      return true;
    }
  }

  return false;
}

/**
 * Check whether the given variable is not empty
 * (not null, not undefined, or not empty array, not empty object) or not.<br>
 *  If the given variables is not empty, return true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is not empty?
 * @memberof tui.util
 */
function isNotEmpty(obj) {
  return !isEmpty(obj);
}

/**
 * Check whether the given variable is an instance of Date or not.<br>
 *  If the given variables is an instance of Date, return true.
 * @param {*} obj - Target for checking
 * @returns {boolean} Is an instance of Date?
 * @memberof tui.util
 */
function isDate(obj) {
  return obj instanceof Date;
}

/**
 * Check whether the given variable is an instance of Date or not.<br>
 *  If the given variables is an instance of Date, return true.<br>
 *  (It is used for multiple frame environments)
 * @param {*} obj - Target for checking
 * @returns {boolean} Is an instance of Date?
 * @memberof tui.util
 */
function isDateSafe(obj) {
  return toString.call(obj) === '[object Date]';
}

/*****************************************************************************/

/**
 * @author NHN Ent. FE Development Team <dl_javascript@nhn.com>
 * @fileoverview Util
 */
const FLOATING_POINT_DIGIT = 2;
const { min, max } = Math;

var lastId = 0;

// eslint-disable-next-line no-useless-escape
const regex = /^data:([a-z]+\/[a-z0-9-+.]+(;[a-z0-9-.!#$%*+.{}|~`]+=[a-z0-9-.!#$%*+.{}|~`]+)*)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*?)$/i;

export default {
  isExisty: isExisty,
  isUndefined: isUndefined,
  isNull: isNull,
  isTruthy: isTruthy,
  isFalsy: isFalsy,
  isArguments: isArguments,
  isArray: isArray,
  isArraySafe: isArraySafe,
  isObject: isObject,
  isFunction: isFunction,
  isFunctionSafe: isFunctionSafe,
  isNumber: isNumber,
  isNumberSafe: isNumberSafe,
  isDate: isDate,
  isDateSafe: isDateSafe,
  isString: isString,
  isStringSafe: isStringSafe,
  isBoolean: isBoolean,
  isBooleanSafe: isBooleanSafe,
  isHTMLNode: isHTMLNode,
  isHTMLTag: isHTMLTag,
  isEmpty: isEmpty,
  isNotEmpty: isNotEmpty,

  forEach(obj, iteratee, context) {
    if (obj instanceof Array) {
      this.forEachArray(obj, iteratee, context);
    } else {
      this.forEachOwnProperties(obj, iteratee, context);
    }
  },
  forEachArray(arr, iteratee, context) {
    var index = 0;
    var len = arr.length;

    context = context || null;

    for (; index < len; index += 1) {
      if (iteratee.call(context, arr[index], index, arr) === false) {
        break;
      }
    }
  },
  forEachOwnProperties(obj, iteratee, context) {
    var key;

    context = context || null;

    for (key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (iteratee.call(context, obj[key], key, obj) === false) {
          break;
        }
      }
    }
  },
  /**
   * Clamp value
   * @param {number} value - Value
   * @param {number} minValue - Minimum value
   * @param {number} maxValue - Maximum value
   * @returns {number} clamped value
   */
  clamp(value, minValue, maxValue) {
    let temp;
    if (minValue > maxValue) {
      temp = minValue;
      minValue = maxValue;
      maxValue = temp;
    }

    return max(minValue, min(value, maxValue));
  },

  /**
   * Make key-value object from arguments
   * @returns {object.<string, string>}
   */
  keyMirror(...args) {
    const obj = {};

    this.forEach(args, key => {
      obj[key] = key;
    });

    return obj;
  },

  /**
   * Make CSSText
   * @param {Object} styleObj - Style info object
   * @returns {string} Connected string of style
   */
  makeStyleText(styleObj) {
    let styleStr = '';

    this.forEach(styleObj, (value, prop) => {
      styleStr += `${prop}: ${value};`;
    });

    return styleStr;
  },

  /**
   * Get object's properties
   * @param {Object} obj - object
   * @param {Array} keys - keys
   * @returns {Object} properties object
   */
  getProperties(obj, keys) {
    const props = {};
    const { length } = keys;
    let i = 0;
    let key;

    for (i = 0; i < length; i += 1) {
      key = keys[i];
      props[key] = obj[key];
    }

    return props;
  },

  /**
   * ParseInt simpliment
   * @param {number} value - Value
   * @returns {number}
   */
  toInteger(value) {
    return parseInt(value, 10);
  },

  /**
   * String to camelcase string
   * @param {string} targetString - change target
   * @returns {string}
   * @private
   */
  toCamelCase(targetString) {
    return targetString.replace(/-([a-z])/g, ($0, $1) => $1.toUpperCase());
  },

  /**
   * Check browser file api support
   * @returns {boolean}
   * @private
   */
  isSupportFileApi() {
    return !!(window.File && window.FileList && window.FileReader);
  },

  /**
   * hex to rgb
   * @param {string} color - hex color
   * @param {string} alpha - color alpha value
   * @returns {string} rgb expression
   */
  getRgb(color, alpha) {
    if (color.length === 4) {
      color = `${color}${color.slice(1, 4)}`;
    }
    const r = parseInt(color.slice(1, 3), 16);
    const g = parseInt(color.slice(3, 5), 16);
    const b = parseInt(color.slice(5, 7), 16);
    const a = alpha || 1;

    return `rgba(${r}, ${g}, ${b}, ${a})`;
  },

  /**
   * Apply css resource
   * @param {string} styleBuffer - serialized css text
   * @param {string} tagId - style tag id
   */
  styleLoad(styleBuffer, tagId) {
    const [head] = document.getElementsByTagName('head');
    const linkElement = document.createElement('link');
    const styleData = encodeURIComponent(styleBuffer);
    if (tagId) {
      linkElement.id = tagId;
      // linkElement.id = 'tui-image-editor-theme-style';
    }
    linkElement.setAttribute('rel', 'stylesheet');
    linkElement.setAttribute('type', 'text/css');
    linkElement.setAttribute('href', `data:text/css;charset=UTF-8,${styleData}`);
    head.appendChild(linkElement);
  },

  /**
   * Get selector
   * @param {HTMLElement} targetElement - target element
   * @returns {Function} selector
   */
  getSelector(targetElement) {
    return str => targetElement.querySelector(str);
  },

  /**
   * Change base64 to blob
   * @param {String} data - base64 string data
   * @returns {Blob} Blob Data
   */
  base64ToBlob(data) {
    const rImageType = /data:(image\/.+);base64,/;
    let mimeString = '';
    let raw, uInt8Array, i;

    raw = data.replace(rImageType, (header, imageType) => {
      mimeString = imageType;

      return '';
    });

    raw = atob(raw);
    const rawLength = raw.length;
    uInt8Array = new Uint8Array(rawLength); // eslint-disable-line

    for (i = 0; i < rawLength; i += 1) {
      uInt8Array[i] = raw.charCodeAt(i);
    }

    return new Blob([uInt8Array], { type: mimeString });
  },

  /**
   * Fix floating point diff.
   * @param {number} value - original value
   * @returns {number} fixed value
   */
  fixFloatingPoint(value) {
    return Number(value.toFixed(FLOATING_POINT_DIGIT));
  },

  /**
   * Assignment for destroying objects.
   * @param {Object} targetObject - object to be removed.
   */
  assignmentForDestroy(targetObject) {
    this.forEach(targetObject, (value, key) => {
      targetObject[key] = null;
    });
  },
  /**
   * Assign a unique id to an object
   * @param {object} obj - Object that will be assigned id.
   * @returns {number} Stamped id
   * @memberof tui.util
   */
  stamp(obj) {
    if (!obj.__fe_id) {
      lastId += 1;
      obj.__fe_id = lastId; // eslint-disable-line camelcase
    }

    return obj.__fe_id;
  },
  /**
   * Extend the target object from other objects.
   * @param {object} target - Object that will be extended
   * @param {...object} objects - Objects as sources
   * @returns {object} Extended object
   * @memberof tui.util
   */
  // eslint-disable-next-line no-unused-vars
  extend(target, objects) {
    var hasOwnProp = Object.prototype.hasOwnProperty;
    var source, prop, i, len;

    for (i = 1, len = arguments.length; i < len; i += 1) {
      source = arguments[i];
      for (prop in source) {
        if (hasOwnProp.call(source, prop)) {
          target[prop] = source[prop];
        }
      }
    }

    return target;
  },

  validDataURL(s) {
    return regex.test((s || '').trim());
  },
};
