import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './style/index.less';

export default class WingBlank extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
    };

    static defaultProps = {
        prefixCls: 'tm-wingblank',
        size: 'lg',
    };

    render() {
        const { 
            prefixCls, 
            size, 
            className, 
            children, 
            style 
        } = this.props;
        
        const wrapCls = classnames(prefixCls, `${prefixCls}-${size}`, className);

        return (
            <div className={wrapCls} style={style}>
                {children}
            </div>
        );
    }
}
