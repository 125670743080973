import React from 'react';

export default class StaticView extends React.Component {
    shouldComponentUpdate(nextProps) {
        return nextProps.shouldUpdate;
    }

    render() {
        return (
            <div>
                {this.props.render()}
            </div>
        );
    }
}