import invariant from 'fbjs/lib/invariant';

export default class DataSource {
    props = {};
    columns = {};
    count = 0;

    static Threshold = 3;

    constructor(props) {
        invariant(
            props && 
            typeof props.getData === 'function' && 
            typeof props.shouldSyncColumn === 'function' && 
            typeof props.getCount === 'function' &&
            typeof props.hasMore === 'function', 
            'Must provide getData, getCount and shouldSyncColumn function'
        );

        this.props.getData = props.getData;
        this.props.hasMore = props.hasMore;
        this.props.shouldSyncColumn = props.shouldSyncColumn;
        this.count = props.getCount();

        invariant(this.count > 0, 'Column count MUST great than zero');
    }

    reset = () => {
        this.columns = {};
    }

    getData = (column, index, forceUpdate) => {
        let data = this.columns[column];
        if (!forceUpdate) {
            let length = 0;
            if (data) {
                if (data.source) length = data.source.length;
                // Do Not request new data, if index did not reach edge of current data
                if ((index >= DataSource.Threshold && (length - index - 1) >= DataSource.Threshold) || !this.hasMore(column, index > DataSource.Threshold)) {
                    return data;
                }
            }
        }

        if (index === undefined) {
            index = -1;
        }
        
        data = this.props.getData(column, index);
        if (!data) return {source: [], index: 0};

        let newData = {
            source: data.source,
            index: data.index
        };
        this.columns[column] = newData;
        return newData;
    }

    shouldSyncColumn = (column, sibling) => {
        return this.props.shouldSyncColumn(column, sibling);
    }

    shouldCycling = column => {
        const { shouldCycling } = this.props;

        if (shouldCycling) {
            return shouldCycling(column);
        }

        return false;
    }

    hasMore = (column, forward) => {
        const { hasMore } = this.props;

        if (hasMore) {
            return hasMore(column, forward);
        }

        return false;
    }

    setIndex(column, index) {
        this.columns[column] && (this.columns[column].index = index);
    }
    index(column) {
        if (this.columns[column]) {
            return this.columns[column].index;
        }

        return -1;
    }

    data = column => {
        if (this.columns[column]) {
            return this.columns[column].source[this.columns[column].index];
        }
        return null;
    }

    column = () => {
        return this.count;
    }
}