import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import View from '../view';

import './style/index.less';

export default class FileLoader extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        contentType: PropTypes.oneOf(['text', 'binary', 'data-url']),
        onChange: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-fileloader',
        contentType: 'text',
    }

    onChange = () => {
        if (this.input.files.length === 0) return;
        
        const file = this.input.files[0];
        let reader = new FileReader();
        reader.onloadend = () => {
            // console.log(reader.result);
            this.props.onChange && this.props.onChange(reader.result);
        };

        if (this.props.contentType === 'text') {
            reader.readAsText(file);
        } else if (this.props.contentType === 'binary') {
            reader.readAsBinaryString(file);
        } else if (this.props.contentType === 'data-url') {
            reader.readAsDataURL(file);
        }
    }

    render() {
        const {
            prefixCls,
            className,
        } = this.props;

        return (
            <View className={classnames(`${prefixCls}`, className)} >
                <input ref={el => this.input = el} type='file' onChange={this.onChange} />
            </View>
        );
    }
}