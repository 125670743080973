import fabric from '../lib/fabric';
import Component from '../interface/component';
import consts from '../lib/consts';
import util from '../lib/util';

const events = consts.eventNames;
const { rejectMessages } = consts;

const pathMap = {
  right:
    'M49.7,17.6L21.8,45.5L6.3,30c-1.7-1.7-1.7-4.2,0-5.9c1.7-1.7,4.2-1.7,5.9,0l9.5,9.7l22.2-22.1 ' +
    'c1.7-1.7,4.2-1.7,5.9,0C51.4,13.4,51.4,15.9,49.7,17.6L49.7,17.6z',
  wrong:
    'M45.3,37.5L45.3,37.5L33.8,26l5.9-5.9l5-4.9c1.6-1.7,1.6-4.3-0.1-5.9c-1.4-1.4-3.5-1.6-5.1-0.6 ' +
    'c0,0-0.1,0-0.1,0.1L28,20.1l-5.8-5.8l-4.9-4.9c-1.7-1.7-4.2-1.7-5.9,0c-1.4,1.4-1.6,3.5-0.6,5.1c0,0,0,0.1,0.1,0.1l11.3,11.3 ' +
    'l-5.9,5.9l-4.9,4.9c-1.7,1.7-1.7,4.2,0,5.9c1.4,1.4,3.5,1.6,5.1,0.6h0.1L28,31.8l6,6l5,5c1.5,1.6,4,1.6,5.7-0.1 ' +
    ' C46.1,41.2,46.3,39.1,45.3,37.5z'
};

/**
 * Icon
 * @class Icon
 * @param {Graphics} graphics - Graphics instance
 * @extends {Component}
 * @ignore
 */
class Icon extends Component {
  constructor(graphics) {
    super(consts.componentNames.ICON, graphics);

    /**
     * Default icon color
     * @type {string}
     */
    this._oColor = '#000000';

    /**
     * Default icon color
     * @type {string}
     */
    this._oScale = 1;

    /**
     * Path value of each icon type
     * @type {Object}
     */
    this._pathMap = pathMap;

    /**
     * Option to add icon to drag.
     * @type {boolean}
     */
    this.useDragAddIcon = graphics.useDragAddIcon;
  }

  /**
   * Start icon mode
   */
  start() {}

  /**
   * End icon mode
   */
  end() {}

  /**
   * Add icon
   * @param {string} type - Icon type
   * @param {Object} options - Icon options
   *      @param {string} [options.fill] - Icon foreground color
   *      @param {string} [options.left] - Icon x position
   *      @param {string} [options.top] - Icon y position
   * @returns {Promise}
   */
  add(type, options) {
    return new Promise((resolve, reject) => {
      const canvas = this.getCanvas();
      const path = this._pathMap[type];
      const selectionStyle = consts.fObjectOptions.SELECTION_STYLE;
      const registerdIcon = Object.keys(consts.defaultIconPath).indexOf(type) >= 0;
      const useDragAddIcon = this.useDragAddIcon && registerdIcon;
      const icon = path ? this._createIcon(path) : null;

      if (!icon) {
        reject(rejectMessages.invalidParameters);
      }

      if (options.color) {
        this._oColor = options.color;
      }

      if (options.scale) {
        this._oScale = options.scale;
      }

      icon.set(
        util.extend(
          {
            type: 'icon',
            // lockUniScaling: true,
            fill: this._oColor,
            scaleX: this._oScale,
            scaleY: this._oScale
          },
          selectionStyle,
          options,
          this.graphics.controlStyle
        )
      );

      canvas.add(icon).setActiveObject(icon);

      if (useDragAddIcon) {
        this._addWithDragEvent(canvas);
      }

      resolve(this.graphics.createObjectProperties(icon));
    });
  }

  /**
   * Added icon drag event
   * @param {fabric.Canvas} canvas - Canvas instance
   * @private
   */
  _addWithDragEvent(canvas) {
    canvas.on({
      'mouse:move': fEvent => {
        canvas.selection = false;

        this.fire(events.ICON_CREATE_RESIZE, {
          moveOriginPointer: canvas.getPointer(fEvent.e)
        });
      },
      'mouse:up': fEvent => {
        this.fire(events.ICON_CREATE_END, {
          moveOriginPointer: canvas.getPointer(fEvent.e)
        });

        canvas.defaultCursor = 'default';
        canvas.off('mouse:up');
        canvas.off('mouse:move');
        canvas.selection = true;
      }
    });
  }

  /**
   * Register icon paths
   * @param {{key: string, value: string}} pathInfos - Path infos
   */
  registerPaths(pathInfos) {
    util.forEach(
      pathInfos,
      (path, type) => {
        this._pathMap[type] = path;
      },
      this
    );
  }

  /**
   * Set default icon object color
   * @param {string} color - Color to set
   */
  setDefaultColor(color) {
    this._oColor = color;
  }

  /**
   * Set icon object color
   * @param {string} color - Color to set
   * @param {fabric.Path}[obj] - Current activated path object
   */
  setColor(color, obj) {
    console.log('Icon -> setColor -> obj', obj);
    this._oColor = color;

    if (obj && obj.get('type') === 'icon') {
      obj.set({ fill: this._oColor });
      this.getCanvas().renderAll();
    }
  }

  /**
   * Get icon color
   * @param {fabric.Path}[obj] - Current activated path object
   * @returns {string} color
   */
  getColor(obj) {
    return obj.fill;
  }

  /**
   * Set style
   * @param {Object} activeObj - Current selected text object
   * @param {Object} styleObj - Initial styles
   *     @param {string} [styleObj.fill] Color
   *     @param {string} [styleObj.scale] Font type for text
   * @returns {Promise}
   */
  setStyle(activeObj, styleObj) {
    return new Promise(resolve => {
      activeObj.set(styleObj);
      this.getCanvas().renderAll();
      resolve();
    });
  }

  /**
   * Create icon object
   * @param {string} path - Path value to create icon
   * @returns {fabric.Path} Path object
   */
  _createIcon(path) {
    return new fabric.Path(path);
  }
}

export default Icon;
