import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import PlayToggle from './PlayToggle';
import ProgressBar from './ProgressBar';
import FullscreenToggle from './FullscreenToggle';
import { Log, Platform } from '../util';
import Icon from '../icon';

import './style/control.less';

export default class Control extends React.Component {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        duration: PropTypes.number,
        currentTime: PropTypes.number,
        onPlay: PropTypes.func,
        onFullscreen: PropTypes.func,
        onSeek: PropTypes.func,
        paused: PropTypes.bool,
        fullscreen: PropTypes.bool,
        rotate: PropTypes.bool,
        enableDownload: PropTypes.bool, 
        onDownload: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-video',
        onPlay: () => {},
        onFullscreen: () => {},
        onSeek: () => {},
        paused: true, 
        fullscreen: false,
        rotate: false,
        duration: 0,
        currentTime: 0,
        enableDownload: false,
    }

    control = null;
    animation = 'active';
    constructor(props) {
        super(props);
        this.state = {
            animation: false,
            visible: true,
        }
    }

    componentDidMount() {
        if (this.control) {
            Log.verbose('add animation listener');
            this.control.addEventListener('animationend', this.onAnimationEnd);
            this.control.addEventListener('webkitAnimationEnd', this.onAnimationEnd);
        }
    }

    componentWillUnmount() {
        if (this.control) {
            this.control.removeEventListener('animationend', this.onAnimationEnd);
            this.control.removeEventListener('webkitAnimationEnd', this.onAnimationEnd);
        }
    }

    set visible(visible) {
        if (visible === this.state.visible) {
            return;
        }

        this.animation = visible ? 'enter' : 'leave';
        this.setState({
            visible: visible,
            animation: !Platform.isIPhone() || visible, //禁止苹果的隐藏动画;
        });
    }
    get visible() {
        return this.state.visible;
    }

    onAnimationEnd = e => {
        if (e.animationName === 'active' || e.animationName === 'deactive') {
            this.setState({
                animation: false
            });
        }
    }

    render() {
        let {
            prefixCls,
            // className,
            onPlay,
            onFullscreen,
            onSeek,
            paused,
            // fullscreen,
            currentTime,
            duration,
            rotate,
            enableDownload,
            onDownload
        } = this.props;

        const controlState = this.state.visible ? 'active' : 'deactive';
        const controlCls = classnames(`${prefixCls}-control`, {
            [`${prefixCls}-control-${controlState}`]: !this.state.animation,
            [`${prefixCls}-control-${this.animation}`]: this.state.animation
        });

        return (<div className={`${prefixCls}-control-panel`}> 
            <div>
                <div className={controlCls} ref={el => this.control = el}>
                    <PlayToggle 
                        paused={paused}
                        onPlay={() => onPlay()} />
                    <FullscreenToggle onFullscreen={onFullscreen} />
                    <ProgressBar 
                        currentTime={currentTime}
                        duration={duration}
                        onSeek={onSeek}
                        rotate={rotate}
                    />
                </div>
                {enableDownload && <div className={classnames(`${prefixCls}-control-download ${controlState}`, {[this.animation]: this.state.animation})}>
                    <div className='tm-icon-md' onClick={onDownload}><Icon type='download' size='md'  color='white'/></div>
                </div>}
            </div>
        </div>);
    }
}