/* eslint-disable no-unused-vars */
import Component from '../interface/component';
import consts from '../lib/consts';

const events = consts.eventNames;

/**
 * Normal
 * @class Deletion
 * @ignore
 */
class Normal extends Component {
  constructor(graphics) {
    super(consts.componentNames.NORMAL, graphics);

    /**
     * is mouse down
     * @type {Object}
     */
    this._isMouseDown = false;
    /**
     * origin pointer
     * @type {Object}
     */
    this._originPointer = null;

    /**
     * Listeners for fabric event
     * @type {Object}
     */
    this._listeners = {};
  }

  /**
   * Start normal mode
   */
  start() {
    const canvas = this.getCanvas();
  }

  /**
   * End normal mode
   */
  end() {
    const canvas = this.getCanvas();
  }
}

export default Normal;
