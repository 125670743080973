import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './style/index.less';

export default class WhiteSpace extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-whitespace',
        size: 'md',
    };

    render() {
        const { 
            prefixCls, 
            size, 
            className, 
            style, 
            onClick } = this.props;

        const wrapCls = classnames(prefixCls, `${prefixCls}-${size}`, className);

        return <div className={wrapCls} style={style} onClick={onClick} />;
    }
}