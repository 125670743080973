import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view';

import './style/index.less';

export default class TouchBoard extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        onChange: PropTypes.func,
        onStroke: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-touchboard',
    };

    drawing = false;
    points = [];
    area = {
        tl: {
            x: 9999,
            y: 9999,
        },
        br: {
            x: 0,
            y: 0,
        }
    };

    componentDidMount() {
        if (this.canvas && this.touchboard) {
            const width = this.touchboard.clientWidth;
            const height = this.touchboard.clientHeight;
            this.canvas.width = width;
            this.canvas.height = height;
            this.canvas.style.width = width + 'px';
            this.canvas.style.height = height + 'px';
            this.canvas.width = width * window.devicePixelRatio;
            this.canvas.height = height * window.devicePixelRatio;

            this.context = this.canvas.getContext('2d');
            this.context.strokeStyle = '#333';
            this.context.lineCap = 'round';
            this.context.lineJoin = 'round';
            this.context.lineWidth = 6 * window.devicePixelRatio;
        }
    }

    clear = () => {
        if (this.context && this.canvas) {
            this.context.clearRect(0, 0, this.canvas.width * window.devicePixelRatio, this.canvas.height * window.devicePixelRatio);
        }
        this.area = {
            tl: {
                x: 9999,
                y: 9999,
            },
            br: {
                x: 0,
                y: 0,
            }
        };
    }

    calibrateArea = (x, y) => {
        if (this.area.tl.x > x) {
            this.area.tl.x = x;
        }
        if (this.area.tl.y > y) {
            this.area.tl.y = y;
        }
        if (this.area.br.x < x) {
            this.area.br.x = x;
        }
        if (this.area.br.y < y) {
            this.area.br.y = y;
        }
    }

    clipArea = () => {
        let x = (this.area.tl.x - 4) * window.devicePixelRatio;
        let y = (this.area.tl.y - 4) * window.devicePixelRatio;
        const width = (this.area.br.x - this.area.tl.x + 4) * window.devicePixelRatio;
        const height = (this.area.br.y - this.area.tl.y + 4) * window.devicePixelRatio;

        const max = Math.max(width, height);
        x = x - (max - width) / 2;
        y = y - (max - height) / 2;
        x = x < 0 ? 0 : x;
        y = y < 0 ? 0 : y;

        const canvas = document.createElement('canvas');
        canvas.width = 64;
        canvas.height = 64;
        const context = canvas.getContext('2d');
        context.drawImage(this.canvas, x, y, max, max, 0, 0, canvas.width, canvas.height);
        const image = canvas.toDataURL('image/png');

        return image;
    }

    onTouchBegin = e => {
        this.drawing = true;
        this.context.save();
        const touches = e.changedTouches ? e.changedTouches[0] : e;
        this.context.beginPath();
        this.context.moveTo(touches.clientX * window.devicePixelRatio, touches.clientY * window.devicePixelRatio);
        this.points = [touches.clientX, touches.clientY];
        this.calibrateArea(touches.clientX, touches.clientY);

        // new stroke
        this.props.onStroke && this.props.onStroke();
    }

    onTouchMove = e => {
        if (!this.drawing) {
            return;
        }
        const touches = e.changedTouches ? e.changedTouches[0] : e;
        this.context.lineTo(touches.clientX * window.devicePixelRatio, touches.clientY * window.devicePixelRatio);
        this.context.stroke();
        const point = {
            x: this.points[this.points.length - 2],
            y: this.points[this.points.length - 1],
        };
        const xs = Math.abs(Math.floor((touches.clientX - point.x) / 72));
        const ys = Math.abs(Math.floor((touches.clientY - point.y) / 72));
        const count = xs > ys ? xs : ys;
        if (count) {
            const stepX = (touches.clientX - point.x) / count;
            const stepY = (touches.clientY - point.y) / count;
            for (let i = 0; i < count; i++) {
                this.points.push(point.x + stepX * i);
                this.points.push(point.y + stepY * i);
                // console.log("x: " + this.points[this.points.length - 2] + " y: " + this.points[this.points.length - 1]);
            }
        }

        this.points.push(touches.clientX);
        this.points.push(touches.clientY);
        this.calibrateArea(touches.clientX, touches.clientY);
        // console.log("x: " + touches.clientX + " y: " + touches.clientY);
    }

    onTouchEnd = e => {
        if (!this.drawing) {
            return;
        }
        this.drawing = false;
        const { onChange } = this.props;
        this.context.scale(window.devicePixelRatio, window.devicePixelRatio);
        this.context.restore();
        const image = this.clipArea();
        onChange && onChange(this.points, image);
    }

    render() {
        const {
            prefixCls,
            className,
            nodes,
        } = this.props;

        const wrapCls = classnames(prefixCls, className);

        return (
            <View 
                ref={el => this.touchboard = ReactDOM.findDOMNode(el)}
                className={wrapCls}
                onMouseDown={this.onTouchBegin}
                onMouseMove={this.onTouchMove}
                onMouseUp={this.onTouchEnd}
                onMouseLeave={this.onTouchEnd}
                onTouchStart={this.onTouchBegin}
                onTouchMove={this.onTouchMove}
                onTouchEnd={this.onTouchEnd}
                onTouchCancel={this.onTouchEnd}
            >
                <canvas 
                    ref={el => this.canvas = el}
                    className={`${prefixCls}-canvas`}
                />
                {nodes}
            </View>
        );
    }
}