import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './style/index.less';

export default class Switch extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        prefixCls: PropTypes.string,
        style: PropTypes.object,
        checked: PropTypes.bool,
        disable: PropTypes.bool,
        color: PropTypes.string,
        name: PropTypes.string,
        platform: PropTypes.string,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-switch',
        name: '',
        checked: false,
        disabled: false,
        platform: 'ios',
        onClick: e => {},
        onChange: e => {},
    };

    onChange = e => {
        const checked = e.target.checked;
        if (this.props.onChange) {
            this.props.onChange(checked);
        }
    }

    onClick = e => {
        if (this.props.onClick) {
            let val;
            if (e && e.target && e.target.checked !== undefined) {
                val = e.target.checked;
            } else {
                val = this.props.checked;
            }
            this.props.onClick(val);
        }
    }

    render() {
        const {
            prefixCls,
            name,
            checked,
            disabled,
            className,
            platform,
            color,
            ...restProps
        } = this.props;

        const wrapCls = classnames(prefixCls, className, {
            [`${prefixCls}-android`]: platform === 'android',
        });

        const fackInputCls = classnames('checkbox', {
            [`checkbox-disabled`]: disabled,
        });

        const globalProps = Object.keys(restProps).reduce((prev, key) => {
            if (
                key.substr(0, 5) === 'aria-' ||
                key.substr(0, 5) === 'data-' ||
                key === 'role'
            ) {
                prev[key] = restProps[key];
            }

            return prev;
        }, {});

        const style = this.props.style || {};
        if (color && checked) {
            style.backgroundColor = color;
        }

        return (
            <label className={wrapCls}>
                <input
                    type="checkbox"
                    name={name}
                    className={`${prefixCls}-checkbox`}
                    disabled={disabled}
                    checked={checked}
                    onChange={this.onChange}
                    value={checked ? 'on' : 'off'}
                    {...(!disabled ? { onClick: this.onClick } : {})}
                    {...globalProps}
                />
                <div
                    className={fackInputCls}
                    style={style}
                    {...(disabled ? { onClick: this.onClick } : {})}
                />
            </label>
        );
    }
}
