import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import loadSprite from './loadSprite';

import './style/index.less';

export default class Icon extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        prefixCls: PropTypes.string,
        type: PropTypes.string,
        color: PropTypes.string,
        size: PropTypes.oneOf(['xxs', 'xs', 'sm', 'md', 'lg']),
        activeColor: PropTypes.string,
        source: PropTypes.any,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-icon',
        size: 'md',
    };

    state = {
        active: false,
    }

    componentDidMount() {
        const { type } = this.props;
        if (type !== 'custom') {
            loadSprite();
        }
    }

    onTouchBegin = e => {
        this.setState({
            active: true,
        });
    }

    onTouchEnd = e => {
        this.setState({
            active: false,
        });
    }

    render() {
        const { 
            className, 
            prefixCls,
            style = {},
            type, 
            source,
            color,
            activeColor,
            size,
            onClick,
            ...restProps 
        } = this.props;
        
        const wrapCls = classnames(
            `${prefixCls}`,
            `${prefixCls}-${type}`,
            `${prefixCls}-${size}`,
            className,
        );

        const userInteract = !!activeColor;
        const svgColor = !!activeColor && this.state.active ? activeColor : color;
        const useSvg = (!!source && type === 'custom') ? <use xlinkHref={`#${source.default.id}`} /> : <use xlinkHref={`#${type}`} />;
        let svgStyle = {...style};
        if (!!svgColor) {
            svgStyle = {
                fill: svgColor,
                ...style
            };
        }

        return (
            <svg 
                className={wrapCls} 
                // color={svgColor}
                style={svgStyle}
                onClick={onClick}
                onTouchStart={userInteract ? this.onTouchBegin : undefined}
                onTouchEnd={userInteract ? this.onTouchEnd : undefined}
                onTouchCancel={userInteract ? this.onTouchEnd : undefined}
                onMouseDown={userInteract ? this.onTouchBegin : undefined}
                onMouseUp={userInteract ? this.onTouchEnd : undefined}
                {...restProps}
            >
                { useSvg }
            </svg>
        );
    }
}
