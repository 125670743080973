import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import invariant from 'fbjs/lib/invariant';
import Hls from 'hls.js';

import { Platform, Log } from '../util';
import View from '../view';
import Image from '../image';
import Icon from '../icon';
import Text from '../text';
// import Toast from '../toast';
import { Spinner } from '../indicator';
import Control from './Control';
import Preload from './Preload';
import Strings from '../strings';
import Audio from '../audio';
import { Event } from '../util';

import './style/video.less';

export default class Video extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        children: PropTypes.any,
        duration: PropTypes.number, // duration of audio file, in milisecond
        startTime: PropTypes.number,
        width: PropTypes.number,
        height: PropTypes.number,
        loop: PropTypes.bool,
        muted: PropTypes.bool,
        autoPlay: PropTypes.bool,
        playsInline: PropTypes.bool,
        poster: PropTypes.string,
        preload: PropTypes.oneOf(['auto', 'metadata', 'none']),
        source: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
        id: PropTypes.string,
        transcoding: PropTypes.bool,
        enableDownload: PropTypes.bool, 
        enableMonitor: PropTypes.bool,
        enableMuted: PropTypes.bool,

        onLoadStart: PropTypes.func,
        onWaiting: PropTypes.func,
        onCanPlay: PropTypes.func,
        onCanPlayThrough: PropTypes.func,
        onPlaying: PropTypes.func,
        onEnded: PropTypes.func,
        onSeeking: PropTypes.func,
        onSeeked: PropTypes.func,
        onPlay: PropTypes.func,
        onPause: PropTypes.func,
        onProgress: PropTypes.func,
        onDurationChange: PropTypes.func,
        onError: PropTypes.func,
        onSuspend: PropTypes.func,
        onAbort: PropTypes.func,
        onStalled: PropTypes.func,
        onLoadedData: PropTypes.func,
        onLoadedMetadata: PropTypes.func,
        onTimeUpdate: PropTypes.func,
        onRateChange: PropTypes.func,
        onVolumeChange: PropTypes.func,
        onResize: PropTypes.func,
        onFullscreen: PropTypes.func,
        onDownload: PropTypes.func,

        showControl: PropTypes.bool,

        // Render one frame as poster, and onPoster will be invoked
        // if props.poster is empty, a base64 image will be returned
        onPoster: PropTypes.func,

        audiotrack: PropTypes.arrayOf(PropTypes.number),
        extension: PropTypes.string,
    };

    static defaultProps = {
        prefixCls: 'tm-video',
        preload: 'metadata',
        startTime: 0,
        duration: 0,
        transcoding: false,
        audiotrack: [0],
        showControl: true,
        extension: '',
        enableDownload: false,
        enableMonitor: false,
        style: {},
    };

    static activeVideo = null;
    static stop = () => {
        if (Video.activeVideo) {
            Video.activeVideo.pause();
            Video.activeVideo = null;
        }
    }

    controlTimer = null;
    control = null;
    activeAudioTracks = [];

    constructor(props) {
        super(props);
        this.video = null;
        this.hls = null;
        this.sourceAttached = false;
        this.audiotrack = props.audiotrack;
        this.state = {
            paused: props.autoPlay !== 'true',
            fullscreen: false,
            duration: props.duration / 1000,
            currentTime: props.startTime,
            loading: false,
            preloading: false,
            showPoster: !props.autoPlay,
            poster: props.poster,
            source: props.source,
            transcoding: props.transcoding,
            key: Math.floor(Math.random() + 1000000)
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let state = {};
        if ('source' in nextProps && nextProps.source !== this.props.source) {
            state.source = nextProps.source;
        }

        if ('poster' in nextProps && nextProps.poster !== this.props.poster) {
            state.poster = nextProps.poster;
        }

        if ('duration' in nextProps && nextProps.duration !== this.props.duration) {
            state.duration = nextProps.duration / 1000;
        }

        if ('audiotrack' in nextProps && nextProps.audiotrack !== this.props.audiotrack) {
            this.audiotrack = nextProps.audiotrack;
        }

        if (!!state.source || !!state.poster || (!!state.duration && state.duration >= 0)) {
            this.setState(state);
        }
    }

    componentDidMount() {
        if (this.state.currentTime > 0) {
            this.currentTime = this.state.currentTime;
        }

        Event.subscribe('back', this.onBack, true);
        if (this.control && !this.props.showControl) {
            this.control.visible = false;
        }
    }

    componentWillUnmount() {
        Event.unsubscribe('back', this.onBack);
        this.exitFullscreen && this.exitFullscreen();
        this.pause();
        if (this.hls) {
            this.hls.destroy();
        }

        if (this.controlTimer) {
            clearTimeout(this.controlTimer);
        }
    }

    onBack = () => {
        if (this.state.fullscreen) {
            this.switchFullscreen();
            return true;
        }

        return false;
    }

    set audiotrack(tracks) {
        if (typeof tracks === 'number') {
            this.activeAudioTracks = [tracks];
        } else if (Object.prototype.toString.call(tracks) === '[object Array]') {
            this.activeAudioTracks = tracks;
        } else {
            invariant(typeof tracks === 'number' || Object.prototype.toString.call(tracks) === '[object Array]', 'audiotracks MUST be a number or array of number');
        }

        if (this.video === null) return;

        let trackSet = new Set(this.activeAudioTracks);
        if (!this.video.audioTracks) return;

        for (let i = 0; i < this.video.audioTracks.length; i++) {
            this.video.audioTracks[i].enabled = trackSet.has(i);
        }
    }

    get audiotrack() {
        return this.activeAudioTracks;
    }

    set source(source) {
        if (source !== this.state.source) {
            this.setState({
                // preloading: true,
                source: source,
            });
        }
    }
    get source() {
        return this.state.source;
    }

    set poster(poster) {
        if (poster !== this.state.poster) {
            this.setState({
                poster
            });
        }
    }
    get poster() {
        return this.state.poster;
    }

    get playbackRate() {
        return this.video.playbackRate;
    }
    set playbackRate(rate) {
        this.video.playbackRate = rate;
    }
    get muted() {
        return this.video.muted;
    }
    set muted(val) {
        this.video.muted = val;
    }
    get volume() {
        return this.video.volume;
    }
    set volume(val) {
        if (val > 1) val = 1;
        if (val < 0) val = 0;
        this.video.volume = val;
    }
    get videoWidth() {
        return this.video.videoWidth;
    }
    get videoHeight() {
        return this.video.videoHeight;
    }

    set transcoding(transcoding) {
        if (this.state.transcode === transcoding) {
            return;
        }
        this.setState({
            transcoding: transcoding,
        });
    }
    get transcoding() {
        return this.state.transcoding;
    }

    get paused() {
        return this.state.paused;
    }
    get currentTime() {
        return this.state.currentTime;
    }

    preload = () => {
        this.setState({
            preloading: true,
        }, () => {
            Preload.load(this.source, this.onPreload);
        });
    }

    play() {
        if (!this.video || !this.source) {
            return;
        }

        if (!Platform.isMobile() && this.source.match('m3u8$') && !this.hls) {
            if(Hls.isSupported()) {
                this.hls = new Hls();
                this.hls.loadSource(this.source);
                this.hls.attachMedia(this.video);
                this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    this.play();
                });
                this.sourceAttached = true;
            }
        } else {
            if (!this.sourceAttached) {
                this.video.src = this.source;
                this.sourceAttached = true;
            }
        }

        Video.stop();
        Audio.stop();

        const promise = this.video.play();
        if (promise !== undefined) {
            promise.catch(error => {})
                   .then(()=>{
                        this.audiotrack = this.activeAudioTracks;
                   });
        }

        this.setState({
            paused: false,
            // preloading: false,
        }, () => {
            this.activateControlTimer();
        });
    }

    pause() {
        if (!this.video) {
            return;
        }
        const promise = this.video.pause();
        if (promise !== undefined) {
            promise.catch(error => {})
                   .then(() => {});
        }

        clearTimeout(this.controlTimer);
        if (this.control && !this.control.visible) {
            this.control.visible = true;
        }

        this.setState({
            paused: true,
            loading: false,
        });
    }

    load() {
        if (this.video) {
            this.video.load();
        }
    }

    togglePlay() {
        if (this.video) {
            if (this.state.paused) {
                this.play();
            } else {
                this.pause();
            }
            // this.setState({
            //     showPoster: false,
            //     paused: !this.state.paused
            // });
        }
    }

    switchFullscreen = () => {
        let fullscreen = !this.state.fullscreen;
        this.setState({
            fullscreen: fullscreen
        }, () => {
            if (this.state.fullscreen) {
                const fs = document.createElement('div',);
                document.body.appendChild(fs);
                this.exitFullscreen = () => {
                    ReactDOM.unmountComponentAtNode(fs);
                    if (this.fsLayer) {
                        this.fsLayer.removeChild(this.videoView);
                        this.videoContainer.appendChild(this.videoView);
                        if (!this.state.paused && this.video.paused) {
                            this.video.play();
                        }
                    }
                    if (fs && fs.parentNode) {
                        fs.parentNode.removeChild(fs);
                    }
                    this.exitFullscreen = null
                };
                ReactDOM.render(
                    <div ref={el => this.fsLayer = el} className={`${this.props.prefixCls}-fullscreen`}/>,
                    fs,
                    () => {
                        this.fsLayer.appendChild(this.videoView);
                        if (!this.state.paused && this.video.paused) {
                            this.video.play();
                        }
                    }
                );
            } else {
                this.exitFullscreen && this.exitFullscreen();
            }
        });
    }

    toggleFullscreen() {
        if (Platform.isMobile() || !this.props.onFullscreen) {
            this.switchFullscreen();
        } else {
            this.props.onFullscreen(!this.state.fullscreen, this.switchFullscreen);
        }
    }

    seek(time) {
        if (this.video) {
            try {
                this.video.currentTime = time + '';
                this.setState({
                    currentTime: this.video.currentTime
                });
            } catch (e) {
                Log.info(e);
            }
        }
    }

    forward(seconds) {
        this.seek(this.video.currentTime + seconds);
    }

    backward(seconds) {
        this.forward(-seconds);
    }

    onPreload = (poster, duration) => {
        if (poster && duration > 0) {
            this.setState({
                poster: poster,
                duration: duration,
                showPoster: true,
            });
        }
    }

    // Fired when the user agent
    // begins looking for media data
    onLoadStart = e => {
        Log.debug('video load start');
        const { onLoadStart } = this.props;
        if (onLoadStart) {
            onLoadStart(e);
        }
    }

    // A handler for events that
    // signal that waiting has ended
    onCanPlay = e => {
        Log.debug('video can play');
        const { onCanPlay } = this.props;

        if (onCanPlay) {
            onCanPlay(e);
        }
    }

    // A handler for events that
    // signal that waiting has ended
    onCanPlayThrough = e => {
        Log.debug('video can play through');
        const { onCanPlayThrough } = this.props;

        if (onCanPlayThrough) {
            onCanPlayThrough(e);
        }
    }

    // A handler for events that
    // signal that waiting has ended
    onPlaying = e => {
        Log.debug('video playing');
        const { onPlaying } = this.props;

        if (onPlaying) {
            onPlaying(e);
        }

        this.setState({
            loading: false,
            showPoster: false,
        });

        Video.activeVideo = this;
    }

    // Fired whenever the media has been started
    onPlay = e => {
        Log.debug('video play');
        const { onPlay } = this.props;

        if (onPlay) {
            onPlay(e);
        }
    }

    // Fired whenever the media has been paused
    onPause = e => {
        Log.debug('video pause');
        const { onPause } = this.props;
        Video.activeVideo = null;
        if (onPause) {
            onPause(e);
        }

        if (this.state.showPoster) {
            this.setState({
                showPoster: false,
            });
        }
    }

    // Fired when the duration of
    // the media resource is first known or changed
    onDurationChange = e => {
        Log.debug('video duration change');
        if (this.props.duration === this.state.duration || this.video.duration === 0) {
            return;
        }

        this.setState({
            duration: this.video.duration
        });
    }

    // Fired while the user agent
    // is downloading media data
    onProgress = e => {
        Log.debug('video progress');
        const { onProgress } = this.props;

        if (onProgress) {
            onProgress(e);
        }
    }

    // Fired when the end of the media resource
    // is reached (currentTime == duration)
    onEnded = e => {
        Log.debug('video end');
        Video.activeVideo = null;
        this.pause();
        //this.seek(0); //修改视频停止过程，重置视频状态!
        this.setState({
            key: this.state.key + 1, 
            showPoster: true,
            currentTime: 0,
        }, () => {
            this.sourceAttached = false
            if (this.hls) {
                this.hls.destroy();
                this.hls = null
            }
            this.props.onEnded && this.props.onEnded(e);
        })
    }

    // Fired whenever the media begins waiting
    onWaiting = e => {
        Log.debug('video waiting');
        const { onWaiting } = this.props;

        if (onWaiting) {
            onWaiting(e);
        }

        this.setState({
            loading: true,
        });
    }

    // Fired whenever the player
    // is jumping to a new time
    onSeeking = e => {
        Log.debug('video seeking');
        const { onSeeking } = this.props;

        if (onSeeking) {
            onSeeking(e);
        }
    }

    // Fired when the player has
    // finished jumping to a new time
    onSeeked = e => {
        Log.debug('video seeked');
        const { onSeeked } = this.props;

        if (onSeeked) {
            onSeeked(e);
        }
    }

    onProgress = e => {
        Log.debug('buffering video');
    }

    // Handle Fullscreen Change
    onFullscreenChange = e => {
    }


    // Fires when the browser is
    // intentionally not getting media data
    onSuspend = e => {
        Log.debug('video suspend', e);
        const { onSuspend } = this.props;
        if (onSuspend) {
            onSuspend(e);
        }
    }

    // Fires when the loading of an audio/video is aborted
    onAbort = e => {
        Log.debug('video abort');
        const { onAbort } = this.props;

        if (onAbort) {
            onAbort(e);
        }
    }

    // Fires when the browser is trying to
    // get media data, but data is not available
    onStalled = e => {
        Log.debug('video stalled');
        const { onStalled } = this.props;

        if (onStalled) {
            onStalled(e);
        }
    }

    // Fires when the browser has loaded
    // meta data for the audio/video
    onLoadedMetaData = e => {
        Log.debug('video loaded meta data');
        const { onLoadedMetadata, startTime } = this.props;

        if (startTime && startTime > 0) {
            this.video.currentTime = startTime + '';
        }

        if (onLoadedMetadata) {
            onLoadedMetadata(e);
        }
    }

    // Fires when the browser has loaded
    // the current frame of the audio/video
    onLoadedData = e => {
        Log.debug('video loaded data');
        const { onLoadedData } = this.props;

        if (onLoadedData) {
            onLoadedData(e);
        }
    }
    //hooked by ljbase
    hookTimeUpdate(current, duration) { }
    // Fires when the current
    // playback position has changed
    onTimeUpdate = e => {
        // Log.debug('video time update - ' + this.video.currentTime + '/' + this.state.duration);
        const { onTimeUpdate } = this.props;
        this.hookTimeUpdate(this.video.currentTime, this.state.duration)
        this.setState({
            currentTime: this.video.currentTime,
            loading: false,
        });

        onTimeUpdate && onTimeUpdate(e);
    }


    // Fires when the playing speed of the audio/video is changed
    onRateChange = e => {
        Log.debug('video rate change');
        const { onRateChange } = this.props;

        if (onRateChange) {
            onRateChange(e);
        }
    }

    // Fires when the volume has been changed
    onVolumeChange = e => {
        Log.debug('video volume change');
        const { onVolumeChange } = this.props;

        if (onVolumeChange) {
            onVolumeChange(e);
        }
    }

    // Fires when an error occurred
    // during the loading of an audio/video
    onError = e => {
        const { onError } = this.props;
        Video.activeVideo = null;
        if (this.video.error) {
            Log.debug('Code: ' + this.video.error.code + ' Message: ' + this.video.error.message);
        }

        onError && onError(e);

        this.pause();

        // if (!this.video.error) {
        //     Toast.show(Strings.media_msg_unknown, 2);
        //     return;
        // }

        // switch(this.video.error.code) {
        //     case MediaError.MEDIA_ERR_ABORTED:
        //     Toast.show(Strings.media_msg_abort, 2);
        //     break;
        //     case MediaError.MEDIA_ERR_NETWORK:
        //     Toast.show(Strings.media_msg_network, 2);
        //     break;
        //     case MediaError.MEDIA_ERR_DECODE:
        //     Toast.show(Strings.media_msg_decode, 2);
        //     break;
        //     case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
        //     Toast.show(Strings.media_msg_src_not_supported, 2);
        //     break;
        //     default:
        //     Toast.show(Strings.media_msg_unknown, 2);
        //     break;
        // }
    }

    onResize = e => {
        Log.debug('video resize');
    }

    onTouchBegin = e => {
        this.activateControlTimer();
    }

    onTouchMove = e => {
        this.activateControlTimer();
    }

    onTouchEnd = e => {
        this.activateControlTimer();
    }

    activateControlTimer() {
        if (!this.control) {
            return;
        }

        this.control.visible = true;
        clearTimeout(this.controlTimer);
        this.controlTimer = setTimeout(() => {
            if (this.control) {
                this.control.visible = false;
            }
        }, 3000);
    }

    render() {
        const {
            prefixCls,
            // poster,
            autoPlay,
            preload,
            className,
            enableDownload,
            enableMuted,
        } = this.props;

        let landscreen = document.body.clientWidth > document.body.clientHeight
        let landscape = true;
        if (this.video) {
            landscape = this.videoWidth >= this.videoHeight;
        }

        let [width, height] = [document.documentElement.clientWidth, document.documentElement.clientHeight];
        let delta = Math.abs(width - height) / 2;
        const rotateStyle = {
            width: height,
            height: width,
            bottom: delta,
            left: -delta
        };

        let shouldRotate = ((landscape && window.orientation === 0) || (!landscape && window.orientation === 90)) && Platform.isMobile() && !landscreen;

        const wrapCls = classnames({
            [className]: !this.state.fullscreen,
            [`${prefixCls}`]: !this.state.fullscreen,
            [`${prefixCls}-landscape-rotate`]: landscape && this.state.fullscreen && Platform.isMobile() && !landscreen,
            [`${prefixCls}-portrait-rotate`]: !landscape && this.state.fullscreen && Platform.isMobile() && !landscreen,
            [`${prefixCls}-fullscreen`]: this.state.fullscreen,
        });

        const wrapVideoCls = classnames(`${prefixCls}-layer`, {
            [`${prefixCls}-layer-preloading`]: this.state.preloading,
        });

        return (
            <div ref={el => this.videoContainer = el} className={className}>
                <View
                    ref={el => this.videoView = ReactDOM.findDOMNode(el)}
                    className={wrapCls}
                    style={shouldRotate && this.state.fullscreen ? rotateStyle : this.state.fullscreen ? {} : this.props.style}
                    onMouseDown={this.onTouchBegin}
                    onMouseMove={this.onTouchMove}
                    onMouseUp={this.onTouchEnd}
                    onTouchStart={this.onTouchBegin}
                    onTouchMove={this.onTouchMove}
                    onTouchEnd={this.onTouchEnd}
                >
                    <video
                        key={this.state.key}
                        className={wrapVideoCls}
                        ref={video => {this.video = video}}
                        autoPlay={autoPlay}
                        preload={preload}
                        muted={enableMuted}
                        poster=''
                        playsInline={true}
                        crossOrigin='anonymous'
                        x-webkit-airplay='true'
                        webkit-playsinline='true'
                        x5-playsinline='true'
                        x5-video-player-type='h5'
                        onLoadStart={this.onLoadStart}
                        onWaiting={this.onWaiting}
                        onCanPlay={this.onCanPlay}
                        onCanPlayThrough={this.onCanPlayThrough}
                        onPlaying={this.onPlaying}
                        onEnded={this.onEnded}
                        onSeeking={this.onSeeking}
                        onSeeked={this.onSeeked}
                        onPlay={this.onPlay}
                        onPause={this.onPause}
                        onProgress={this.onProgress}
                        onDurationChange={this.onDurationChange}
                        onError={this.onError}
                        onSuspend={this.onSuspend}
                        onAbort={this.onAbort}
                        onStalled={this.onStalled}
                        onLoadedMetadata={this.onLoadedMetaData}
                        onLoadedData={this.onLoadedData}
                        onTimeUpdate={this.onTimeUpdate}
                        onRateChange={this.onRateChange}
                        onVolumeChange={this.onVolumeChange}
                    />
                    { this.state.showPoster && !this.state.transcoding ? <Image className={`${prefixCls}-poster`} source={this.state.poster}/> : null}
                    { !this.state.transcoding ? null :
                        <View className={`${prefixCls}-transcode`}>
                            <Icon type='transcoding' size='lg' color='white'/>
                            <Text className={`${prefixCls}-transcode-text`}>{Strings.media_msg_transcode}</Text>
                        </View>
                    }
                    <Control
                        ref={el => this.control = el}
                        onPlay={() => this.togglePlay()}
                        onFullscreen={() => this.toggleFullscreen()}
                        onSeek={time => this.seek(time)}
                        paused={this.state.paused}
                        fullscreen={this.state.fullscreen}
                        currentTime={this.state.currentTime}
                        duration={this.state.duration}
                        rotate={shouldRotate && this.state.fullscreen}
                        enableDownload={enableDownload && !Platform.isIPhone() && !this.state.transcoding}
                        onDownload={this.onDownload.bind(this)}
                    />
                    {this.state.loading ? <Spinner /> : null}
                </View>
            </div>
        );
    }
}