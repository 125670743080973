import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../../view';

import './style/index.less';

export default class Loading extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        barClassName: PropTypes.string,
        animate: PropTypes.bool,
    };

    static defaultProps = {
        prefixCls: 'tm-indicator',
        animate: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            progress: 0,
            animate: props.animate,
        };
    }

    get progress() {
        return this.state.progress;
    }
    set progress(progress) {
        if (progress === this.state.progress) {
            return;
        }

        if (progress < 0) progress = 0;
        if (progress > 100) progress = 100;

        this.setState({
            progress: progress
        });
    }

    get animate() {
        return this.state.animate;
    }
    set animate(animate) {
        if (animate === this.state.animate) {
            return;
        }
        this.setState({
            animate: animate,
        });
    }

    calculateOpacityOfBar(index) {
        let progress = Math.round(this.progress / 12);
        if (index === progress) {
            return 1;
        } else if (index < progress) {
            return 1 + (index - progress) / 12;
        } else {
            return (index - progress) / 12;
        }
    }

    render() {
        const {
            prefixCls,
            className,
            barClassName,
        } = this.props;

        const renderBars = () => {
            let bars = [];
            for (let i = 0; i < 12; i++) {
                const barIndex = 'bar' + i;
                const wrapCls = classnames(`${prefixCls}-loading-${barIndex}`, {[`${prefixCls}-loading-${barIndex}-anim`]: this.animate}, barClassName);
                const style = {
                    opacity: this.calculateOpacityOfBar(i)
                };
                bars.push(<View key={barIndex} className={wrapCls} style={this.animate ? {} : style} />);
            }
            return bars;
        }
        return (
            <View className={classnames(`${prefixCls}-loading`, className)}>
                { renderBars() }
            </View>
        );
    }
}