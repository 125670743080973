import React from 'react';
import PropTypes from 'prop-types';

export default class View extends React.PureComponent {
    static propTypes = {
        Element: PropTypes.string,
        visible: PropTypes.bool,
        onResize: PropTypes.func,
    }

    static defaultProps = {
        Element: 'div',
        visible: true,
    }

    state = {
        visible: !!this.props.visible,
    }

    set visible(visible) {
        if (this.state.visible === visible) return;
        this.setState({
            visible: visible,
        });
    }
    get visible() {
        return this.state.visible;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ('visible' in nextProps) {
            if (nextProps.visible !== this.state.visible) {
                this.setState({
                    visible: nextProps.visible
                });
            }
        }
    }

    UNSAFE_componentWillMount() {
        let { onResize } = this.props;
        if (onResize) {
            window.addEventListener('resize', onResize);
        }
    }

    componentWillUnmount() {
        let { onResize } = this.props;
        if (onResize) {
            window.removeEventListener('resize', onResize);
        }
    }

    render() {
        const {
            Element,
            visible,
            ...restProps
        } = this.props;

        if (!this.visible) return null;

        return (<Element {...restProps} />);
    }
}