import React from 'react'
import { Modal } from 'antd-mobile'
import BgDiv from 'Components/BgDiv'
import { IMAGE_URL } from 'Constants'
import { parseUrl, buildUrl } from 'src/Utils'
import './PreAnswer.less'

const PREFIX_CLS = 'pre-answer'

class PreAnswer extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      firstEnter: false,
      showModal: false,
      modalCanEnterNextPage: false,
    }

    this.urlParam = parseUrl()
  }

  componentDidMount() {
    document.title = '海选答题'
    this.init()
  }

  init = async () => {

  }

  handleShowModal = () => {
    this.setState({ showModal: !this.state.showModal })
  }

  handleStart = () => {
    this.setState({
      showModal: !this.state.showModal,
      modalCanEnterNextPage: true,
    })
  }

  handleReadRules = () => {
    this.setState({
      showModal: !this.state.showModal,
      modalCanEnterNextPage: false,
    })
  }

  handleModalOk = () => {
    if (this.state.modalCanEnterNextPage) {
      this.doStart()
    } else {
      this.handleShowModal()
    }
  }

  doStart = () => {
    const url = buildUrl('/normal/in-answer', {
      token: this.urlParam.token,
      matchId: this.urlParam.matchId,
    })
    this.props.history.push(url)
  }

  render() {
    const { showModal, modalCanEnterNextPage } = this.state

    return (
      <BgDiv
        className={PREFIX_CLS}
        style={{ height: '100%' }}
        src={require('Images/bg-mudan.png').default}
      >
        <p className={`${PREFIX_CLS}-label1`}>为您随机抽选诗词题目</p>
        <p className={`${PREFIX_CLS}-ti`}>50<span>道</span></p>

        <div className={`${PREFIX_CLS}-spacer`}></div>

        <div
          className={`${PREFIX_CLS}-start`}
          onClick={this.handleStart}
        >
          <img src={IMAGE_URL.BG_START} alt='' className={`${PREFIX_CLS}-start-bg`}/>
          <div className={`${PREFIX_CLS}-start-text`}>开始答题</div>
        </div>

        <div className={`${PREFIX_CLS}-spacer`}></div>

        <p className={`${PREFIX_CLS}-label2`}>请认真阅读答题须知后开始答题</p>
        <p
          onClick={this.handleReadRules}
          className={`${PREFIX_CLS}-hint`}
        >
          答题须知
        </p>

        <Modal
          wrapClassName={`${PREFIX_CLS}-modal`}
          visible={showModal}
          transparent
          transitionName='am-zoom'
          maskTransitionName='am-fade'
          onClose={this.handleShowModal}
        >
          <BgDiv
            className={`${PREFIX_CLS}-modal-content`}
            src={require('Images/bg-modal.png').default}
          >
            <p>1、答题次数：</p>
            <p>每名选手仅有一次答题机会，中途退出无法重新参加比赛</p>
            <p>2、题型题量：</p>
            <p>系统随机抽取50道诗词题，每位选手的题目均不同，题型有选择题和判断题</p>
            <p>3、答题时间：</p>
            <p>每道题都有答题时间限制，选手须在规定时间内完成作答，点击【下一题】进入下一题；超时未作答将自动进入下一题</p>
            <p>4、晋级规则：</p>
            <p>以正确题数成绩排名，成绩相同以答题时间择优晋级</p>

            <p className={`${PREFIX_CLS}-modal-title`}>温馨提示</p>
            <div className={`${PREFIX_CLS}-modal-footer`}>
              <BgDiv
                src={require('Images/bg-btn.png').default}
                className={`${PREFIX_CLS}-modal-btn`}
                onClick={this.handleModalOk}
              >
                {modalCanEnterNextPage ? '我已了解，开始答题' : '确定'}
              </BgDiv>
            </div>
          </BgDiv>
        </Modal>
      </BgDiv>
    )
  }
}

export default PreAnswer
