import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import View from '../view/index';
import { Log } from '../util';

import './style/index.less';

export default class Badge extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        prefixCls: PropTypes.string,
        hot: PropTypes.bool,
        style: PropTypes.any,
        size: PropTypes.oneOf(['large', 'small']),
        overflowCount: PropTypes.number,
        corner: PropTypes.bool,
        dot: PropTypes.bool,
        text: PropTypes.any
    };

    static defaultProps = {
        prefixCls: 'tm-badge',
        size: 'small',
        overflowCount: 99,
        dot: false,
        corner: false,
    };

    constructor(props) {
        super(props);
        this.state = {
            text: props.text,
        };
    }

    set text(text) {
        if (text === this.state.text) return;
        this.setState({
            text: text
        });
    }
    get text() {
        return this.state.text;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.text !== this.props.text) {
            this.text = nextProps.text;
        }
    }

    render() {
        let {
            className,
            prefixCls,
            children,
            text,
            size,
            overflowCount,
            dot,
            corner,
            hot,
            ...restProps
        } = this.props;
        
        Log.verbose('Render badge');
        text = typeof this.text === 'number' && this.text > overflowCount ? `${overflowCount}+` : this.text;

        // dot mode don't need text
        if (dot) {
            text = '';
        }

        const scrollNumberCls = classnames({
            [`${prefixCls}-dot`]: dot,
            [`${prefixCls}-dot-large`]: dot && size === 'large',
            [`${prefixCls}-text`]: !dot && !corner,
            [`${prefixCls}-corner`]: corner,
            [`${prefixCls}-corner-large`]: corner && size === 'large',
        });

        const badgeCls = classnames(prefixCls, className, {
            [`${prefixCls}-not-a-wrapper`]: !children,
            [`${prefixCls}-corner-wrapper`]: corner,
            [`${prefixCls}-hot`]: !!hot,
            [`${prefixCls}-corner-wrapper-large`]: corner && size === 'large',
        });

        const childStyle = {
            display: 'inline-block',
        };

        return (
            <span className={badgeCls}>
                <View style={childStyle}>
                    {children}
                </View>
                {(text || dot) && (
                    <sup className={scrollNumberCls} {...restProps}>
                        {text}
                    </sup>
                )}
            </span>
        );
    }
}
