import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import View from '../../view';

import './style/index.less';

export default class Circle extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        color: PropTypes.string,
    };

    static defaultProps = {
        prefixCls: 'tm-indicator-circle',
    };

    render() {
        const {
            prefixCls,
            className,
            color,
        } = this.props;

        const circles = [];

        for (let i = 0; i < 12; i++) {
            const wrapCls = classnames(`${prefixCls}-circle${i+1}`, `${prefixCls}-child`);
            circles.push(<View key={`circle-${i}`} className={wrapCls} style={{'--color': color}} />);
        }

        return (
            <View className={classnames(`${prefixCls}`, className)}>
                {circles}
            </View>
        );
    }
}