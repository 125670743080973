import React from 'react'
import { Switch, Route } from 'react-router'
import PreAnswer from './PreAnswer'
import InAnswer from './InAnswer'

class Router extends React.PureComponent {
  render() {
    const path = this.props.match.path

    return (
      <Switch>
        <Route path={`${path}/pre-answer`} component={PreAnswer}/>
        <Route path={`${path}/in-answer`} component={InAnswer}/>
        <Route path={`${path}`} component={PreAnswer}/>
      </Switch>
    )
  }
}

export default Router

