import React from 'react';
import classnames from 'classnames';
import ReactDOM from 'react-dom';
import ScrollView from '../scroll-view'
import {setPxStyle} from './utils'
import {_px2rem} from '../util'

export default class TabBar extends React.Component {
  saveRef = (name,saveCallback) => {
    return node => {
      this[name] = node;
      if (saveCallback){
        saveCallback(node);
      }
    };
  }
  onSaveTabRef = (key)=>{
    return node =>{
      if (node){
        const { tabs } = this.props;
        tabs.forEach((child) => {
            if (child.key === key){
              child.node = node;
              child.offsetLeft = node.offsetLeft;
              child.width = node.clientWidth;
              child.height = node.clientHeight;
            }
        });
      }
    };
  }

  onTabClick = (key, e) => {
    this.props.onTabClick(key, e);
  }

  onKeyDown = (e) => {
    this.props.onNavKeyDown(e);
  }

  getTabs = () => {
    const { tabs, activeKey, prefixCls,options} = this.props;
    const rst = [];
    if (tabs){
      let   nIndex = 0;
      let   nTabCount = tabs.length;
      tabs.forEach((child) => {
        const key = child.key;
        let tabStyle = {};
        if (activeKey === key){
          if (options && options.activeColor){
            tabStyle.color=options.activeColor;
          }
          if (child.activeColor){
            tabStyle.color=child.activeColor;
          }
        }
        else{
          if (child.disabled){
            if (options && options.disableColor){
              tabStyle.color=options.disableColor;
            }
            if (child.disableColor){
              tabStyle.color=child.disableColor;
            }
          }
          else{
            if (options && options.color){
              tabStyle.color=options.color;
            }
            if (child.color){
              tabStyle.color=child.color;
            }
          }
        }
        if (options && options.fontSize){
          tabStyle.fontSize=`${_px2rem(options.fontSize)}rem`;
        }
        if (child.fontSize){
          tabStyle.fontSize=`${_px2rem(options.fontSize)}rem`;
        }        
        if (options && options.fontWeight){
          tabStyle.fontWeight=`${options.fontWeight}`;
        }
        if (child.fontWeight){
          tabStyle.fontWeight=`${child.fontWeight}`;
        }    
        if (options && options.tabTopGap){
          tabStyle.paddingTop=`${_px2rem(options.tabTopGap)}rem`;
        }
        if (options && options.tabBottomGap){
          tabStyle.paddingBottom=`${_px2rem(options.tabBottomGap)}rem`;
        }
        if (options && options.tabWidth){
           tabStyle.width=`${_px2rem(options.tabWidth)}rem`;
           tabStyle.textAlign='center';
        }
        let tabGap = 0;
        let tabMargin = 0;
        if (options && options.tabGap){
            tabGap = options.tabGap / 2;
        }
        if (options && options.tabMargin){
          tabMargin = options.tabMargin;
        }
        if (nIndex === 0){//最左边tab
          tabStyle.marginLeft=`${_px2rem(tabMargin)}rem`;
          tabStyle.marginRight=`${_px2rem(tabGap)}rem`;
        }
        else{
          if (nIndex === (nTabCount - 1)){//最右边tab
            tabStyle.marginLeft=`${_px2rem(tabGap)}rem`;
            tabStyle.marginRight=`${_px2rem(tabMargin)}rem`;
          }
          else{
            tabStyle.marginLeft=`${_px2rem(tabGap)}rem`;
            tabStyle.marginRight=`${_px2rem(tabGap)}rem`;
          }
        }
        let events = {};
        if (!child.disabled) {
          events.onClick = (e) => this.onTabClick.call(this, key, e);
        }
        const ref = {};
        ref.ref = this.onSaveTabRef(key);
        rst.push(
          <div
            role="tab"
            aria-disabled={child.disabled ? 'true' : 'false'}
            aria-selected={activeKey === key ? 'true' : 'false'}
            {...events}
            className={`${prefixCls}-tab`}
            style={tabStyle}
            key={key}
            {...ref}
          >
            {child.name}
          </div>
        );
        nIndex++;
      });
    }
    //在创建一个inktab
    if (options && options.inkSize && (options.inkSize > 0)){
      let inkColor = '#0000FF';
      if (options.inkColor){
        inkColor = options.inkColor;
      }
      let borderRadius = options.inkSize * 4;
      let inkStyle = {width:'0px',height:`${_px2rem(options.inkSize)}rem`,backgroundColor:inkColor,borderRadius:`${_px2rem(borderRadius)}rem`};
      rst.push(
        <div
          className={`${prefixCls}-ink-bar`}
          style={inkStyle}
          key='inktab-bar'
          ref={this.saveRef('inkBar')}
        />
      );
    }
    return rst;
  }

  setHeaderPosition(node,value) {
    setPxStyle(node,value);
  }

  componentDidMount() {
    if (!this.headerNode){
      this.headerNode = ReactDOM.findDOMNode(this['tab-header']);
    }
    this.adjustTabsPos();
    this.adjustInkBarPos();    
  }

  UNSAFE_componentWillReceiveProps() {
  }

  componentDidUpdate() {
    this.adjustInkBarPos();
  }
  
  adjustTabsPos = () => {
    const { tabs,options } = this.props;
    let HeaderWidth = this.headerNode.clientWidth;
    let nTabCnt = tabs.length;
    let maxTabOffset = tabs[nTabCnt-1].offsetLeft + tabs[nTabCnt-1].width + options.tabMargin;
    let nOffset = 0;
    let node;
    let nTotalTabsWidth = 0;
    let nInterval = 0;
    if (maxTabOffset < HeaderWidth)//需要调整
    {
        let align = options.align;
        if (!align){
          align = 'space-around';
        }
        switch(align){
          case 'flex-end':
            nOffset = HeaderWidth - maxTabOffset;
            node = ReactDOM.findDOMNode(tabs[0].node);
            node.style.marginLeft = `${nOffset + options.tabMargin}px`;
            for(let index = 0; index < nTabCnt; index++){
              tabs[index].offsetLeft += nOffset;
            }
            break;
          case 'center':
            nOffset = (HeaderWidth - maxTabOffset) / 2;
            node = ReactDOM.findDOMNode(tabs[0].node);
            node.style.marginLeft = `${nOffset + options.tabMargin}px`;
            for(let index = 0; index < nTabCnt; index++){
              tabs[index].offsetLeft += nOffset;
            }
            break;
          case 'space-between':
            if (nTabCnt > 1){
              nTotalTabsWidth = options.tabMargin * 2;
              for(let index = 0; index < nTabCnt; index++){
                nTotalTabsWidth += tabs[index].width;
              }
              nInterval = (HeaderWidth - nTotalTabsWidth) / (nTabCnt - 1);
              node = ReactDOM.findDOMNode(tabs[0].node);
              node.style.marginLeft = `${options.tabMargin}px`;
              nOffset = tabs[0].width + options.tabMargin;
              for(let index = 1; index < nTabCnt; index++){
                nOffset += nInterval;
                tabs[index].offsetLeft = nOffset;
                node = ReactDOM.findDOMNode(tabs[index].node);
                node.style.marginLeft = `${nInterval}px`;
                nOffset += tabs[index].width;
              }
            }
            break;
          case 'space-around':
            nTotalTabsWidth = 0;
            for(let index = 0; index < nTabCnt; index++){
              nTotalTabsWidth += tabs[index].width;
            }
            nInterval = (HeaderWidth - nTotalTabsWidth) / (nTabCnt + 1);
            for(let index = 0; index < nTabCnt; index++){
              nOffset += nInterval;
              tabs[index].offsetLeft = nOffset;
              node = ReactDOM.findDOMNode(tabs[index].node);
              node.style.marginLeft = `${nInterval}px`;
              nOffset += tabs[index].width;
            }
            break;
          default:
            break;
        }
    }
  }

  adjustInkBarPos = () => {
    const { tabs,activeKey } = this.props;
    //let tabHeight = this.headerNode.clientHeight;
    tabs.forEach((child) => {
      if (child.key === activeKey){
        let node = ReactDOM.findDOMNode(child.node);
        let nodeInk = ReactDOM.findDOMNode(this['inkBar']);
        //nodeInk.style.marginTop = `${tabHeight - nodeInk.clientHeight}px`;
        nodeInk.style.left = `${node.offsetLeft}px`;
        nodeInk.style.width = `${node.clientWidth}px`;
        if (node && this.headerNode)//调整活动key到显示中间位置
        {
          let parentNode = this.headerNode.parentNode;
          let parentFrameNode = parentNode.parentNode;
          let showWidth = parentFrameNode.clientWidth;
          let offset = node.offsetLeft + node.clientWidth / 2;
          offset -= showWidth / 2;
          if (offset < 0){
            offset = 0;
          }
          if (offset >= this.headerNode.clientWidth - showWidth){
            offset = this.headerNode.clientWidth - showWidth;
          }
          // parentNode.style.left = `${-offset}px`;
          // parentNode.style.transition = 'left 0.3s cubic-bezier(0.35, 0, 0.25, 1)';
          if (this.scrollViewNode){
            this.scrollViewNode.scrollTo({x:offset,y:0});
          }
          // else{
              //parentNode.scrollLeft = offset;
          // }
        }
        return;
      }
    });
  }

  render() {
    const {
      prefixCls,
      options,
      refParent,
    } = this.props;    
    const cls = classnames({
      [`${prefixCls}-header`]: 1
    });    
    let headerStyle = {};
    if (options && options.background){
       headerStyle.background = options.background;
    }
    let tabHeight = '60px';
    if (options && options.tabHeight && (options.tabHeight > 0)){
        tabHeight = `${_px2rem(options.tabHeight)}rem`;
        headerStyle.height = tabHeight;
    }
    if (options && options.interval && options.intervalColor && (options.interval > 0)){
        headerStyle.borderBottomWidth = `${_px2rem(options.interval)}rem`;
        headerStyle.borderBottomColor = `${options.intervalColor}`;
        headerStyle.borderBottomStyle = 'solid';
    }
    if (this.props.options.position){
      headerStyle.position = this.props.options.position;
    }
    let saveCallback = refParent.saveRef('tab-header');
    return (
      <ScrollView style={{height:tabHeight,width:'100%'}} ref = {(node) => {this.scrollViewNode = node;}} orientation='horizontal'>
          <div
            className={cls}
            style={headerStyle}
            ref={this.saveRef('tab-header',saveCallback)}
            onKeyDown={this.onKeyDown}
          >
            {this.getTabs()}
          </div>
      </ScrollView>
    );
  }
}