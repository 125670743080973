import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import View from '../view';
import Control from './Control';
import Preload from './Preload';
import Video from '../video';
import { Log } from '../util';

import './style/index.less';

export default class Audio extends React.Component {
    static propTypes = {
        children: PropTypes.any,
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        source: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        id: PropTypes.string,
        loop: PropTypes.bool,
        autoPlay: PropTypes.bool,
        preload: PropTypes.oneOf(['auto', 'metadata', 'none']),
        mode: PropTypes.oneOf(['simple', 'advance', 'backend']),
        fileName: PropTypes.string,
        duration: PropTypes.number,
        startTime: PropTypes.number,
        player: PropTypes.any,
        onPlay: PropTypes.func,
        onPlaying: PropTypes.func,
        onPause: PropTypes.func,
        onTimeUpdate: PropTypes.func,
        onDurationChange: PropTypes.func,
        onAbort: PropTypes.func,
        onStalled: PropTypes.func,
        onError: PropTypes.func,
        onEnded: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-audio',
        mode: 'advance',
        autoPlay: false,
        loop: false,
        closeOthers: true,
        preload: 'none',
        duration: 0,
        startTime: 0,
    };

    player = null;

    static activeAudio = null;
    static stop = () => {
        if (Audio.activeAudio) {
            if (typeof Audio.activeAudio.stop === 'function') {
                Audio.activeAudio.stop();
            } else if(typeof Audio.activeAudio.pause === 'function') {
                Audio.activeAudio.pause();
            }
            Audio.activeAudio = null;
        }
    }

    constructor(props) {
        super(props);
        this.audio = null;
        this.player = props.player;
        this.state = {
            paused: true,
            source: '',
            duration: props.duration / 1000,
            currentTime: props.startTime / 1000,
            loading: false,
        };
        this.startPlayTime = props.startPlayTime;
    }

    preloading = false;
    startPlayTime = 0;
    shouldPause = false;
    seeking = false;
    shouldPlay = false;

    mbDidMount = false;

    UNSAFE_componentWillMount() {
        const { source } = this.props;
        if (source) {
            if (typeof source === 'string') {
                if (this.player) this.player.source = source;
                this.setState({source});
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const state = {};
        if ('source' in nextProps && nextProps.source !== this.state.source) {
            state.source = nextProps.source;
            if (this.player) this.player.source = nextProps.source;
        }
        if ('duration' in nextProps && nextProps.duration !== this.state.duration) {
            state.duration = nextProps.duration / 1000;
        }

        if (!!state.source || !!state.duration) {
            this.pause();
            if (this.player) {
                this.player.stop();
            }
            state.paused = true;
            state.currentTime = 0;
            state.loading = false;
            this.setState(state);
        }
    }

    componentWillUnmount() {
        console.log('Audio will unmount');
        this.pause();
        if (this.player) {
            this.player.stop();
        }

        this.mbDidMount = false;
    }

    componentDidMount() {
        this.preloading = !this.props.autoPlay && !this.player;
        if (this.preloading) {
            Preload.load(this.state.source, this.onPreload);
        }

        if (this.state.startTime > 0) {
            this.currentTime = this.state.startTime;
        }

        this.mbDidMount = true;
    }

    set source(source) {
        if (source === this.state.source) {
            return;
        }
        this.stop();
        this.setState({
            source: source,
        }, () => {
            if (this.audio) {
                Preload.load(this.state.source, this.onPreload);
                this.load();
            }
        });
        if (this.player) this.player.source = source;
    }
    get source() {
        return this.state.source;
    }

    set muted(muted) {
        if (this.audio) {
            this.audio.muted = muted;
        }
    }
    get muted() {
        if (this.audio) {
            return this.audio.muted;
        }
        return true;
    }
    set currentTime(time) {
        if (this.audio) {
            this.audio.currentTime = time;
        } else if (this.player) {
            if (this.player.state === 'idle') {
                this.startPlayTime = Math.floor(time * 1000);
            } else {
                this.seeking = true;
                this.player.seek(Math.floor(time * 1000));
            }
        }
    }
    get currentTime() {
        if (this.audio) {
            return this.audio.currentTime;
        }
        
        return this.state.currentTime;
    }
    get paused() {
        return this.state.paused;
    }

    get duration() {
        return this.state.duration;
    }

    forceUpdateSource = source => {
        if (this.player) this.player.source = source;
        this.setState({
            source: source + '&random=' + Math.floor(Math.random() * 1000),
        });
    }

    play() {
        const { closeOthers } = this.props
        if (this.audio) {

            if (this.lastPlayedSource === this.state.source && !!this.audio.error && this.audio.error.code > 0) {
                this.onError();
                return;
            }

            this.lastPlayedSource = this.state.source;

            if (closeOthers) {
                Audio.stop();
                Video.stop();
            }
            
            this.audio.play();

            this.setState({
                paused: false,
                loading: true,
            });
        } else if (this.player) {
            this.stop();
            Audio.stop();
            Video.stop();
            // play or resume
            if (this.player.state === 'idle') {
                this.player.play(this.onPlayerStateChange);
            } else if (this.player.state === 'pause') {
                if (!this.seeking) {
                    this.player.resume();
                } else {
                    //寻找后应该播放
                    this.shouldPlay = true;
                }
            }
        }
    }

    pause() {
        if (this.audio) {
            this.audio.pause();
            this.setState({
                paused: true
            });
        } else if (this.player) {
            if (this.player.state === 'loading') {
                this.shouldPause = true;
                return;
            }
            this.shouldPause = false;
            this.player.pause();
        }
    }

    stop() {
        if (this.player) {
            this.player.stop();
        }
    }

    load() {
        if (this.audio) {
            this.audio.load();
        }
    }

    seek(time) {
        this.currentTime = time;
        this.setState({
            currentTime: time
        });
    }

    togglePlay() {
        if (this.paused) {
            this.play();
        } else {
            this.pause();
        }
    }

    preload = () => {
        Preload.load(this.state.source, this.onPreload);
    }

    onPreload = duration => {
        if (this.state.duration === 0 && duration > 0) {
            this.setState({
                duration: duration,
                preloading: false,
            });
        }
    }

    onPlayerStateChange = state => {
        if (!this.player || !this.mbDidMount) {
            return {keepcallback: 0};
        }

        const {
            onPlay,
            onPause,
            onTimeUpdate,
            onSeek,
            onEnded,
            onError,
        } = this.props;

        let res = {
            keepcallback: 1
        };

        console.log(state);

        if (state.code !== 0) {
            Log.error(state.msg);
            this.player.state = 'idle';
            this.setState({
                loading: false,
                paused: true,
            });             
            res.keepcallback = 0;
            if (Audio.activeAudio === this) Audio.activeAudio = null;
            onError && onError(state.msg);
            return res;
        }

        this.player.state = state.status;

        switch (state.status) {
            case 'start':
            this.player.id = state.id;
            this.setState({
                loading: false,
                paused: false,
            });
            if (this.startPlayTime > 0) {
                this.player.seek(this.startPlayTime);
                this.startPlayTime = 0;
            }
            if (this.shouldPause) {
                setTimeout(() => this.pause(), 200);
                break;
            }
            Audio.activeAudio = this;
            onPlay && onPlay();
            break;
            case 'loading':
            this.setState({
                loading: true,
                paused: false,
            });
            Audio.activeAudio = this;
            break;
            case 'failed':
            this.player.state = 'idle';
            this.setState({
                loading: false,
                paused: true,
            });
            if (Audio.activeAudio === this) Audio.activeAudio = null;
            onError && onError(state.msg);
            Log.error(state.msg);
            res.keepcallback = 0;
            break;
            case 'pause':
            this.setState({
                loading: false,
                paused: true,
            });
            if (Audio.activeAudio === this) Audio.activeAudio = null;
            onPause && onPause();
            break;
            case 'resume':
            this.setState({
                loading: false,
                paused: false,
            });
            Audio.activeAudio = this;
            onPlay && onPlay();
            break;
            case 'playing':
            this.setState({
                currentTime: state.pos / 1000,
            });
            onTimeUpdate && onTimeUpdate(state.pos / 1000);
            break;
            case 'setpos':
            this.player.state = this.state.paused ? 'pause' : 'playing';
            this.seeking = false;
            onSeek && onSeek();
            if (this.shouldPlay) {
                this.shouldPlay = false;
                this.player.resume();
            }
            break;
            case 'closed':
            this.player.state = 'idle';
            this.setState({
                currentTime: 0,
                paused: true,
            });
            res.keepcallback = 0;
            onEnded && onEnded();
            break;
            default:
            res.keepcallback = 0;
            break;
        }

        return res;
    }

    onDurationChange = e => {
        Log.info('duration', this.duration);

        if (this.duration === this.state.duration && this.state.duration > 0) {
            return;
        }

        this.setState({
            duration: this.duration
        });

        this.props.onDurationChange && this.props.onDurationChange(this.duration);
    }

    onTimeUpdate = e => {
        this.setState({
            currentTime: this.currentTime,
        });
        this.props.onTimeUpdate && this.props.onTimeUpdate(this.currentTime);
    }

    onPlay = e => {
        Log.debug('onPlay');
        this.setState({
            loading: false,
        });
        this.props.onPlay && this.props.onPlay();
    }

    onPause = e => {
        this.props.onPause && this.props.onPause();
        Audio.activeAudio = null;
    }

    onPlaying = e => {
        Log.debug('onPlaying');
        this.props.onPlaying && this.props.onPlaying();
        Audio.activeAudio = this;
    }

    onAbort = e => {
        Log.verbose("onAbort");
        this.props.onAbort && this.props.onAbort();
        this.pause();
    }
    
    onStalled = e => {
        Log.verbose("onStalled");
        this.props.onStalled && this.props.onStalled();
    }

    onError = e => {
        this.props.onError && this.props.onError();
        if (this.audio) Log.error(this.audio.error);
        this.pause();
        Audio.activeAudio = null;

        // if (!this.audio.error) {
        //     Toast.show(Strings.media_msg_unknown, 2);
        //     return;
        // }

        // switch(this.audio.error.code) {
        //     case MediaError.MEDIA_ERR_ABORTED:
        //     Toast.show(Strings.media_msg_abort, 2);
        //     break;
        //     case MediaError.MEDIA_ERR_NETWORK:
        //     Toast.show(Strings.media_msg_network, 2);
        //     break;
        //     case MediaError.MEDIA_ERR_DECODE:
        //     Toast.show(Strings.media_msg_decode, 2);
        //     break;
        //     case MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
        //     Toast.show(Strings.media_msg_src_not_supported, 2);
        //     break;
        //     default:
        //     Toast.show(Strings.media_msg_unknown, 2);
        //     break;
        // }
    }

    onEnded = e => {
        Log.verbose('Audio finish');
        this.setState({
            currentTime: this.state.duration,
        }, () => {
            setTimeout(() => {
                this.pause();
                this.seek(0);
            }, 200);
        });
        Audio.activeAudio = null;
        this.stop();

        this.props.onEnded && this.props.onEnded();
    }

    render() {
        const {
            className,
            prefixCls,
            loop,
            autoPlay,
            preload,
            mode,
            fileName
        } = this.props;

        const wrapCls = classnames({
            [`${prefixCls}-simple`]: mode === 'simple',
            [`${prefixCls}-advance`]: mode === 'advance',
            [`${prefixCls}-backend`]: mode === 'backend',
        }, `${prefixCls}`, className);

        const control = () => {
            if (mode === 'backend') {
                return null;
            }

            return (
                <Control 
                    onPlay={() => this.togglePlay()}
                    onSeek={time => this.seek(time)}
                    paused={this.state.paused}
                    currentTime={this.state.currentTime}
                    duration={!this.state.duration ? 0 : this.state.duration}
                    advance={mode === 'advance'}
                    fileName={fileName}
                    loading={this.state.loading}
                />
            );
        }

        return (
            <View className={wrapCls}>
                {
                    !!this.player ? null :
                    <audio
                        ref={ref => this.audio = ref}
                        src={this.state.source}
                        loop={loop}
                        autoPlay={autoPlay}
                        preload={preload}
                        onPlay={() => this.onPlay()}
                        onPause={() => this.onPause()}
                        onPlaying={() => this.onPlaying()}
                        onAbort={() => this.onAbort()}
                        onStalled={() => this.onStalled()}
                        onError={() => this.onError()}
                        onDurationChange={() => this.onDurationChange()}
                        onTimeUpdate={() => this.onTimeUpdate()}
                        onEnded={() => this.onEnded()}
                    />
                }
                {control()}
            </View>
        );
    }
}