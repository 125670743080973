import React from 'react';
import PropTypes from 'prop-types';
import Picker from '../picker';
import Strings from '../strings';
import { Time } from '../util';

import './style/index.less';

export default class TimePicker extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        time: PropTypes.string,
        second: PropTypes.bool,
        header: PropTypes.string,
        tag: PropTypes.string,
        onChange: PropTypes.func,
        onConfirm: PropTypes.func,
        onCancel: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-time-picker',
        range: false,
        second: true,
        tag: false,
    };

    hours = {source: [], index: 0};
    minutes = {source: [], index: 0};
    seconds = {source: [], index: 0};

    lastContext = {hour: -1, minute: -1, second: -1};

    constructor(props) {
        super(props);
        this.state = {
            dataSource: new Picker.DataSource({
                getData: this.getData,
                getCount: () => props.second ? 3 : 2,
                hasMore: () => true,
                shouldSyncColumn: () => false,
            }),
            header: props.header || Strings.selectTime,
        };

        this.generate(this.hours.source, 0, 23, props.tag ? Strings.hour : '');
        this.generate(this.minutes.source, 0, 59, props.tag ? Strings.minute : '');
        this.generate(this.seconds.source, 0, 59, props.tag ? Strings.second : '');

        let timerx = /^\d{1,2}:\d{1,2}:\d{1,2}$/;
        let timerx1 = /^\d{1,2}:\d{1,2}$/;
        if (!!props.time && props.time !== '' && (timerx.test(props.time) || timerx1.test(props.time))) {
            let times = props.time.split(':');
            if (times.length >= 2) {
                this.lastHour = parseInt(times[0], 10);
                this.lastMinute = parseInt(times[1], 10);
                if (times.length > 2) {
                    this.lastSecond = parseInt(times[2], 10);
                }
            }
            this.lastContext = { hour: this.lastHour, minute: this.lastMinute, second: this.lastSecond };
        } else {
            let date = new Date();
            this.lastContext = { hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds() };
        }
    }

    getData = (column, index) => {
        if (column === 0) {
            return this.generateHour();
        } else if (column === 1) {
            return this.generateMinute();
        } else if (column === 2) {
            return this.generateSecond();
        }
    }

    generate = (data, start, end, suffix) => {
        for (let i = start; i <= end; i++) {
            let text = i + suffix;
            if (i < 10) text = '0' + text;
            data.push({id: i, label: text});
        }
    }

    generateHour = () => {
        let hour = this.lastHour;
        const startIndex = hour - 10;
        const endIndex = hour + 10;
        let source = [];
        for (let i = startIndex; i <= endIndex; i++) {
            let tmpIndex = (i + 24) % 24;
            source.push(this.hours.source[tmpIndex]);
        }

        return { source: source, index: 10 };
    }

    generateMinute = () => {
        let minute = this.lastMinute;
        const startIndex = minute - 10;
        const endIndex = minute + 10;
        let source = [];
        for (let i = startIndex; i <= endIndex; i++) {
            let tmpIndex = (i + 60) % 60;
            source.push(this.minutes.source[tmpIndex]);
        }

        return { source: source, index: 10 };
    }

    generateSecond = () => {
        let second = this.lastSecond;
        const startIndex = second - 10;
        const endIndex = second + 10;
        let source = [];
        for (let i = startIndex; i <= endIndex; i++) {
            let tmpIndex = (i + 60) % 60;
            source.push(this.seconds.source[tmpIndex]);
        }

        return { source: source, index: 10 };
    }

    format = () => {
        let fmt = 'hh:mm';
        let hour = this.lastHour;
        let minute = this.lastMinute;
        let second = 0;
        if (this.props.second) {
            fmt += ':ss';
            second = this.lastSecond;
        }

        return Time.format(hour * 60 * 60 + minute * 60 + second, fmt);
    }

    activate(now) {
        if (now) {
            let date = new Date();
            this.lastContext = { hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds() };
        }

        if (this.lastContext) {
            this.lastHour = this.lastContext.hour;
            this.lastMinute = this.lastContext.minute;
            this.lastSecond = this.lastContext.second;
        }

        this.state.dataSource.reset();
        
        if (this.picker) {
            this.picker.activate();
        }

        this.props.onChange && this.props.onChange(this.format());
    }

    onChange = data => {
        const { onChange } = this.props;
        if (data.length < 2) return;

        this.lastHour = this.state.dataSource.data(0).id;
        this.lastMinute = this.state.dataSource.data(1).id;
        this.lastSecond = 0;
        if (this.props.second && data.length === 3) {
            this.lastSecond = this.state.dataSource.data(2).id;
        }
        onChange && onChange(this.format(this.lastHour, this.lastMinute, this.lastSecond));
    }

    onConfirm = () => {
        this.lastContext = {
            hour: this.state.dataSource.data(0).id,
            minute: this.state.dataSource.data(1).id,
            second: 0,
        }
        if (this.props.second) {
            this.lastContext.second = this.state.dataSource.data(2).id;
        }

        this.props.onConfirm && this.props.onConfirm(this.format());
    }

    onCancel = () => {
        this.props.onCancel && this.props.onCancel();
    }

    render() {
        const {
            prefixCls,
        } = this.props;

        return (
            <Picker
                ref={el => this.picker = el}
                bodyClassName={`${prefixCls}-content`}
                modalClassName={`${prefixCls}-modal`}
                onChange={this.onChange}
                columns={this.state.dataSource.column()}
                dataSource={this.state.dataSource}
                header={ this.state.header }
                onConfirm={this.onConfirm}
                onCancel={this.onCancel}
            />
        )
    }
}