function format(time, fmt) {
    let second = time % 60;
    time = parseInt(time / 60,10);
    let minute = time % 60;
    let hour = parseInt(time / 60,10);
    let pattern = { 
        'm+' : minute,
        's+' : second,
        'h+' : hour,
    };
    
    for(var k in pattern) {
        if(new RegExp("("+ k +")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (pattern[k]) : (("00" + pattern[k]).substr(("" + pattern[k]).length)));   
        }
    }
    return fmt;   
}

export default {format};