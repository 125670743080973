const [VERBOSE, DEBUG, INFO, WARN, ERROR, FATAL] = [300, 250, 200, 100, 50, 10];
var current_log_level = VERBOSE;

function log(level) {
    if (level > current_log_level || arguments.length === 0) {
        return;
    }

    const args = [...arguments];
    args.shift();
    console.log(...args);
}

function setLevel(level) {
    if (level > VERBOSE) level = VERBOSE;
    current_log_level = level;
}

function verbose() {
    if (current_log_level < VERBOSE) {
        return;
    }

    log(VERBOSE, 'Verbose:', ...arguments);
}

function debug() {
    if (current_log_level < DEBUG) {
        return;
    }

    log(DEBUG, 'Debug:', ...arguments);
}

function info() {
    if (current_log_level < INFO) {
        return;
    }

    log(INFO, 'Info:', ...arguments);
}

function warn() {
    if (current_log_level < WARN) {
        return;
    }

    log(WARN, 'Warn:', ...arguments);
}

function error() {
    log(ERROR, 'Error:', ...arguments);
}

function fatal() {
    log(FATAL, 'Fatal:', ...arguments);
}

export default { setLevel, verbose, debug, info, warn, error, fatal };