import React from 'react'
import classnames from 'classnames';
import PropTypes from 'prop-types';

import View from '../view';
import { Loading } from '../indicator';

import './style/index.less';

const HEIGHT = 48;
const MAX_HEIGHT = 128;

export default class Refresher extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        onRefresh: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-listview-refresher',
    };

    state = {
        height: 0,
        animate: false,
        refreshing: false,
    };

    get height() {
        return this.state.height;
    }
    set height(height) {
        if (height === this.state.height || this.state.height > this.maximumHeight) return;

        if (height <= 0) {
            if (this.loading) {
                this.loading.animate = false;
            }
            this.setState({
                height: 0,
                refreshing: false,
            })
            return;
        }

        const delta = height - this.state.height;
        const factor = (Math.PI / 2 - Math.atan(this.state.height / this.maximumHeight)) / Math.PI;
        height = delta * factor + this.state.height;

        this.setState({
            height: height > this.maximumHeight ? this.maximumHeight : Math.round(height * 100) / 100,
            animate: this.state.refreshing,
        });
        if (this.loading) {
            this.loading.progress = height / HEIGHT * 100;
        }
    }

    get refreshing() {
        return this.state.refreshing;
    }

    get maximumHeight() {
        return MAX_HEIGHT;
    }

    activate(force) {
        let height = 0;
        let animate = true;
        let refreshing = true;
        if (this.state.height >= HEIGHT || !!force) {
            height = HEIGHT;
            if (this.loading) {
                this.loading.animate = true;
            }
        } else {
            animate = false;
            refreshing = false;
        }
        
        this.setState({
            height: height,
            animate: animate,
            refreshing: refreshing,
        }, () => {
            if (this.refreshing) {
                this.props.onRefresh && this.props.onRefresh();
            }
        });
    }

    render() {
        const {
            prefixCls,
            className,
        } = this.props;

        const style = {
            height: this.height,
        };

        return (
            <View
                className={classnames(`${prefixCls}`, className, {
                    [`${prefixCls}-animate`]: this.state.animate
                })}
                style={style}
            >
                <Loading 
                    ref={el => this.loading = el} 
                    className={`${prefixCls}-indicator`}
                    animate={false}
                />
            </View>
        )
    }
}