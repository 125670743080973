import React from 'react';
import './index.less';

const Button = React.memo((props) => {
  let timer = true;

  const onClick = () => {
    if (timer) {
      timer = false;
      props.onClick();
      setTimeout(() => {
        timer = true;
      }, 1000);
    }
  };

  return (
    <div className='my-btn' onClick={() => onClick()}>
      <img src={require('Images/bg-btn.png').default} alt=''></img>
      {props.children}
    </div>
  );
});

export default Button;
