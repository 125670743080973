import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view';
import Icon from '../icon';
import Text from '../text';
import Toast from '../toast';
import Strings from '../strings';
import { Platform } from '../util';

const [RATIO_AUTO, RATIO_PHONE, RATIO_1x1, RATIO_2x3, RATIO_3x2, RATIO_3x4, RATIO_4x3, RATIO_9x16, RATIO_16x9] = [0, 1, 2, 3, 4, 5, 6, 7, 8];
const [ROTATE, ROTATE_REVERSE, FLIP_H, FLIP_V] = [0, 1, 2, 3];
const defaultRatios = [
    {
        ratio: RATIO_AUTO,
        icon: 'ie-ratio-auto'
    },
    {
        ratio: RATIO_PHONE,
        icon: 'ie-ratio-phone',
    }, 
    {
        ratio: RATIO_1x1, 
        icon: 'ie-ratio-1x1',
    }, 
    {
        ratio: RATIO_2x3, 
        icon: 'ie-ratio-2x3',
    },
    {
        ratio: RATIO_3x2, 
        icon: 'ie-ratio-3x2', 
    },
    {
        ratio: RATIO_3x4, 
        icon: 'ie-ratio-3x4',
    },
    {
        ratio: RATIO_4x3, 
        icon: 'ie-ratio-4x3',
    },
    {
        ratio: RATIO_9x16,
        icon: 'ie-ratio-9x16',
    },
    {
        ratio: RATIO_16x9,
        icon: 'ie-ratio-16x9',
    },
];

// const defaultTransform = [
//     {
//         trans: ROTATE,
//         icon: 'ie-rotate',
//     },
//     {
//         trans: ROTATE_REVERSE,
//         icon: 'ie-rotate-reverse',
//     },
//     {
//         trans: FLIP_H,
//         icon: 'ie-flip-h',
//     },
//     {
//         trans: FLIP_V,
//         icon: 'ie-flip-v',
//     },
// ];

class Editor extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        source: PropTypes.string,
        onConfirm: PropTypes.func,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-image-editor'
    };

    metrics = undefined;
    clipperMetrics = undefined;
    rotateMetrics = undefined;
    originalSource = undefined;
    originalClipSource = undefined;
    sliderHandleLeft = 0;
    originalImage = document.createElement('img');
    image = document.createElement('img');
    isMobile = Platform.isMobile();

    lastTouch = null;

    constructor(props) {
        super(props);

        this.state = {
            ratio: RATIO_AUTO,
            clip: true,
            source: props.source,
            angle: 0,
            showNotice: false,
            showImage: true,
        };
        this.originalSource = props.source;
        this.originalClipSource = props.source;
        this.originalImage.crossOrigin = 'anonymous';
        this.originalImage.src = props.source;
        this.image.crossOrigin = 'anonymous';
        this.image.src = props.source;
        this.image.onerror = () => {
            props.onClose();
            Toast.show(Strings.image_load_error);
        }
    }

    componentDidMount() {
        this.sliderTrack = document.getElementById('slider-track');
        this.sliderRailLeft = document.getElementById('slider-rail-left');
        this.sliderRailRight = document.getElementById('slider-rail-right');
        this.sliderHandle = document.getElementById('slider-handle');
        this.sliderHandleLeft = this.sliderTrack.clientWidth / 2;
        this.sliderHandle.style.left = this.sliderHandleLeft + 'px';

        this.reload(this.state.source);
        document.addEventListener(this.isMobile ? 'touchstart' : 'mousedown', this.onTouchBegin);
    }

    updateMetrics = () => {
        if (!this.image || !this.content) return;

        let width = this.image.naturalWidth;
        let height = this.image.naturalHeight;

        const orginalRatio = width / height;
        const contentRatio = this.content.clientWidth / this.content.clientHeight;
        this.metrics = {};
        if (orginalRatio > contentRatio) {
            this.metrics.width = this.content.clientWidth;
            this.metrics.height = Math.floor(this.metrics.width / orginalRatio);
            this.metrics.x = 0;
            this.metrics.y = (this.content.clientHeight - this.metrics.height) / 2;
        } else {
            this.metrics.height = this.content.clientHeight;
            this.metrics.width = Math.floor(this.metrics.height * orginalRatio);
            this.metrics.x = (this.content.clientWidth - this.metrics.width) / 2;
            this.metrics.y = 0;
        }
        this.adjustClipperMetrics(null, this.state.ratio);
    }

    updateCanvas = image => {
        if (!this.canvas) return;

        this.canvas.style.left = this.metrics.x + 'px';
        this.canvas.style.top = this.metrics.y + 'px';
        this.canvas.style.width = this.metrics.width + 'px';
        this.canvas.style.height = this.metrics.height + 'px';

        if (image) {
            this.canvas.width = this.image.naturalWidth;
            this.canvas.height = this.image.naturalHeight;
            const context = this.canvas.getContext('2d');
            context.drawImage(this.image, 0, 0, this.image.naturalWidth, this.image.naturalHeight, 0, 0, this.canvas.width, this.canvas.height);
        }
    }

    adjustClipperMetrics = (offset, ratio) => {
        if (this.metrics === undefined || !this.clipper) {
            return;
        }

        let metrics = {
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        }

        const orignalRatio = this.metrics.width / this.metrics.height;
        let activeRatio = 0;
        switch(ratio) {
            case RATIO_AUTO:
            metrics = {...this.metrics};
            break;
            case RATIO_PHONE:
            activeRatio = document.documentElement.clientWidth / document.documentElement.clientHeight;
            break;
            case RATIO_1x1:
            activeRatio = 1;
            break;
            case RATIO_2x3:
            activeRatio = 2 / 3;
            break;
            case RATIO_3x2:
            activeRatio = 3 / 2;
            break;
            case RATIO_3x4:
            activeRatio = 3 / 4;
            break;
            case RATIO_4x3:
            activeRatio = 4 / 3;
            break;
            case RATIO_9x16:
            activeRatio = 9 / 16;
            break;
            case RATIO_16x9:
            activeRatio = 16 / 9;
            break;
            default:
            return;
        }

        if (!!offset) {
            metrics = this.clipperMetrics ? {...this.clipperMetrics} : {...this.metrics};

            if (offset.width + metrics.width > this.metrics.width) {
                // make sure clipper width less or equal than image width
                metrics.width = this.metrics.width;
            } else {
                metrics.width += offset.width;
            }

            if (offset.height + metrics.height > this.metrics.height) {
                // make sure clipper height less or equal than image height
                metrics.height = this.metrics.height;
            } else {
                metrics.height += offset.height;
            }

            if ((metrics.width !== this.clipperMetrics.width || metrics.height !== this.clipperMetrics.height) && activeRatio > 0) {
                // chosen fixed ratio, make sure width and height scale with same ratio
                const tmpRatio = metrics.width / metrics.height;
                if (tmpRatio > activeRatio) {
                    const width = metrics.height * activeRatio;
                    if (offset.x !== 0) {
                        // adjust offset-x
                        offset.x += metrics.width - width;
                    }
                    metrics.width = width;
                } else {
                    const height = metrics.width / activeRatio;
                    if (offset.y !== 0) {
                        // adjust offset-y
                        offset.y += metrics.height - height;
                    }
                    metrics.height = height;
                }
            }

            if (offset.x + metrics.x < this.metrics.x) {
                metrics.x = this.metrics.x;
            } else if (offset.x + metrics.x + metrics.width > this.metrics.x + this.metrics.width) {
                metrics.x = this.metrics.x + this.metrics.width - metrics.width;
            } else {
                metrics.x += offset.x;
            }
            
            if (offset.y + metrics.y < this.metrics.y) {
                metrics.y = this.metrics.y
            } else if (offset.y + metrics.y + metrics.height > this.metrics.y + this.metrics.height) {
                metrics.y = this.metrics.y + this.metrics.height - metrics.height;
            } else {
                metrics.y += offset.y;
            }
        } else if (activeRatio > 0) {
            if (orignalRatio > activeRatio) {
                metrics.height = this.metrics.height;
                metrics.width = Math.floor(metrics.height * activeRatio);
            } else {
                metrics.width = this.metrics.width;
                metrics.height = Math.floor(metrics.width / activeRatio);
            }
            metrics.x = (this.content.clientWidth - metrics.width) / 2;
            metrics.y = (this.content.clientHeight - metrics.height) / 2;
        }

        this.clipperMetrics = metrics;

        this.clipper.style.left = metrics.x + this.margin + 'px';
        this.clipper.style.top = metrics.y + this.margin + 'px';
        this.clipper.style.width = metrics.width + 'px';
        this.clipper.style.height = metrics.height + 'px';
    }

    reload = source => {
        if (!source) return;

        this.image.src = source;
        this.image.onload = () => {
            this.updateMetrics();
            this.updateCanvas(this.image);
        }
    }

    onCancel = e => {
        this.props.onClose && this.props.onClose();
    }

    onConfirm = e => {
        const {
            onConfirm,
            onClose,
        } = this.props;

        const toast = Toast.loading();
        setTimeout(() => {
            const metrics = this.calculateOriginalMetrics(this.clipperMetrics);
            this.canvas.width = metrics.width;
            this.canvas.height = metrics.height;
    
            let context = this.canvas.getContext('2d');
            context.clearRect(0, 0, this.canvas.width, this.canvas.height);
            context.drawImage(this.image, metrics.x, metrics.y, metrics.width, metrics.height, 0, 0, this.canvas.width, this.canvas.height);
    
            const source = this.canvas.toDataURL('image/png');
    
            onConfirm && onConfirm(source);
            onClose && onClose();

            toast.close();
        }, 5);
    }

    onRotate = () => {
        this.onTransformChange(ROTATE);
    }

    onFlip = () => {
        this.onTransformChange(FLIP_H);
    }

    onRatioChange = ratio => {
        if (ratio === this.state.ratio) return;
        this.setState({ratio}, () => {
            this.adjustClipperMetrics(null, ratio);
        });
    }

    calculateOriginalMetrics = cm => {
        const metrics = {}

        const scale = this.image.naturalWidth / this.metrics.width;

        metrics.x = (cm.x - (this.content.clientWidth - this.canvas.clientWidth) / 2) * scale;
        metrics.y = (cm.y - (this.content.clientHeight - this.canvas.clientHeight) / 2) * scale;
        metrics.width = cm.width * scale;
        metrics.height = cm.height * scale;

        return metrics;
    }

    onClipConfirm = e => {
        const metrics = this.calculateOriginalMetrics(this.clipperMetrics);
        this.canvas.width = metrics.width;
        this.canvas.height = metrics.height;

        let context = this.canvas.getContext('2d');
        context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        context.drawImage(this.image, metrics.x, metrics.y, metrics.width, metrics.height, 0, 0, this.canvas.width, this.canvas.height);

        const source = this.canvas.toDataURL('image/png');
        this.originalClipSource = source;
        this.setState({
            source: source,
        }, () => {
            this.reload(source);
        });
    }

    onTransformChange = transform => {
        let context = this.canvas.getContext('2d');
        let width = this.canvas.width;
        let height = this.canvas.height;
        switch(transform) {
            case ROTATE:
            this.canvas.width = height;
            this.canvas.height = width;
            context.translate(height / 2, width / 2);
            context.rotate(Math.PI / 2);
            break;
            case ROTATE_REVERSE:
            this.canvas.width = height;
            this.canvas.height = width;
            context.translate(height / 2, width / 2);
            context.rotate(-Math.PI / 2);
            break;
            case FLIP_H:
            this.canvas.width = width;
            this.canvas.height = height;
            context.translate(width / 2, 0);
            context.scale(-1, 1);
            height = 0;
            break;
            case FLIP_V:
            this.canvas.width = width;
            this.canvas.height = height;
            context.translate(0, height / 2);
            context.scale(1, -1);
            width = 0;
            break;
            default:
            return;
        }

        context.drawImage(this.image, -width / 2, -height / 2);
        const source = this.canvas.toDataURL('image/png');
        this.setState({
            source,
        }, () => {
            this.reload(this.state.source);
            // reset slider
            this.originalImage.src = this.state.source;
            if (this.sliderTrack) {
                this.sliderHandleLeft = this.sliderTrack.clientWidth / 2;
                if (this.sliderHandle && this.sliderTrack) {
                    this.sliderHandle.style.left = this.sliderHandleLeft + 'px';
                }
                if (this.sliderNotice && this.sliderTrack) {
                    this.sliderNotice.style.left = this.sliderHandleLeft + 'px';
                }

                if (this.sliderRailRight) this.sliderRailRight.style.width = 0;
                if (this.sliderRailLeft) this.sliderRailLeft.style.width = 0;
            }
        });
    }

    onImageCanvasRef = el => {
        this.content = ReactDOM.findDOMNode(el);
        if (this.content && !this.margin) {
            this.margin = this.content.offsetTop;
        }
    }

    calculateInnerRectangle = angle => {
        if (angle === 0) {
            return {
                x: 0, 
                y: 0,
                width: this.originalImage.naturalWidth,
                height: this.originalImage.naturalHeight,
            };
        }
        let width = this.originalImage.naturalWidth;
        let height = this.originalImage.naturalHeight;
        const clockwise = angle > 0;
        const landscape = width > height;

        const deltaRad = angle / 180 * Math.PI;
        const radian = Math.atan2(height / 2, width / 2);
        const radius = Math.sqrt(width * width / 4 + height * height / 4);

        const rad1 = landscape ? Math.PI - radian - deltaRad : Math.PI + radian - deltaRad;
        const rad2 = landscape ? radian - deltaRad : Math.PI - radian - deltaRad;
        
        const x1 = Math.cos(rad1) * radius;
        const y1 = Math.sin(rad1) * radius;
        const x2 = Math.cos(rad2) * radius;
        const y2 = Math.sin(rad2) * radius;

        let deltaX = x1 - x2;
        let deltaY = y1 - y2;
        const a1 = deltaY;
        const b1 = -deltaX;
        const s1 = -deltaY * x2 + deltaX * y2;
        const a2 = 1;
        const b2 = (clockwise && landscape) || (!clockwise && !landscape) ? -width / height : width / height;
        const s2 = 0;

        const x = (b2 * s1 - b1 * s2) / (a2 * b1 - a1 * b2);
        const y = (-a1 * x - s1) / b1;

        const w = Math.abs(x) * 2;
        const h = Math.abs(y) * 2;

        return {
            x: (width - w) / 2,
            y: (height - h) / 2,
            width: w,
            height: h
        };
    }

    rotateImage = angle => {
        if (!this.canvas) return;

        const radian = angle / 180 * Math.PI;
        let context = this.canvas.getContext('2d');
        let w = this.originalImage.naturalWidth;
        let h = this.originalImage.naturalHeight;
        this.canvas.width = w;
        this.canvas.height = h;

        this.rotateMetrics = this.calculateInnerRectangle(angle);

        context.save();
        context.globalAlpha = 0.5;
        context.fillStyle = 'black';
        context.fillRect(0, 0, w, h);
        context.clearRect(this.rotateMetrics.x, this.rotateMetrics.y, this.rotateMetrics.width, this.rotateMetrics.height);
        context.globalAlpha = 1.0;
        context.translate(w / 2, h / 2);
        context.rotate(radian);
        context.globalCompositeOperation = 'destination-over';
        context.drawImage(this.originalImage, -w / 2, -h / 2);
        context.restore();

        this.setState({
            angle
        });
    }

    clipRotateArea = () => {
        if (!this.rotateMetrics || !this.canvas) return;

        const toast = Toast.loading();
        setTimeout(() => {
            let source = this.canvas.toDataURL('image/png');
            this.image.src = source;
            this.image.onload = () => {
                const metrics = this.rotateMetrics;
                this.canvas.width = this.canvas.clientWidth;
                this.canvas.height = this.canvas.clientHeight;

                let context = this.canvas.getContext('2d');
                context.clearRect(0, 0, this.canvas.width, this.canvas.height);
                context.drawImage(this.image, metrics.x, metrics.y, metrics.width, metrics.height, 0, 0, this.canvas.width, this.canvas.height);
                source = this.canvas.toDataURL('image/png');
                this.setState({source}, () => {
                    this.reload(this.state.source);
                });
                toast.close();
            }
        }, 5);
    }

    onSliderMove = x => {
        if (!this.sliderTrack) return;

        const width = this.sliderTrack.clientWidth;
        this.sliderHandleLeft += x;
        if (this.sliderHandleLeft > width) {
            this.sliderHandleLeft = width;
        }
        if (this.sliderHandleLeft < 0) {
            this.sliderHandleLeft = 0;
        }

        const delta = this.sliderHandleLeft - width / 2;
        const angle = Math.ceil(delta / (width / 2 / 20));

        if (delta > 0) {
            this.sliderRailRight.style.width = delta + 'px';
            this.sliderRailLeft.style.width = 0;
        } else {
            this.sliderRailRight.style.width = 0;
            this.sliderRailLeft.style.width = -delta + 'px';
        }

        this.sliderHandle.style.left = this.sliderHandleLeft + 'px';
        if (this.sliderNotice) {
            this.sliderNotice.style.left = this.sliderHandleLeft + 'px';
        }

        // skip rotate
        if (angle === this.state.angle || isNaN(angle)) return;

        this.rotateImage(angle);
    }

    onTouchBegin = e => {
        const touches = e.changedTouches ? e.changedTouches[0] : e;
        this.lastTouch = {
            x: touches.clientX,
            y: touches.clientY,
        };

        const target = e.target;
        this.activeElem = 'none';
        switch (target.id) {
            case 'handle-tl':
            this.activeElem = 'top-left';
            break;
            case 'handle-bl':
            this.activeElem = 'bottom-left';
            break;
            case 'handle-tr':
            this.activeElem = 'top-right';
            break;
            case 'handle-br':
            this.activeElem = 'bottom-right';
            break;
            case 'handle-ct':
            this.activeElem = 'center-top';
            break;
            case 'handle-cb':
            this.activeElem = 'center-bottom';
            break;
            case 'handle-cl':
            this.activeElem = 'center-left';
            break;
            case 'handle-cr':
            this.activeElem = 'center-right';
            break;
            case 'clipper':
            case 'line-h1':
            case 'line-h2':
            case 'line-v1':
            case 'line-v2':
            this.activeElem = 'clipper';
            break;
            case 'slider-handle':
            this.activeElem = 'slider-handle';
            this.setState({
                showNotice: true,
                showImage: false,
            }, () => {
                this.sliderNotice = document.getElementById('slider-notice');
                this.sliderNotice.style.left = this.sliderHandle.style.left;
                this.onSliderMove(0);
            });
            break;
            case 'canvas':
            this.activeElem = 'canvas';
            break;
            default:
            break;
        }

        document.addEventListener(this.isMobile ? 'touchmove' : 'mousemove', this.onTouchMove);
        document.addEventListener(this.isMobile ? 'touchend' : 'mouseup', this.onTouchEnd);
        // this.clipper.addEventListener(this.isMobile ? 'touchcancel' : 'mouseleave', this.onTouchEnd);
    }

    onTouchMove = e => {
        if (this.activeElem === 'none') return;

        const touches = e.changedTouches ? e.changedTouches[0] : e;
        const touch = {
            x: touches.clientX,
            y: touches.clientY,
        };

        const offset = {
            x: touch.x - this.lastTouch.x,
            y: touch.y - this.lastTouch.y,
            width: 0,
            height: 0,
        };
        this.lastTouch = touch;

        switch (this.activeElem) {
            case 'top-left':
            offset.width = -offset.x;
            offset.height = -offset.y;
            break;
            case 'bottom-left':
            offset.width = -offset.x;
            offset.height = offset.y;
            offset.y = 0;
            break;
            case 'top-right':
            offset.width = offset.x;
            offset.height = -offset.y;
            offset.x = 0;
            break;
            case 'bottom-right':
            offset.width = offset.x;
            offset.height = offset.y;
            offset.x = 0;
            offset.y = 0;
            break;
            case 'center-top':
            offset.x = 0;
            offset.height = -offset.y;
            break;
            case 'center-bottom':
            offset.height = offset.y;
            offset.y = 0;
            offset.x = 0;
            break;
            case 'center-left':
            offset.y = 0;
            offset.width = -offset.x;
            break;
            case 'center-right':
            offset.width = offset.x;
            offset.x = 0;
            offset.y = 0;
            break;
            case 'clipper':
            break;
            case 'slider-handle':
            this.onSliderMove(offset.x);
            return;
            default:
            return;
        }

        this.adjustClipperMetrics(offset, this.state.ratio);
    }

    onTouchEnd = e => {
        document.removeEventListener(this.isMobile ? 'touchmove' : 'mousemove', this.onTouchMove);
        document.removeEventListener(this.isMobile ? 'touchend' : 'mouseup', this.onTouchEnd);
        // this.clipper.removeEventListener(this.isMobile ? 'touchcancel' : 'mouseleave', this.onTouchEnd);
        if (this.state.showNotice) {
            this.setState({
                showNotice: false,
            });
            this.sliderNotice = null;
            this.clipRotateArea();
        }
    }

    renderOperation = () => {
        const prefixCls = this.props.prefixCls + '-menu';
        const renderRatioItems = () => {
            return defaultRatios.map(item => {
                return (
                    <View key={item.ratio} className={`${prefixCls}-center-item`}>
                        <Icon
                            type={item.icon}
                            color={item.ratio === this.state.ratio ? '#03c0ab' : '#333'}
                            onClick={e => this.onRatioChange(item.ratio)}
                        />
                    </View>
                );
            });
        }
        return (
            <View className={`${prefixCls}`} >
                <View className={`${prefixCls}-top`}>
                    <View className={`${prefixCls}-top-slider`}>
                        {!this.state.showNotice ? null :
                            <View id={'slider-notice'} className={`${prefixCls}-top-slider-notice`}>
                                <Text className={`${prefixCls}-top-slider-notice-text`}>{this.state.angle + ''}</Text>
                            </View>
                        }
                        <View id={'slider-track'} className={`${prefixCls}-top-slider-track`} />
                        <View id={'slider-rail-left'} className={`${prefixCls}-top-slider-rail-left`} />
                        <View id={'slider-rail-right'} className={`${prefixCls}-top-slider-rail-right`} />
                        <View className={`${prefixCls}-top-slider-dot`} />
                        <View id='slider-handle' className={`${prefixCls}-top-slider-handle`} />
                    </View>
                </View>
                <View className={`${prefixCls}-center`}>
                    {renderRatioItems()}
                </View>
                <View className={`${prefixCls}-bottom`}>
                    <View className={`${prefixCls}-bottom-cancel`} >
                        <Icon 
                            type='ie-cancel' 
                            size='md'
                            color='#333'
                            onClick={this.onCancel}
                        />
                    </View>
                    <View className={classnames(`${prefixCls}-rotate`)} >
                        <Icon
                            type='ie-rotate'
                            size='md'
                            color='#333'
                            onClick={this.onRotate}
                        />
                    </View>
                    <View className={classnames(`${prefixCls}-flip`)} >
                        <Icon
                            type='ie-flip-h'
                            size='md'
                            color='#333'
                            onClick={this.onFlip}
                        />
                    </View>
                    <View className={`${prefixCls}-bottom-confirm`} >
                        <Icon 
                            type='ie-confirm' 
                            size='md'
                            color='#333'
                            onClick={this.onConfirm}
                        />
                    </View>
                </View>
            </View>
        );
    }

    render() {
        const {
            prefixCls,
        } = this.props;

        return (
            <View className={prefixCls}>
                <View className={`${prefixCls}-zone`}>
                    <View 
                        ref={this.onImageCanvasRef}
                        className={`${prefixCls}-canvas`}
                    >
                        <canvas ref={el => this.canvas = el} style={{position: 'absolute'}} />
                    </View>
                    {!this.state.clip ? null :
                        <View 
                            ref={el => this.clipper = ReactDOM.findDOMNode(el)}
                            id='clipper'
                            className={`${prefixCls}-clipper`}
                        >
                            <View id='line-h1' className={`${prefixCls}-line-h1`}/>
                            <View id='line-h2' className={`${prefixCls}-line-h2`}/>
                            <View id='line-v1' className={`${prefixCls}-line-v1`}/>
                            <View id='line-v2' className={`${prefixCls}-line-v2`}/>
                            <View id='handle-tl' className={`${prefixCls}-handle-tl`}/>
                            <View id='handle-tr' className={`${prefixCls}-handle-tr`}/>
                            <View id='handle-bl' className={`${prefixCls}-handle-bl`}/>
                            <View id='handle-br' className={`${prefixCls}-handle-br`}/>
                            {this.state.ratio === RATIO_AUTO ? <View id='handle-ct' className={`${prefixCls}-handle-ct`}/> : null}
                            {this.state.ratio === RATIO_AUTO ? <View id='handle-cb' className={`${prefixCls}-handle-cb`}/> : null}
                            {this.state.ratio === RATIO_AUTO ? <View id='handle-cl' className={`${prefixCls}-handle-cl`}/> : null}
                            {this.state.ratio === RATIO_AUTO ? <View id='handle-cr' className={`${prefixCls}-handle-cr`}/> : null}
                        </View>
                    }
                </View>
                <View className={`${prefixCls}-menu`}>
                    {this.renderOperation()}
                </View>
            </View>
        );
    }
}

function edit(image, onConfirm) {
    let editor = document.createElement('div');
    document.body.appendChild(editor);
    const close = () => {
        ReactDOM.unmountComponentAtNode(editor);
        if (editor && editor.parentNode) {
            editor.parentNode.removeChild(editor);
        }
    };

    ReactDOM.render(
        <Editor 
            source={image} 
            onConfirm={onConfirm}
            onClose={close}
        />, 
        editor
    );
}

export default edit;