import DrawingMode from "../interface/drawingMode";
import consts from "../lib/consts";

const { drawingModes } = consts;
const components = consts.componentNames;

/**
 * IconMode class
 * @class
 * @ignore
 */
class IconMode extends DrawingMode {
  constructor() {
    super(drawingModes.ICON);
  }

  /**
   * start this icon mode
   * @param {Graphics} graphics - Graphics instance
   * @override
   */
  start(graphics) {
    const comp = graphics.getComponent(components.ICON);
    comp.start();
  }

  /**
   * stop this icon mode
   * @param {Graphics} graphics - Graphics instance
   * @override
   */
  end(graphics) {
    const comp = graphics.getComponent(components.ICON);
    comp.end();
  }
}

export default IconMode;
