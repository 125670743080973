//把px转换为rem
export function _px2rem(px) {
    return px / 75;
};

//计算适配后的像素大小
export function _px2adjustpx(px) {
    return _px2rem(px) * window.rem;
};

export function _rem2px(rem) {
    return rem * 75;
}