import React from 'react';
import { Image } from 'thanos';
import { ANSWER_RESULT } from 'Constants';
import './topiclist.less';

const CSS = 'topiclist';
const TopicList = React.memo((props) => {
  return (
    <div className={CSS}>
      {props.list.map((v, i) => (
        <div className={`${CSS}-item`} key={`answer-item-${i}`}>
          {v.submitRet === ANSWER_RESULT.error && (
            <div>
              <Image
                source={require('Images/fail.png').default}
                className='bg'
              />
              <div className='topic-index' style={{ color: '#D8271C' }}>
                {i + 1}
              </div>
            </div>
          )}
          {v.submitRet === ANSWER_RESULT.correct && (
            <div>
              <Image
                source={require('Images/success.png').default}
                className='bg'
              />
              <div className='topic-index' style={{ color: '#15A5A5' }}>
                {i + 1}
              </div>
            </div>
          )}
          {v.submitRet === ANSWER_RESULT.unanswer && (
            <div>
              <Image
                source={require('Images/unanswer.png').default}
                className='bg'
              />
              <div className='topic-index' style={{ color: '#989898' }}>
                {i + 1}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
});

export default TopicList;
