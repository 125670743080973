import React from 'react';
import PropTypes from 'prop-types';
import View from '../view';

export default class Text extends React.PureComponent {
    static propTypes = {
        Element: PropTypes.string,
        text: PropTypes.string,
    };

    static defaultProps = {
        Element: 'span'
    };

    constructor(props) {
        super(props);
        this.state = {
            text: this.props.children || this.props.text,
            hasChild: !!this.props.children,
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.hasChild && nextProps.children !== this.props.children) {
            this.text = nextProps.children;
        } else if (!this.state.hasChild && nextProps.text !== this.props.text) {
            this.text = nextProps.text;
        }
    }

    set text(text) {
        if (this.state.hasChild || text === this.state.text) return;

        this.setState({
            text: text,
        });
    }
    get text() {
        return this.state.hasChild ? this.props.children : this.state.text;
    }

    render() {
        const {
            children,
            ...props
        } = this.props;

        return <View {...props}>{this.text}</View>;
    }
}