import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

import Hls from 'hls.js';

import { Platform, Log } from '../util';

class PreloadView extends React.PureComponent {
    static propTypes = {
        source: PropTypes.string,
        onPreload: PropTypes.func,
        onClose: PropTypes.func,
    };

    duration = 0;

    componentDidMount() {
        const { source, onClose } = this.props;
        const isSourceValid = !source || source.slice(0, 4) === 'http';
        if (!this.video || !isSourceValid) {
            onClose && onClose();
            return;
        }

        if (!Platform.isMobile() && source.match('m3u8$') && !this.hls) {
            if(Hls.isSupported()) {
                this.hls = new Hls();
                this.hls.loadSource(source);
                this.hls.attachMedia(this.video);
                this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    this.play();
                });
            }
        } else {
            this.video.src = source;
        }
        
        this.video.muted = true;
        this.video.play();
    }

    componentWillUnmount() {
        if (this.video) {
            this.video.pause();
        }

        if (this.hls) {
            this.hls.destroy();
        }
    }

    onDurationChange = e => {
        const { onPreload , onClose } = this.props;
        this.duration = this.video.duration;
        if (this.duration > 1 && this.poster) {
            // Log.debug(this.poster);
            onPreload && onPreload(this.poster, this.duration);
            onClose && onClose();
        }
    }

    onTimeUpdate = e => {
        const { onPreload, onClose } = this.props;

        if (this.poster || this.video.currentTime < 0.1) return;

        this.video.pause();
        Log.debug('screenshot');

        const width = this.video.videoWidth;
        const height = this.video.videoHeight;

        const canvas = document.createElement('canvas');

        canvas.width = width;
        canvas.height = height;

        let context = canvas.getContext('2d');
        if (Platform.isIPhone() && width < height) { 
            context.rotate(90 * Math.PI / 180);
        }
        context.fillRect(0, 0, width, height);
        context.drawImage(this.video, 0, 0, width, height);
        this.poster = canvas.toDataURL('image/png');

        // Log.debug(this.poster);

        if (this.duration > 0) {
            onPreload && onPreload(this.poster, this.duration);
            onClose && onClose();
        }
    }

    render() {
        return (
            <video 
                ref={el => this.video = el} 
                className='tm-video-preload'
                autoPlay={false}
                preload='metadata'
                playsInline={true}
                crossOrigin='anonymous'
                x-webkit-airplay='true'
                x5-playsinline='true'
                webkit-playsinline='true'
                onTimeUpdate={this.onTimeUpdate}
                onDurationChange={this.onDurationChange}
            />
        );
    }
}

const load = (source, onPreload) => {
    const preload = document.createElement('div');
    document.body.appendChild(preload);

    const close = () => {
        ReactDOM.unmountComponentAtNode(preload);
        if (preload && preload.parentNode) {
            preload.parentNode.removeChild(preload);
        }
    }

    ReactDOM.render(
        <PreloadView
            source={source}
            onPreload={onPreload}
            onClose={close}
        />,
        preload
    );
}

export default { load }