import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import View from '../view';
import TouchFeedback from '../touch-feedback';
import Text from '../text';
import Modal from '../modal';
import Strings from '../strings';
import { Log } from '../util';
import PickerItem from './PickerItem';
import DataSource from './DataSource';

import './style/index.less';

export default class Picker extends React.Component {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        modalClassName: PropTypes.string,
        headerClassName: PropTypes.string,
        bodyClassName: PropTypes.string,
        itemClassName: PropTypes.string,
        itemStyles: PropTypes.array,
        columns: PropTypes.number,
        rows: PropTypes.oneOf([3, 5]),
        onCancel: PropTypes.func,
        onConfirm: PropTypes.func,
        onChange: PropTypes.func,
        renderHeader: PropTypes.func,
        dataSource: PropTypes.instanceOf(DataSource).isRequired,
        header: PropTypes.string,
    };

    static defaultProps = {
        prefixCls: 'tm-picker',
        rows: 5,
        columns: 3,
        header: '',
    };
    static Threshold = 3;
    static DataSource = null;

    selectedValues = [];
    closeable = true;

    state = {
        visible: false,
    };

    maskClosable = () => {
        return this.closeable;
    }

    onCloseableChange = closeable => {
        Log.verbose('closeable: ' + closeable);
        this.closeable = closeable;
    }

    activate() {
        if (this.state.visible) return;
        let data = {};
        this.selectedValues = [];
        for (let i = 0; i < this.props.columns; i++) {
            let columnData = this.props.dataSource.getData(i);
            data[`data${i}`] = columnData;
            this.selectedValues.push(columnData.source[columnData.index]);
        }
        this.setState({
            visible: true,
            ...data
        });
    }

    dismiss = () => {
        this.setState({
            visible: false,
        });
    }

    reset = () => {
        let data = {};
        this.selectedValues = [];
        for (let i = 0; i < this.props.columns; i++) {
            let columnData = this.props.dataSource.getData(i);
            data[`data${i}`] = columnData;
            this.selectedValues.push(columnData.source[columnData.index]);
        }
        this.setState({
            visible: true,
            ...data
        });
    }

    onCancel = () => {
        this.setState({
            visible: false,
        });

        this.props.onCancel && this.props.onCancel();
    }

    onConfirm = () => {
        this.setState({
            visible: false,
        });

        this.props.onConfirm && this.props.onConfirm();
    }

    onChange = (column, index, value) => {
        Log.verbose('Column ' + column + ' changes index - ' + index);
        const { dataSource, onChange, columns } = this.props;
        let data = this.state[`data${column}`];
        let columnData = dataSource.getData(column, index);
        let cacheData = {};
        let shouldUpdate = false;
        if (columnData !== data) {
            cacheData[`data${column}`] = columnData;
            shouldUpdate = true;
        } else {
            dataSource.setIndex(column, index);
        }

        // Log.verbose('Index - ' + dataSource.index(column));

        if (column < (columns - 1)) {
            for (let i = column + 1; i < columns; i++) {
                if (dataSource.shouldSyncColumn(column, i)) {
                    cacheData[`data${i}`] = dataSource.getData(i, dataSource.index(i), true);
                    let ii = cacheData[`data${i}`].index;
                    this.selectedValues[i] = cacheData[`data${i}`].source[ii];
                    dataSource.setIndex(i, ii);
                    shouldUpdate = true;
                }
            }
        }
        
        if (shouldUpdate) {
            setTimeout(() => {
                this.setState({
                    ...cacheData
                });
            }, 100);
        }

        this.selectedValues[column] = value;
        onChange && onChange(this.selectedValues);
    }

    render() {
        let {
            prefixCls,
            className,
            modalClassName,
            headerClassName,
            itemClassName,
            itemStyles,
            bodyClassName,
            rows,
            columns,
            header,
        } = this.props;

        if (Object.prototype.toString.call(itemStyles) !== '[object Array]' || itemStyles.length !== columns) {
            itemStyles = [];
            for (let i = 0; i < columns; i++) itemStyles.push({});
        }

        const itemStyle = {
            width: Math.round(10000 / columns) / 100 + '%',
        };

        const wrapCls = classnames(`${prefixCls}`, className);
        const wrapHeaderCls = classnames(`${prefixCls}-header`, headerClassName);

        const renderHeader = () => {
            if (this.props.renderHeader) {
                return this.props.renderHeader();
            }

            return (
                <View className={wrapHeaderCls}>
                    <TouchFeedback activeClassName={`${prefixCls}-header-left-active`}>
                        <Text className={`${prefixCls}-header-left`} onClick={this.onCancel}>{Strings.cancel}</Text>
                    </TouchFeedback>
                    <View className={`${prefixCls}-header-text`}>{header}</View>
                    <TouchFeedback activeClassName={`${prefixCls}-header-right-active`}>
                        <Text className={`${prefixCls}-header-right`} onClick={this.onConfirm}>{Strings.ok}</Text>
                    </TouchFeedback>
                </View>
            );
        }

        const renderPicker = () => {
            let items = [];
            for (let i = 0; i < columns; i++) {
                if (!this.state[`data${i}`]) break;
                const item = (
                    <PickerItem 
                        key={i}
                        rows={rows}
                        column={i}
                        style={{...itemStyle, ...itemStyles[i]}}
                        className={itemClassName} 
                        data={this.state[`data${i}`]} 
                        onChange={(index, value) => this.onChange(i, index, value)}
                        onCloseableChange={this.onCloseableChange}
                    />
                );
                items.push(item);
            }
            
            let splitterIndexs = [0, 1];
            if (rows === 5) {
                splitterIndexs = [1, 2];
            }
            
            const splitters = splitterIndexs.map(index => {
                return <View key={index} className={`${prefixCls}-splitter-${index}`} />
            });
            return (
                <View className={classnames(`${prefixCls}-body`, bodyClassName)} >
                    {items}
                    {splitters}
                </View>
            );
        }

        // Log.verbose('Render picker');

        return (
            <Modal
                popup
                className={modalClassName}
                maskClosable={this.maskClosable}
                animationType="slide-up"
                visible={this.state.visible}
                onClose={this.onCancel}
            >
                <View className={wrapCls} >
                    {renderHeader()}
                    {renderPicker()}
                </View>
            </Modal>
        );
    }
}