import React from 'react'
import { ScrollView } from 'thanos'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './index.less'

class Scroll extends React.PureComponent {
  render() {
    const { className, direction, style } = this.props
    return (
      <ScrollView
        className={classnames('g-scroll', className, direction)}
        style={style}
        orientation={direction}
      >
        {this.props.children}
      </ScrollView>
    );
  }
}

Scroll.propTypes = {
  direction: PropTypes.string, // vertical, horizontal
}

Scroll.defaultProps = {
  direction: 'vertical',
}

export default Scroll;
