import React from 'react';
import PropTypes from 'prop-types';

export default class StaticView extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        shouldUpdate: PropTypes.bool
    };

    shouldComponentUpdate(nextProps) {
        return nextProps.shouldUpdate;
    }

    render() {
        const {style, className} = this.props;

        return (
            <div style={style} className={className}>
                {this.props.render()}
            </div>
        );
    }
}