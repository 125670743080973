import React from 'react';
import ReactDOM from 'react-dom';

import { Log } from '../util';

import Share from './Share';

function show(actions, onShare) {
    if (!actions || Object.prototype.toString.call(actions) !== "[object Array]" || actions.length === 0) {
        Log.error('Invalid arguments, "actions" should be a non-empty array!');
        return;
    }
    const elem = document.createElement('div');
    document.body.appendChild(elem);

    const close = el => {
        ReactDOM.unmountComponentAtNode(el);
        if (el && el.parentNode) {
            el.parentNode.removeChild(el);
        }
    };

    ReactDOM.render(
        <Share
            actions={actions}
            onShare={onShare}
            onClose={close}
            element={elem}
        />,
        elem
    );
}

export default { 
    show, 
    WECHAT: Share.WECHAT, 
    TIMELINE: Share.TIMELINE, 
    QQ: Share.QQ, 
    QZONE: Share.QZONE, 
    WEIBO: Share.WEIBO, 
    LECHAT: Share.LECHAT, 
    MOMENT: Share.MOMENT, 
    COPYLINK: Share.COPYLINK 
};