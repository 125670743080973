import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import TouchFeedback from '../touch-feedback/index';
import View from '../view/index';

export default class Item extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        prefixCls: PropTypes.string,
        icon: PropTypes.node,
        disabled: PropTypes.bool,
        firstItem: PropTypes.bool,
        activeStyle: PropTypes.object,
        style: PropTypes.object,
    };

    static defaultProps = {
        prefixCls: 'tm-popover',
        disabled: false,
    };

    static myName = 'PopoverItem';

    render() {
        const {
            children,
            className,
            prefixCls,
            icon,
            disabled,
            firstItem,
            activeStyle,
            ...restProps
        } = this.props;

        const cls = classnames(`${prefixCls}-item`, className, {
            [`${prefixCls}-item-disabled`]: disabled,
        });

        let activeClass = `${prefixCls}-item-active `;
        if (firstItem) {
            activeClass += `${prefixCls}-item-fix-active-arrow`;
        }

        return (
            <TouchFeedback
                disabled={disabled}
                activeClassName={activeClass}
                activeStyle={activeStyle}
            >
                <View className={cls} {...restProps}>
                    <View className={`${prefixCls}-item-container`}>
                        {icon ? (
                        // tslint:disable-next-line:jsx-no-multiline-js
                        <View Element='span' className={`${prefixCls}-item-icon`} aria-hidden="true">
                            {icon}
                        </View>
                        ) : null}
                        <View Element='span' className={`${prefixCls}-item-content`}>{children}</View>
                    </View>
                </View>
            </TouchFeedback>
        );
    }
}
