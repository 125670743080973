export function getActiveIndex(tabs, activeKey) {
  for (let i = 0; i < tabs.length; i++) {
    if (tabs[i].key === activeKey) {
      return i;
    }
  }
  return -1;
}

export function getActiveKey(tabs, index) {
  return tabs[index].key;
}

export function setTransform(style, v) {
  style.transform = v;
  style.webkitTransform = v;
  style.mozTransform = v;
}

export function isTransformSupported(style) {
  return 'transform' in style ||
    'webkitTransform' in style ||
    'MozTransform' in style;
}

export function setTransition(style, v) {
  style.transition = v;
  style.webkitTransition = v;
  style.MozTransition = v;
}

export function getTransformPropValue(v) {
  return {
    transform: v,
    WebkitTransform: v,
    MozTransform: v,
  };
}

export function setPxStyle(el, value) {
  value =`${value}px, 0px, 0px`;
  setTransform(el.style, `translate3d(${value})`);
}
