import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import invariant from 'fbjs/lib/invariant';

import ScrollView from './ScrollView';
import Refresher from './Refresher';
import Loader from './Loader';
import StaticView from './StaticView';

const DEFAULT_PAGESIZE = 10;
const DEFAULT_EDGE_THRESHOLD = 50;

const [ACTION_NONE, ACTION_REFRESH, ACTION_LOAD] = [0, 1, 2];

export default class ListView extends React.Component {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        scrollable: PropTypes.bool,
        useCustomEvent: PropTypes.bool,
        onScroll: PropTypes.func,

        pageSize: PropTypes.number,

        row: PropTypes.number,
        distance: PropTypes.number,

        dataSource: PropTypes.array, // presented as [row1, row2, row3, ...]
        renderRow: PropTypes.func,
        rowHasChanged: PropTypes.func,

        viewDidMount: PropTypes.func,

        onRefresh: PropTypes.func,
        onLoad: PropTypes.func,
        hasMore: PropTypes.func,
        forceRefresh: PropTypes.bool,

        forceUpdate: PropTypes.bool,

        horizontalTouchable: PropTypes.bool,
    };

    static defaultProps = {
        prefixCls: 'tm-listview',
        rowHasChanged: (row1, row2) => row1 !== row2,
        scrollable: true,
        useCustomEvent: false,
        pageSize: DEFAULT_PAGESIZE,
        forceUpdate: false,
        horizontalTouchable: true,
    };

    lastDataSource = [];
    latestMetrics = {};
    rowRefs = {};
    firstVisibleItem = {row: 0, distance: 0};

    constructor(props) {
        super(props);

        invariant(props.dataSource && Object.prototype.toString.call(props.dataSource) === '[object Array]', 'a array formated dataSource MUST be passed in props');
        invariant(props.row === undefined || props.row < props.dataSource.length, 'row specified in props out of range');

        this.state = {
            width: 0,
            height: 0,
            pageSize: props.pageSize,
            startRow: 0,
            endRow: 0,
        };

        this.firstVisibleItem = {row: !props.row ? 0 : props.row, distance: !props.distance ? 0 : props.distance};
        const state = this.calibrateContextParams();
        this.state.startRow = state.startRow;
        this.state.endRow = state.endRow;
    }

    set width(width) {
        if (this.state.width === width) {
            return;
        }
        this.setState({
            width
        });
    }
    get width() {
        return this.state.height;
    }

    set height(height) {
        if (this.state.height === height) {
            return;
        }

        this.setState({
            height
        });
    }

    set dataSource(ds) {
        if (!ds) {
            return;
        }

        invariant(Object.prototype.toString.call(ds) === '[object Array]', 'a array formated dataSource MUST be passed in args');

        if (ds !== this.state.dataSource) {
            this.lastDataSource = this.props.dataSource;
            let count = ds.length - this.lastDataSource.length;
            if (count > 0) {
                count = count > this.state.pageSize ? this.state.pageSize : count;
                this.setState({
                    endRow: this.state.endRow + count,
                    dataSource: ds,
                });
            } else {
                this.setState({
                    startRow: 0,
                    endRow: ds.length - 1,
                    dataSource: ds,
                });
            }
        }
    }

    componentDidMount() {
        if (this.listview) {
            const elem = ReactDOM.findDOMNode(this.listview);
            const state = this.calibrateContextParams();
            this.setState({
                width: elem.clientWidth,
                height: elem.clientHeight,
                startRow: state.startRow,
                endRow: state.endRow,
            }, () => {
                if (this.refresher) {
                    this.listview.refresher = this.refresher;
                    if (!!this.props.forceRefresh) {
                        this.refresher.activate(true)
                    }
                }
                if (this.loader) {
                    this.listview.loader = this.loader;
                }
                this.props.viewDidMount && this.props.viewDidMount();
                if (this.props.row !== undefined && this.props.distance !== undefined) {
                    this.firstVisibleItem = {row: !this.props.row ? 0 : this.props.row, distance: !this.props.distance ? 0 : this.props.distance};
                    this.scrollToRow(this.firstVisibleItem.row, this.firstVisibleItem.distance, 0);
                }
            });
        }
    }

    componentWillUpdate(nextProps, nextState) {
        if ('dataSource' in nextProps && nextProps.dataSource !== this.state.dataSource) {
            this.lastDataSource = this.props.dataSource;
            let count = nextProps.dataSource.length - this.lastDataSource.length;
            if (count > 0) {
                count = count > this.state.pageSize ? this.state.pageSize : count;
                nextState.endRow = this.state.endRow + count
            } else if(count < 0) {
                nextState.startRow = 0
                nextState.endRow = nextProps.dataSource.length - 1
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.forceUpdate) {
            return true;
        }

        if (nextProps.dataSource !== this.props.dataSource) {
            return true;
        }

        if (nextState.width !== this.state.width || 
            nextState.height !== this.state.height || 
            nextState.pageSize !== this.state.pageSize ||
            nextState.startRow !== this.state.startRow ||
            nextState.endRow !== this.state.endRow) {
            return true;
        }

        return false;
    }

    refreshable = () => this.state.startRow === 0 && this.props.onRefresh && typeof this.props.onRefresh === 'function'
    loadable = () => this.state.endRow === this.props.dataSource.length - 1 && this.props.onLoad && typeof this.props.onLoad === 'function'

    // get the first visible item properties, if row = -1, and ListView has header, means top item is header
    firstVisibleItemProps = () => {
        return this.firstVisibleItem;
    }

    rowHasChanged = index => {
        let lastRows = this.lastDataSource;
        let rows = this.props.dataSource;

        if (index >= lastRows.length || !this.rowRefs['row' + index]) {
            return true;
        }
        return this.props.rowHasChanged(lastRows[index], rows[index]);
    }

    // scroll to specific row
    //
    // @param row           the row about to show
    // @param duration      scroll animation duration, 0, or undefined means no animation
    scrollToRow = (row, distance, duration) => {
        if (!this.listview) {
            return;
        }

        if (!distance) distance = 0;
        if (!duration) duration = 0;

        const scroll = () => {
            let height = 0;
            for (let i = this.state.startRow; i <= row; i++) {
                const elem = this.rowRefs['row' + i];
                if(elem) {
                    height += elem.clientHeight;
                }
            }

            height -= distance;
            this.listview.scrollTo(height, duration);
        };

        let startRow = this.state.startRow;
        let endRow = this.state.endRow;
        let maxRowIndex = this.props.dataSource.length - 1;
        
        if (row <= startRow) {
            startRow -= this.state.pageSize;
            startRow = startRow < 0 ? 0 : startRow;
        }

        if (row >= endRow) {
            endRow += this.state.pageSize;
            endRow = endRow > maxRowIndex ? maxRowIndex : endRow;
        }

        if (startRow !== this.state.startRow || endRow !== this.state.endRow) {
            this.setState({
                startRow: startRow,
                endRow: endRow,
            }, () => {
                scroll();
            });
            return;
        }

        scroll();
    }

    // scroll to top of ListView, only current loaded data will be shown
    scrollToTop = duration => {
        this.setState({
            startRow: 0,
        }, () => {
            this.listview.scrollTo(0, duration);
        });
    }

    // scroll to bottom of ListView, only current loaded data will be shown, wont trigger load more action
    scrollToBottom = duration => {
        this.setState({
            endRow: this.props.dataSource.length - 1,
        }, () => {
            const metrics = this.metrics();
            const offset = metrics.contentLength - this.state.height;
            this.listview.scrollTo(offset, duration, false);
        });
    }

    // scroll by distance, relative to current position
    scrollBy = (distance, duration) => {
        this.listview.scrollBy(distance, duration);
    }

    scrollTo = (position, duration) => {
        this.listview.scrollTo(position, duration);
    }

    stopRefreshing = () => {
        if (this.refresher && this.refresher.refreshing) {
            this.refresher.height = 0;
        }
    }

    stopLoading = () => {
        if (this.loader && this.loader.loading) {
            this.loader.loading = false;
        }
    }

    metrics = () => {
        return this.listview.metrics();
    }

    calibrateContextParams = action => {
        action = !action ? ACTION_NONE : action;
        let pageSize = this.state.pageSize;
        let startRow = this.state.startRow;
        let endRow = this.state.endRow;
        const maxRowIndex = this.props.dataSource.length - 1;
        let count = 0;

        if (action === ACTION_NONE) {
            if (this.firstVisibleItem.row > pageSize) {
                startRow = this.firstVisibleItem.row - (this.firstVisibleItem.row % pageSize + pageSize);
            } else {
                startRow = 0;
            }

            if (endRow === 0) {
                endRow = startRow + pageSize * 3;
            }
   
            if (endRow > maxRowIndex) {
                endRow = maxRowIndex;
            }
        } else if (action === ACTION_LOAD) {
            count = maxRowIndex - endRow;
            if (count === 0) return;
            count = count > pageSize ? pageSize : count;
            endRow += count;
        } else if (action === ACTION_REFRESH) {
            count = startRow;
            if (count === 0) return;
            count = count > pageSize ? pageSize : count;
            startRow -= count;
        }

        const state = {
            startRow: startRow,
            endRow: endRow
        };
        return state;
    }

    loadMoreIfNecessary = metrics => {
        const maxRowIndex = this.props.dataSource.length - 1;
        if (metrics.distanceFromEnd < DEFAULT_EDGE_THRESHOLD) {
            if (this.state.endRow === maxRowIndex && this.loader) {
                this.listview.loadMore();
            } else if (this.state.endRow < maxRowIndex) {
                const state = this.calibrateContextParams(ACTION_LOAD);
                this.setState(state);
            }
        }
    }

    refreshIfNecessary = metrics => {
        if (metrics.offset < DEFAULT_EDGE_THRESHOLD && this.state.startRow > 0) {
            const state = this.calibrateContextParams(ACTION_REFRESH);
            this.setState(state);
        }
    }

    findFirstVisibleItem = metrics => {
        const offset = metrics.offset;
        let height = 0;
        for (let i = this.state.startRow; i <= this.state.endRow; i++) {
            const elem = this.rowRefs['row' + i];
            height += elem.clientHeight;
            if (offset < height) {
                return {
                    row: i,
                    distance: height - offset,
                };
            }
        }
        return {row: 0, distance: 0};
    }

    onScroll = e => {
        const { onScroll } = this.props;

        if (!this.listview || this.shouldSkipScroll) {
            return;
        }

        const metrics = this.metrics();
        // console.log(metrics);
        const distance = metrics.offset - this.latestMetrics.offset;
        this.latestMetrics = metrics;
        if (distance < 0) {
            this.refreshIfNecessary({...metrics});
        } else if (distance > 0) {
            this.loadMoreIfNecessary({...metrics});
        }

        this.firstVisibleItem = this.findFirstVisibleItem({...metrics});
        // console.log(this.firstVisibleItem);
        e.firstVisibleItem = this.firstVisibleItem;
        onScroll && onScroll(e);
    }

    onRefresherRef = el => {
        this.refresher = el;
        if (this.listview) {
            this.listview.refresher = this.refresher;
        }
    }

    onLoaderRef = el => {
        this.loader = el;
        if (this.listview) {
            this.listview.loader = this.loader;
        }
    }

    render() {
        const {
            dataSource,
            onRefresh,
            onLoad,
            hasMore,
            renderRow,
        } = this.props;

        const itemCount = dataSource.length;
        const items = [];
        if (this.refreshable()) {
            const refresher = (
                <Refresher 
                    key='refresher'
                    ref={this.onRefresherRef} 
                    onRefresh={onRefresh}
                />
            );
            items.push(refresher);
        }

        const endRow = (itemCount - 1) > this.state.endRow ? this.state.endRow : itemCount - 1;
        for (let i = this.state.startRow; i <= endRow; i++) {
            const item = (
                <StaticView
                    key={'row' + i}
                    ref={el => this.rowRefs['row' + i] = ReactDOM.findDOMNode(el)}
                    shouldUpdate={this.rowHasChanged(i)}
                    render={renderRow.bind(null, i, dataSource[i])}
                />
            );
            items.push(item);
        }
        
        if (this.loadable()) {
            const loader = (
                <Loader
                    key='loader'
                    ref={this.onLoaderRef}
                    onLoad={onLoad}
                    hasMore={hasMore}
                />
            );
            items.push(loader);
        }

        return React.cloneElement(<ScrollView {...this.props} onScroll={this.onScroll} />, {
            ref: el => this.listview = el || this.listview,
            refreshable: this.refreshable,
        }, items);
    }
}