import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view';
import { Platform, Log, Event, _px2rem, _rem2px } from '../util';
import Operation from './Operation';

import './style/index.less';

const fonts = {
    small: {
        rem: _px2rem(20),
        pixel: 12,
        size: 2,
    },
    medium: {
        rem: _px2rem(28),
        pixel: 16,
        size: 3,
    },
    large: {
        rem: _px2rem(36),
        pixel: 20,
        size: 4,
    },
    'x-large': {
        rem: _px2rem(44),
        pixel: 24,
        size: 5,
    },
    'xx-large': {
        rem: _px2rem(52),
        pixel: 28,
        size: 6
    }
}

function findFontByRem(rem) {
    const pixel = _rem2px(rem);

    for (const key in fonts) {
        if (fonts.hasOwnProperty(key)) {
            const font = fonts[key];
            const pixel1 = _rem2px(font.rem);
            if (Math.abs(pixel1 - pixel) < 1) {
                return key;
            }
        }
    }

    return 'medium';
}

const blankValue = '<p><br></p>';

export default class TextEdit extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        prefixCls: PropTypes.string,
        content: PropTypes.string,
        placeholder: PropTypes.string,
        readonly: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
        mode: PropTypes.oneOf(['fixed', 'collapse', 'float', 'plaintext']),
        onChange: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-textedit',
        readonly: false,
        mode: 'collapse',
    };

    state = {
        contentempty: true,
        focus: false,
        operateZone: this.props.mode === 'fixed' ? 'display' : 'hidden', // 'display', 'hidden', 'scalein', 'scaleout', 'fadein', 'fadeout'
        menuVisibility: false,
    };

    opstate = {
        italic: false,
        underline: false,
        bold: false,
        fontColor: '#000000',
        fontSize: 'medium',
    }

    lastOpstate = this.opstate;

    lastTouch = 0;
    lastTouchTime = 0;
    stopInertiaMove = false;
    dragging = false;
    velocity = 0;
    lastRange = null;

    zoneState = this.state.operateZone;

    focusWithKeyboard = false;

    componentDidMount() {
        const { content } = this.props;
        if (this.editor) {
            if (!content || content === '') {
                this.editor.innerHTML = blankValue;
            } else {
                this.editor.innerHTML = content;
            }
            this.setState({
                contentempty: this.isEmpty()
            });
        }

        if (this.operation) {
            this.operationElem = ReactDOM.findDOMNode(this.operation);
            this.operationElem.addEventListener('animationend', this.onAnimationEnd);
            this.operationElem.addEventListener('webkitAnimationEnd', this.onAnimationEnd);
        }
        if (!this.isPlainText()) document.addEventListener('selectionchange', this.onSelectionChange);
        document.addEventListener('click', this.onClick);
        if(Platform.isMobile()) {
            document.addEventListener('touchstart', this.onTouch);
        }
        Event.subscribe('keyboard', this.onKeyboardEvent, true);
    }

    componentWillUnmount() {
        if (this.operationElem) {
            this.operationElem.removeEventListener('animationend', this.onAnimationEnd);
            this.operationElem.removeEventListener('webkitAnimationEnd', this.onAnimationEnd);
        }

        if (!this.isPlainText()) document.removeEventListener('selectionchange', this.onSelectionChange);
        document.removeEventListener('click', this.onClick);
        if(Platform.isMobile()) {
            document.removeEventListener('touchstart', this.onTouch);
        }
        Event.unsubscribe('keyboard', this.onKeyboardEvent);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.content !== this.props.content) {
            if ('content' in nextProps) {
                this.editor.innerHTML = nextProps.content;
            } else {
                this.editor.innerHTML = blankValue;
            }
        }

        this.setState({
            contentempty: this.isEmpty()
        });
    }

    get text() {
        if (this.props.mode !== 'plaintext') {
            return '';
        }

        return this.editor.innerText;
    }
    set text(text) {
        if (this.props.mode === 'plaintext') {
            this.editor.innerText = text;
        }
    }

    set html(html) {
        this.editor.innerHTML = html;
        this.setState({contentempty: this.isEmpty()})
    }

    get html() {
        return this.editor.innerHTML;
    }

    isEmpty = () => this.editor.innerHTML === blankValue || this.editor.innerHTML === ''
    isCollapsible = () => this.props.mode === 'collapse'
    isFloat = () => this.props.mode === 'float'
    isFixed = () => this.props.mode === 'fixed'
    isPlainText = () => this.props.mode === 'plaintext'
    isEditable = () => {
        const { readonly } = this.props;
        if (!readonly) return true;
        if (typeof readonly === 'boolean') {
            return !readonly;
        } else if (typeof readonly === 'string') {
            return readonly !== 'readonly' && readonly !== 'true';
        }

        return true;
    }

    getMetrics = () => {
        return {
            visibleLength: this.editor.offsetHeight,
            contentLength: this.editor.scrollHeight,
            offset: this.editor.scrollTop,
        };
    }

    focus() {
        if (this.editor && this.isEditable()) {
            this.editor.focus();
        }
    }

    blur() {
        if (this.editor && this.isEditable()) {
            this.editor.blur();
        }
    }

    matchDOM(node, target) {
        if (!target || !node) return false;
        if (node === target) return true;
        let parent = target.parentNode;
        while (parent) {
            if (parent === node && parent.id === node.id) return true;
            parent = parent.parentNode;
        }

        return false;
    }

    showOperationZone = visible => {
        if (!this.isFixed()) {
            let operateZone = 'hidden';
            if (this.state.operateZone === 'hidden' && visible && !this.shouldHideOperation) {
                operateZone = this.isFloat() ? 'fadein' : 'scalein';
            } else if (this.state.operateZone === 'display' && !visible) {
                operateZone = this.isFloat() ? 'fadeout' : 'scaleout';
            } else {
                return;
            }

            this.setState({
                operateZone: operateZone
            }, () => {
                this.zoneState = 'idle';
            });
        }
    }

    onKeyboardEvent = height => {
        if (!this.state.focus && !this.focusWithKeyboard) {
            return;
        }

        this.focusWithKeyboard = height > 0;

        if (Platform.isIPhone()) {
            if (height === 0) {
                document.body.scrollTop = 0;
            }
            window.keyboardShown = height > 0;
            return;
        }

        if (height > 0 && window.keyboardShown) {
            return;
        }

        window.keyboardHeight = height;
        if (!this.editor) return;
        if (window.keyboardBottom === undefined) {
            window.keyboardBottom = 0;
        }

        if (height > 0) {
            if (!this.state.focus) return;
            height = height / window.devicePixelRatio;
            const rect = this.editor.getBoundingClientRect();
            const documentHeight = document.documentElement.clientHeight;
            const deltaHeight = documentHeight - height;
            if (deltaHeight > rect.bottom) {
                if (deltaHeight > (rect.bottom + window.keyboardBottom)) {
                    height = 0;
                } else {
                    height = rect.bottom - window.keyboardBottom - deltaHeight;
                }
            } else {
                height = rect.bottom - deltaHeight;
            }
        }
        window.keyboardBottom = height;
        window.keyboardShown = window.keyboardHeight > 0;
        document.documentElement.style.transform = `translate3d(0, -${height}px, 0)`;
        document.documentElement.style.webkitTransform = `translate3d(0, -${height}px, 0)`;
    }

    onAnimationEnd = e => {
        if (e.animationName === 'TEScaleIn' || e.animationName === 'TEFadeIn') {
            this.setState({
                operateZone: 'display'
            });
        } else if (e.animationName === 'TEScaleOut' || e.animationName === 'TEFadeOut') {
            this.setState({
                operateZone: 'hidden'
            });
        }
    }

    onFocus = e => {
        Log.info('onFocus');
        let operateZone = 'display';
        if (this.zoneState === 'touch' || this.zoneState === 'menu' || this.isFixed()) {
            operateZone = 'display';
        } else if (this.state.operateZone === 'hidden') {
            if (this.isCollapsible()) {
                operateZone = 'scalein';
            } else if (this.isFloat()) {
                operateZone = 'fadein';
            }
        }
        this.setState({
            focus: true,
            operateZone: operateZone,
        }, () => {
            this.zoneState = 'idle';
            if (window.keyboardHeight && window.keyboardHeight > 0) {
                this.onKeyboardEvent(window.keyboardHeight);
            }
        });

        // Log.info('focus', this.lastRange);
        if (this.lastRange) {
            let selection = getSelection();
            selection.removeAllRanges();
            selection.addRange(this.lastRange);
        }

        this.props.onFocus && this.props.onFocus(e);
        this.shouldSkipOnBlur = false;
    }

    onBlur = e => {
        Log.info('onBlur');
        let operateZone = 'hidden';
        if (this.zoneState === 'touch' || this.zoneState === 'menu' || this.isFixed()) {
            operateZone = 'display';
        } else if (this.state.operateZone === 'display') {
            operateZone = this.isCollapsible() ? 'scaleout' : 'fadeout';
        }
        this.setState({
            focus: false,
            operateZone: operateZone,
        }, () => {
            if (this.zoneState === 'touch') {
                if (!this.linkVisible) {
                    this.focus();
                }
                this.zoneState = 'idle';
            }
        });

        e.target.value = this.editor.innerHTML === '<p><br/></p>' ? '' : this.editor.innerHTML;

        if (!this.shouldSkipOnBlur || this.isPlainText()) {
            if (window.keyboardShown) {
                setTimeout(() => {
                    document.documentElement.style.transform = `translate3d(0, 0px, 0)`;
                    document.documentElement.style.webkitTransform = `translate3d(0, 0px, 0)`;
                }, 0)
            }
            this.shouldSkipOnBlur = true;
            this.props.onBlur && this.props.onBlur(e);
        }
    }

    onSelectionChange = e => {
        const target = e.currentTarget && e.currentTarget.activeElement;
        if (target !== this.editor) {
            return;
        }

        let selection = getSelection();
        if (selection.rangeCount > 0) {
            const range = selection.getRangeAt(0);
            if (range) {
                this.lastRange = range;
            }
        }

        let node = selection.anchorNode;

        // Update fontsize, replace 'small', 'medium' ...  with pixel size
        const updateFontSize = node => {
            const fontSize = node.style.fontSize;
            const font = fonts[fontSize];
            if (font) {
                node.style.fontSize = font.rem + 'rem';
            }
        };

        while (node) {
            if (node.nodeName === 'P') {
                break;
            }

            if (node.nodeName === 'SPAN') {
                updateFontSize(node);
            }

            node = node.parentNode;
        }

        if (!this.shouldUpdateOperateZone) {
            return;
        }

        // Log.info(e);

        const opstate = {
            italic: false,
            underline: false,
            bold: false,
            fontColor: '#000000',
            fontSize: 'medium',
        }

        node = selection.anchorNode;
        while (node) {
            if (node.nodeName === 'P') {
                break;
            }

            if (node.nodeName === 'I') {
                if (!opstate.italic) {
                    opstate.italic = true;
                }
            }

            if (node.nodeName === 'U') {
                if (!opstate.underline) {
                    opstate.underline = true;
                }
            }

            if (node.nodeName === 'B') {
                if (!opstate.bold) {
                    opstate.bold = true;
                }
            }

            if (node.nodeName === 'SPAN') {
                let rem = 0;
                const fontSizeString = node.style.fontSize;
                if (Platform.isIPhone() && fontSizeString.indexOf("px") !== -1) {
                    const pixel = parseInt(fontSizeString.replace('px'), 10) * window.devicePixelRatio;
                    rem = _px2rem(pixel);
                } else {
                    rem = parseFloat(fontSizeString.replace('rem'));
                }
                if (opstate.fontSize === 'medium' && rem > 0) {
                    opstate.fontSize = findFontByRem(rem);
                }

                const fontColor = node.style.color;
                if (opstate.fontColor === '#000000' && !!fontColor) {
                    opstate.fontColor = fontColor;
                }

                const fontWeight = node.style.fontWeight;
                if (!opstate.bold && fontWeight === 'bold') {
                    opstate.bold = true;
                }

                const fontStyle = node.style.fontStyle;
                if (!opstate.italic && fontStyle === 'italic') {
                    opstate.italic = true;
                }

                const textDecoration = node.style.textDecoration;
                if (!opstate.underline && textDecoration === 'underline') {
                    opstate.underline = true;
                }
            }

            node = node.parentNode;
        }

        if (this.activeMenuItem === 'bold' ||
            this.activeMenuItem === 'italic' ||
            this.activeMenuItem === 'underline' ||
            this.activeMenuItem === 'fontcolor' ||
            this.activeMenuItem === 'fontsize' ||
            this.activeMenuItem === 'op'
        ) {
            if (this.activeMenuItem === 'bold') {
                opstate.bold = this.menuTouched ? !this.opstate.bold : this.opstate.bold;
                opstate.italic = this.opstate.italic;
                opstate.underline = this.opstate.underline;
            } else if (this.activeMenuItem === 'italic') {
                opstate.italic = this.menuTouched ? !this.opstate.italic : this.opstate.italic;
                opstate.bold = this.opstate.bold;
                opstate.underline = this.opstate.underline;
            } else if (this.activeMenuItem === 'underline') {
                opstate.underline = this.menuTouched ? !this.opstate.underline : this.opstate.underline;
                opstate.bold = this.opstate.bold;
                opstate.italic = this.opstate.italic;
            } else {
                opstate.bold = this.opstate.bold;
                opstate.italic = this.opstate.italic;
                opstate.underline = this.opstate.underline;
            }

            opstate.fontColor = this.opstate.fontColor;
            opstate.fontSize = this.opstate.fontSize;

            if (this.menuTouched) {
                this.updateContentStyle = true;
            }
            if (Platform.isMobile()) {
                this.shouldUpdateOperateZone = false;
            }
            this.menuTouched = false; // Avoid the second selection change event on single menu item touched
        }
        console.log(opstate);
        this.opstate = opstate;
        this.operation.update(opstate);

        this.showOperationZone(true);
    }

    onClick = e => {
        // e.stopPropagation();
        const target = e.target;
        if (!this.matchDOM(this.editor, target)) {
            return;
        }

        if (Platform.isIPhone() && !this.state.focus) {
            this.operation && this.operation.closeMenu();
            this.zoneState = 'idle';
            this.shouldHideOperation = false;
            this.shouldUpdateOperateZone = true;
            this.shouldSkipOnBlur = true;
            this.showOperationZone(true);
            this.focus();
        }
    }

    onTouch = e => {
        // Log.info('editor touch');
        // e.stopPropagation();
        this.zoneState = 'idle';
        const target = e.target;
        const textEditTouched = this.matchDOM(this.textedit, target);
        if (!textEditTouched) {
            this.shouldHideOperation = true;
            this.operation && this.operation.closeMenu();
            this.shouldSkipOnBlur = false;
            this.shouldUpdateOperateZone = false;
            setTimeout(() => {
                this.blur();
            }, 200);
            return;
        }

        if (this.matchDOM(this.editor, target)) {
            this.operation && this.operation.closeMenu();
        }

        this.activeMenuItem = 'none';
        const opEl = ReactDOM.findDOMNode(this.operation);
        this.menuTouched = this.matchDOM(opEl, target);
        if (this.menuTouched) {
            this.activeMenuItem = this.operation.touchedMenuItem(e);
            if (this.activeMenuItem === 'none') {
                this.activeMenuItem = 'op';
            }
        }

        this.linkVisible = this.matchDOM(this.operation ? this.operation.link : null, target);

        this.shouldHideOperation = false;
        this.shouldSkipOnBlur = true;
        this.shouldUpdateOperateZone = true;
        this.showOperationZone(true);
        this.zoneState = !this.state.focus && this.state.operateZone === 'hidden' ? 'idle' : 'touch';
        if (this.zoneState === 'idle') {
            this.focus();
        }

        // Log.info(this.lastRange);
    }

    onOperationChange = (type, value) => {
        Log.info('onOperationChange');
        let selection = getSelection();
        let node = selection.anchorNode;
        if (!node) {
            // Log.info(this.lastRange);
            // In case editor focus not ready for link, restore selection for lastRange
            if (type === 'link' && this.lastRange) {
                selection = getSelection();
                selection.removeAllRanges();
                selection.addRange(this.lastRange);
                node = selection.anchorNode;
            }
        }

        if (!this.matchDOM(this.editor, node)) {
            return;
        }

        if (!Platform.isMobile()) {
            this.shouldUpdateOperateZone = false;
        }
        document.execCommand('styleWithCSS', false, true);
        if (type === 'fontsize') {
            if (this.hasSelectContent()) {
                document.execCommand('fontSize', false, fonts[value].size);
                this.updateContentStyle = false;
            } else {
                this.opstate.fontSize = value;
                this.activeMenuItem = type;
                this.updateContentStyle = true;
            }
        } else if (type === 'fontcolor') {
            if (this.hasSelectContent()) {
                document.execCommand('foreColor', false, value);
                this.updateContentStyle = false;
            } else {
                this.opstate.fontColor = value;
                this.activeMenuItem = type;
                this.updateContentStyle = true;
            }
        } else if (type === 'bold' && this.hasSelectContent()) {
            document.execCommand('bold');
            this.updateContentStyle = false;
        } else if (type === 'italic' && this.hasSelectContent()) {
            document.execCommand('italic');
            this.updateContentStyle = false;
        } else if (type === 'underline' && this.hasSelectContent()) {
            document.execCommand('underline');
            this.updateContentStyle = false;
        } else if (type === 'link') {
            if (value.url === '') {
                return;
            }

            let url = value.url;
            if (url.search(/:/) < 0) {
                url = 'http://' + url;
            }

            if (url.search(/\?.+=/) > 0) {
                url = url + '&_ljshellUrlOpenInBrowser=true';
            } else {
                url = url + '?_ljshellUrlOpenInBrowser=true';
            }

            if (value.brief === '') {
                document.execCommand('createLink', false, url);
                return;
            }

            const linkHTML = '<a href="' + url + '">' + value.brief + '</a>';
            document.execCommand('insertHTML', false, linkHTML);
        }
    }

    onOperationMenuShown = shown => {
        // Log.info('onOperationMenuShown');
        this.zoneState = 'menu'
        this.setState({
            menuVisibility: shown
        }, () => {
            if (shown) {
                this.blur();
            } else {
                if (this.shouldHideOperation) {
                    let operateZone = 'display';
                    if (this.isCollapsible()) operateZone = 'scaleout';
                    else if (this.isFloat()) operateZone = 'fadeout';
                    this.setState({
                        focus: false,
                        operateZone: operateZone,
                    }, () => {
                        this.zoneState = 'idle';
                    });
                }
            }
        });
    }

    onKeyDown = e => {
        // Log.info(e);
        if (this.activeMenuItem === 'bold' ||
            this.activeMenuItem === 'italic' ||
            this.activeMenuItem === 'underline' ||
            this.activeMenuItem === 'fontsize' ||
            this.activeMenuItem === 'fontcolor') {
            if (this.updateContentStyle) {
                document.execCommand('styleWithCSS', false, true);
                let fontsize = fonts[this.opstate.fontSize].size;
                if (!fontsize) fontsize = 3;

                if (document.queryCommandState('bold') !== this.opstate.bold) {
                    document.execCommand('bold');
                    if (!document.queryCommandState('bold') && Platform.isWindows()) {
                        document.execCommand('insertHTML', false, '&zwnj;');
                    }
                }

                if (document.queryCommandState('italic') !== this.opstate.italic) {
                    document.execCommand('italic');
                    if (!document.queryCommandState('italic') && Platform.isWindows()) {
                        document.execCommand('insertHTML', false, '&zwnj;');
                    }
                }

                if (document.queryCommandState('underline') !== this.opstate.underline) {
                    document.execCommand('underline');
                    if (!document.queryCommandState('underline') && Platform.isWindows()) {
                        document.execCommand('insertHTML', false, '&zwnj;');
                    }
                }

                if (this.lastOpstate.fontSize !== this.opstate.fontSize) {
                    document.execCommand('fontSize', false, fontsize);
                }

                if (this.lastOpstate.fontColor !== this.opstate.fontColor) {
                    document.execCommand('foreColor', false, this.opstate.fontColor);
                }
            }
        }
        this.lastOpstate = this.opstate;
        this.updateContentStyle = false;
    }

    onCompositionStart = e => {
        // console.log(e);
        this.shouldUpdateOperateZone = false;
    }

    onCompositionEnd = e => {
        // console.log(e);
        this.shouldUpdateOperateZone = true;
    }

    onInput = e => {
        if (!this.editor) {
            return;
        }

        // Log.info(e);

        if (this.isFloat() && this.state.operateZone === 'display') {
            this.setState({
                operateZone: 'fadeout'
            }, () => {
                this.zoneState = 'idle';
            });
        }

        this.shouldHideOperation = true;

        // Reset the editor's content if whose content is empty, and make sure placeholder can display correctly
        if (this.editor.innerHTML === '') {
            this.editor.innerHTML = blankValue;
        }

        this.setState({
            contentempty: this.isEmpty()
        });

        // Log.verbose(this.editor.innerHTML);

        this.props.onChange && this.props.onChange(this.isEmpty() ? '' : this.editor.innerHTML);
    }

    onTouchBegin = e => {
        this.dragging = true;
        this.velocity = 0;
        this.lastTouch = e.changedTouches ? e.changedTouches[0].clientY : e.clientY;
        this.lastTouchTime = Date.now();
        this.stopInertiaMove = true;
        // this.editor.focus();
    }

    onTouchMove = e => {
        if (this.dragging) {
            let touch =  e.changedTouches ? e.changedTouches[0].clientY : e.clientY;
            let touchOffset = touch - this.lastTouch;

            this.editor.scrollTop -= touchOffset;

            let touchTime = Date.now();
            let duration = touchTime - this.lastTouchTime;

            this.velocity = duration > 0 ? touchOffset / duration : 0;
            this.lastTouch = touch;
            this.lastTouchTime = touchTime;
            this.stopInertiaMove = true;
        }
    }

    onTouchEnd = e => {
        // const selection = getSelection();
        // if (selection.rangeCount > 0) {
        //     this.lastRange = selection.getRangeAt(0);
        // }

        if (!this.dragging) {
            return;
        }

        this.dragging = false;
        this.stopInertiaMove = false;

        let scrollOffset = this.editor.scrollTop;

        const inertiaScroll = () => {
            if (this.stopInertiaMove) return;
            const direction = this.velocity > 0 ? 1 : -1;
            const deceleration = 0.005 * direction;
            let time = Date.now() - this.lastTouchTime;
            let v = this.velocity - time * deceleration;

            if (v * direction < 0) {
                return;
            }

            let offset = (this.velocity + v) / 2 * time;
            this.editor.scrollTop = scrollOffset - offset;
            const metrics = this.getMetrics();
            const offsetToEnd = metrics.contentLength - metrics.visibleLength - metrics.offset;
            if (offsetToEnd < 0.1) return;
            setTimeout(inertiaScroll, 10);
        }

        inertiaScroll();
    }

    onWheel = e => {
        if (!this.editor) {
            return;
        }

        this.editor.scrollTop += e.deltaY;
    }

    onPaste = e => {
        // Log.verbose(e);
        // e.preventDefault();
        let clipboardData = e.clipboardData;
        if (!clipboardData) clipboardData = e.originalEvent.clipboardData;
        if (!clipboardData) clipboardData = e.nativeEvent.clipboardData;
        if (clipboardData) {
            var text = clipboardData.getData('text/plain');
            if (!!text && text !== '') {
                e.preventDefault();
                let regWinReturn = /\r/g
                if(typeof(text) === 'string') {
                    text = text.replace(regWinReturn, '') //移除掉windows下的\r
                }
                document.execCommand('inserttext', false, text);
            }
        }
        // Log.verbose('text to past: ', text);
    }

    hasSelectContent = () => {
        if (this.lastRange) {
            return !this.lastRange.collapsed;
        }

        return false;
    }

    render() {
        const {
            prefixCls,
            className,
            placeholder,
        } = this.props;

        const wrapOperationCls = classnames({
            [`${prefixCls}-operation-float`]: this.isFloat(),
            [`${prefixCls}-operation-float-menu-visible`]: this.state.menuVisibility,
            [`${prefixCls}-operation-hidden`]: this.state.operateZone === 'hidden' || !this.isEditable(),
            [`${prefixCls}-operation-scalein`]: this.state.operateZone === 'scalein',
            [`${prefixCls}-operation-scaleout`]: this.state.operateZone === 'scaleout',
            [`${prefixCls}-operation-fadein`]: this.state.operateZone === 'fadein',
            [`${prefixCls}-operation-fadeout`]: this.state.operateZone === 'fadeout',
            [`${prefixCls}-operation-fixed`]: this.props.mode === 'fixed',

        });
        const wrapEditorCls = classnames(`${prefixCls}-editor`, {
            'needsclick': this.isEditable(),
            'needsfocus': this.isEditable(),
            [`${prefixCls}-editor-editable`]: this.isEditable(),
            [`${prefixCls}-editor-pin`]: this.state.menuVisibility || this.isFixed(),
            [`${prefixCls}-editor-focus`]: this.state.focus && this.isEditable() && !this.isFloat(),
            [`${prefixCls}-editor-focus-float`]: this.state.focus && this.isEditable() && this.isFloat(),
            [`${prefixCls}-editor-plaintext`]: !this.isEditable() || this.isPlainText(),
            [this.props.mode]: true,
        }, className);

        const wrapOperationMenuCls = classnames({
            [`${prefixCls}-operation-menu-float`]: this.isFloat(),
        });

        const wrapEditorProps = this.isEditable() ? {
            contentEditable: this.isEditable() ? 'plaintext-only' : false,
            spellCheck: false,
            placeholder: placeholder,
            'data-empty': this.state.contentempty,
            onInput: this.onInput,
            onDragStart: e => e.preventDefault(),
            // onClick: this.onEditorClick,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
        } :  {};

        return (
            <View
                ref={el => this.textedit = ReactDOM.findDOMNode(el)}
                className={`${prefixCls} ${this.props.mode}`}
            >
                <View
                    ref={el => this.editor = ReactDOM.findDOMNode(el)}
                    className={wrapEditorCls}
                    // onMouseDown={this.onTouchBegin}
                    // onMouseMove={this.onTouchMove}
                    // onMouseUp={this.onTouchEnd}
                    onMouseLeave={this.onTouchEnd}
                    onWheel={this.onWheel}
                    onTouchStart={this.onTouchBegin}
                    onTouchMove={this.onTouchMove}
                    onTouchEnd={this.onTouchEnd}
                    onKeyDown={this.onKeyDown}
                    onCompositionStart={this.onCompositionStart}
                    onCompositionEnd={this.onCompositionEnd}
                    onPaste={this.onPaste}
                    {...wrapEditorProps}
                />
                {
                    this.isPlainText() ? null :
                    <Operation
                        ref={el => this.operation = el}
                        className={wrapOperationCls}
                        menuClassName={wrapOperationMenuCls}
                        onChange={this.onOperationChange}
                        onMenuShown={this.onOperationMenuShown}
                        hasSelectContent={this.hasSelectContent}
                        fonts={fonts}
                    />
                }
            </View>
        );
    }
}