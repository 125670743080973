import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import View from '../view/index';

import './style/index.less';

export default class NavBar extends React.Component {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        mode: PropTypes.oneOf(['light', 'dark']),
        icon: PropTypes.element,
        leftContent: PropTypes.element,
        rightContent: PropTypes.element,
        onLeftClick: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-navbar',
        mode: 'dark',
        onLeftClick: () => {},
    };

    render() {
        const {
            prefixCls,
            className,
            children,
            mode,
            icon,
            onLeftClick,
            leftContent,
            rightContent,
            ...restProps
        } = this.props;

        return (
            <View
                {...restProps}
                className={classnames(className, prefixCls, `${prefixCls}-${mode}`)}
            >
                <View
                    className={`${prefixCls}-left`}
                    role="button"
                    onClick={onLeftClick}
                >
                    {icon ? (
                        <span className={`${prefixCls}-left-icon`} aria-hidden="true">
                            {icon}
                        </span>
                    ) : null}
                    {leftContent}
                </View>
                <View className={`${prefixCls}-title`}>
                    {children}
                </View>
                <View className={`${prefixCls}-right`}>
                    {rightContent}
                </View>
            </View>
        );
    }
}
