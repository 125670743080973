import React from 'react'
import { Route } from 'react-router-dom'
import { withRouter, Switch } from 'react-router'
import Home from './Home'
import Register from './Register'
import GameList from './GameList'
import NormalGame from './NormalGame'
import AnswerReport from './AnswerReport'
import AnswerDetail from './AnswerDetail'
import PromotionList from './PromotionList'
import Result from './Result'
import 'antd-mobile/dist/antd-mobile.css';

class AppView extends React.PureComponent {
  componentDidMount() {
  }

  componentWillUnmount() {
  }

  render() {
    return (
      <div id='app' style={{ height: '100%' }}>
        <Switch>
          <Route path='/register' component={Register}/>
          <Route path='/gamelist' component={GameList}/>
          <Route path='/normal' component={NormalGame}/>
          <Route path='/answer-report' component={AnswerReport}/>
          <Route path='/answer-detail' component={AnswerDetail}/>
          <Route path='/promotion-list' component={PromotionList}/>
          <Route path='/result' component={Result}/>
          <Route exact path='/' component={Home}/>
        </Switch>
      </div>
    )
  }
}

export default withRouter(AppView)
