import React from 'react';
import PropTypes from 'prop-types';

import View from '../view/index';
import Text from '../text/index';
import Slider from '../slider/index';
import { Time } from '../util/index';

import './style/progress-bar.less';

export default class ProgressBar extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        currentTime: PropTypes.number,
        duration: PropTypes.number,
        onSeek: PropTypes.func,
        rotate: PropTypes.bool,
    };

    static defaultProps = {
        prefixCls: 'tm-video',
        currentTime: 0,
        duration: 0,
        onSeek: time => {},
        rotate: false,
    };

    constructor(props) {
        super(props);
        this.currentTime = this.props.currentTime;
        this.state = {
            dragging: false,
        }
    }

    onChange(time) {
        this.currentTime = time;
        this.setState({
            dragging: true
        })
    }

    onAfterChange(time) {
        let {onSeek} = this.props;
        onSeek(time);
        // this.props.currentTime = time;
        this.setState({
            dragging: false
        })
    }

    render() {
        const {
            prefixCls,
            currentTime,
            duration,
            rotate,
        } = this.props;

        let fmt = 'mm:ss';
        if (duration > 3600) {
            fmt = 'hh:' + fmt;
        }

        let curtime = Time.format(parseInt(currentTime,10), fmt);
        let durtime = Time.format(parseInt(duration,10), fmt);
        const sliderStyle = {
            marginTop: '22px',
        };

        return (
            <View className={`${prefixCls}-control-progress-bar`}>
                <Slider
                    style={sliderStyle}
                    min={0}
                    max={duration}
                    value={this.state.dragging ? this.currentTime : currentTime}
                    onAfterChange={time => this.onAfterChange(time)}
                    onChange={time => this.onChange(time)}
                    vertical={rotate}
                />
                <Text className={`${prefixCls}-control-time-current`}>{curtime}</Text>
                <Text className={`${prefixCls}-control-time-total`}>{durtime}</Text>
            </View>
        );
    }
}