import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view/index';

import './style/index.less';

export default class PanelContent extends React.Component {
    static propTypes = {
        prefixCls: PropTypes.string,
        isActive: PropTypes.bool,
        children: PropTypes.any,
        destroyInactivePanel: PropTypes.bool,
        forceRender: PropTypes.bool,
        role: PropTypes.string,
    };

    shouldComponentUpdate(nextProps) {
        return this.props.isActive || nextProps.isActive;
    }

    render() {
        this._isActived = this.props.forceRender || this._isActived || this.props.isActive;
        if (!this._isActived) {
            return null;
        }
        const { prefixCls, isActive, children, destroyInactivePanel, forceRender, role } = this.props;
        const contentCls = classnames({
            [`${prefixCls}-content`]: true,
            [`${prefixCls}-content-active`]: isActive,
            [`${prefixCls}-content-inactive`]: !isActive,
        });

        const child = !forceRender && !isActive && destroyInactivePanel ? null :
        <View className={`${prefixCls}-content-box`}>{children}</View>;

        return (
            <View
                className={contentCls}
                role={role}
            >
                {child}
            </View>
        );
    }
}