import React from 'react'
import { Image } from 'thanos'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './index.less'

const PREFIX_CLS = 'bgdiv'

class BgDiv extends React.PureComponent {
  render() {
    const { src, className, style, children, onClick } = this.props

    return (
      <div
        className={classnames(PREFIX_CLS, className)}
        style={style}
        onClick={onClick}
      >
        <Image source={src} className={`${PREFIX_CLS}-bg`}/>
        <div className={classnames(`${PREFIX_CLS}-content`, className)}>
          {children}
        </div>
      </div>
    )
  }
}

BgDiv.propTypes = {
  src: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onClick: PropTypes.func,
}

BgDiv.defaultProps = {
  src: null,
  onClick: () => {},
}

export default BgDiv
