import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { AppContainer } from 'react-hot-loader';
import 'Styles/index.less';
import { HashRouter as Router } from 'react-router-dom';
import AppView from './Modules';
import adjustCss from 'Styles/js/flexible_css';
import adjustfunc from 'Styles/js/flexible';
import { iNoBounce, isIPhone } from 'Styles/js/iosnobounce'
import _get from 'lodash/get'
import { configureStore } from './Store';

adjustCss();
adjustfunc(window, window['lib'] || (window['lib'] = {}));

if (isIPhone()){
  iNoBounce.enable();
  document.body.style.cursor = 'pointer';
}

render();

function render() {
  try {
    // Create redux store
    const store = configureStore({
      // userInfo: userInfo,
    })
    window.store = store.getState

    if (process.env.NODE_ENV === 'development') {
      const state = store.getState();
      console.log('----------- store:', state)
      doRender(store)
    } else {
      doRender(store)
    }
  } catch (err) {
    alert(err);
  }
}

function doRender(store) {
  ReactDOM.render(
    <AppContainer>
      <Provider store={store}>
        <Router>
          <AppView/>
        </Router>
      </Provider>
    </AppContainer>
    , document.getElementById('root')
  )
}
