import React from 'react';
import PropTypes from 'prop-types';

import './index.less';

import View from '../../view';
import Icon from '../../icon';

const prefixCls = 'tm-picture-editor-icon-select';

const defaultIcons = [
  { name: 'right', type: 'ie-confirm' },
  { name: 'wrong', type: 'ie-cancel' }
];

class IconSelect extends React.PureComponent {
  render() {
    return (
      <View className={prefixCls}>
        {defaultIcons.map(icon => (
          <Icon
            key={icon.name}
            type={icon.type}
            className="icon-item"
            size="lg"
            color={'#333'}
            onClick={() => this.props.onSelect(icon.name)}
          />
        ))}
      </View>
    );
  }
}

IconSelect.propTypes = {
  onSelect: PropTypes.func
};

IconSelect.defaultProps = {};

export default IconSelect;
