import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view';

export default class Spot extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        id: PropTypes.string,
        source: PropTypes.string,
        row: PropTypes.number,
        column: PropTypes.number,
        coords: PropTypes.arrayOf(PropTypes.number),
        shape: PropTypes.oneOf(['rect', 'oval']),
        scale: PropTypes.number,
        draggable: PropTypes.bool,
        onSelect: PropTypes.func,
        onDecorate: PropTypes.func,
        clickable: PropTypes.bool,
    };

    static defaultProps = {
        prefixCls: 'tm-hotspot-spot',
        selected: false,
        clickable: true,
        shape: 'rect',
        index: 0,
        id: 'spot',
    };

    state = {
        selected: false,
    };

    get selected() {
        return this.state.selected;
    }

    set selected(selected) {
        if (selected === this.state.selected) {
            return;
        }
        this.setState({
            selected: selected,
        });
    }

    get row() {
        return this.props.row;
    }

    get column() {
        return this.props.column;
    }

    onDrag = e => {
        e.dataTransfer.setData("Text", e.target.id);
    }

    onSelect = e => {
        const { onSelect, row, column } = this.props;

        if (onSelect) {
            this.setState({
                selected: !this.state.selected
            }, () => {
                onSelect(this.state.selected, row, column);
            });
        }
    }

    render() {
        const {
            className,
            prefixCls,
            id,
            source,
            shape,
            scale,
            draggable,
            coords,
            onDecorate,
            clickable
        } = this.props;

        const containerStyle = {
            left: coords[0] * scale + 'px',
            top: coords[1] * scale + 'px',
            width: coords[2] * scale + 'px',
            height: coords[3] * scale + 'px',
        };

        const contentStyle = {
            width: coords[2] * scale + 'px',
            height: coords[3] * scale + 'px',
            // borderRadius: shape === 'oval' ? '50%' : '0px',
            // objectFit: 'contain',
            // position: 'relative'
        };

        const containerCls = classnames({
            [`${prefixCls}`]: true,
            [`${prefixCls}-selected`]: this.state.selected,
            [`${prefixCls}-radius`]: shape === 'oval',
        }, className);

        const contentCls = classnames({
            [`${prefixCls}-image`]: this.state.selected,
            [`${prefixCls}-image-radius`]: shape === 'oval',
        });

        return (
            <View
                className={containerCls}
                style={containerStyle}
            >
                <View
                    id={id + '-' + this.row + '-' + this.column}
                    Element='img'
                    className={contentCls}
                    style={contentStyle}
                    src={source}
                    alt=''
                    draggable={draggable}
                    onDragStart={this.onDrag}
                    onClick={clickable ? this.onSelect : undefined}
                />
                {onDecorate && onDecorate(this.state.selected)}
            </View>
        );
    }
}