function getName(id) {
  const keys = Object.keys(this)
  let name = ''
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i]
    if (this[key].id === id) {
      name = this[key].name
      break
    }
  }
  return name
}

function getList(needSort = true) {
  let keys = Object.keys(this)
  if (needSort) {
    keys.sort((a, b) => a - b)
  }
  const list = []
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i]
    list.push(this[key])
  }
  return list
}

function getListById(idList, needSort = true) {
  let keys = Object.keys(this)
  if (needSort) {
    keys.sort()
  }

  const list = []
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i]
    const index = idList.indexOf(this[key].id)
    if (index !== -1) {
      list.push(this[key])
    }
  }
  return list
}

function getIdList(needSort = true) {
  let keys = Object.keys(this)
  if (needSort) {
    keys.sort()
  }
  const list = []
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i]
    list.push(this[key].id)
  }
  return list
}

function getNameList(idList = undefined, needSort = true) {
  let keys = Object.keys(this)
  if (needSort) {
    keys.sort()
  }

  const byId = Array.isArray(idList)

  const list = []
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i]
    if (byId) {
      const index = idList.indexOf(this[key].id)
      if (index !== -1) {
        list.push(this[key].name)
      }
    } else {
      list.push(this[key].name)
    }
  }
  return list
}

function defineProperties(obj, extraValues) {
  const properties = {
    'getName':     { value: getName },
    'getList':     { value: getList },
    'getListById': { value: getListById },
    'getIdList':   { value: getIdList },
    'getNameList': { value: getNameList },
    ...extraValues,
  }

  Object.defineProperties(obj, properties)
}

// export const DIFFICULTY = {
//   level1: { id: 20,  name: '易' },    // 易
//   level2: { id: 40,  name: '较易' },  // 较易
//   level3: { id: 60,  name: '中等' },  // 中等
//   level4: { id: 80,  name: '较难' },  // 较难
//   level5: { id: 100, name: '困难' },  // 困难
// }
// defineProperties(DIFFICULTY)

export const ANSWER_RESULT = {
  error: 0,    // 错误
  correct: 1,  // 正确
  unanswer: 2, // 未答
  outtime: 3   //超时
}

export const QUESTION_DIFFICULTY = {
  simple: 1,
  middle: 2,
  hard: 3,
}

export const QUESTION_TYPE = {
  select: 1, // 选择
  judge: 2,  // 判断
  blank: 3,  // 填空
}

export const TABLE_STATE = {
  new: 0,   // 未开始
  going: 1, // 进行中
  over: 2,  // 已结束
}

export const USER_GROUP = {
  primary: { id: 1, name: '小学组' },
  junior: { id: 2, name: '初中组' },
  high: { id: 3, name: '高中组' },
  college: { id: 4, name: '高校组' },
  sociology: { id: 5, name: '社会组' },
}
defineProperties(USER_GROUP)

export const PROMOTION_TYPE = {
  new: 0,     // 未揭晓
  success: 1, // 晋级
  failed: 2,  // 未晋级
}
