import { post, get } from './BaseQuery'
import _ from 'lodash'

let DOMAIN = 'https://poem.ljlx.com/api';
if (process.env.NODE_ENV === 'development') {
  DOMAIN = 'http://172.16.0.15:9060';
}

const URL_GetMatchRank           = DOMAIN + '/MatchInfo/GetMatchRank'
const URL_GetServerTime          = DOMAIN + '/MatchInfo/GetServerTime';
const URL_GetTableInfo           = DOMAIN + '/MatchInfo/GetTableInfo';
const URL_GetUserMatchRank       = DOMAIN + '/MatchInfo/GetUserMatchRank'
const URL_Start                  = DOMAIN + '/MatchInfo/Start';
const URL_SubmitAnswer           = DOMAIN + '/MatchInfo/SubmitAnswer';
const URL_SyncTableInfo          = DOMAIN + '/MatchInfo/SyncTableInfo';
const URL_GetAllMatchInfo        = DOMAIN + '/MatchInfo/GetAllMatchInfo'; //获取所有的阶段比赛信息，比如：海选，复赛
const URL_GetUserMatchLog        = DOMAIN + '/MatchInfo/GetUserMatchLog'; //用户是否参加过比赛
const URL_GetUserAnswer          = DOMAIN + '/MatchInfo/GetTableUserAnswerList'; //比赛结果信息页
const URL_GetTableQuestionResult = DOMAIN + '/MatchInfo/GetTableQuestionResult'

const URL_BatchUsers    = DOMAIN + '/api/user/BatchUsers'
const URL_RegUser       = DOMAIN + '/api/user/RegUser'; //注册
const URL_GetToken      = DOMAIN + '/api/user/GetToken'; //获取用户标识 token

export async function apiGetMatchRank(matchId, pageIndex, pageSize) {
  const data = await post(URL_GetMatchRank, {
    matchId: matchId,
    pageIndex: pageIndex,
    pageSize: pageSize,
  })

  return data
}

export async function apiGetServerTime() {
  const data = await post(URL_GetServerTime);
  return data;
}

export async function apiGetTableInfo(tableId) {
  const data = await post(URL_GetTableInfo, { tableId: tableId });
  return data;
}

export async function apiGetUserMatchRank(matchId) {
  const data = await post(URL_GetUserMatchRank, { matchId: matchId })
  return data
}

export async function apiStart(matchId) {
  const data = await post(URL_Start, { matchId: matchId });
  return data;
}

export async function apiSubmitAnswer(arg) {
  const data = await post(URL_SubmitAnswer, arg);
  return data;
}

export async function apiSyncTableInfo(tableId) {
  const data = await post(URL_SyncTableInfo, { tableId: tableId });
  return data;
}

export async function apiBatchUsers(userList) {
  const data = await post(URL_BatchUsers, userList)
  return data
}

/* 注册 */
export async function apiRegUser(form) {
  const data = await post(URL_RegUser, form);
  return data;
}

/* 比赛列表 */
export async function apiGetAllMatchInfo() {
  const data = await post(URL_GetAllMatchInfo);
  return data;
}

export async function apiGetToken(code) {
  const data = await get(URL_GetToken, { code: code }, { useToken: false })
  return data
}

export async function apiGetUserMatchLog(matchId) {
  const data = await post(URL_GetUserMatchLog, { matchId: matchId });
  return data;
}

export async function apiGetUserAnswer(tableId) {
  const data = await post(URL_GetUserAnswer, { tableId: tableId });
  return data;
}

export async function apiGetTableQuestionResult(tableId, questionId) {
  const data = await post(URL_GetTableQuestionResult, {
    matchTableID: tableId,
    questionId: questionId,
  })

  return data
}
