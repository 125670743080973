import React from 'react';
import PropTypes from 'prop-types';

import View from '../view/index';
import Slider from '../slider/index';
import Text from '../text/index';
import { Time } from '../util/index';
import Toggle from './Toggle';
import './style/index.less';

export default class Control extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        fileName: PropTypes.string,
        advance: PropTypes.bool,
        paused: PropTypes.bool,
        loading: PropTypes.bool,
        duration: PropTypes.number,
        currentTime: PropTypes.number,
        onPlay: PropTypes.func,
        onSeek: PropTypes.func,
        onTimeChange: PropTypes.func,
    };

    static defaultProps = {
        advance: true,
        paused: true,
        loading: false,
        fileName: '',
        duration: 0,
        currentTime: 0,
        onPlay: () => {},
        onSeek: time => {},
        onTimeChange: time => {}
    };

    constructor(props) {
        super(props);
        this.currentTime = 0;
        this.state = {
            dragging: false
        }
    }

    onChange(time) {
        this.currentTime = time;
        this.setState({
            dragging: true
        })
    }

    onAfterChange(time) {
        let {onSeek} = this.props;
        onSeek(time);
        this.setState({
            dragging: false
        });
    }

    renderPlayToggle() {
        const {
            paused,
            onPlay,
            advance
        } = this.props;

        return (
            <Toggle
                advance={advance}
                onPlay={onPlay}
                paused={paused}
            />
        );
    };

    renderDetail() {
        const {
            duration,
            currentTime,
            fileName,
            loading,
        } = this.props;

        let fmt = 'mm:ss';
        if (duration > 3600) {
            fmt = 'hh:' + fmt;
        }

        let curtime = Time.format(parseInt(currentTime, 10), fmt);
        let durtime = Time.format(parseInt(duration, 10), fmt);

        const sliderStyle = {
            backgroundColor: 'transparent',
            height: '3px',
            // marginTop: '2.5px',
        };
        
        const trackStyle = {
            backgroundColor: '#03c0ab',
            height: '3px',
        };
        
        const railStyle = {
            backgroundColor: '#ddd',
            height: '3px',
        };

        const handleStyle = {
            borderColor: '#03c0ab',
            height: '8px',
            width: '8px',
            marginLeft: '-4px',
            marginTop: '-3.5px',
            backgroundColor: '#03c0ab',
        };

        return (
            <View className='tm-audio-control-detail'>
                <View className='tm-audio-control-text'>
                    <Text className='tm-audio-control-filename'>{fileName}</Text>
                </View>
                <Slider
                    style={sliderStyle}
                    trackStyle={trackStyle}
                    railStyle={railStyle}
                    handleStyle={handleStyle}
                    min={0}
                    max={duration}
                    value={this.state.dragging ? this.currentTime : currentTime}
                    loading={loading}
                    onAfterChange={(time) => this.onAfterChange(time)}
                    onChange={(time) => this.onChange(time)}
                />
                <View className='tm-audio-control-text'>
                    <Text className='tm-audio-control-current'>{curtime}</Text>
                    <Text className='tm-audio-control-duration'>{durtime}</Text>
                </View>
            </View>
        );
    };

    render() {
        const {
            advance
        } = this.props;

        return (
            <View className='tm-audio-control'>
                {this.renderPlayToggle()}
                {advance ? this.renderDetail() : null}
            </View>
        )
    }
}