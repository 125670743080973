export default function(str,args) {
    if (arguments.length < 1){
        return "";
    }
    if (arguments.length === 1){
        return arguments[0];
    }
    var result = str;
    var reg;
    if (arguments.length === 2 && typeof (args) === "object") {
        for (var key in args) {
            if (args[key] !== undefined) {
                reg = new RegExp("({" + key + "})", "g");
                result = result.replace(reg, args[key]);
            }
        }
    }
    else {
        for (var i = 1; i < arguments.length; i++) {
            if (arguments[i] !== undefined) {
                reg = new RegExp("({)" + (i-1) + "(})", "g");
                result = result.replace(reg, arguments[i]);
            }
        }
    }
    return result;
}