import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Hammer from 'hammerjs';

import View from '../view';
import Image from '../image';
import Icon from '../icon';
import Text from '../text';
import Toast from '../toast';
import Modal from '../modal';
import { Platform, Event } from '../util';

import './style/index.less';

export default class Gallery extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        source: PropTypes.array.isRequired,
        index: PropTypes.number,
        onClose: PropTypes.func,
        onDownload: PropTypes.func,
        editText: PropTypes.string,
        showRotateButton: PropTypes.bool,
    };

    static defaultProps = {
        prefixCls: 'tm-gallery',
        index: 0,
        editText: '编辑',
        showRotateButton: true
    };

    content = null;
    position = 0;
    dragging = false;
    pinching = false;
    lastTouch = 0;
    lastTouchTime = 0;
    velocity = 0;
    itemWidth = document.documentElement.clientWidth;

    imageRefs = {};
    downloadattrs = {};
    static gallery = undefined;
    static setDownloadEnabled = (index, enable) => {
        if (!Gallery.gallery) {
            return;
        }

        Gallery.gallery.setDownloadEnabled(index, enable);
    }

    constructor(props) {
        super(props);
        this.state = {
          source: props.source,
          activeIndex: this.props.index || 0,
          downloadShown: !!this.props.onDownload,
        }
    }

    onBack = () => {
        this.props.onClose() && this.props.onClose();
        return true;
    }

    componentDidMount() {
        Gallery.gallery = this;

        Event.fire('galleryDidMount');

        // console.log('Gallery did mount');
        Event.subscribe('back', this.onBack);

        const position = -this.state.activeIndex * this.itemWidth
        this.setPosition(position);

        if (Platform.isMobile()) {
            this.gesture = new Hammer.Manager(this.content);
            let pinch = new Hammer.Pinch();
            this.gesture.add([pinch]);
            this.gesture.on('pinch', this.onPinch);
        }

        const imgref = this.imageRefs[this.state.activeIndex];
        if (imgref && this.resolution) {
            this.resolution.text = imgref.resolution;
        }
    }

    componentWillUnmount() {
        // console.log('Gallery will unmount');
        Event.fire('galleryWillUnmount');
        Gallery.gallery = undefined;
        Event.unsubscribe('back', this.onBack);
    }

    setDownloadEnabled = (index, enable) => {
        this.downloadattrs[index] = enable;
        this.setState({
            downloadShown: enable && !!this.props.onDownload,
        });
    }

    setPosition(position) {
        this.position = position;
        if (this.content) {
            this.content.style.transform = `translate3d(${this.position}px, 0, 0)`;
            this.content.style.webkitTransform = `translate3d(${this.position}px, 0, 0)`;
        }
    }

    setTransitionDuration = time => {
        if (this.content) {
            this.content.style.transitionDuration = time + 'ms';
        }
    }

    calculateIndex = (position, scrollLeft) => {
        const { source } = this.state;
        let index = this.state.activeIndex;
        const delta = Math.abs(this.position + index * this.itemWidth);
        const threshold = this.itemWidth / 8;
        if (scrollLeft && delta > threshold) {
            index += 1;
        } else if (!scrollLeft && delta > threshold) {
            index -= 1;
        }

        if (index < 0) {
            return 0;
        }
        if (index > (source.length - 1)) {
            return source.length - 1;
        }

        return index;
    }

    onPinch = e => {
        if (!this.pinching) {
            this.pinching = true;
        }

        let scale = e.scale;
        let ref = this.imageRefs[this.state.activeIndex];
        if (!ref) return;
        scale = scale * ref.scale;

        this.onScale(this.state.activeIndex, scale);
    }

    matchDom = (target, node) => {
        if (!node || !target) return false;
        while (target) {
            if (node === target) return true;
            target = target.parentNode;
        }

        return false;
    }

    onClick = e => {
        if (this.matchDom(e.target, this.download)) {
            return;
        }

        if (this.matchDom(e.target, this.rotate)) {
            return;
        }

        if (!Platform.isMobile()) {
            const timeElapse = e.timeStamp - this.trackClickTimestamp;
            const clickPoint = {
                x: e.clientX,
                y: e.clientY,
            };
            const distance = Math.abs(clickPoint.x - this.trackClickStartPoint.x);
            if (timeElapse > 300 || distance > 10) {
                e.stopPropagation();
                return;
            }
        }
        this.props.onClose && this.props.onClose();
    }

    onWheel = e => {
        const ref = this.imageRefs[this.state.activeIndex];
        if (!ref) {
            return;
        }

        let scale = -e.deltaY / document.documentElement.clientHeight;
        scale += ref.scale;

        this.onScale(this.state.activeIndex, scale);
    }

    onTouchBegin = e => {
        if (!Platform.isMobile()) {
            this.trackClickTimestamp = e.timeStamp;
            this.trackClickStartPoint = {
                x: e.clientX,
                y: e.clientY,
            };
        }

        this.swipping = this.state.source.length > 1;

        this.setTransitionDuration(0);

        this.dragging = true;
        this.pinching = false;

        const touches = e.changedTouches ? e.changedTouches[0] : e;
        this.lastTouch = {
            x: touches.clientX,
            y: touches.clientY,
        };
        this.velocity = 0;
        this.lastTouchTime = Date.now();
    }

    onTouchMove = e => {
        // console.log('touchmove');
        if (Platform.isMobile()) {
            if (e.touches && e.touches.length > 1) {
                this.dragging = false;
                this.swipping = false;
            }
        }

        if (this.dragging) {
            const touches = e.changedTouches ? e.changedTouches[0] : e;
            let delta = touches.clientX - this.lastTouch.x;
            let ref = this.imageRefs[this.state.activeIndex];
            if (ref && ref.scale > 1) {
                let deltaY = touches.clientY - this.lastTouch.y;
                let minTop = document.documentElement.clientHeight - ref.height;
                let minLeft = document.documentElement.clientWidth - ref.width;

                if (deltaY > 0 && ref.top < 0) {
                    ref.top += deltaY;
                    if (ref.top > 0) ref.top = 0;
                } else if (deltaY < 0 && (ref.top + deltaY) > minTop) {
                    ref.top += deltaY;
                    if (ref.top < minTop) ref.top = minTop;
                }

                let shouldReturn = true;
                if (delta > 0 && ref.left < 0) {
                    ref.left += delta;
                    if (ref.left > 0) {
                        delta = ref.left;
                        ref.left = 0;
                        shouldReturn = false;
                    }
                } else if (delta < 0 && (ref.left + delta) > minLeft) {
                    ref.left += delta;
                    if (ref.left < minLeft) {
                        delta = ref.left - minLeft;
                        ref.left = minLeft;
                        shouldReturn = false;
                    }
                } else {
                    shouldReturn = false;
                }
                ref.image.style.top = ref.top + 'px';
                ref.image.style.left = ref.left + 'px';

                if (shouldReturn) {
                    this.lastTouch = {
                        x: touches.clientX,
                        y: touches.clientY,
                    };
                    return;
                }
            }

            if (this.swipping) {
                if (delta > 0 && this.state.activeIndex === 0) {
                    const deltaWidth = this.itemWidth / 4.0;
                    const factor = (Math.PI / 2.0 - Math.atan(delta / deltaWidth)) / Math.PI;
                    delta = delta * factor;
                } else if (delta < 0 && this.state.activeIndex === (this.state.source.length - 1)) {
                    const deltaWidth = this.itemWidth / 4.0;
                    const factor = (Math.PI / 2.0 - Math.atan(delta / deltaWidth)) / Math.PI;
                    delta = delta * factor;
                }
                let position = this.position + delta;
                this.setPosition(position);
            }

            const timestamp = Date.now();
            let duration = timestamp - this.lastTouchTime;
            if (duration > 0) {
                this.velocity = delta * 1000 / duration;
            } else {
                this.velocity = 0;
            }
            this.lastTouch = {
                x: touches.clientX,
                y: touches.clientY,
            };
            this.lastTouchTime = duration;
        }
    }

    onTouchEnd = e => {
        // console.log('touchend');
        if (this.pinching) {
            this.pinching = false;
            let ref = this.imageRefs[this.state.activeIndex];
            if (ref) {
                ref.scale = ref.tempScale;
            }
        }

        if (this.dragging) {
            this.dragging = false;
            this.swipping = false;

            this.setTransitionDuration(300);

            const direction = this.position < (-this.state.activeIndex * this.itemWidth) ? -1 : 1;

            const decelerator = 0.00005;
            const duration = Math.abs(this.velocity / decelerator);
            const bounce = (this.velocity * duration - decelerator * duration * duration / 2) * direction;
            // console.log('duration: ' + duration + ' bounce: ' + bounce + ' velocity: ' + this.velocity);
            const position = -this.state.activeIndex * this.itemWidth + bounce;
            const index = this.calculateIndex(position, direction === -1);
            // let ref = this.imageRefs[this.state.activeIndex];
            // const shouldRestoreScale = (index !== this.state.activeIndex) && ref && ref.scale > 1;
            // const lastIndex = this.state.activeIndex;
            this.setPosition(-index * this.itemWidth);
            this.setState({
                activeIndex: index,
                downloadShown: index === this.state.activeIndex ? this.state.downloadShown : !!this.downloadattrs[index] && !!this.props.onDownload,
            }, () => {
                this.position = -this.state.activeIndex * this.itemWidth;
                // if (shouldRestoreScale) {
                //     this.onScale(lastIndex, 1);
                // }
            });
        }
    }

    onDownload = () => {
        this.props.onDownload && this.props.onDownload(this.state.activeIndex);
    }

    onRotate = () => {
        let ref = this.imageRefs[this.state.activeIndex];
        if (!ref) return;

        ref.rotate += 1;
        ref.rotate %= 4;
        ref.image.style.transform = 'rotate(' + ref.rotate * 90 + 'deg)';
        this.onScale(this.state.activeIndex, 1);
    }

    onEdit = (e) => {
      e.stopPropagation();

      const { source } = this.state;
      const { onEdit } = this.props;
      const { activeIndex } = this.state;
       
      let ref = this.imageRefs[activeIndex];
      if (!ref || !onEdit) return;

      onEdit(ref.image.src, source[activeIndex], ref.rotate * 90).then(res=>{
        // 还原旋转
        ref.rotate = -1;
        this.onRotate();
        
        source[activeIndex] = res;
        this.setState({
            source: source.slice(0)
        });
      })
    }

    onClear = (e) => {
        e.stopPropagation();

        const { source, activeIndex } = this.state;
        const { onClear } = this.props; 

        if(!onClear) return;

        const toast = Toast.loading('', 30);
        onClear(source[activeIndex]).then(res=>{
            toast.close();
            source[activeIndex] = res;
            this.setState({
                source: source.slice(0)
            })
        }).catch(err => {
            toast.close(); 
            Modal.alert('', err.message);
        });

    }

    onSaveImageRef = (el, index) => {
        if (this.imageRefs[index]) {
            return;
        }

        const node = ReactDOM.findDOMNode(el);

        this.imageRefs[index] = {
            image: node,
            scale: 1,
            tempScale: 1,
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            rotate: 0,
            resolution: node.naturalWidth + 'x' + node.naturalHeight,
        };

        if (node.naturalWidth === 0) {
            node.onload = e => this.onImageLoad(index);
            this.imageRefs[index].resolution = '';
            return;
        }

        this.onScale(index, 1);
    }

    onImageLoad = index => {
        const ref = this.imageRefs[index];
        if (!ref) return;

        ref.resolution = ref.image.naturalWidth + 'x' + ref.image.naturalHeight;
        if (this.resolution && this.state.activeIndex === index) {
            this.resolution.text = ref.resolution;
        }

        this.onScale(index, 1);
    }

    isImageReady= (index) => {
        return true;
    }

    onScale = (index, scale) => {
        let left = 0;
        let top = 0;
        let width = 0;
        let height = 0;

        let ref = this.imageRefs[index];
        if (!ref) return;

        let { clientWidth, clientHeight } = document.documentElement;
        let switchHW = ref.rotate === 1 || ref.rotate === 3;
        let ratio = ref.image.naturalWidth / ref.image.naturalHeight;
        if(switchHW){
            ratio = ref.image.naturalHeight / ref.image.naturalWidth;
            width = clientHeight;
            height = width * ratio;

            if(height > clientWidth){
                height = clientWidth;
                width = height / ratio;
            }   
        }else{
            width = clientWidth;
            height = width / ratio;

            if(height > clientHeight){
                height = clientHeight;
                width = height * ratio;
            }
        }

        top = (document.documentElement.clientHeight - height) / 2;
        left = (document.documentElement.clientWidth - width) / 2;

        // console.log('scale: ' + scale);
        // console.log('old scale: ' + ref.scale);

        if (scale < 1) scale = 1;
        if (scale > 5) scale = 5;

        if  (scale > 1) {
            width *= scale;
            height *= scale;
            top = (document.documentElement.clientHeight - height) / 2;
            left = (document.documentElement.clientWidth - width) / 2;
        }

        ref.tempScale = scale;
        if (!Platform.isMobile()) {
            ref.scale = scale;
        }

        ref.top = top;
        ref.left = left;
        ref.width = width;
        ref.height = height;

        ref.image.style.top = ref.top + 'px';
        ref.image.style.left = ref.left + 'px';
        ref.image.style.width = ref.width + 'px';
        ref.image.style.height = ref.height + 'px';
    }

    renderImages = () => {
        const { source } = this.state;
        return source.map((image, index) => {
            return this.getImageItem(index, image);
        });
    }

    getImageItem = (index, image) => {
        const { prefixCls } = this.props;
        const style = {
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight
        };

        if (this.downloadattrs[index] === undefined) {
            this.downloadattrs[index] = true;
        }

        return (
            <View key={index} style={style} className={`${prefixCls}-imageview`} >
                <Image
                    ref={el => this.onSaveImageRef(el, index)}
                    className={`${prefixCls}-imageview-image`}
                    source={image}
                    onDragStart={e => e.preventDefault()}
                    // onLoad={this.onImageLoaded}
                />
            </View>
        );
    }

    renderIndicators = () => {
        const { source } = this.state;
        const { prefixCls } = this.props;
        return source.map((image, index) => {
            const wrapCls = classnames(`${prefixCls}-indicator-item`, {
                [`${prefixCls}-indicator-item-active`]: index === this.state.activeIndex
            });
            return (
                <View
                    key={index}
                    className={wrapCls}
                />
            );
        });
    }

    render() {
        console.log("render -> this.props", this.props)
        const { prefixCls, onCanEdit, editText, showRotateButton, onCanClear } = this.props;
        const { source, activeIndex } = this.state;

        const showEditButton = onCanEdit ? onCanEdit(source) : false;
        const showClearButton = onCanClear ? onCanClear(source[activeIndex]) : false;

        const contentStyle = {
            width: source.length * this.itemWidth,
        };

        const imgref = this.imageRefs[this.state.activeIndex];
        const resolution = !imgref ? '' : imgref.resolution;
        return (
            <View
                className={`${prefixCls}`}
                onMouseDown={this.onTouchBegin}
                onMouseMove={this.onTouchMove}
                onMouseUp={this.onTouchEnd}
                onMouseLeave={this.onTouchEnd}
                onTouchStart={this.onTouchBegin}
                onTouchMove={this.onTouchMove}
                onTouchEnd={this.onTouchEnd}
                onWheel={this.onWheel}
                onClick={this.onClick}
            >
                <View
                    ref={el => this.content = ReactDOM.findDOMNode(el)}
                    className={`${prefixCls}-content`}
                    style={contentStyle}
                >
                    {this.renderImages()}
                </View>
                {showEditButton && this.isImageReady(activeIndex) && (
                  <View
                      className={`${prefixCls}-edit`}
                      onClick={this.onEdit}
                  >
                      <Text>{editText}</Text>
                  </View>
                )}
                {showClearButton && this.isImageReady(activeIndex) && (
                    <View
                        className={`${prefixCls}-clear`}
                        onClick={this.onClear}
                    >
                        <Text>还原原图</Text>
                    </View>
                )}
                {showRotateButton && this.isImageReady(activeIndex) && (
                  <View
                      className={`${prefixCls}-rotate`}
                      onClick={this.onRotate}
                      ref={el => this.rotate = ReactDOM.findDOMNode(el)}
                  >
                      <Icon type='ie-rotate-picture' color='#ffffff' />
                  </View>
                )}
                {
                    (!this.state.downloadShown || ! this.isImageReady(activeIndex)) ? null :
                    <View
                        ref={el => this.download = ReactDOM.findDOMNode(el)}
                        className={`${prefixCls}-download`}
                        onClick={this.onDownload}
                    >
                        <Icon color='#ffffff' type='download' />
                    </View>
                }
                <View className={`${prefixCls}-resolution`}>
                    <View className={`${prefixCls}-resolution-text`}>
                        <Text ref={el => this.resolution = el} text={resolution} />
                    </View>
                </View>
                {
                    source.length <= 1 ? null :
                    <View className={`${prefixCls}-indicator`}>
                        {this.renderIndicators()}
                    </View>
                }
            </View>
        );
    }
}