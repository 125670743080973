import commandFactory from '../factory/command';
import consts from '../lib/consts';

const { commandNames, rejectMessages } = consts;

const command = {
  name: commandNames.REMOVE_OBJECT,

  /**
   * Remove an object
   * @param {Graphics} graphics - Graphics instance
   * @param {number} ids - object ids
   * @returns {Promise}
   */
  execute(graphics, ids) {
    return new Promise((resolve, reject) => {
      this.undoData.objects = ids.map(id => graphics.removeObjectById(id)[0]);
      if (this.undoData.objects.length) {
        resolve();
      } else {
        reject(rejectMessages.noObject);
      }
    });
  },
  /**
   * @param {Graphics} graphics - Graphics instance
   * @returns {Promise}
   */
  undo(graphics) {
    const { objects } = this.undoData;
    for (let i = 0; i < objects.length; i++) {
      const object = objects[i];
      graphics.add(object);
    }
    return Promise.resolve();
  },
};

commandFactory.register(command);

export default command;
