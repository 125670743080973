const clipSpot = (source, spots, callback) => {
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');
    let image = document.createElement('img');
    image.crossOrigin = 'anonymous';

    const clip = coords => {
        canvas.width = coords[2];
        canvas.height = coords[3];
        context.drawImage(image, coords[0], coords[1], coords[2], coords[3], 0, 0, canvas.width, canvas.height);

        return canvas.toDataURL();
    }

    image.onload = () => {
        let spotData = [];
        spots.forEach(rows => {
            const data = rows.map(item => {
                let spot = item['rect'] || item['oval'];
                if (spot) {
                    const coords = [spot.lt.x, spot.lt.y, spot.rb.x - spot.lt.x, spot.rb.y - spot.lt.y];
                    return clip(coords);
                } else {
                    return 'data:image/png;base64,';
                }
            });

            spotData.push(data);
        });

        callback && callback(spotData);
    }

    image.src = source;
}

export default clipSpot;