import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view';
import Text from '../text';
import { Loading } from '../indicator';
import { Log, Event } from '../util';

import './style/index.less';

class Toast extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        type: PropTypes.oneOf(['text', 'loading']),
        message: PropTypes.string.isRequired,
        duration: PropTypes.number,
        onClose: PropTypes.func,
        element: PropTypes.any,
        node: PropTypes.any,
    };

    static defaultProps = {
        prefixCls: 'tm-toast',
        message: '',
        duration: 3,
    }

    state = {
        animate: 'fadeIn', //fadeIn, fadeOut, idle
    };

    timeout = false;

    toastId = Math.floor(Math.random() * 1000);

    onBack = () => true

    componentDidMount() {
        const { duration, element } = this.props;

        Log.info('Toast componentDidMount, id: ' + this.toastId);

        Event.subscribe('back', this.onBack);

        this.element = element;

        if (this.toast) {
            this.toast.addEventListener('animationend', this.onAnimationEnd);
            this.toast.addEventListener('webkitAnimationEnd', this.onAnimationEnd);
        }

        if (duration > 0) {
            this.timer = setTimeout(() => {
                this.timeout = true;
                this.setState({
                    animate: 'fadeOut',
                }, () => {
                    this.closeWatchdog();
                });
            }, duration * 1000);
        }
    }

    componentWillUnmount() {
        Log.info('Toast componentWillUnmount, id: ' + this.toastId);
        Event.unsubscribe('back', this.onBack);
        if (this.toast) {
            this.toast.removeEventListener('animationend', this.onAnimationEnd);
            this.toast.removeEventListener('webkitAnimationEnd', this.onAnimationEnd);
        }
    }

    closeWatchdog = () => {
        this.watchdog = setTimeout(() => {
            Log.warn('Toast close watchdog activated');
            this.props.onClose && this.props.onClose(this.element, this.timeout);
        }, 300);
    }

    close = () => {
        Log.info('---request close loading, id: ' + this.toastId + '---');
        if (this.timer) {
            clearTimeout(this.timer);
        }

        this.setState({
            animate: 'fadeOut',
        }, () => {
            this.closeWatchdog();
        });
    }

    onAnimationEnd = e => {
        this.setState({
            animate: 'idle',
        });

        Log.info('---toast: onAnimationEnd ' + e.animationName + ' id: ' + this.toastId + '---');
        if (e.animationName === 'toastFadeOut') {
            Log.info('---toast: onAnimationEnd onClose---');
            if (this.watchdog) {
                clearTimeout(this.watchdog);
            }
            this.props.onClose && this.props.onClose(this.element, this.timeout);
        }
        Log.info('---toast: onAnimationEnd exit---');
    }

    render() {
        const {
            prefixCls,
            message,
            type,
            node
        } = this.props;

        const wrapCls = classnames(`${prefixCls}-content`, {
            [`${prefixCls}-fade-in`]: this.state.animate === 'fadeIn',
            [`${prefixCls}-fade-out`]: this.state.animate === 'fadeOut',
            [`${prefixCls}-content-custom`]: !!node,
        });

        const renderContent = () => {
            if (type === 'text') {
                return (
                    <Text className={`${prefixCls}-content-text`}>
                        {message}
                    </Text>
                );
            } else if (type === 'loading') {
                if (node) {
                    return node;
                }

                return (
                    <View className={`${prefixCls}-content-loading`}>
                        <Loading
                            className={`${prefixCls}-content-loading-indicator`}
                            barClassName={`${prefixCls}-content-loading-indicator-bar`}
                            animate={true}
                        />
                        {message && message !== '' ? <Text>{message}</Text> : null}
                    </View>
                );
            }

            return null;
        }

        return (
            <View className='tm-toast' >
                <View
                    ref={el => this.toast = ReactDOM.findDOMNode(el)}
                    className={wrapCls}
                >
                    {renderContent()}
                </View>
            </View>
        );
    }
}

function show(message, duration) {

    Log.verbose(message);
    const elem = document.createElement('div');
    document.body.appendChild(elem);

    const close = (el, timeout) => {
        ReactDOM.unmountComponentAtNode(el);
        if (el && el.parentNode) {
            el.parentNode.removeChild(el);
        }
    };

    ReactDOM.render(
        <Toast
            type='text'
            message={message}
            duration={duration}
            onClose={close}
            element={elem}
        />,
        elem
    );
}

function loading(message, duration, onClose, node) {

    const elem = document.createElement('div');
    document.body.appendChild(elem);

    const close = (el, timeout) => {
        Log.info('-------loading closed, timeout: ' + timeout + '--------');
        ReactDOM.unmountComponentAtNode(el);
        if (el && el.parentNode) {
            el.parentNode.removeChild(el);
        }

        onClose && onClose(timeout);
    };

    let toast = {
        waiting: 0,
        view: null,
        closed: false,
        close: function() {
            Log.info('-----request close loading-----');
            if (this.view) {
                Log.info('-----close loading-----');
                this.view.close();
                this.view.closed = true;
            } else if (this.waiting < 2000 && !this.waiting) {
                Log.info('-----delay close loading-----');
                this.waiting += 10;
                setTimeout(this.close.bind(this), 10);
            }
        }
    }

    toast.close.bind(toast);

    ReactDOM.render(
        <Toast
            ref={el => toast.view = el}
            type='loading'
            message={message}
            duration={duration}
            onClose={close}
            element={elem}
            node={node}
        />,
        elem
    );

    return toast;
}

export default { show, loading };