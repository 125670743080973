import React from 'react';
import ReactDOM from 'react-dom';
import Modal from './Modal';
import closest from './closest';

import { default as ProgressBar } from '../progress';

class Progress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            title: '正在上传资源文件...',
            progress: 0,
        };
    }

    set title(title) {
        this.setState({title: title});
    }

    get title() {
        return this.state.title;
    }

    set progress(val) {
        this.setState({progress: val});
    }

    get progress() {
        return this.state.progress;
    }

    close() {
        let { close, onCancel } = this.props;
        if (close) {
            close();
            onCancel && onCancel();
        }
    }
    
    onWrapTouchStart(e) {
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        const pNode = closest(e.target, '.tm-modal-footer');
        if (!pNode) {
            e.preventDefault();
        }
    }

    render() {
        const footer = this.props.cancelable ? [{text: '取消', onPress: () => this.close()}] : [];
        // const style = {
        //     height: '8px',
        // };
        const barStyle = {
            height: '8px',
        }

        return (
            <Modal
                transparent
                visible={true}
                title={this.state.title}
                closable={false}
                maskClosable={false}
                footer={footer}
                maskTransitionName="tm-fade"
                wrapProps={{ onTouchStart: this.onWrapTouchStart }}
            >
                <div className='tm-progress-content'>
                    <ProgressBar className='tm-progress' percent={this.state.progress} barStyle={barStyle} position='normal' />
                    <div aria-hidden="true">{this.state.progress}%</div>
                </div>
            </Modal>
        );
    }
}

export default function progress(title, onCancel) {
    let instance = null;

    const div = document.createElement('div');
    document.body.appendChild(div);

    function close() {
        ReactDOM.unmountComponentAtNode(div);
        if (div && div.parentNode) {
            div.parentNode.removeChild(div);
        }
    }

    ReactDOM.render(
        <Progress 
            ref={ref => instance = ref}
            close={close}
            onCancel={onCancel}
            cancelable={typeof onCancel === 'function'}
        />,
        div
    );

    if (instance && title) {
        instance.title = title;
    }

    return { 
        update: (title, progress, autoclose= true) => {
            if (instance) {
                if (title && instance.title !== title) {
                    instance.title = title;
                }
                if (progress >= 0 && progress <= 100) {
                    instance.progress = progress;
                }

                if ((progress < 0 || progress >= 100) && autoclose) {
                    close();
                    return;
                }
            }
        },
        close
    };
}