import React from 'react'
import { Image } from 'thanos'
import PropTypes from 'prop-types'
import { QUESTION_TYPE } from 'Constants'

import './index.less'

const PREFIX_CLS = 'question'

class Question extends React.PureComponent {
  getAnswer = () => {
    const { data, userAnswer } = this.props

    let answer
    let youAnswer
    if (data.type === QUESTION_TYPE.select) {
      answer = data.answer
      youAnswer = userAnswer
    }

    if (data.type === QUESTION_TYPE.judge) {
      answer = data.answer === '1' ? '对' : '错'

      if (userAnswer.trim() !== '1' && userAnswer.trim() !== '2') {
        youAnswer = userAnswer.trim()
      } else {
        youAnswer = userAnswer.trim() === '1' ? '对' : '错'
      }
    }

    return { answer, youAnswer }
  }

  renderOptions() {
    const { data, activeOption } = this.props

    if (data.type === QUESTION_TYPE.select) {
      return (
        <React.Fragment>
          {data.options.map((v, idx) => {
            return (
              <div
                key={idx}
                className={`${PREFIX_CLS}-option`}
                onClick={this.props.onOptionClick.bind(null, v.code)}
              >
                <Image
                  source={activeOption === v.code ?
                    require('Images/option-2.svg') :
                    require('Images/option-1.svg')
                  }
                  className={`${PREFIX_CLS}-option-bg`}
                />
                <div
                  className={`${PREFIX_CLS}-option-text`}
                >
                  {v.code}{v.optionValue}
                </div>
              </div>
            )
          })}
        </React.Fragment>
      )
    }

    if (data.type === QUESTION_TYPE.judge) {
      const options = [
        { code: '1', optionValue: '对' },
        { code: '2', optionValue: '错' },
      ]

      return (
        <React.Fragment>
          {options.map((v, idx) => {
            return (
              <div
                key={idx}
                className={`${PREFIX_CLS}-option`}
                onClick={this.props.onOptionClick.bind(null, v.code)}
              >
                <Image
                  source={activeOption === v.code ?
                    require('Images/option-2.svg') :
                    require('Images/option-1.svg')
                  }
                  className={`${PREFIX_CLS}-option-bg`}
                />
                <div
                  className={`${PREFIX_CLS}-option-text`}
                >
                  {v.optionValue}
                </div>
              </div>
            )
          })}
        </React.Fragment>
      )
    }

    return null
  }

  renderAnalysis() {
    const { data, userAnswer } = this.props

    const { answer, youAnswer } = this.getAnswer()

    return (
      <div className={`${PREFIX_CLS}-analysis`}>
        <div>答案</div>
        <p className={`${PREFIX_CLS}-result`}>
          {data.answer.trim() === userAnswer.trim() ? '正确' : '错误'}
        </p>
        <p className={`${PREFIX_CLS}-answer`}>
          正确答案是
          <span>{answer}</span>
        </p>
        <p className={`${PREFIX_CLS}-answer`}>
          你的答案是
          <span>{youAnswer}</span>
        </p>
      </div>
    )
  }

  render() {
    const { data, mode } = this.props

    if (!data) {
      return null
    }

    return (
      <div className={PREFIX_CLS}>
        <div className={`${PREFIX_CLS}-stem`}>
          <p>{data.title}</p>
          {data.content}
        </div>
        {mode === 'answer' &&
          <div className={`${PREFIX_CLS}-options`}>
            {this.renderOptions()}
          </div>
        }
        {mode === 'analysis' &&
          <div className={`${PREFIX_CLS}-analysis`}>
            {this.renderAnalysis()}
          </div>
        }
      </div>
    )
  }
}

Question.propTypes = {
  data: PropTypes.object,
  mode: PropTypes.oneOf(['answer', 'analysis']),
  userAnswer: PropTypes.string,
  activeOption: PropTypes.string,
  onOptionClick: PropTypes.func,
}

Question.defaultProps = {
  data: null,
  mode: 'answer',
  userAnswer: '',
  activeOption: '',
  onOptionClick: () => {},
}

export default Question
