import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Dialog from '../dialog';
import TouchFeedback from '../touch-feedback';
import View from '../view';

import './style/index.less';

export default class Modal extends React.Component {
    static propTypes = {
        prefixCls: PropTypes.string,
        transitionName: PropTypes.string,
        maskTransitionName: PropTypes.string,
        className: PropTypes.string,
        wrapClassName: PropTypes.string,
        wrapProps: PropTypes.any,
        platform: PropTypes.string,
        style: PropTypes.object,
        bodyStyle: PropTypes.object,

        title: PropTypes.node,
        visible: PropTypes.bool,
        maskClosable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
        closable: PropTypes.bool,
        footer: PropTypes.arrayOf(PropTypes.object),
        onClose: PropTypes.func,
        transparent: PropTypes.bool,
        popup: PropTypes.bool,
        animated: PropTypes.bool,
        animationType: PropTypes.any,
        onAnimationLeave: PropTypes.func,
        animateAppear: PropTypes.bool,
        operation: PropTypes.bool,
    };

    static defaultProps = {
        prefixCls: 'tm-modal',
        transparent: false,
        popup: false,
        animationType: 'slide-down',
        animated: true,
        style: {},
        onShow() {},
        footer: [],
        closable: false,
        operation: false,
        platform: 'ios',
    };

    static alert = (title, message, actions, platform) => { return {close: () => {} };};
    static prompt = (title, message, callbackOrActions, type, defaultValue, placeholders, platform) => {return { close: () => {} };};
    static operation = (actions, platform) => {return { close: () => {} };};
    static progress = title => { return { update: (title, progress) => {}, close: () => {} };}

    renderFooterButton(button, prefixCls, i) {
        let buttonStyle = {};
        if (button.style) {
            buttonStyle = button.style;
            if (typeof buttonStyle === 'string') {
                const styleMap = {
                    cancel: {},
                    default: {},
                    destructive: { color: 'red' },
                };
                buttonStyle = styleMap[buttonStyle] || {};
            }
        }

        const onClickFn = (e) => {
            e.preventDefault();
            if (button.onPress) {
                button.onPress();
            }
        };

        return (
            <TouchFeedback activeClassName={`${prefixCls}-button-active`} key={i}>
                <a
                    className={`${prefixCls}-button`}
                    role="button"
                    style={buttonStyle}
                    onClick={onClickFn}
                >
                    {button.text || `Button`}
                </a>
            </TouchFeedback>
        );
    }

    render() {
        const {
            prefixCls,
            className,
            wrapClassName,
            transitionName,
            maskTransitionName,
            style,
            platform,
            footer = [],
            operation,
            animated,
            transparent,
            popup,
            animationType,
            ...restProps
        } = this.props;

        const btnGroupClass = classnames(
            `${prefixCls}-button-group-${
                footer.length === 2 && !operation ? 'h' : 'v'
            }`,
            `${prefixCls}-button-group-${operation ? 'operation' : 'normal'}`,
        );
        const footerDom = footer.length ? (
            <View className={btnGroupClass} role="group">
                {footer.map((button, i) =>
                // tslint:disable-next-line:jsx-no-multiline-js
                this.renderFooterButton(button, prefixCls, i),
                )}
            </View>
        ) : null;

        let transName;
        let maskTransName;
        if (animated) {
            // tslint:disable-next-line:prefer-conditional-expression
            if (transparent) {
                transName = maskTransName = 'tm-fade';
            } else {
                transName = maskTransName = 'tm-slide-up';
            }
            if (popup) {
                transName =
                animationType === 'slide-up' ? 'tm-slide-up' : 'tm-slide-down';
                maskTransName = 'tm-fade';
            }
        }

        const wrapCls = classnames(wrapClassName, {
            [`${prefixCls}-wrap-popup`]: popup,
        });
        const cls = classnames(className, {
            [`${prefixCls}-transparent`]: transparent,
            [`${prefixCls}-popup`]: popup,
            [`${prefixCls}-popup-${animationType}`]: popup && animationType,
            [`${prefixCls}-android`]: platform === 'android',
        });

        return (
            <Dialog
                {...restProps}
                prefixCls={prefixCls}
                className={cls}
                wrapClassName={wrapCls}
                transitionName={transitionName || transName}
                maskTransitionName={maskTransitionName || maskTransName}
                style={style}
                footer={footerDom}
            />
        );
    }
}
