import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../../view';

import './style/index.less';

export default class Wave extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        colors: PropTypes.arrayOf(PropTypes.string),
        amplitude: PropTypes.number,
        reverse: PropTypes.bool,
    };

    static defaultProps = {
        amplitude: 18,
        reverse: false,
        colors: ['#2ecc71', '#3498db', '#9b59b6', '#e67e22', '#c0392b', '#e74c3c', '#e74c8c'],
    };

    render() {
        const { className, amplitude, colors, reverse } = this.props;
        const delays = [-1.1, -0.96, -0.84, -0.72, -0.64, -0.53, -0.47];
        let barColors = !!colors && colors.length === 7 ? [...colors] : [...Wave.defaultProps.colors];

        const bars = ['one', 'two', 'three', 'four', 'five', 'six', 'seven'].map((bar, index) => {
            const delay = reverse ? delays[6 - index] : delays[index];
            return <View key={index} className={`bar-${bar}`} style={{backgroundColor: barColors[index], animationDelay: delay + 's'}}/>;
        });

        return (
            <View className={classnames('tm-indicator-wave', className)} style={{height: amplitude + 'px'}} >
                {bars}
            </View>
        );
    }
}