import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Platform, Event } from '../util';

import './style/index.less';

export default class LineEdit extends React.PureComponent {
    static propTypes = {
        id: PropTypes.string,
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        editorClassName: PropTypes.string,
        style: PropTypes.object,
        placeholder: PropTypes.string,
        readonly: PropTypes.bool,
        scrollable: PropTypes.bool,
        content: PropTypes.string,
        maxlength: PropTypes.number,
        type: PropTypes.oneOf(['text', 'password']),
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onInput: PropTypes.func,
        onKeyDown: PropTypes.func,
        onKeyUp: PropTypes.func,
        onPaste: PropTypes.func,
        onCompositionStart: PropTypes.func,
        onCompositionUpdate: PropTypes.func,
        onCompositionEnd: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-lineedit',
        placeholder: '',
        readonly: false,
        content: '',
        type: 'text',
        scrollable: false,
    };

    focused = false;
    focusWithKeyboard = false;

    constructor(props) {
        super(props);
        this.state = {
            contentempty: true,
        }
    }

    set value(text) {
        if (this.editor) {
            this.editor.innerHTML = text;
            this.collapse(false);
        }
        if (this.value !== '' && this.state.contentempty) {
            this.setState({
                contentempty: false,
            });
        } else if (this.value === '' && !this.state.contentempty) {
            this.setState({
                contentempty: true,
            });
        }
    }

    get value() {
        if (this.editor) {
            return this.editor.innerHTML;
        }
        return '';
    }

    set text(text) {
        if (this.editor) {
            this.editor.innerText = text;
            this.collapse(false);
        }
    }
    get text() {
        if (this.editor) {
            return this.editor.innerText;
        }
        return '';
    }

    componentDidMount() {
        window.keyboardShown = false;
        if (!this.props.readonly) {
            document.addEventListener('click', this.onClick);
            document.addEventListener(Platform.isMobile() ? 'touchstart' : 'mousedown', this.onTouch);
            Event.subscribe('keyboard', this.onKeyboardEvent, true);
        }
        if ('content' in this.props && this.props.content !== '') {
            this.editor.innerHTML = this.props.content;
        }
    }

    componentWillUnmount() {
        if (!this.props.readonly) {
            document.removeEventListener('click', this.onClick);
            document.removeEventListener(Platform.isMobile() ? 'touchstart' : 'mousedown', this.onTouch);
            Event.unsubscribe('keyboard', this.onKeyboardEvent);
        }
    }

    componentWillReceiveProps(nextProps) {
        if ('content' in nextProps && nextProps.content !== this.props.content) {
            this.editor.innerHTML = nextProps.content;
        }
    }

    setValue(text, needCollapse = true) {
        if (this.editor) {
            this.editor.innerHTML = text;
            needCollapse && this.collapse(false);
        }
        if (this.value !== '' && this.state.contentempty) {
            this.setState({
                contentempty: false,
            });
        } else if (this.value === '' && !this.state.contentempty) {
            this.setState({
                contentempty: true,
            });
        }
    }

    collapse = atStart => {
        if (!this.editor) return;
        var range = document.createRange();
        range.selectNodeContents(this.editor);
        range.collapse(atStart);

        var selection = window.getSelection();
        selection.removeAllRanges();
        selection.addRange(range);
    }

    focus = () => {
        if (this.editor && !this.focused) {
            this.editor.focus();
        }
    }

    blur = () => {
        if (this.editor && this.focused) {
            this.editor.blur();
        }
    }

    matchDOM(node, target) {
        if (!target || !node) return false;
        if (node === target) return true;
        let parent = target.parentNode;
        while (parent) {
            if (parent === node && parent.id === node.id) return true;
            parent = parent.parentNode;
        }

        return false;
    }

    onKeyboardEvent = height => {
        if (!this.focusWithKeyboard && !this.focused) {
            return;
        }

        this.focusWithKeyboard = height > 0;

        if (Platform.isIPhone()) {
            if (height === 0) {
                document.body.scrollTop = 0;
            }
            window.keyboardShown = height > 0;
            return;
        }

        if (height > 0 && window.keyboardShown) {
            return;
        }

        window.keyboardHeight = height;
        if (!this.editor) return;
        if (window.keyboardBottom === undefined) {
            window.keyboardBottom = 0;
        }
        if (height > 0) {
            height = height / window.devicePixelRatio;
            const rect = this.editor.getBoundingClientRect();
            const documentHeight = document.documentElement.clientHeight;
            const deltaHeight = documentHeight - height;
            if (deltaHeight > rect.bottom) {
                if (deltaHeight > (rect.bottom + window.keyboardBottom)) {
                    height = 0;
                } else {
                    height = rect.bottom - window.keyboardBottom - deltaHeight;
                }
            } else {
                height = rect.bottom - deltaHeight;
            }
        }

        window.keyboardBottom = height;
        window.keyboardShown = window.keyboardHeight > 0;
        document.documentElement.style.transform = `translate3d(0, -${height}px, 0)`;
        document.documentElement.style.webkitTransform = `translate3d(0, -${height}px, 0)`;
    }

    onClick = e => {
        if (!this.matchDOM(this.lineedit, e.target)) return;
        if (Platform.isIPhone()) {
            setTimeout(() => this.focus(), 200);
        }
    }

    onTouch = e => {
        if (!this.matchDOM(this.lineedit, e.target)) {
            return;
        }
        setTimeout(() => this.focus(), 200);
    }

    onFocus = e => {
        console.log('onFocus');
        this.focused = true;
        if (window.keyboardHeight && window.keyboardHeight > 0) {
            this.onKeyboardEvent(window.keyboardHeight);
        }
        this.props.onFocus && this.props.onFocus(e);
    }

    onBlur = e => {
        console.log('onBlur');
        if (window.keyboardShown) {
            setTimeout(() => {
                document.documentElement.style.transform = `translate3d(0, 0px, 0)`;
                document.documentElement.style.webkitTransform = `translate3d(0, 0px, 0)`;
            }, 0)
        }
        this.focused = false;
        this.props.onBlur && this.props.onBlur(e);
    }

    onInput = e => {
        const { maxlength } = this.props;
        if (this.value !== '' && this.state.contentempty) {
            this.setState({
                contentempty: false,
            });
        } else if (this.value === '' && !this.state.contentempty) {
            this.setState({
                contentempty: true,
            });
        }

        if (maxlength && maxlength > 0 && this.value.length > maxlength) {
            let value = this.value;
            this.value = value.slice(0, maxlength);
        }

        this.props.onInput && this.props.onInput(e);
    }

    onKeyDown = e => {
        if (this.props.onKeyDown) {
            this.props.onKeyDown(e);
            return;
        }

        if (e.keyCode === 13) {
            e.preventDefault();
        }
    }

    onKeyUp = e => {
        if (this.props.onKeyUp) {
            this.props.onKeyUp(e);
            return;
        }
    }

    onTouchBegin = e => {
        if (!this.props.scrollable) {
            return;
        }

        this.dragging = true;
        this.lastTouch = e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
        this.velocity = 0;
        this.lastTouchTime = Date.now();
        this.stopInertiaMove = true;
    }

    onTouchMove = e => {
        if (this.dragging && this.props.scrollable) {
            let touch =  e.changedTouches ? e.changedTouches[0].clientX : e.clientX;
            let touchOffset = touch - this.lastTouch;

            this.editor.scrollLeft -= touchOffset;

            let touchTime = Date.now();
            let duration = touchTime - this.lastTouchTime;

            this.velocity = duration > 0 ? touchOffset / duration : 0;
            this.lastTouch = touch;
            this.lastTouchTime = touchTime;
            this.stopInertiaMove = true;
        }
    }

    onTouchEnd = e => {
        if (!this.dragging || !this.props.scrollable) {
            return;
        }

        this.dragging = false;
        this.stopInertiaMove = false;

        let scrollOffset = this.editor.scrollTop;

        const inertiaScroll = () => {
            if (this.stopInertiaMove) return;
            const direction = this.velocity > 0 ? 1 : -1;
            const deceleration = 0.005 * direction;
            let time = Date.now() - this.lastTouchTime;
            let v = this.velocity - time * deceleration;

            if (v * direction < 0) {
                return;
            }

            let offset = (this.velocity + v) / 2 * time;
            this.editor.scrollTop = scrollOffset - offset;
            const metrics = this.metrics();
            const offsetToEnd = metrics.contentLength - metrics.visibleLength - metrics.offset;
            if (offsetToEnd < 0.1) return;
            setTimeout(inertiaScroll, 10);
        }

        inertiaScroll();
    }

    metrics = () => {
        return {
            visibleLength: this.editor.offsetHeight,
            contentLength: this.editor.scrollHeight,
            offset: this.editor.scrollTop,
        };
    }

    render() {
        const {
            id,
            prefixCls,
            className,
            editorClassName,
            style = {},
            placeholder,
            readonly,
            type,
            onPaste,
            onCompositionStart,
            onCompositionUpdate,
            onCompositionEnd,
        } = this.props;

        const wrapCls = classnames({
            [`${prefixCls}`]: true,
        }, className);

        const wrapProps = {
            'data-empty': this.state.contentempty,
            placeholder: placeholder,
            contentEditable: !readonly,
            onKeyDown: this.onKeyDown,
            onKeyUp: this.onKeyUp,
            onPaste: onPaste,
            onCompositionStart: onCompositionStart,
            onCompositionUpdate: onCompositionUpdate,
            onCompositionEnd: onCompositionEnd,
            onTouchStart: this.onTouchBegin,
            onTouchMove: this.onTouchMove,
            onTouchEnd: this.onTouchEnd,
            onMouseDown: this.onTouchBegin,
            onMouseMove: this.onTouchMove,
            onMouseUp: this.onTouchEnd,
            onMouseLeave: this.onTouchEnd,
        };

        const wrapEditorCls = classnames(`${prefixCls}-editor`, 'needsclick', 'needsfocus', {
            [`${prefixCls}-editor-editable`]: !readonly,
            [`${prefixCls}-editor-password`]: type === 'password' && this.text !== '',
            [`${prefixCls}-editor-scrollable`]: !!this.props.scrollable,
        }, editorClassName);

        const wrapContentCls = classnames(`${prefixCls}-content`, {
            [`${prefixCls}-content-scrollable`]: !!this.props.scrollable,
        });

        return (
            <div ref={el => this.lineedit = el} id={id} className={wrapCls} style={style}>
                <div className={wrapContentCls}>
                    <div
                        ref={el => this.editor = el}
                        className={wrapEditorCls}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        onInput={this.onInput}
                        {...wrapProps}
                    />
                </div>
            </div>
        );
    }
}
