function isIPhone() {
    return /(iPhone|iPad|iPod|iOS|Mac)/i.test(navigator.userAgent);
}

function isAndroid() {
    return /(Android)/i.test(navigator.userAgent);
}

function isWindows() {
    return /(Win)/i.test(navigator.userAgent) || /(Win)/i.test(navigator.platform);
}

function isMobile() {
    return (isIPhone() || isAndroid()) && !isWindows();
}

export default { isIPhone, isAndroid, isWindows, isMobile };