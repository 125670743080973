import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view';
import Icon from '../icon';
import Text from '../text';
import TouchFeedback from '../touch-feedback';
import Modal from '../modal';
import Strings from '../strings';

import './style/index.less';

export default class Share extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        element: PropTypes.any,
        actions: PropTypes.arrayOf(PropTypes.number).isRequired,
        onShare: PropTypes.func,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-share',
        actions: [Share.WECHAT, Share.TIMELINE, Share.QQ, Share.QZONE, Share.WEIBO, Share.COPYLINK],
        onShare: action => {},
    };

    // Share to wechat friend
    static WECHAT = 0;
    // Share to wechat timeline
    static TIMELINE = 1;
    // Share to QQ friend
    static QQ = 2;
    // Share to qzone
    static QZONE = 3;
    // Share to weibo
    static WEIBO = 4;
    // Share to lechat
    static LECHAT = 5;
    // Share to lechat moment
    static MOMENT = 6;
    // Copy link
    static COPYLINK = 7;

    state = {
        visible: true,
    };

    onClose = () => {
        this.setState({
            visible: false
        });
    }

    onAnimationLeave = () => {
        const { onClose, element } = this.props;
        if (!this.state.visible && onClose && element) {
            onClose(element);
        }
    }

    onShare = action => {
        this.onClose();
        this.props.onShare && this.props.onShare(action);
    }

    renderHeader = () => {
        const { prefixCls } = this.props;

        return (
            <View className={`${prefixCls}-header`}>
                {Strings.share}
            </View>
        );
    }

    renderActions = () => {
        const { prefixCls, actions } = this.props;

        const renderAction = social => {
            return (
                <td key={social.action}>
                    <View className={`${prefixCls}-content-action`} onClick={e => this.onShare(social.action)} >
                        <Icon type={social.icon} className={`${prefixCls}-content-action-icon`} />
                        <Text className={`${prefixCls}-content-action-text`}>{social.text}</Text>
                    </View>
                </td>
            );
        }

        let items = [];
        const rows = Math.ceil(actions.length / 4);
        for (let row = 0; row < rows; row++) {
            let columns = 4;
            if (row === (rows - 1) && (actions.length % 4) !== 0) {
                columns = actions.length % 4;
            }

            let rowItems = [];
            for (let column = 0; column < columns; column++) {
                const index = row * 4 + column;
                const social = socials[actions[index]];
                if (social) {
                    rowItems.push(renderAction(social));
                }
            }
            items.push(<tr key={row}>{rowItems}</tr>);
        }

        return (
            <table className={`${prefixCls}-content`}>
                <tbody>
                    {items}
                </tbody>
            </table>
        );
    };

    renderCancel = () => {
        const { prefixCls } = this.props;

        return (
            <TouchFeedback activeClassName={`${prefixCls}-cancel-active`} >
                <View className={`${prefixCls}-cancel`} onClick={this.onClose} >
                    {Strings.cancel}
                </View>
            </TouchFeedback>
        );
    }

    render() {
        const { prefixCls, className } = this.props;
        const wrapCls = classnames(className, prefixCls);

        return (
            <Modal
                popup
                className={`${prefixCls}-modal`}
                visible={this.state.visible}
                closable={false}
                maskClosable={true}
                onClose={this.onClose}
                onAnimationLeave={this.onAnimationLeave}
                animationType='slide-up'
                // wrapProps={{ onTouchStart: this.onWrapTouchStart }}
            >
                <View className={wrapCls}>
                    {this.renderHeader()}
                    {this.renderActions()}
                    {this.renderCancel()}
                </View>
            </Modal>
        );
    }
}

const socials = [
    {
        action: Share.WECHAT,
        icon: 'share-wechat',
        text: Strings.share_wechat,
    },
    {
        action: Share.TIMELINE,
        icon: 'share-timeline',
        text: Strings.share_timeline,
    },
    {
        action: Share.QQ,
        icon: 'share-qq',
        text: Strings.share_qq,
    },
    {
        action: Share.QZONE,
        icon: 'share-qzone',
        text: Strings.share_qzone,
    },
    {
        action: Share.WEIBO,
        icon: 'share-weibo',
        text: Strings.share_weibo,
    },
    {
        action: Share.LECHAT,
        icon: 'share-lechat',
        text: Strings.share_lechat,
    },
    {
        action: Share.MOMENT,
        icon: 'share-moment',
        text: Strings.share_moment,
    },
    {
        action: Share.COPYLINK,
        icon: 'share-copylink',
        text: Strings.share_copylink,
    }
];