import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view/index';

import './style/toggle.less';

export default class PlayToggle extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        paused: PropTypes.bool,
        onPlay: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-video',
        state: 'paused',
        onPlay: () => {}
    }
    
    render() {
        let { 
            prefixCls, 
            paused,
            onPlay
        } = this.props;

        const iconState = paused ? 'play' : 'pause';
        const toggleCls = classnames(`${prefixCls}-control-stream`, `${prefixCls}-control-stream-${iconState}`);

        return (
            <View className={toggleCls} onClick={() => onPlay()} />
        );
    }
}