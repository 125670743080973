import React from 'react'
import Scroll from 'Components/Scroll'
import Question from 'Components/Question'
import { Image } from 'thanos';
import BgDiv from 'Components/BgDiv';
import { IMAGE_URL, ANSWER_RESULT } from 'Constants';
import { apiGetUserAnswer, apiGetTableQuestionResult } from 'src/Api'
import { parseUrl, getQuestionTypeString } from 'src/Utils'
import './index.less'

const PREFIX_CLS = 'answer-detail'

class AnswerDetail extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      answerList: [],
      currentIndex: -1,
      currentQid: -1,
      currentQuestion: null,
    }

    this.urlParam = parseUrl()
  }

  componentDidMount() {
    document.title = '答题详情'
    this.getData()
  }

  getData = async () => {
    try {
      const tableId = this.urlParam.tableId
      const ret = await apiGetUserAnswer(tableId)
      const answerList = ret.answerList || []

      if (answerList.length === 0) {
        return
      }

      const firstQuetion = answerList[0]

      const data = await apiGetTableQuestionResult(firstQuetion.tableId, firstQuetion.questionId)

      this.setState({
        answerList: answerList,
        currentIndex: 0,
        currentQuestion: { ...firstQuetion, ...data }
      })
    } catch (err) {
      console.log('eeeeeeeeeeeeeee', err.message)
    }
  }

  getQuestionDetail = async () => {
    try {
      let { answerList, currentIndex, currentQid } = this.state
      const data = await apiGetTableQuestionResult('88f3bcb3-9211-470b-b250-1c3c3391273d', currentQid);
      this.setState({
        currentQuestion: { ...answerList[currentIndex], ...data }
      })
    } catch (err) {
      console.log('eeeeeeeeeeeeeee', err.message)
    }
  }

  choseTopic(v,idx){
    this.setState({
      currentQid: v.questionId,
      currentIndex: idx
    },()=> this.getQuestionDetail());
  }

  renderScroll() {
    const { answerList, currentQid } = this.state;
    return (
      <Scroll direction='horizontal' className={`${PREFIX_CLS}-topic-list`}>
        <div className='topic' style={{ width: (answerList.length * 1) + 'rem' }}>
          {
            answerList.map((v, i) => (
              <div
                className={v.questionId === currentQid ? 'topic-active' : 'topic-normal'}
                key={v.questionId}
                onClick={this.choseTopic.bind(this, v, i)}
              >
                {
                  v.submitRet === ANSWER_RESULT.error &&
                  <div>
                    <Image source={require('Images/fail.png').default} className='topic-bg' />
                    <div className='topic-num'  style={{ color: '#D8271C' }}>{i + 1}</div>
                  </div>
                }
                {
                  v.submitRet === ANSWER_RESULT.correct &&
                  <div>
                    <Image source={require('Images/success.png').default} className='topic-bg' />
                    <div className='topic-num' style={{ color: '#15A5A5' }}>{i + 1}</div>
                  </div>
                }
                {
                  v.submitRet === ANSWER_RESULT.unanswer &&
                  <div>
                    <Image source={require('Images/unanswer.png').default} className='topic-bg' />
                    <div className='topic-num'  style={{ color:'#989898' }}>{i + 1}</div>
                  </div>
                }
              </div>
            ))
          }
        </div>
      </Scroll>
    )
  }

  renderQuestion() {
    const { currentQuestion } = this.state

    if (!currentQuestion) {
      return null
    }

    return (
      <div className={`${PREFIX_CLS}-question`}>
        <p className={`${PREFIX_CLS}-question-type`}>
          {getQuestionTypeString(currentQuestion.question.type)}
        </p>
        <Question
          data={currentQuestion.question}
          userAnswer={currentQuestion.submitAnswer}
          mode='analysis'
        />
      </div>
    )
  }

  renderStat() {
    const { currentQuestion } = this.state

    if (!currentQuestion) {
      return null
    }

    const status = (number, unit, text) => (
      <div className={`${PREFIX_CLS}-item`}>
        <p className={`${PREFIX_CLS}-item-data`}>
          <span>{number}</span>{unit}
        </p>
        <p>{text}</p>
      </div>
    )

    let rate = (currentQuestion.correctAnswerNumber / currentQuestion.answerNumber * 100)
    rate = Math.floor(rate * 100) / 100

    return (
      <div className={`${PREFIX_CLS}-stat`}>
        <p className={`${PREFIX_CLS}-stat-label`}>数据</p>

        <div className={`${PREFIX_CLS}-data`}>
          <Image
            source={require('Images/info-bg.svg')}
            className={`${PREFIX_CLS}-data-bg`}
          />
          <div className={`${PREFIX_CLS}-data-content`}>
            {status(Math.floor(currentQuestion.useTime / 1000), '秒', '我的用时')}
            {status(currentQuestion.answerNumber, '人', '当前答题人数')}
            {status(rate, '%', '全网正确率')}
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <BgDiv style={{ height: '100%' }} src={IMAGE_URL.BG_MUDAN}>
        <div  className={PREFIX_CLS}>
          {this.renderScroll()}
          {this.renderQuestion()}
          {this.renderStat()}
        </div>
      </BgDiv>
    )
  }
}

export default AnswerDetail
