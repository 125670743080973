import Component from '../interface/component';
import consts from '../lib/consts';

/**
 * Path
 * @class Path
 * @param {Graphics} graphics - Graphics instance
 * @extends {Component}
 * @ignore
 */
class Path extends Component {
  constructor(graphics) {
    super(consts.componentNames.PATH, graphics);
  }

  /**
   * Start icon mode
   */
  start() {}

  /**
   * End icon mode
   */
  end() {}
}

export default Path;
