import React from 'react'
import { Modal } from 'antd-mobile'
import PropTypes from 'prop-types'

import './CountdownModal.less'

const PREFIX_CLS = 'countdown-modal'

class CountdownModal extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      countdown: 5,
    }

    this.timer = null
  }

  componentDidMount() {
    this.timer = setInterval(() => {
      if (this.state.countdown > 1) {
        this.setState({ countdown: this.state.countdown - 1 })
      } else {
        clearInterval(this.timer)
        this.timer = null
        this.props.onEnd()
      }
    }, 1000)
  }

  render() {
    const { visible } = this.props

    return (
      <Modal
        wrapClassName={PREFIX_CLS}
        visible={visible}
        transparent
        transitionName='am-zoom'
        maskTransitionName='am-fade'
      >
        <div className={`${PREFIX_CLS}-content`}>
          {this.state.countdown}
        </div>
      </Modal>
    )
  }
}

CountdownModal.propTypes = {
  visible: PropTypes.bool,
  onEnd: PropTypes.func,
}

CountdownModal.defaultProps = {
  visible: false,
  onEnd: () => {},
}

export default CountdownModal
