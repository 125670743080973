import React from 'react'
import { Image } from 'thanos'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import './index.less'

const PREFIX_CLS = 'progress'

class Progress extends React.PureComponent {
  render() {
    const { className, percent, preText, text } = this.props

    const percentStyle = {
      width: `${percent}%`,
    }

    return (
      <div className={classnames(PREFIX_CLS, className)}>
        {preText && <div className={`${PREFIX_CLS}-pretext`}>{preText}</div>}

        <div className={`${PREFIX_CLS}-bar`}>
          <div className={`${PREFIX_CLS}-bar1`}></div>
          <div className={`${PREFIX_CLS}-bar2`} style={percentStyle}>
            <Image className={`${PREFIX_CLS}-point`} source={require('Images/clock.svg')}/>
          </div>
        </div>

        {text && <div className={`${PREFIX_CLS}-text`}>{text}</div>}
      </div>
    )
  }
}

Progress.propTypes = {
  percent: PropTypes.number,
  preText: PropTypes.string,
  text: PropTypes.string,
}

Progress.defaultProps = {
  percent: 0,
  preText: '',
  text: '',
}

export default Progress
