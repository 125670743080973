import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view/index';

import './style/toggle.less';

export default class FullscreenToggle extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        prefixCls: PropTypes.string,
        onFullscreen: PropTypes.func.isRequired,
    };

    static defaultProps = {
        prefixCls: 'tm-video',
        onFullscreen: () => {}
    };

    constructor(props) {
        super(props);

        this.state = {
            fullscreen: false
        }
    }

    onFullscreen() {
        const { onFullscreen } = this.props;
        if (onFullscreen) {
            onFullscreen();
        }

        this.setState({ fullscreen: !this.state.fullscreen });
    }

    render() {
        const {
            className,
            prefixCls,
        } = this.props;

        const iconState = this.state.fullscreen ? 'minimize' : 'maximize';
        const toggleCls = classnames(className, `${prefixCls}-control-fullscreen`, `${prefixCls}-control-fullscreen-${iconState}`);

        return (
            <View className={toggleCls} onClick={() => this.onFullscreen()} />
        );
    }
}