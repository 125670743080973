import React from 'react';
import BgDiv from 'Components/BgDiv';
import Scroll from 'Components/Scroll';
import Button from 'Components/Button';
import { IMAGE_URL, ANSWER_RESULT } from 'Constants';
// import Circle from './Circle';
import TopicList from './TopicList';
import { Image } from 'thanos';
import { apiGetUserAnswer } from 'src/Api';
import { buildUrl, parseUrl } from 'src/Utils';
import './index.less';

const CSS = 'report';

class AnswerReport extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      errorCount: 0,
      correctCount: 0,
      unanswerCount: 0,
      timeCount: 0,
      totalScore: 100,
    };
  }

  componentDidMount() {
    document.title = '答题报告';
    this.getUserAnswerList();
  }

  async getUserAnswerList() {
    let url = parseUrl();
    if (!url.hasOwnProperty('tableId')) return;
    try {
      const res = await apiGetUserAnswer(url.tableId);
      this.countData(res);
    } catch (e) {
      console.log(e.message);
    }
  }

  countData(res){
    let error = 0, correct = 0, unanswer = 0, timecount = 0;
    let list = res.answerList;
    list.forEach((v) => {
      if (v.submitRet === ANSWER_RESULT.error) {
        error += 1;
      }
      if (v.submitRet === ANSWER_RESULT.correct) {
        correct += 1;
      }
      if (v.submitRet === ANSWER_RESULT.unanswer) {
        unanswer += 1;
      }
      timecount += v.useTime;
    });
    this.setState({
      errorCount: error,
      correctCount: correct,
      unanswerCount: unanswer,
      timeCount: Math.floor(timecount / 1000),
      totalScore: res.totalScore,
      list: res.answerList
    })
  }

  checkInfo = () => {
    let url = parseUrl();
    if (!url.hasOwnProperty('tableId')) return;
    let p = {
      token: url.token,
      tableId: url.tableId
    }
    let gotourl = buildUrl('/answer-detail', p)
    this.props.history.replace(gotourl);
  };

  render() {
    const { errorCount, correctCount, unanswerCount, timeCount, totalScore, list } = this.state;
    return (
      <BgDiv style={{ height: '100%' }} src={IMAGE_URL.BG_MUDAN}>
        <div className={CSS}>
          <div className={`${CSS}-progress`}>
            <div className='circle-box'>
              <Image source={require('Images/score_bg.png').default} className='pro-bg' />
              <div className='score'>
                <div className='my-score'>
                  <span>{correctCount * 2}</span>
                  <span>分</span>
                </div>
                <div className='desc'>我的成绩</div>
                <div className='desc'>满分{totalScore}</div>
              </div>
              {/* <Circle score={80} /> */}
            </div>
          </div>
          <div className={`${CSS}-info`}>
            <Image source={require('Images/info-bg.svg')} className='bg' />
            <div className='content'>
              <div className='content-item'>
                <div className='count'>
                  <span>{correctCount}</span>&nbsp;
                  <span>道</span>
                </div>
                <div className='con-state'>答对</div>
              </div>
              <div className='content-item'>
                <div className='count'>
                  <span>{errorCount}</span>&nbsp;
                  <span>题</span>
                </div>
                <div className='con-state'>答错</div>
              </div>
              <div className='content-item'>
                <div className='count'>
                  <span>{unanswerCount}</span>&nbsp;
                  <span>题</span>
                </div>
                <div className='con-state'>未答</div>
              </div>
              <div className='content-item'>
                <div className='count'>
                  <span>{timeCount}</span>&nbsp;
                  <span>秒</span>
                </div>
                <div className='con-state'>用时</div>
              </div>
            </div>
          </div>
          <div className={`${CSS}-state`}>
            <div className='state-item'>
              <span></span>&nbsp;
              <span>答对</span>
            </div>
            <div className='state-item'>
              <span style={{ backgroundColor: '#D9271C' }}></span>&nbsp;
              <span>答错</span>
            </div>
            <div className='state-item'>
              <span style={{ backgroundColor: '#AFAFAF' }}></span>&nbsp;
              <span>未答</span>
            </div>
          </div>
          <Scroll>
            <TopicList list={list} />
          </Scroll>
          <div className={`${CSS}-btn`}>
            <Button onClick={this.checkInfo}>查看详情</Button>
          </div>
        </div>
      </BgDiv>
    );
  }
}

export default AnswerReport;
