import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './index.less';

import View from '../view';
import Icon from '../icon';
import Text from '../text';
import Toast from '../toast';

import FabricCanvas from './fabric-canvas';
import BrushSelect from './brush-select';
import IconSelect from './icon-select';
import { defaultColors } from './colors';

import consts from './fabric-canvas/lib/consts';
import alert from './alert';

const { drawingModes } = consts;

const prefixCls = 'tm-picture-editor';

class PictureEditor extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      drawingMode: '', // 模式
      zoom: 1, // 缩放比例

      // 笔刷
      brushColor: defaultColors[0],
      brushWidth: 5,
      brushSelectVisible: false,

      // 文本大小
      textColor: defaultColors[0],
      textWidth: 50,

      // 图标
      iconColor: defaultColors[0],
      iconWidth: 3,
      iconSelectVisible: false,

      // 撤销、重做
      undoNum: 0,
      redoNum: 0,
    };

    this.name = props.name;
    this.source = props.source;
    this.onConfirm = props.onConfirm;
  }

  onLoaded = () => {
    this._fabricCanvas.setBrush({
      width: this.state.brushWidth,
      color: this.state.brushColor,
    });
    this.activateNormalMode();
    // this._attachHammerEvents();
  };

  onMouseDown = () => {
    const { drawingMode } = this.state;
    if (drawingMode === drawingModes.FREE_DRAWING) {
      this.hideBrushSelect();
    }
  };

  onDrawingModeChanged = (drawingMode) => {
    this.setState({
      drawingMode,
    });
    if (drawingMode === drawingModes.DELETION) {
      this.hideIconSelect();
    }
  };

  onRemoveObject = (objs) => {
    const ids = objs.map((obj) => obj.__fe_id);
    this._fabricCanvas.removeObjects(ids);
  };

  onObjectActivated = (obj) => {
    this.activeObjectId = obj.id;

    if (obj.type === 'icon') {
      this.activateIconMode();
    } else if (obj.type === 'text' || obj.type === 'i-text') {
      this.activateTextMode();
    } else if (obj.type === 'path') {
      this.activatePathMode();
    }
  };

  onUndoStackChanged = (obj) => {
    this.setState({
      undoNum: obj,
    });
  };

  onRedoStackChanged = (obj) => {
    console.log('redoNum', obj);
    this.setState({
      redoNum: obj,
    });
  };

  onSelectionCleared = () => {
    this.activeObjectId = null;

    if (!this._fabricCanvas) return;

    this.activateNormalMode();
    this.hideBrushSelect();
    this.hideIconSelect();
  };

  onSelectionCreated = () => {};

  handleCancel = () => {
    this._fabricCanvas.restoreZoom();

    this.props.onClose && this.props.onClose();
  };

  handleConfirm = (e) => {
    this._fabricCanvas.restoreZoom();

    const { noToast } = this.props;

    const toast = Toast.loading('', 60);
    this._fabricCanvas
      .toDataURL()
      .then(this.onConfirm)
      .then((res) => {
        toast.close();
        !noToast && Toast.show('保存成功', 0.6);
        this.props.onClose();
      })
      .catch((err) => {
        toast.close();
        alert('', err.message, [
          { text: '取消', onPress: () => {} },
          { text: '重试', onPress: () => this.handleConfirm(e) },
        ]);
      });
  };

  addText = () => {
    this.discardSelection();
    this.hideIconSelect();
    this._fabricCanvas.addText('双击编辑', {
      fontSize: this.state.textWidth,
      fill: this.state.textColor,
    });
  };

  undo = () => {
    this._fabricCanvas.undo();
  };

  redo = () => {
    this._fabricCanvas.redo();
  };

  rotate = () => {
    this._fabricCanvas.rotate(90);
  };

  activateNormalMode = () => {
    this._fabricCanvas.startDrawingMode('NORMAL');
  };

  activateFreeDrawingMode = () => {
    const { drawingMode, brushSelectVisible } = this.state;

    this.discardSelection();
    this.hideIconSelect();

    if (drawingMode === drawingModes.FREE_DRAWING) {
      if (brushSelectVisible) {
        this.hideBrushSelect();
      } else {
        this.showBrushSelect();
      }
    } else {
      this._fabricCanvas.startDrawingMode('FREE_DRAWING');
    }
  };

  activateTextMode = () => {
    this._fabricCanvas.startDrawingMode('TEXT');
  };

  activateIconMode = () => {
    this._fabricCanvas.startDrawingMode('ICON');
  };

  activatePathMode = () => {
    this._fabricCanvas.startDrawingMode('PATH');
  };

  activateDeletionMode = () => {
    this._fabricCanvas.startDrawingMode('DELETION');
  };

  load(source, name, onConfirm) {
    this.name = name;
    this.source = source;
    this.onConfirm = onConfirm;

    this._fabricCanvas.load(source, name);
  }

  clear() {
    this._fabricCanvas.clear();
  }

  selectIcon = () => {
    this.discardSelection();
    this.activateIconMode();
    if (this.state.iconSelectVisible) {
      this.hideIconSelect();
    } else {
      this.showIconSelect();
    }
  };

  addIcon = (type) => {
    this.hideIconSelect();

    this._fabricCanvas.addIcon(type, {
      color: this.state.iconColor,
      scaleX: this.state.iconWidth,
      scaleY: this.state.iconWidth,
    });
  };

  discardSelection = () => {
    this._fabricCanvas.discardSelection();
  };

  // brush

  onBrushColorChange = (color) => {
    this.setState({ brushColor: color }, () => {
      this._fabricCanvas.setBrush({ color });
    });
  };

  onBrushWidthChange = (width) => {
    this.setState({ brushWidth: width }, () => {
      this._fabricCanvas.setBrush({ width }, false);
    });
  };

  onBrushWidthConfirm = (width) => {
    this.setState({ brushWidth: width }, () => {
      this._fabricCanvas.setBrush({ width });
    });
  };

  // text

  onTextColorChange = (color) => {
    this.setState({ textColor: color }, () => {
      this._fabricCanvas.changeTextStyle(this.activeObjectId, { fill: color });
    });
  };

  onTextWidthChange = (width) => {
    this.setState({ textWidth: width }, () => {
      this._fabricCanvas.changeTextStyle(this.activeObjectId, { fontSize: width }, false);
    });
  };

  onTextWidthConfirm = (width) => {
    this.setState({ textWidth: width }, () => {
      setTimeout(() => {
        this._fabricCanvas.changeTextStyle(this.activeObjectId, { fontSize: width });
      }, 100);
    });
  };

  // icon color changed

  onIconColorChange = (color) => {
    this.setState({ iconColor: color }, () => {
      this._fabricCanvas.changeIconStyle(this.activeObjectId, { fill: color });
    });
  };

  onIconWidthChange = (width) => {
    this.setState({ iconWidth: width }, () => {
      this._fabricCanvas.changeIconStyle(this.activeObjectId, { scaleX: width, scaleY: width }, false);
    });
  };

  onIconWidthConfirm = (width) => {
    this.setState({ iconWidth: width }, () => {
      setTimeout(() => {
        this._fabricCanvas.changeIconStyle(this.activeObjectId, { scaleX: width, scaleY: width });
      }, 100);
    });
  };

  showBrushSelect = () => {
    this.setState({
      brushSelectVisible: true,
    });
  };

  hideBrushSelect = () => {
    this.setState({
      brushSelectVisible: false,
    });
  };

  showIconSelect = () => {
    this.setState({
      iconSelectVisible: true,
    });
  };

  hideIconSelect = () => {
    this.setState({
      iconSelectVisible: false,
    });
  };

  zoomIn = () => {
    this._fabricCanvas.zoomIn();
  }

  zoomOut = () => {
    this._fabricCanvas.zoomOut();
  }

  restoreZoom = () => {
    this._fabricCanvas.restoreZoom();
  }

  renderHeader = () => {
    const headerPrefixCls = `${prefixCls}-header`;
    return (
      <View className={`${headerPrefixCls}`}>
        <View className={`${headerPrefixCls}-cancel`}>
          <Text onClick={this.handleCancel}>取消</Text>
        </View>
        <View className={`${headerPrefixCls}-center`}>
          <View className={classnames(`${headerPrefixCls}-zoom-in`)}>
            <Icon
              type="ie-zoom-in"
              size="md"
              color={'#ffffff'}
              onClick={this.zoomIn}
            />
          </View>
          <View className={classnames(`${headerPrefixCls}-zoom-out`)}>
            <Icon
              type="ie-zoom-out"
              size="md"
              color={'#ffffff'}
              onClick={this.zoomOut}
            />
          </View>
          <View className={classnames(`${headerPrefixCls}-zoom-restore`)}>
            <Icon
              type="ie-zoom-restore"
              size="md"
              color={'#ffffff'}
              onClick={this.restoreZoom}
            />
          </View>
          <span className={classnames(`${headerPrefixCls}-separate`)}/>
          <View className={classnames(`${headerPrefixCls}-undo`)}>
            <Icon
              type="ie-undo"
              size="md"
              color={this.state.undoNum ? '#ffffff' : 'rgb(255,255,255, 0.3)'}
              onClick={this.undo}
            />
          </View>
          <View className={classnames(`${headerPrefixCls}-redo`)}>
            <Icon
              type="ie-redo"
              size="md"
              color={this.state.redoNum ? '#ffffff' : 'rgb(255,255,255, 0.3)'}
              onClick={this.redo}
            />
          </View>
          <span className={classnames(`${headerPrefixCls}-separate`)}/>
          <View className={classnames(`${headerPrefixCls}-rotate`)}>
          <Icon type="ie-rotate-picture" size="md" color="#ffffff" onClick={this.rotate} />
        </View>
        </View>
        <View className={`${headerPrefixCls}-confirm`}>
          <Text onClick={this.handleConfirm}>完成</Text>
        </View>
      </View>
    );
  };

  renderFooter = () => {
    const footerPrefixCls = `${prefixCls}-footer`;
    const { drawingMode } = this.state;
    return (
      <View className={`${footerPrefixCls}`}>
        {drawingMode === drawingModes.FREE_DRAWING && this.state.brushSelectVisible && (
          <BrushSelect
            color={this.state.brushColor}
            onColorChange={this.onBrushColorChange}
            width={this.state.brushWidth}
            widthMin={1}
            widthMax={20}
            onWidthChange={this.onBrushWidthChange}
            onWidthConfirm={this.onBrushWidthConfirm}
          />
        )}
        {drawingMode === drawingModes.TEXT && this.activeObjectId && (
          <BrushSelect
            color={this.state.textColor}
            onColorChange={this.onTextColorChange}
            width={this.state.textWidth}
            widthMin={10}
            widthMax={150}
            onWidthChange={this.onTextWidthChange}
            onWidthConfirm={this.onTextWidthConfirm}
          />
        )}
        {drawingMode === drawingModes.ICON && this.activeObjectId && (
          <BrushSelect
            color={this.state.iconColor}
            onColorChange={this.onIconColorChange}
            width={this.state.iconWidth}
            widthMin={1}
            widthMax={10}
            onWidthChange={this.onIconWidthChange}
            onWidthConfirm={this.onIconWidthConfirm}
          />
        )}

        {drawingMode === drawingModes.ICON && this.state.iconSelectVisible && (
          <IconSelect
            onSelect={this.addIcon} //
          />
        )}
        <View className={`${footerPrefixCls}-bottom`}>
          <View className={`${footerPrefixCls}-bottom-pointer`}>
            <Icon
              type="ie-pointer"
              size="md"
              color={drawingMode === drawingModes.NORMAL ? '#03c0ab' : '#333'}
              onClick={this.activateNormalMode}
            />
          </View>
          <View className={`${footerPrefixCls}-bottom-draw`}>
            <Icon
              type="ie-draw"
              size="md"
              color={drawingMode === drawingModes.FREE_DRAWING ? '#03c0ab' : '#333'}
              onClick={this.activateFreeDrawingMode}
            />
          </View>
          <View className={`${footerPrefixCls}-bottom-remove`}>
            <Icon
              type="ie-eraser"
              size="md"
              color={drawingMode === drawingModes.DELETION ? '#03c0ab' : '#333'}
              onClick={this.activateDeletionMode}
            />
          </View>
          <View className={`${footerPrefixCls}-bottom-text`}>
            <Icon
              type="ie-text"
              size="md"
              color={drawingMode === drawingModes.TEXT ? '#03c0ab' : '#333'}
              onClick={this.addText}
            />
          </View>
          <View className={`${footerPrefixCls}-bottom-icon`}>
            <Icon
              type="ie-icon"
              size="md"
              color={drawingMode === drawingModes.ICON ? '#03c0ab' : '#333'}
              onClick={this.selectIcon}
            />
          </View>
        </View>
      </View>
    );
  };

  render() {
    const { name, source } = this.props;
    return (
      <View className={prefixCls}>
        <div className={`${prefixCls}-body`}>
          <FabricCanvas
            ref={(e) => (this._fabricCanvas = e)} //
            name={name}
            source={source}
            cssMaxWidth={document.documentElement.clientWidth}
            cssMaxHeight={document.documentElement.clientHeight - 120}
            onLoaded={this.onLoaded}
            onMouseDown={this.onMouseDown}
            onRemoveObject={this.onRemoveObject}
            onObjectActivated={this.onObjectActivated}
            onUndoStackChanged={this.onUndoStackChanged}
            onRedoStackChanged={this.onRedoStackChanged}
            onDrawingModeChanged={this.onDrawingModeChanged}
            onSelectionCleared={this.onSelectionCleared}
            onSelectionCreated={this.onSelectionCreated}
          />
        </div>
        {this.renderHeader()}
        {this.renderFooter()}
      </View>
    );
  }
}

PictureEditor.propTypes = {
  prefixCls: PropTypes.string,
  className: PropTypes.string,
  name: PropTypes.string,
  source: PropTypes.string,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func,
  noToast: PropTypes.bool, // 不显示Toast成功提示
};

PictureEditor.defaultProps = {
  noToast: false,
};

let pictureEditor = null;

PictureEditor.edit = function (image, name, onConfirm, noToast) {
  const id = prefixCls + '-root';

  let element = document.getElementById(id);
  if (element) {
    element.style.display = 'block';
    pictureEditor.load(image, name, onConfirm);
    return;
  }

  element = document.createElement('div');
  element.id = id;
  document.body.appendChild(element);

  const close = () => {
    element.style.display = 'none';
    pictureEditor.clear();
  };

  ReactDOM.render(
    <PictureEditor
      ref={(e) => (pictureEditor = e)}
      name={name} //
      source={image}
      onConfirm={onConfirm}
      onClose={close}
      noToast={noToast}
    />,
    element
  );
};

export default PictureEditor;
