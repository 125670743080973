import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view';
import { Log } from '../util';
import Spot from './Spot';

import './style/index.less';

export default class Hotspot extends React.Component {

    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        id: PropTypes.string,
        source: PropTypes.string,
        width: PropTypes.number.isRequired,
        height: PropTypes.number.isRequired,
        spots: PropTypes.array.isRequired,
        draggable: PropTypes.bool,
        onSelect: PropTypes.func,
        onDecorate: PropTypes.func,
        onReady: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-hotspot',
        id: 'hotspot',
        draggable: false,
    }

    spots = {};
    spotElems = [];

    constructor(props) {
        super(props);
        this.state = {
            scale: 1,
            height: 0,
            source: props.source
        };
    }

    set source(source) {
        if (source !== this.state.source) {
            this.setState({
                source: source
            });
        }
    }
    get source() {
        return this.state.source;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ('source' in nextProps && nextProps.source !== this.state.source) {
            this.setState({
                source: nextProps.source
            });
        }
    }

    reset = () => {
        for (let elems in this.spotElems) {
            for (let elem in elems) {
                if (elem) {
                    elem.selected = false;
                }
            }
        }
    }

    getElements() {
        return this.spotElems.map(item => {
            let res = [];
            for(let key in item) {
                res.push({key:parseInt(key,10),value:item[key]});
            }
            return res.sort((a,b) => { return a.key - b.key}).map(item=>item.value);
        });
    }

    loadSpots = e => {
        const {
            spots,
            width,
            height,
        } = this.props;

        if (!width || width === 0 && !height || height === 0 || !spots || spots.length < 1 || !this.hotspot) {
            return;
        }

        const elemWidth = this.hotspot.clientWidth;
        const elemHeight = elemWidth / width * height;
        const scale = elemWidth / width;

        this.spots = [];

        let spotCanvas = document.createElement('canvas');
        let spotCanvasContext = spotCanvas.getContext('2d');

        const clipSpotImage = coords => {
            spotCanvas.width = coords[2];
            spotCanvas.height = coords[3];
            spotCanvasContext.drawImage(this.hotspot, coords[0], coords[1], coords[2], coords[3], 0, 0, spotCanvas.width, spotCanvas.height);
            return spotCanvas.toDataURL('image/png');
        }

        spots.forEach((rowData) => {
            const rows = rowData.map((data) => {
                let spot = {};
                let spotObj;
                if (typeof data === 'string') {
                    spotObj = JSON.parse(data);
                } else if (Object.prototype.toString.call(data) === '[object Object]') {
                    spotObj = data;
                } else {
                    return spot;
                }

                const coordinate = shape => {
                    const leftTop = shape['lt'];
                    const rightBottom = shape['rb'];
                    const width = rightBottom.x - leftTop.x;
                    const height = rightBottom.y - leftTop.y;
                    return [leftTop.x, leftTop.y, width, height];
                }

                if (spotObj.hasOwnProperty('rect')) {
                    const shape = spotObj['rect'];
                    spot['shape'] = 'rect';
                    spot['coords'] = coordinate(shape);
                    spot['source'] = clipSpotImage(spot['coords']);
                } else if (spotObj.hasOwnProperty('oval')) {
                    const shape = spotObj['oval'];
                    spot['shape'] = 'oval';
                    spot['coords'] = coordinate(shape);
                    spot['source'] = clipSpotImage(spot['coords']);
                }

                return spot;
            });
            this.spots.push(rows);
        });
        
        const {onReady} = this.props;
        this.setState({
            scale: scale,
            height: elemHeight,
        },onReady);
    }

    onDragOver = e => {
        e.preventDefault();
    }

    onDrop = e => {
        const id = e.dataTransfer.getData('Text');
        const parts = id.split('-');
        if (parts.length <= 2) {
            return;
        }

        const row = parseInt(parts[parts.length - 2], 10);
        const column = parseInt(parts[parts.length - 1], 10);
        const spot = this.spotElems[row][column];
        if (!spot) {
            return;
        }

        let elem = ReactDOM.findDOMNode(spot);
        if (elem.childElementCount === 0) {
            elem.appendChild(document.getElementById(id));
        }
    }

    saveSpotElement = (elem, row, column) => {
        let elems = this.spotElems[row] || {};
        elems[column] = elem;
        this.spotElems[row] = elems;
    }

    onDecorate= (row,column,bSelect) => {
        const {onDecorate} = this.props;
        if(onDecorate) {
            return onDecorate(row,column,bSelect)
        }
        return null;
    }

    renderSpots = () => {
        const {
            spots, 
            onSelect, 
            draggable, 
            width, 
            height,
            id
        } = this.props;

        if (!width || !height || !spots || spots.length === 0 || this.state.height === 0) {
            return null;
        }

        let elements = [];

        this.spots.forEach((data, row) => {
            const elems = data.map((spot, column) => {
                return (
                    <Spot 
                        ref={el => this.saveSpotElement(el, row, column)}
                        key={row + '-' + column}
                        id={id}
                        row={row}
                        column={column}
                        shape={spot.shape}
                        coords={spot.coords}
                        source={spot.source}
                        scale={this.state.scale}
                        draggable={draggable}
                        onSelect={onSelect}
                        onDecorate={(bSelect) => { return this.onDecorate(row,column,bSelect) }}
                        clickable={!!onSelect}
                    />
                );
            });
            elements = elements.concat(elems);
        });

        return elements;
    }

    render() {
        const {
            prefixCls,
            className,
            draggable,
        } = this.props;

        const wrapCls = classnames(`${prefixCls}`, className);
        const wrapBGCls = classnames(`${prefixCls}-bg`);
        const wrapStyle = {
            height: this.state.height + 'px'
        };

        Log.verbose('render hot spot: ' + this.state.height);

        return (
            <View 
                className={wrapCls}
                style={wrapStyle}
            >
                <View 
                    Element='img'
                    ref={el => this.hotspot = ReactDOM.findDOMNode(el)}
                    className={wrapBGCls}
                    src={this.state.source}
                    alt=''
                    crossOrigin='anonymous'
                    onDragOver={draggable ? this.onDragOver : undefined}
                    onDrop={draggable ? this.onDrop : undefined}
                    onLoad={this.loadSpots}
                >
                </View>
                {this.renderSpots()}
            </View>
        );
    }
}