import React from 'react';
import PropTypes from 'prop-types';

import './index.less';

import Colors from '../colors';
import Slider from '../slider';

const prefixCls = 'tm-picture-editor-brush-select';

class BrushSelect extends React.PureComponent {
  render() {
    return (
      <div className={prefixCls}>
        <Slider
          value={this.props.width}
          min={this.props.widthMin}
          max={this.props.widthMax}
          onChange={this.props.onWidthChange}
          onAfterChange={this.props.onWidthConfirm}
        />
        <div className="tm-space-row" />
        <Colors value={this.props.color} onChange={this.props.onColorChange} />
      </div>
    );
  }
}

BrushSelect.propTypes = {
  visible: PropTypes.bool,
  width: PropTypes.number,
  widthMin: PropTypes.number,
  widthMax: PropTypes.number,
  onWidthChange: PropTypes.func,
  onWidthConfirm: PropTypes.func,
  color: PropTypes.string,
  onColorChange: PropTypes.func
};

BrushSelect.defaultProps = {};

export default BrushSelect;
