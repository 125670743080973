class Event {
    static events = {};
    static guid = Math.floor(Math.random() * 1000000);
    
    static fire = (event, args) => {
        const handlers = Event.events[event] || [];
        const keepalive = [];
        let handled = false;
        for (let index = 0; index < handlers.length; index++) {
            let handler = handlers[index];
            if (!handled) {
                handled = handler.apply(null, [args]);
                if (handler.state > 0) {
                    handler.state = 2;
                    handler.firedArgs = args;
                    keepalive.push(handler);
                }
            } else {
                keepalive.push(handler);
            }
        }

        Event.events[event] = keepalive;

        return handled;
    }

    static subscribe = (event, handler, sticky) => {
        const handlers = Event.events[event] || [];
        let guid = handler.observer_guid;
        if (!guid) {
            guid = '' + Event.guid++;
            handler.observer_guid = guid;
            handler.state = sticky ? 1 : 0;
            handlers.unshift(handler);
            Event.events[event] = handlers;
        } else {
            for (let h in handlers) {
                if (h.observer_guid === guid) {
                    handler = h;
                    break;
                }
            }
        }

        if (handler && handler.state === 2) {
            handler.apply(null, handler.firedArgs);
        }
    }

    static unsubscribe = (event, handler) => {
        const handlers = Event.events[event] || {};
        const guid = handler.observer_guid;
        if (!guid) return;
        const keepalive = [];
        for (let index = 0; index < handlers.length; index++) {
            const h = handlers[index];
            if (h.observer_guid !== guid) {
                keepalive.push(h);
            }
        }
        Event.events[event] = keepalive;
        delete handler.observer_guid
    }
}

export default Event;