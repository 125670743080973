import React from 'react';
import PropTypes from 'prop-types';

import StaticView from './StaticView';

import Animate from '../animate/index';

function noop() {
}

export default class Dialog extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        style: PropTypes.object,
        mask: PropTypes.bool,
        children: PropTypes.any,
        afterClose: PropTypes.func,
        onClose: PropTypes.func,
        closable: PropTypes.bool,
        maskClosable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
        visible: PropTypes.bool,
        title: PropTypes.node,
        footer: PropTypes.node,
        transitionName: PropTypes.string,
        maskTransitionName: PropTypes.string,
        animation: PropTypes.any,
        maskAnimation: PropTypes.any,
        wrapStyle: PropTypes.object,
        bodyStyle: PropTypes.object,
        maskStyle: PropTypes.object,
        prefixCls: PropTypes.string,
        wrapClassName: PropTypes.string,
        onAnimateLeave: PropTypes.func,
        zIndex: PropTypes.number,
        maskProps: PropTypes.any,
        wrapProps: PropTypes.any,
    };

    static defaultProps = {
        afterClose: noop,
        className: '',
        mask: true,
        visible: false,
        closable: true,
        maskClosable: true,
        prefixCls: 'tm-dialog',
        onClose: noop,
    };

    dialogRef = null;
    bodyRef = null;
    headerRef = null;
    footerRef = null;
    wrapRef = null;

    componentWillUnmount() {
        // fix: react@16 no dismissing animation
        document.body.style.overflow = '';
        if (this.wrapRef) {
            this.wrapRef.style.display = 'none';
        }
    }

    getZIndexStyle() {
        const style = {};
        const props = this.props;
        if (props.zIndex !== undefined) {
            style.zIndex = props.zIndex;
        }
        return style;
    }

    getWrapStyle() {
        const wrapStyle = this.props.wrapStyle || {};
        return {...this.getZIndexStyle(), ...wrapStyle};
    }

    getMaskStyle() {
        const maskStyle = this.props.maskStyle || {};
        return {...this.getZIndexStyle(), ...maskStyle};
    }

    getMaskTransitionName() {
        const props = this.props;
        let transitionName = props.maskTransitionName;
        const animation = props.maskAnimation;
        if (!transitionName && animation) {
            transitionName = `${props.prefixCls}-${animation}`;
        }
        return transitionName;
    }

    getTransitionName() {
        const props = this.props;
        let transitionName = props.transitionName;
        const animation = props.animation;
        if (!transitionName && animation) {
            transitionName = `${props.prefixCls}-${animation}`;
        }
        return transitionName;
    }

    maskClosable = () => {
        const { maskClosable } = this.props;
        if (typeof maskClosable === 'boolean') {
            return maskClosable;
        } else if (typeof maskClosable === 'function') {
            return maskClosable();
        } else {
            return true;
        }
    }

    getMaskElement() {
        const props = this.props;
        let maskElement;
        if (props.mask) {
            const maskTransition = this.getMaskTransitionName();
            maskElement = (
                <StaticView
                    style={this.getMaskStyle()}
                    key="mask-element"
                    className={`${props.prefixCls}-mask`}
                    hiddenClassName={`${props.prefixCls}-mask-hidden`}
                    visible={props.visible}
                    {...props.maskProps}
                />
            );
            if (maskTransition) {
                maskElement = (
                <Animate
                    key="mask"
                    showProp="visible"
                    transitionAppear
                    component=""
                    transitionName={maskTransition}
                >
                    {maskElement}
                </Animate>
                );
            }
        }
        return maskElement;
    }

    getDialogElement = () => {
        const props = this.props;
        const closable = props.closable;
        const prefixCls = props.prefixCls;

        let footer;
        if (props.footer) {
            footer = (
                <div className={`${prefixCls}-footer`} ref={el => this.footerRef = el}>
                    {props.footer}
                </div>
            );
        }
        let header;
        if (props.title) {
            header = (
                <div className={`${prefixCls}-header`} ref={el => this.headerRef = el}>
                    <div className={`${prefixCls}-title`}>
                        {props.title}
                    </div>
                </div>
            );
        }

        let closer;
        if (closable) {
        closer = (
            <button
            onClick={this.close}
            aria-label="Close"
            className={`${prefixCls}-close`}
            >
            <span className={`${prefixCls}-close-x`} />
            </button>);
        }

        const transitionName = this.getTransitionName();
        const dialogElement = (
            <StaticView
                key="dialog-element"
                role="document"
                ref={el => this.dialogRef = el}
                style={props.style || {}}
                className={`${prefixCls} ${props.className || ''}`}
                visible={props.visible}
            >
                <div className={`${prefixCls}-content`}>
                    {closer}
                    {header}
                    <div
                        className={`${prefixCls}-body`}
                        style={props.bodyStyle}
                        ref={el => this.bodyRef = el}
                    >
                        {props.children}
                    </div>
                    {footer}
                </div>
            </StaticView>
        );
        return (
            <Animate
                key="dialog"
                showProp="visible"
                onAppear={this.onAnimateAppear}
                onLeave={this.onAnimateLeave}
                transitionName={transitionName}
                component=""
                transitionAppear
            >
                {dialogElement}
            </Animate>
        );
    }

    onAnimateAppear = () => {
        document.body.style.overflow = 'hidden';
    }

    onAnimateLeave = () => {
        document.body.style.overflow = '';
        if (this.wrapRef) {
            this.wrapRef.style.display = 'none';
        }
        if (this.props.onAnimateLeave) {
            this.props.onAnimateLeave();
        }
        if (this.props.afterClose) {
            this.props.afterClose();
        }
    }

    close = (e) => {
        if (this.props.onClose) {
            this.props.onClose(e);
        }
    }

    onMaskClick = (e) => {
        if (e.target === e.currentTarget) {
            if (this.maskClosable()) {
                this.close(e);
            }
        }
    }

    render() {
        const { props } = this;
        const { prefixCls } = props;
        const style = this.getWrapStyle();
        if (props.visible) {
            style.display = null;
        }
        return (
            <div>
                {this.getMaskElement()}
                <div
                    className={`${prefixCls}-wrap ${props.wrapClassName || ''}`}
                    ref={el => this.wrapRef = el}
                    onClick={this.onMaskClick}
                    role="dialog"
                    aria-labelledby={props.title}
                    style={style}
                    {...props.wrapProps}
                >
                    {this.getDialogElement()}
                </div>
            </div>
        );
    }
}