import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view';
import Icon from '../icon';
import Text from '../text';
import Strings from '../strings';
import TouchFeedback from '../touch-feedback';
import { Log } from '../util';
import Link from './Link';

export default class Operation extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        menuClassName: PropTypes.string,
        fonts: PropTypes.object.isRequired,
        onChange: PropTypes.func,
        onMenuShown: PropTypes.func,
        hasSelectContent: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-textedit-operation',
        hasSelectContent: () => false,
    };

    state = {
        italic: false,
        underline: false,
        bold: false,
        fontColor: '#000000',
        fontSize: 'medium',
        menu: 'none',
    };

    set italic(italic) {
        if (this.state.italic === italic) {
            return;
        }

        this.setState({
            italic: italic
        });
    }
    get italic() {
        return this.state.italic;
    }

    set underline(underline) {
        if (this.state.underline === underline) {
            return;
        }
        this.setState({
            underline: underline
        });
    }
    get underline() {
        return this.state.underline;
    }

    set bold(bold) {
        if (this.state.bold === bold) {
            return;
        }
        this.setState({
            bold: bold
        });
    }
    get bold() {
        return this.state.bold;
    }

    set fontSize(size) {
        if (this.state.fontSize === size) {
            return;
        }
        this.setState({
            fontSize: size
        });
    }
    get fontSize() {
        return this.state.fontSize;
    }

    set fontColor(color) {
        if (this.state.fontColor === color) {
            return;
        }
        this.setState({
            fontColor: color
        });
    }
    get fontColor() {
        return this.state.fontColor;
    }

    get link() {
        return this.linkElem;
    }

    closeMenu = () => {
        if (this.state.menu === 'none') return;
        this.setState({
            menu: 'none'
        }, () => {
            this.props.onMenuShown && this.props.onMenuShown(false);
        });
    }

    update = state => {
        this.setState(state);
    }

    matchDOM(node, target) {
        if (!target || !node) return false;
        if (node === target) return true;
        let parent = target.parentNode;
        while (parent) {
            if (parent === node && parent.id === node.id) return true;
            parent = parent.parentNode;
        }

        return false;
    }

    touchedMenuItem = e => {
        const elem = e.target;
        if (this.matchDOM(this.fontSizeElem, elem)) {
            return 'fontsize';
        } else if (this.matchDOM(this.fontColorElem, elem)) {
            return 'fontcolor';
        } else if (this.matchDOM(this.boldElem, elem)) {
            return 'bold';
        } else if (this.matchDOM(this.italicElem, elem)) {
            return 'italic';
        } else if (this.matchDOM(this.underlineElem, elem)) {
            return 'underline';
        } else if (this.matchDOM(this.linkElem, elem)) {
            return 'link';
        }

        return 'none';
    }

    onClick = (event, type) => {
        Log.info('Menu click');
        const { onChange, onMenuShown } = this.props;
        event.stopPropagation();
        let menu = 'none';
        let menuShown = false;
        if (type === 'bold') {
            this.setState({
                // bold: !this.state.bold,
                menu: menu
            }, () => {
                onChange && onChange('bold', this.state.bold);
            });
            return;
        } else if (type === 'italic') {
            this.setState({
                // italic: !this.state.italic,
                menu: menu
            }, () => {
                onChange && onChange('italic', this.state.italic);
            });
            return;
        } else if (type === 'underline') {
            this.setState({
                // underline: !this.state.underline,
                menu: menu
            }, () => {
                onChange && onChange('underline', this.state.underline);
            });
            return;
        } else if (type === 'fontcolor') {
            menu = this.state.menu === 'fontcolor' ? 'none' : 'fontcolor';
            menuShown = this.state.menu === 'fontcolor';
        } else if (type === 'fontsize') {
            menu = this.state.menu === 'fontsize' ? 'none' : 'fontsize';
            menuShown = this.state.menu === 'fontsize';
        } else if (type === 'link') {
            // menu = this.state.menu === 'link' ? 'none' : 'link'
            // menuShown = this.state.menu === 'link'
            Link.show((type, value) => {
                this.props.onMenuShown && this.props.onMenuShown(false);
                this.props.onChange && this.props.onChange(type, value);
            }, !this.props.hasSelectContent());
            onMenuShown && onMenuShown(true);
            return;
        }

        this.setState({
            menu
        }, () => {
            onMenuShown && onMenuShown(menuShown);
        });
    }

    onChange = (event, type, value) => {
        event.stopPropagation(); 
        let state = {menu: 'none'};
        if (type === 'fontsize') {
            state['fontSize'] = value;
        } else if (type === 'fontcolor') {
            state['fontColor'] = value;
        } else if (type === 'link') {
            // this.props.onMenuShown && this.props.onMenuShown(false);
        } else {
            return;
        }

        this.setState(state, () => {
            this.props.onMenuShown && this.props.onMenuShown(false);
            this.props.onChange && this.props.onChange(type, value);
        });
    }

    renderFontSize = () => {
        const { prefixCls, fonts } = this.props;
        const sizes = ['small', 'medium', 'large', 'x-large', 'xx-large'];
        return sizes.map(size => {
            const font = fonts[size];
            const wrapCls = classnames(`${prefixCls}-menu-item`, {
                [`${prefixCls}-menu-item-size-active`]: this.state.fontSize === size,
            });
            return (
                <View 
                    key={font.size} 
                    className={wrapCls}
                    onClick={e => this.onChange(e, 'fontsize', size)}
                >
                    <Text style={{lineHeight: `${font.pixel}px`, fontSize: `${font.pixel}px`, fontColor: '#333'}}>
                        A
                    </Text>
                </View>
            );
        });
    }

    renderFontColor = () => {
        const { prefixCls } = this.props;
        const colors = ['#ff0000', '#ff8000', '#f5c700', '#30b536', '#02a2e0', '#0077d1', '#fe6553', '#333333', '#b6b6b6', '#000000'];
        return colors.map((color, index) => {
            const style = {
                background: color,
            };
            const wrapCls = classnames(`${prefixCls}-menu-item`, `${prefixCls}-menu-color`);
            return (
                <View
                    key={index}
                    className={wrapCls}
                    style={style}
                    onClick={e => this.onChange(e, 'fontcolor', color)}
                >
                    {this.state.fontColor === color ? <Icon type='check' color='white'/> : null}
                </View>
            );
        });
    }

    renderLink = () => {
        const { prefixCls, hasSelectContent } = this.props;
        return (
            <View className={`${prefixCls}-menu-link`}>
                {
                    hasSelectContent() ? null :
                    <View className={`${prefixCls}-menu-link-brief`}>
                        <Text>{Strings.link_brief}</Text>
                        <input 
                            ref={el => this.brief = el}
                            type='text' 
                            placeholder={Strings.link_brief_placeholder}
                            spellCheck={false}
                        />
                    </View>
                }
                <View className={`${prefixCls}-menu-link-url`}>
                    <Text>{Strings.link_url}</Text>
                    <input 
                        ref={el => this.url = el}
                        type='text' 
                        placeholder={Strings.link_url_placeholder} 
                        spellCheck={false}
                    />
                </View>
                <TouchFeedback activeClassName={`${prefixCls}-menu-link-button-active`}>
                    <View 
                        className={`${prefixCls}-menu-link-button`} 
                        onClick={e => this.onChange(e, 'link', {
                            brief: this.brief ? this.brief.value : '',
                            url: this.url ? this.url.value : '',
                        })}
                    >
                        {Strings.link_insert}
                    </View>
                </TouchFeedback>
            </View>
        );
    }

    render() {
        const {
            className,
            menuClassName,
            prefixCls
        } = this.props;

        const wrapCls = classnames(`${prefixCls}`, className);
        const wrapMenuCls = classnames({
            [`${prefixCls}-menu`]: true,
            [`${prefixCls}-menu-hidden`]: this.state.menu === 'none',
        }, menuClassName);
        const renderMenu = () => {
            if (this.state.menu === 'fontsize') {
                return this.renderFontSize();
            } else if (this.state.menu === 'fontcolor') {
                return this.renderFontColor();
            } else if (this.state.menu === 'link') {
                return this.renderLink();
            } else {
                return null;
            }
        }
        return (
            <View className={wrapCls} >
                <View className={wrapMenuCls}>
                    {renderMenu()}
                </View>
                <View className={`${prefixCls}-zone`} >
                    <Icon 
                        ref={el => this.fontSizeElem = ReactDOM.findDOMNode(el)}
                        type='A'
                        className={classnames({
                            [`${prefixCls}-zone-icon`]: true,
                        })} 
                        onClick={e => this.onClick(e, 'fontsize')}
                        color='#333'
                        activeColor='#939595'
                    />
                    <Icon 
                        ref={el => this.italicElem = ReactDOM.findDOMNode(el)}
                        type='italic' 
                        className={classnames({
                            [`${prefixCls}-zone-icon`]: true,
                            [`${prefixCls}-zone-icon-checked`]: this.state.italic,
                        })} 
                        onClick={e => this.onClick(e, 'italic')}
                        color='#333'
                        activeColor='#939595'
                    />
                    <Icon 
                        ref={el => this.boldElem = ReactDOM.findDOMNode(el)}
                        type='bold' 
                        className={classnames({
                            [`${prefixCls}-zone-icon`]: true,
                            [`${prefixCls}-zone-icon-checked`]: this.state.bold,
                        })} 
                        onClick={e => this.onClick(e, 'bold')}
                        color='#333'
                        activeColor='#939595'
                    />
                    <Icon 
                        ref={el => this.underlineElem = ReactDOM.findDOMNode(el)}
                        type='underline' 
                        className={classnames({
                            [`${prefixCls}-zone-icon`]: true,
                            [`${prefixCls}-zone-icon-checked`]: this.state.underline,
                        })} 
                        onClick={e => this.onClick(e, 'underline')}
                        color='#333'
                        activeColor='#939595'
                    />
                    <View 
                        ref={el => this.fontColorElem = ReactDOM.findDOMNode(el)}
                        className={`${prefixCls}-zone-color`} 
                        onClick={e => this.onClick(e, 'fontcolor')}
                    >
                        <View className={`${prefixCls}-zone-color-content`} >
                            <Icon 
                                type='A'
                                size='xxs' 
                                className={classnames({
                                    [`${prefixCls}-zone-icon`]: true,
                                    [`${prefixCls}-zone-icon-color`]: true,
                                })}
                                color='#333'
                                activeColor='#939595'
                            />
                            <View 
                                className={classnames({
                                    [`${prefixCls}-zone-line`]: true,
                                })}
                                style={{borderBottomColor: this.state.fontColor}}
                            />
                        </View>
                    </View>
                    <Icon 
                        ref={el => this.linkElem = ReactDOM.findDOMNode(el)}
                        type='link' 
                        className={classnames({
                            [`${prefixCls}-zone-icon`]: true,
                        })}
                        onClick={e => this.onClick(e, 'link')}
                        color='#333'
                        activeColor='#939595'
                    />
                </View>
            </View>
        );
    }
}