import _ from 'lodash'

export function getMyClasses(userInfo, isMaster = false) {
  const classes = _.get(userInfo, 'classes', [])
  let myClasses = []
  if (isMaster) {
    for (let i = 0; i < classes.length; ++i) {
      if (classes[i].master) {
        myClasses.push(classes[i])
      }
    }
  } else {
    myClasses = classes
  }

  myClasses = _.sortBy(myClasses, [v => v.grade, v => v.name])
  return myClasses
}

export function getSchoolStages(userInfo) {
  const grades = _.get(userInfo, 'grades', {});
  const stages = Object.keys(grades).map(grade => Number(grade));
  return stages;
}

export function getCurrentUserInfo(state) {
  let userInfoList = _.get(state, 'userglobalinfo.userInfoList', []);
  let selectUserIndex = _.get(state, 'userglobalinfo.selectUserIndex', -1);
  if (selectUserIndex < 0 || selectUserIndex >= userInfoList.length) {
    return {};
  }

  return userInfoList[selectUserIndex];
}