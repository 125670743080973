import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view';
import { Platform, Log } from '../util';

import StaticView from './StaticView';

import './style/index.less';

const [TOUCH_IDLE, TOUCH_BEGIN, TOUCH_ACCEPT, TOUCH_DENY] = [0, 1, 2, 3];

export default class Pager extends React.Component {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        pages: PropTypes.array,            // A array of pages
        renderPage: PropTypes.func,         // renderPage returns rendered page for each index
        activeIndex: PropTypes.func,        // activeIndex returns current active page index
        preloadSiblings: PropTypes.number,  // Pre-rendered siblings around active page
        keepSiblings: PropTypes.number,     // Maximum keeped siblings around active page
        destroySibling: PropTypes.bool,     // Destroy inactive siblings(excluding pre-rendered siblings) if true
        onChangeThreshold: PropTypes.number,// Specify the rate of page width, which will trigger change page if drag distance exceed width * onChangeThreshold
        onChange: PropTypes.func,           // Fire when current active page index changed
        onLayout: PropTypes.func,           // Fire when pager is ready, which means the dom has correct width and height
        pageHasChanged: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-pager',
        preloadSiblings: 1,
        keepSiblings: 1,
        destroySibling: false,
        onChangeThreshold: 0.3,
    };

    content = null;
    position = 0;
    dragging = false;
    lastTouch = 0;
    lastTouchTime = 0;
    velocity = 0;
    numberOfPage = 3;
    innerIndex = 0;
    touchType = TOUCH_IDLE;

    pages = [];

    animating = false;

    touchType = TOUCH_IDLE;

    constructor(props) {
        super(props);
        this.innerIndex = !!props.activeIndex ? props.activeIndex() : 0; 
        this.state = {
            activeIndex: this.innerIndex,
            width: 0,
            height: 0,
            pages: [],
        };
        this.updatePages(this.innerIndex);
        if (this.pages.length > 0) {
            this.state.pages = this.pages;
        }
    }

    set activeIndex(activeIndex) {
        if (this.state.activeIndex === activeIndex) {
            return;
        }
        this.updatePages(activeIndex);
        this.setState({
            activeIndex,
            pages: this.pages,
        }, () => {
            if (this.props.pages && this.props.pages.length > 0) {
                this.setTransitionDuration(200);
                this.setPosition(-activeIndex * this.width);
            }
        });
    }
    get activeIndex() {
        return this.state.activeIndex;
    }

    set width(width) {
        if (this.state.width === width) {
            return;
        }
        this.setState({width});
    }
    get width() {
        return this.state.width;
    }

    set height(height) {
        if (this.state.height === height) {
            return;
        }
        this.setState({
            height: height,
        });
    }
    get height() {
        return this.state.height;
    }

    componentDidMount() {
        if (this.pager) {
            this.updatePages(this.innerIndex);
            this.setState({
                width: this.pager.clientWidth,
                height: this.pager.clientHeight,
                pages: this.pages,
            }, () => {
                const position = -this.innerIndex * this.width;
                this.setPosition(position);
                this.props.onLayout && this.props.onLayout({
                    x: this.pager.offsetLeft,
                    y: this.pager.offsetTop,
                    width: this.pager.clientWidth,
                    height: this.pager.clientHeight
                });
            });
        }
        if (this.content) {
            this.content.addEventListener('webkitTransitionEnd', this.onTransitionEnd);
        }
    }

    componentWillUnmount() {
        if (this.content) {
            this.content.removeEventListener('webkitTransitionEnd', this.onTransitionEnd);
        }
    }

    componentDidUpdate() {
        const { pages } = this.props;
        if (!pages || pages.length === 0) {
            this.setTransitionDuration(0);
            this.setPosition(-this.innerIndex * this.width);
        }
    }

    shouldComponentUpdate() {
        return !this.animating;
    }

    setPosition = position => {
        this.position = position;
        if (this.content) {
            this.content.style.transform = `translate3d(${this.position}px, 0, 0)`;
            this.content.style.webkitTransform = `translate3d(${this.position}px, 0, 0)`;
        }
    }

    setTransitionDuration = time => {
        if (this.content) {
            this.content.style.transitionDuration = time + 'ms';
        }
    }
    
    calculateIndex = (position, scrollLeft) => {
        const { onChangeThreshold } = this.props;
        let index = this.innerIndex;
        const delta = Math.abs(position + index * this.width);
        const threshold = this.width * onChangeThreshold;
        if (scrollLeft && delta > threshold) {
            index += 1;
        } else if (!scrollLeft && delta > threshold) {
            index -= 1;
        }

        if (index < 0) {
            return 0;
        }
        if (index > (this.numberOfPage - 1)) {
            return this.numberOfPage - 1;
        }

        return index;
    }

    updatePages = activeIndex => {
        const { renderPage, pages } = this.props;
        if (pages && pages.length > 0 && this.pages.length === 0) {
            this.numberOfPage = pages.length;
            this.pages = [];
            pages.forEach((child, index) => {
                this.pages.push({
                    node: child,
                    ref: null,
                    index: index,
                });
            });
        } else if (renderPage && typeof renderPage === 'function') {
            let index = activeIndex - 1;
            this.numberOfPage = 0;
            this.pages = [];
            for (let i = 0; i < 3; i++) {
                let page = null;
                for (let j = 0; j < this.state.pages.length; j++) {
                    page = this.state.pages[j];
                    if (page.index === (index + i)) {
                        break;
                    }
                }
                if (page && page.index === (index + i)) {
                    this.pages.push(page);
                    this.numberOfPage++;
                    continue;
                }

                const node = renderPage(index + i);
                if (node) {
                    this.numberOfPage++;
                    this.pages.push({
                        node: node,
                        index: index + i,
                        ref: null
                    });
                }
            }
        }

        for (let i = 0; i < this.pages.length; i++) {
            if (this.pages[i].index === activeIndex) {
                this.innerIndex = i;
                break;
            }
        }
    }

    updatePageAt= (i) => {
        const { renderPage, pages } = this.props;
        if (pages && pages.length > i && this.pages.length > i) {
            this.pages[i].node = pages[i]
        } else if (renderPage && typeof renderPage === 'function'){
            for(let j = 0; j < this.pages.length; j++) {
                let page = this.pages[j]
                if(page.index === i) {
                    page.node = renderPage(i)
                    break
                }
            }
        }
    }

    onTransitionEnd = e => {
        if (this.content === e.target) {
            this.animating = false;
            if (this.shouldUpdatePages) {
                setTimeout(() => {
                    this.updatePages(this.pages[this.innerIndex].index);
                    this.setState({
                        pages: this.pages,
                        activeIndex: this.pages[this.innerIndex].index,
                    });
                    this.shouldUpdatePages = false;
                }, 50);
            }
        }
    }

    onClick = e => {
        if (!Platform.isMobile()) {    
            const timeElapse = e.timeStamp - this.trackClickTimestamp;
            const clickPoint = {
                x: e.clientX,
                y: e.clientY,
            };
            const distance = Math.abs(clickPoint.x - this.trackClickStartPoint.x);
            if (timeElapse > 300 || distance > 10) {
                e.stopPropagation();
                return;
            }
        }
    }

    onTouchBegin = e => {
        if (!Platform.isMobile()) {
            this.trackClickTimestamp = e.timeStamp;
            this.trackClickStartPoint = {
                x: e.clientX,
                y: e.clientY,
            };
        }

        if (this.numberOfPage < 2) return;

        this.setTransitionDuration(0);
        
        this.dragging = true;
        this.touchType = TOUCH_BEGIN;

        const touches = e.changedTouches ? e.changedTouches[0] : e;
        this.lastTouch = {
            x: touches.clientX,
            y: touches.clientY,
        };
        this.velocity = 0;
        this.lastTouchTime = e.timestamp;
    }

    onTouchMove = e => {
        if (this.dragging) {
            const touches = e.changedTouches ? e.changedTouches[0] : e;
            const touch = {
                x: touches.clientX,
                y: touches.clientY,
            };
            
            const distance = {
                x: touch.x - this.lastTouch.x,
                y: touch.y - this.lastTouch.y,
            }

            if (this.touchType !== TOUCH_ACCEPT) {
                if (Math.max(Math.abs(distance.x), Math.abs(distance.y)) < 10) {
                    return;
                }
            }

            if (this.touchType === TOUCH_BEGIN) {
                this.touchType = Math.abs(distance.x) < Math.abs(distance.y) ? this.touchType = TOUCH_DENY : this.touchType = TOUCH_ACCEPT;
            }

            if (this.touchType !== TOUCH_ACCEPT) {
                this.dragging = false;
                return;
            }

            let delta = distance.x;
            if (delta > 0 && this.innerIndex === 0 && this.position >= 0) {
                Log.verbose('resistance drag');
                const deltaWidth = this.width / 4.0;
                const factor = (Math.PI / 2.0 - Math.atan(Math.abs(this.position) / deltaWidth)) / Math.PI;
                delta = delta * factor;
            } else if (delta < 0 && this.innerIndex === (this.numberOfPage - 1) && this.position <= (-this.innerIndex * this.width)) {
                const deltaWidth = this.width / 4.0;
                const factor = (Math.PI / 2.0 - Math.atan(Math.abs(this.position + this.innerIndex * this.width) / deltaWidth)) / Math.PI;
                delta = delta * factor;
            }
            let position = this.position + delta;
            this.setPosition(position);
            
            this.lastTouch = touch;
            this.lastTouchTime = Date.now();
        }
    }

    onTouchEnd = e => {
        if (this.dragging) {
            this.dragging = false;
            if (this.touchType !== TOUCH_ACCEPT) {
                return;
            }

            this.touchType = TOUCH_IDLE;
            this.setTransitionDuration(200);
            const direction = this.position < (-this.innerIndex * this.width) ? -1 : 1;

            const touches = e.changedTouches ? e.changedTouches[0] : e;
            let delta = touches.clientX - this.lastTouch.x;

            const timestamp = Date.now();
            let duration = timestamp - this.lastTouchTime;
            if (duration > 0) {
                this.velocity = delta / duration;
            } else {
                this.velocity = 0.1 * direction;
            }

            const decelerator = 0.05;
            duration = Math.abs(this.velocity / decelerator);
            const bounce = decelerator * duration * duration / 2 * direction;
            let position = this.position + bounce;
            this.innerIndex = this.calculateIndex(position, direction === -1);
            const activeIndex = this.pages[this.innerIndex].index;
            this.shouldUpdatePages = activeIndex !== this.activeIndex;
            this.props.onChange && this.props.onChange(activeIndex);
            this.animating = true;
            this.setPosition(-this.innerIndex * this.width);
        }
    }

    renderPages = () => {
        // if (this.width === 0 || this.height === 0) {
        //     return null;
        // }

        const {
            prefixCls,
            preloadSiblings,
            keepSiblings,
            destroySibling,
            pageHasChanged
        } = this.props;

        return this.state.pages.map((page, index) => {
            const deltaPages = Math.abs(this.innerIndex - index);
            if (deltaPages > preloadSiblings) {
                const logoStyle = {
                    width: "180px",
                    height: "160px",
                    left: index * this.width + (this.width - 180) / 2 + 'px',
                    top: (this.height - 160) / 2 + 'px',
                };

                const logo = <View key={page.index} className={`${prefixCls}-logo`} style={logoStyle} />;
                const siblings = Math.max(preloadSiblings, keepSiblings);

                if (page.ref) {
                    if (destroySibling && deltaPages > siblings) {
                        page.ref = null;
                        return logo;
                    }
                } else {
                    return logo;
                }
            }

            const style = {
                width: this.width + 'px',
                height: this.height + 'px',
                left: index * this.width + 'px',
            };

            let shouldUpdate = this.props.pages && this.props.pages.length > 0 ? !page.ref || page.ref.clientWidth !== this.width || page.ref.clientHeight !== this.height : true;
            if(!shouldUpdate) {
                shouldUpdate = pageHasChanged ? pageHasChanged(index) : false
            }
            if(shouldUpdate) {
                this.updatePageAt(index)
            }
            // console.log('shouldUpdate: ' + shouldUpdate);
            return (
                <StaticView
                    key={page.index}
                    ref={el => page.ref = ReactDOM.findDOMNode(el)}
                    style={style}
                    className={`${prefixCls}-static`}
                    shouldUpdate={shouldUpdate}
                    index={page.index}
                    render={() => {
                        return page.node;
                    }}
                />
            );
        });
    }

    render() {
        const {
            className,
            prefixCls,
            style ={},
        } = this.props;

        // this.updatePages();

        const contentStyle = {
            width: this.numberOfPage * this.width,
        };

        const wrapCls = classnames(`${prefixCls}`, className);

        return (
            <View 
                ref={el => this.pager = ReactDOM.findDOMNode(el)}
                className={wrapCls}
                style={style}
                onMouseDown={this.onTouchBegin}
                onMouseMove={this.onTouchMove}
                onMouseUp={this.onTouchEnd}
                onMouseLeave={this.onTouchEnd}
                onTouchStart={this.onTouchBegin}
                onTouchMove={this.onTouchMove}
                onTouchEnd={this.onTouchEnd}
                onClick={this.onClick}
            >
                <View 
                    ref={el => this.content = ReactDOM.findDOMNode(el)}
                    className={`${prefixCls}-content`}
                    style={contentStyle}
                >
                    {this.renderPages()}
                </View>
            </View>
        );
    }
}