import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Item from './Item';

import View from '../view/index';

import './style/index.less';

export default class List extends React.PureComponent {
    static Item = Item;

    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        role: PropTypes.string,
        style: PropTypes.object,
    };

    static defaultProps = {
        prefixCls: 'tm-list',
    };

    render() {
        const {
            prefixCls,
            children,
            className,
            style,
            renderHeader,
            renderFooter,
            ...restProps
        } = this.props;

        const wrapCls = classnames(prefixCls, className);

        return (
            <View 
                className={wrapCls} 
                style={style} 
                {...restProps}>
                {renderHeader ? (
                    <View className={`${prefixCls}-header`}>
                        {typeof renderHeader === 'function' ? renderHeader() : renderHeader}
                    </View>
                ) : null}

                {children ? (
                    <View className={`${prefixCls}-body`}>{children}</View>
                ) : null}
                {renderFooter ? (
                    <View className={`${prefixCls}-footer`}>
                        {typeof renderFooter === 'function' ? renderFooter() : renderFooter}
                    </View>
                ) : null}
            </View>
        );
    }
}
