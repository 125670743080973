import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view/index';

import './style/index.less';

export default class Toggle extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        paused: PropTypes.bool,
        onPlay: PropTypes.func,
        advance: PropTypes.bool,
    };

    static defaultProps = {
        prefixCls: 'tm-audio',
        paused: false,
        advance: true,
        onPlay: () => {}
    }

    render() {
        let { 
            prefixCls, 
            paused,
            advance,
            onPlay,
        } = this.props;

        const iconState = paused ? 'play' : 'pause';
        const toggleCls = classnames(`${prefixCls}-control-toggle`, `${prefixCls}-control-toggle-${iconState}`, {advance});

        return (
            <View className={toggleCls} onClick={(e) => {
                e && e.stopPropagation()
                onPlay(e)
            }} />
        );
    }
}