import produce from 'immer'

const initState = {

}

const reducer = produce(
  (state, action) => {
    switch (action.type) {
      default:
        return
    }
  },
  initState
)

export { reducer as userInfo }

