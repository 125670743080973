import React from 'react';
import './register.less';
import BgDiv from 'Components/BgDiv';
import Button from 'Components/Button';
import { IMAGE_URL } from 'Constants';
import { Input, MyPicker, Sex, AreaPicker } from './ReInput';
import { User_Group, User_Grade, Location } from './FormData';
import { parseUrl } from 'src/Utils';
import { apiRegUser } from 'src/Api';

import { Toast } from 'antd-mobile';

const CSS = 'register';

class Register extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isSchool: false
    };
    this.formdata = {
      openid: '',
      usergroup: 0, //用户组
      username: '', //姓名
      idcard: '', //身份证号
      phone: '', //手机号
      sex: 1, //性别
      school: '', //学校
      grade: 0, //nianji
      location: [], //地区
    };
    this.location = Location();
  }


  componentDidMount() {
    document.title = '报名活动';
    let url = parseUrl(window.location.href);
    if (url.hasOwnProperty('openid')){
      this.formdata.openid = url.openid;
    }
    document.body.height = document.body.clientHeight;
  }

  choseBack = (type, value) => {
    this.formdata[type] = value[0];
    if (type === 'usergroup'){
      this.setState({ isSchool: value[0] === 5 ? false : true });
    }
  }

  choseAreaBack = (value) => {
    this.formdata.location = value;
  }

  inputBack = (type, value) => {
    this.formdata[type] = value;
  }

  saveForm = async () => {
    if (!this.checkForm(this.formdata)){
      return
    }
    let form = {
      openId: this.formdata.openid,
      group: this.formdata.usergroup,
      gender: this.formdata.sex,
      idNum:  this.formdata.idcard,
      name: this.formdata.username,
      mobile: parseInt(this.formdata.phone, 10),
      province: parseInt(this.formdata.location[0], 10),
      city: parseInt(this.formdata.location[1], 10),
      county: parseInt(this.formdata.location[2], 10),
      school: this.formdata.school,
      grade: this.formdata.grade
    }
    try {
      const res = await apiRegUser(form);
      Toast.success('注册成功', 2);
    } catch (err) {
      Toast.fail(err.message, 2);
    }
  }

  checkForm(form){
    if (form.usergroup === 0){
      Toast.fail('请选择用户组', 2);
      return false;
    }
    if (form.username === ''){
      Toast.fail('请输入姓名', 2);
      return false;
    }
    if (form.usergroup !== 5){
      if (form.school === ''){
        Toast.fail('请输入学校', 2);
        return false;
      }
      if (form.grade === 0){
        Toast.fail('请输入年级', 2);
        return false;
      }
    }
    if (form.idcard.length !== 18){
      Toast.fail('请输入有效身份证', 2);
      return false;
    }
    if (typeof form.phone !== 'number' && form.phone.length !== 11){
      Toast.fail('请输入有效手机号', 2);
      return false;
    }
    return form
  }

  render() {
    const { isSchool } = this.state;
    return (
      <BgDiv style={{ height: '100%' }} className='register-bg' src={IMAGE_URL.BG_MUDAN}>
        <div className={CSS}>
          <div className={`${CSS}-title`}>牡丹诗词大赛报名信息</div>
          <MyPicker label='用户组' data={User_Group} type='usergroup' onchange={this.choseBack} placeholder='请选择' />
          {
            isSchool &&
            <Input label='学校' type='school' onchange={this.inputBack} placeholder='请填写学校名称' />
          }
          {
            isSchool &&
            <MyPicker label='年级' data={User_Grade} type='grade' onchange={this.choseBack} placeholder='请选择' />
          }
          <AreaPicker label='地区' data={this.location} onchange={this.choseAreaBack} placeholder='请选择' />
          <Input label='姓名' type='username' onchange={this.inputBack} placeholder='请填写真实姓名' />
          <Sex label='性别' type='sex' onchange={this.inputBack} />
          <Input label='身份证号' type='idcard' onchange={this.inputBack} placeholder='18位身份证号' />
          <Input label='手机号' type='phone' onchange={this.inputBack} placeholder='填写11位手机号' />
          <div className={`${CSS}-tips`}>
            请如实填写姓名、性别、身份证号、手机号等信息，填写错误会影响您的比赛资格及晋级资格
          </div>
          {/* {
            !isSchool &&
            <div className={`${CSS}-space`}></div>
          } */}
          <div className={`${CSS}-space`}></div>
          <Button onClick={this.saveForm}>立即报名</Button>
          <div className='sponsor'>
            <div>主办单位:中华诗词学会 | 中共洛阳市委宣传部</div>
            <div>承办单位:洛阳市教育局 | 洛阳市文化广电</div>
            <div>和旅游局洛阳广播电视台</div>
            <div>技术支持：北京乐教乐学科技有限责任公司</div>
          </div>
        </div>
      </BgDiv>
    );
  }
}

export default Register;
