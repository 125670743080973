import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../../view';

import './style/index.less';

export default class Spinner extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        const { className } = this.props;

        return (
            <View className={classnames({'tm-indicator-spinner': true, [className]: true})}>
                <View className='tm-indicator-spinner-outer' />
                <View className='tm-indicator-spinner-inner' />
            </View>
        );
    }
}