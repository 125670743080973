import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import TouchFeedback from '../touch-feedback/index';
import View from '../view/index';

export class Brief extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        role: PropTypes.string,
        children: PropTypes.any,
        wrap: PropTypes.bool,
        style: PropTypes.any,
    }

    render() {
        return (
            <View className="tm-list-brief" style={this.props.style}>
                {this.props.children}
            </View>
        );
    }
}

class Item extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        role: PropTypes.string,
        style: PropTypes.any,
        align: PropTypes.oneOf(['top', 'middle', 'bottom']),
        disabled: PropTypes.bool,
        multipleLine: PropTypes.bool,
        children: PropTypes.any,
        thumb: PropTypes.any,
        extra: PropTypes.any,
        arrow: PropTypes.oneOf(['horizontal', 'down', 'up', 'empty', '']),
        wrap: PropTypes.bool,
        activeStyle: PropTypes.any,
        error: PropTypes.bool,
        platform: PropTypes.oneOf(['android', 'ios']),
        onClick: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-list',
        align: 'middle',
        error: false,
        multipleLine: false,
        wrap: false,
        platform: 'ios',
    };

    static Brief = Brief;
    debounceTimeout = null;

    constructor(props) {
        super(props);
        this.state = {
            coverRippleStyle: { display: 'none' },
            RippleClicked: false,
        };
    }

    componentWillUnmount() {
        if (this.debounceTimeout) {
            clearTimeout(this.debounceTimeout);
            this.debounceTimeout = null;
        }
    }

    onClick = e => {
        const { onClick, platform } = this.props;
        const isAndroid = platform === 'android';
        if (!!onClick && isAndroid) {
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout);
                this.debounceTimeout = null;
            }
            const Item = e.currentTarget;
            const RippleWidth = Math.max(Item.offsetHeight, Item.offsetWidth);
            const ClientRect = e.currentTarget.getBoundingClientRect();
            const pointX = e.clientX - ClientRect.left - Item.offsetWidth / 2;
            const pointY = e.clientY - ClientRect.top - Item.offsetWidth / 2;
            const coverRippleStyle = {
                width: `${RippleWidth}px`,
                height: `${RippleWidth}px`,
                left: `${pointX}px`,
                top: `${pointY}px`,
            };

            this.setState(
                {
                    coverRippleStyle,
                    RippleClicked: true,
                },
                () => {
                    this.debounceTimeout = setTimeout(() => {
                        this.setState({
                        coverRippleStyle: { display: 'none' },
                        RippleClicked: false,
                        });
                    }, 1000);
                },
            );
        }

        if (onClick) {
            onClick(e);
        }
    }

    render() {
        const {
            prefixCls,
            className,
            activeStyle,
            error,
            align,
            wrap,
            disabled,
            children,
            multipleLine,
            thumb,
            extra,
            arrow,
            onClick,
            ...restProps
        } = this.props;

        const { platform, ...otherProps } = restProps;
        const { coverRippleStyle, RippleClicked } = this.state;

        const wrapCls = classnames(`${prefixCls}-item`, className, {
            [`${prefixCls}-item-disabled`]: disabled,
            [`${prefixCls}-item-error`]: error,
            [`${prefixCls}-item-top`]: align === 'top',
            [`${prefixCls}-item-middle`]: align === 'middle',
            [`${prefixCls}-item-bottom`]: align === 'bottom',
        });

        const rippleCls = classnames(`${prefixCls}-ripple`, {
            [`${prefixCls}-ripple-animate`]: RippleClicked,
        });

        const lineCls = classnames(`${prefixCls}-line`, {
            [`${prefixCls}-line-multiple`]: multipleLine,
            [`${prefixCls}-line-wrap`]: wrap,
        });

        const arrowCls = classnames(`${prefixCls}-arrow`, {
            [`${prefixCls}-arrow-horizontal`]: arrow === 'horizontal',
            [`${prefixCls}-arrow-vertical`]: arrow === 'down' || arrow === 'up',
            [`${prefixCls}-arrow-vertical-up`]: arrow === 'up',
        });

        const content = (
            <View
                {...otherProps}
                onClick={ev => {
                this.onClick(ev);
                }}
                className={wrapCls}
            >
                {thumb ? (
                <View className={`${prefixCls}-thumb`}>
                    {typeof thumb === 'string' ? <img src={thumb} alt='' /> : thumb}
                </View>
                ) : null}
                <View className={lineCls}>
                {children !== undefined && (
                    <View className={`${prefixCls}-content`}>{children}</View>
                )}
                {extra !== undefined && (
                    <View className={`${prefixCls}-extra`}>{extra}</View>
                )}
                {arrow && <View className={arrowCls} aria-hidden="true" />}
                </View>
                <View style={coverRippleStyle} className={rippleCls} />
            </View>
        );

        const touchProps = {};
        Object.keys(otherProps).forEach(key => {
            if (/onTouch/i.test(key)) {
                touchProps[key] = (otherProps)[key];
                delete (otherProps)[key];
            }
        });

        return (
        <TouchFeedback
            {...touchProps}
            disabled={disabled || !onClick}
            activeStyle={activeStyle}
            activeClassName={`${prefixCls}-item-active`}
        >
            {content}
        </TouchFeedback>
        );
    }
}

export default Item;
