import React from 'react'
import { apiGetToken } from 'src/Api'
import { buildUrl, parseUrl } from 'src/Utils'

class Home extends React.PureComponent {
  componentDidMount() {
    this.init()
  }

  init = async () => {
    try {
      const urlParam = parseUrl()
      const code = urlParam.code
      const res = await apiGetToken(code)
      if (!res.token && res.openid) {
        this.props.history.replace('/register', { openid: res.openid })
      } else if (res.token && res.openid) {
        const url = buildUrl('/gamelist', { token: res.token })
        this.props.history.replace(url)
      } else {
        // do nothing
        console.log('~~~~~~~~~~~~ no token & no openid')
      }
    } catch (err) {
      console.log('eeeeeeeeeeeeeee', err.message)
    }
  }

  render() {
    return (
      <div>
      </div>
    )
  }
}

export default Home
