const JUST_NOW = '刚刚';
const BEFORE_MINUTES = '分钟前';
const BEFORE_HOURS = '小时前';

// 以毫秒计
const MINUTE = 60 * 1000
const HOUR = MINUTE * 60

export const getZeroTimestamp = (timestamp, isMillisecond = true) => {
  const tmpTimestamp = isMillisecond ? timestamp : timestamp * 1000
  const date = new Date(tmpTimestamp)
  date.setHours(0, 0, 0, 0)
  return date.getTime()
}

export const getRelativeDate = (timestamp, fmt = 'yyyy-MM-dd hh:mm:ss', isMillisecond = true) => {
  const tmpTimestamp = isMillisecond ? timestamp : timestamp * 1000
  const now = Date.now()
  const zero = getZeroTimestamp(now)
  const date = new Date(tmpTimestamp)

  if (now - tmpTimestamp < MINUTE) {
    return JUST_NOW
  } else if (now - tmpTimestamp < HOUR) {
    const minutes = Math.floor((now - tmpTimestamp) / MINUTE)
    return minutes + BEFORE_MINUTES
  } else if (tmpTimestamp > zero) {
    const hours = Math.floor((now - tmpTimestamp) / HOUR)
    return hours + BEFORE_HOURS
  } else {
    return date.format(fmt)
  }
}

export const getYMDFromTimestamp = (timestamp, isMillisecond = true) => {
  let tmpTimestamp = isMillisecond ? timestamp : timestamp * 1000
  const date = new Date(tmpTimestamp)
  return {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate()
  }
}

export const getDateString = (timestamp, fmt, isMillisecond = true) => {
  let tmpTimestamp = isMillisecond ? timestamp : timestamp * 1000
  return new Date(tmpTimestamp).format(fmt)
}

export const formatTime = (time, isMillisecond = true) => {
  const MINUTE = 60
  const HOUR = MINUTE * 60

  let second = Math.floor(isMillisecond ? time / 1000 : time)
  let h = Math.floor(second / HOUR)
  let minute = Math.floor((second % HOUR) / MINUTE)
  let remainSecond = second % MINUTE

  let str = ''
  if (h > 0) {
    str = h < 10 ? str + '0' + h : str + h
  }

  if (str !== '') {
    str += ':'
  }

  str = minute < 10 ? str + '0' + minute : str + minute
  str += ':'
  str = remainSecond < 10 ? str + '0' + remainSecond : str + remainSecond

  return str
}

export const getHourMinuteString = (hour, minute) => {
  if (!Number.isInteger(hour) || !Number.isInteger(minute)) {
    return ''
  }

  let tmpHour = hour < 10 ? '0' + hour : hour
  let tmpMinute = minute < 10 ? '0' + minute : minute
  return tmpHour + ':' + tmpMinute
}

export const getSeconds = (hour, minute) => {
  return hour * 60 * 60 + minute * 60;
};

export const getMinutes = (sections, floor = true) => {
  const minutes = floor ? Math.floor(sections / 60) : Math.ceil(sections / 60)
  return minutes
}
