import React from 'react';
import ReactDOM from 'react-dom';
import Modal from '../modal/Modal';

export default function alert(title, message, actions = [{ text: '确定' }], platform = 'ios') {
  let closed = false;

  if (!title && !message) {
    return {
      close: () => {},
    };
  }

  const div = document.createElement('div');
  div.className = 'tm-picture-editor-alert';
  document.body.appendChild(div);

  function close() {
    ReactDOM.unmountComponentAtNode(div);
    if (div && div.parentNode) {
      div.parentNode.removeChild(div);
    }
  }

  const footer = actions.map((button) => {
    // tslint:disable-next-line:only-arrow-functions
    const orginPress = button.onPress || function () {};
    button.onPress = () => {
      if (closed) {
        return;
      }

      const res = orginPress();
      if (res && res.then) {
        res
          .then(() => {
            closed = true;
            close();
          })
          .catch(() => {});
      } else {
        closed = true;
        close();
      }
    };
    return button;
  });

  const prefixCls = 'tm-modal';

  const maskClosable = !actions || actions.length === 0;

  ReactDOM.render(
    <Modal
      visible
      transparent
      title={title}
      transitionName="tm-zoom"
      closable={false}
      maskClosable={maskClosable}
      onClose={maskClosable ? close : undefined}
      footer={footer}
      maskTransitionName="tm-fade"
      platform={platform}
    >
      <div className={`${prefixCls}-alert-content`}>{message}</div>
    </Modal>,
    div
  );

  return {
    close,
  };
}
