import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import './style/index.less';

export default class Progress extends React.Component {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        barClassName: PropTypes.string,
        style: PropTypes.object,
        barStyle: PropTypes.object,
        percent: PropTypes.number,
        position: PropTypes.oneOf(['fixed', 'normal']),
        unfilled: PropTypes.bool,
        appearTransition: PropTypes.bool,
    };

    static defaultProps = {
        prefixCls: 'tm-progress',
        percent: 0,
        position: 'fixed',
        unfilled: true,
        appearTransition: false,
    };

    barRef = null;
    noAppearTransition = false;

    constructor(props) {
        super(props);
        this.state = {
            percent: props.percent,
        };
    }

    set percent(percent) {
        if (percent === this.state.percent) return;
        this.setState({
            percent: percent,
        });
    }
    get percent() {
        return this.state.percent;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.noAppearTransition = true;
        if ('percent' in nextProps) {
            if (nextProps.percent !== this.props.percent) {
                this.setState({
                    percent: nextProps.percent,
                });
            }
        }
    }

    componentDidMount() {
        if (this.props.appearTransition) {
            setTimeout(() => {
                if (this.barRef) {
                    this.barRef.style.width = `${this.percent}%`;
                }
            }, 10);
        }
    }

    render() {
        const {
            className,
            barClassName,
            prefixCls,
            position,
            unfilled,
            style = {},
            barStyle = {},
        } = this.props;

        const percentStyle = {
            width:
            this.noAppearTransition || !this.props.appearTransition
                ? `${this.percent}%`
                : 0,
        };

        const wrapCls = classnames(`${prefixCls}-outer`, className, {
            [`${prefixCls}-fixed-outer`]: position === 'fixed',
            [`${prefixCls}-hide-outer`]: !unfilled,
        });

        const wrapBarCls = classnames(`${prefixCls}-bar`, barClassName);
        let renderBar = null;
        if (this.percent > 0) {
          renderBar = <div ref={el => (this.barRef = el)} className={wrapBarCls} style={{ ...barStyle, ...percentStyle }}/>
        }
        return (
            <div
                style={style}
                className={wrapCls}
                role="progressbar"
                aria-valuenow={this.props.percent}
                aria-valuemin={0}
                aria-valuemax={100}
            >
              {renderBar}
            </div>
        );
    }
}