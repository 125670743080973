import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PanelContent from './PanelContent';

import Animate from '../animate/index';
import View from '../view/index';

import './style/index.less';

export default class Panel extends React.PureComponent {
    static propTypes = {
        className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        id: PropTypes.string,
        children: PropTypes.any,
        openAnimation: PropTypes.object,
        prefixCls: PropTypes.string,
        header: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node]),
        headerClass: PropTypes.string,
        showArrow: PropTypes.bool,
        isActive: PropTypes.bool,
        onItemClick: PropTypes.func,
        style: PropTypes.object,
        destroyInactivePanel: PropTypes.bool,
        disabled: PropTypes.bool,
        accordion: PropTypes.bool,
        forceRender: PropTypes.bool,
    };
      
    static defaultProps = {
        showArrow: true,
        isActive: false,
        destroyInactivePanel: false,
        onItemClick() {},
        headerClass: '',
        forceRender: false,
    };

    handleItemClick = () => {
        if (this.props.onItemClick) {
            this.props.onItemClick();
        }
    }

    handleKeyPress = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13 || e.which === 13) {
            this.handleItemClick();
        }
    }

    render() {
        const {
            className,
            id,
            style,
            prefixCls,
            header,
            headerClass,
            children,
            isActive,
            showArrow,
            destroyInactivePanel,
            disabled,
            accordion,
            forceRender,
        } = this.props;

        const headerCls = classNames(`${prefixCls}-header`, {
            [headerClass]: headerClass,
        });

        const itemCls = classNames({
            [`${prefixCls}-item`]: true,
            [`${prefixCls}-item-active`]: isActive,
            [`${prefixCls}-item-disabled`]: disabled,
        }, className);

        return (
            <View className={itemCls} style={style} id={id}>
                <View
                    className={headerCls}
                    onClick={this.handleItemClick}
                    role={accordion ? 'tab' : 'button'}
                    tabIndex={disabled ? -1 : 0}
                    aria-expanded={isActive}
                    onKeyPress={this.handleKeyPress}
                >
                    {showArrow && <i className="arrow" />}
                    {header}
                </View>
                <Animate
                    showProp="isActive"
                    exclusive
                    component=""
                    animation={this.props.openAnimation}
                >
                    <PanelContent
                        prefixCls={prefixCls}
                        isActive={isActive}
                        destroyInactivePanel={destroyInactivePanel}
                        forceRender={forceRender}
                        role={accordion ? 'tabpanel' : null}
                    >
                        {children}
                    </PanelContent>
                </Animate>
            </View>
        );
    }
}