import React from 'react';
import { IMAGE_URL, TABLE_STATE } from 'Constants';
import BgDiv from 'Components/BgDiv';
import Scroll from 'Components/Scroll';
import { Hx } from './GameItem';
import { apiGetAllMatchInfo, apiGetUserMatchLog, apiGetTableInfo } from 'src/Api';
import Dayjs from 'dayjs';
import { parseUrl, buildUrl } from 'src/Utils';
import './index.less';

const CSS = 'gamelist';

class GameList extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      list: []
    };
  }
  componentDidMount() {
    document.title = '比赛列表';
    this.getGameList();
  }

  async getUserMatchLog(mid) {
    let id = '';
    try {
      const res = await apiGetUserMatchLog(mid);
      id = res
    } catch (e) {
      console.log('UserMatchLog----', e.message);
    }
    return id;
  }

  async getTableInfo(tid) {
    let state = -1;
    try {
      const res = await apiGetTableInfo(tid);
      if (res && res.hasOwnProperty('baseInfo')){
        state = res.baseInfo.state;
      }
    } catch (e){
      console.log('TableInfo----', e.message);
    }
    return state;
  }

  async getGameList() {
    try {
      const res = await apiGetAllMatchInfo();
      if (res && res.length > 0) {
        res.map(v => {
          let start = new Date(v.startDate).getTime();
          let end = new Date(v.endDate).getTime();
          v.days = Math.floor((end - start) / (1000 * 3600 * 24));
          v.startT = Dayjs(v.startDate).format('YYYY-MM-DD');
          v.endT = Dayjs(v.endDate).format('YYYY-MM-DD');
          v.sT = Dayjs(v.startDate).format('MM.DD');
          v.eT = Dayjs(v.endDate).format('MM.DD');
        });
        this.setState({ list: res });
      }
    } catch (e) {
      console.log('game-list~~~', e);
    }
  }

  gotoMatch = async (v) => {
    let params = parseUrl();
    let tableId = await this.getUserMatchLog(v.matchId);
    let tableState, url;
    if (tableId && tableId !== ''){
      tableState = await this.getTableInfo(tableId);
    } else {
      let p = {
        token: params.token,
        deadline: new Date(v.unveilingDate).getTime(),
        matchId: v.matchId,
      }
      url = buildUrl('/normal/pre-answer', p)
      this.props.history.replace(url);
    }
    if (params.hasOwnProperty('token')){
      let p = {
        token: params.token,
        deadline: new Date(v.unveilingDate).getTime(),
        matchId: v.matchId,
        tableId: tableId
      }
      switch (tableState){
        case TABLE_STATE.new:
          url = buildUrl('/normal/pre-answer', p)
          this.props.history.replace(url);
          break;
        case TABLE_STATE.going:
          url = buildUrl('/normal/pre-answer', p)
          this.props.history.replace(url);
          break;
        case TABLE_STATE.over:
          url = buildUrl('/result', p)
          this.props.history.replace(url);
          break;
        default:
          break;
      }
    }
  }

  render() {
    const { list } = this.state;
    return (
      <BgDiv style={{ height: '100%' }} src={IMAGE_URL.BG_MUDAN}>
        <div className={CSS}>
          <Scroll>
            {
              list.map(v => (
                <div key={`game-item${v.matchId}`}>
                  <Hx data={v} gotoMatch={this.gotoMatch}  />
                </div>
              ))
            }
          </Scroll>
        </div>
      </BgDiv>
    );
  }
}

export default GameList;
