import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view';
import Text from '../text';
import TouchFeedback from '../touch-feedback';
import Icon from '../icon';
import Progress from '../progress';
import Strings from '../strings';
import Modal from '../modal';
import Audio from '../audio';
import Video from '../video';
import { Time, Log } from '../util';

import Timeline from './Timeline';

import './style/index.less';

export default class Recorder extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        prefixCls: PropTypes.string,
        mode: PropTypes.oneOf(['simple', 'advance']), // mode 'simple' is not support yet, just for future extension
        onComplete: PropTypes.func,       //fired while audio file is ready
        onCancel: PropTypes.func,       //fired while user cancel record
        onError: PropTypes.func,        //fired while error happen on recording
        caption: PropTypes.string,      //functional text show in component, default is '录音'
        recorder: PropTypes.object      //data source object, contains a set of native functions, such as start, stop, pause, etc
    };

    static defaultProps = {
        prefixCls: 'tm-recorder',
        mode: 'advance',
        caption: Strings.record,
    };

    timestamp = 0;
    canFinish = false;

    constructor(props) {
        super(props);

        this.state = {
            status: 'idle', //'idle', 'recording', 'paused'
            visible: false,
            amplitude: 10,
            audioPaused: true,
        };
    }

    activate() {
        this.setState({
            visible: true,
        }, () => {
            Audio.stop();
            Video.stop();
        });
    }

    update = (volume, duration) => {
        this.canFinish = duration > 0;
        this.shouldSeek = false;
        this.timestamp = duration;
        if (this.timeline) {
            this.timeline.update(volume, duration);
        }

        if (this.time) {
            this.time.text = Time.format(parseInt(duration, 10), 'hh:mm:ss');
        }
    }

    onTaggle = () => {
        if (this.state.status === 'idle') {
            this.onStart();
        } else if (this.state.status === 'paused') {
            this.onResume();
        } else if (this.state.status === 'recording') {
            this.onPause();
        }
    }

    onRecorderStatusChange = status => {
        const {
            onError,
            onComplete,
        } = this.props;

        const handleError = error => {
            onError && onError(error);
            this.setState({
                status: 'idle',
                visible: false,
            });
        }
        if (!status) {
            handleError(Strings.error_unknown);
            return;
        }

        let activeStatus = 'idle';
        let visible = true;
        let resetPlayer = false;
        Log.info('Recorder state - ' + status.state);

        switch (status.state) {
            case 'error':
            handleError(status.error);
            visible = false;
            return;
            case 'start':
            activeStatus = 'recording';
            break;
            case 'resume':
            case 'seek':
            activeStatus = 'recording';
            resetPlayer = true;
            break;
            case 'pause':
            activeStatus = 'paused';
            resetPlayer = true;
            break;
            case 'stop':
            activeStatus = 'encoding';
            resetPlayer = true;
            break;
            case 'done': 
            onComplete && onComplete(status.file);
            visible = false;
            resetPlayer = true;
            break;
            case 'reset':
            resetPlayer = true;
            if (this.time) this.time.text = '00:00:00';
            break;
            case 'cancel':
            resetPlayer = true;
            visible = false;
            break;
            case 'update':
            this.update(status.volume, status.duration);
            return;
            case 'encode':
            Log.verbose(status.progress);
            activeStatus = 'encoding';
            if (this.encoder) this.encoder.percent = status.progress;
            return;
            default:
            handleError(Strings.error_unknown);
            return;
        }

        if (this.player) {
            if (resetPlayer) {
                this.player.pause();
                this.player.seek(0);
                this.audioTimeline = 0;
            }
            Log.info(status.name);
            if (status.state === 'pause') {
                this.player.source = status.name;
            }
        }

        if (status.state === 'seek') {
            this.timeline.relocate(this.timestamp);
        }

        if (status.state === 'reset') {
            this.timeline.relocate(0, true);
        }

        setTimeout(() => {
            this.setState({
                status: activeStatus,
                visible: visible,
                audioPaused: status.state !== 'pause' ? true : this.state.audioPaused,
            });
        }, 10);
    }

    onStart = () => {
        const { recorder } = this.props;
        recorder && recorder.start(this.onRecorderStatusChange);

        this.setState({
            status: 'recording',
        });
    }

    onPause = () => {
        const { recorder } = this.props;
        recorder && recorder.pause();
    }

    onResume = () => {
        const { recorder, player } = this.props;
        if (player.state !== 'idle') {
            player.stop();
        }
        if (this.shouldSeek) {
            Log.verbose(this.timestamp);
            recorder && recorder.seek(this.timestamp);
        } else {
            recorder && recorder.resume();
        }
    }

    onStop = () => {
        const { recorder, player } = this.props;
        if (player.state !== 'idle') {
            player.stop();
        }
        recorder && recorder.stop();
    }

    onReset = () => {
        const { recorder, player } = this.props;
        if (player.state !== 'idle') {
            player.stop();
        }
        recorder && recorder.reset();
    }

    onCancel = () => {
        const { recorder, player } = this.props;
        if (player.state !== 'idle') {
            player.stop();
        }

        const initialised = 'idle' !== this.state.status;
        this.setState({
            status: 'idle',
            visible: initialised,
        }, () => {
            if (initialised) {
                recorder && recorder.cancel();
            }
        });
    }

    onSeek = (timestamp, duration) => {
        this.shouldSeek = timestamp !== duration && this.player.paused;
        this.shouldAudioSeek = this.shouldSeek;
        this.timestamp = timestamp;
        if (this.time) {
            this.time.text = Time.format(parseInt(timestamp, 10), 'hh:mm:ss');
        }
    }

    onAudioUpdate = timestamp => {
        if (this.shouldSeek) {
            this.timestamp = timestamp;
        }

        if (this.state.audioPaused) {
            this.setState({
                audioPaused: false,
            });
        }

        // Log.verbose('timestamp: ', timestamp);
        const deltaTime = Math.abs(timestamp - this.audioTimeline);
        // Sync timeline and audio time
        if (deltaTime > 0.2) {
            this.audioTimeline = timestamp;
        }

        if (!this.audioTimer && timestamp > 0) {
            this.audioTimeline = timestamp;
            this.audioTimer = setInterval(() => {
                if (this.timeline) {
                    // Log.verbose('timer: ', this.audioTimeline);
                    this.timeline.locate(this.audioTimeline);
                    this.audioTimeline += 0.1;
                }
            }, 100);
        }
    }

    onTogglePlay = () => {
        this.shouldSeek = false;
        if (this.shouldAudioSeek && this.player.paused) {
            this.player.seek(this.timestamp);
            this.shouldAudioSeek = false;
        }
        clearInterval(this.audioTimer);
        this.audioTimer = null;
        this.player.togglePlay();
    }

    onAudioPlaying = () => {
        this.setState({
            audioPaused: false,
        });
        if (this.timeline) this.timeline.scrollable = false;
    }

    onAudioPause = () => {
        this.setState({
            audioPaused: true,
        });
        if (this.timeline) this.timeline.scrollable = true;
        clearInterval(this.audioTimer);
        this.audioTimer = null;
    }

    onAudioEnded = () => {
        Log.verbose('Audio play finish');
        this.setState({
            audioPaused: true,
        });
        if (this.timeline) this.timeline.scrollable = true;

        setTimeout(() => {
            clearInterval(this.audioTimer);
            this.audioTimeline = 0;
            this.timeline && this.timeline.onPlaybackFinish();
        }, 100);
    }

    onAudioError = () => {
        if (this.timeline) this.timeline.scrollable = true;
        clearInterval(this.audioTimer);
        this.audioTimeline = 0;
    }

    renderSimpleRecorder = () => {
        const {
            prefixCls,
            className,
            mode,
        } =  this.props;

        if (mode !== 'simple') {
            return null;
        }

        const wrapCls = classnames(`${prefixCls}-${mode}`, className);

        return (
            <View className={wrapCls} >
            </View>
        );
    }

    renderAdvanceRecorder = () => {
        const {
            prefixCls,
            className,
            mode,
            caption,
            player,
        } =  this.props;

        if (mode !== 'advance') {
            return null;
        }

        const wrapCls = classnames(`${prefixCls}-${mode}`, className);
        const disableDone = this.state.status === 'recording' || this.state.status === 'idle' || this.state.status === 'encoding' || !this.state.audioPaused || !this.canFinish;

        const  renderResetKnob = () => {
            const isPaused = () => this.state.status === 'paused';
            return (
                <Icon 
                    type='recorder-reset' 
                    color={isPaused() ? 'rgb(3, 193, 172)' : '#747575'}
                    activeColor= { isPaused() ? 'rgba(3, 193, 172, 0.6)' : undefined}
                    onClick={isPaused() ? this.onReset : undefined}
                    size='lg'
                />
            );
        };

        const renderPlayer = () => {
            const isRecorderPaused = () => this.state.status === 'paused';
            return (
                <View>
                    <Audio 
                        ref={el => this.player = el}
                        mode='backend' 
                        player={player}
                        onPlay={this.onAudioPlaying}
                        onPause={this.onAudioPause}
                        onEnded={this.onAudioEnded}
                        onError={this.onAudioError}
                        onTimeUpdate={this.onAudioUpdate}
                    />
                    <Icon 
                        type={this.state.audioPaused ? 'recorder-play' : 'recorder-pause'}
                        color={isRecorderPaused() ? 'rgb(3, 193, 172)' : '#747575'}
                        activeColor= { isRecorderPaused() ? 'rgba(3, 193, 172, 0.6)' : undefined}
                        onClick={isRecorderPaused() ? () => this.player ? this.onTogglePlay() : {} : undefined}
                        size='lg'
                    />
                </View>
            );
        };

        return (
            <View className={wrapCls}>
                <View className={`${prefixCls}-${mode}-header`}>
                    <TouchFeedback activeClassName={`${prefixCls}-${mode}-header-cancel-active`}>
                        <Text 
                            className={`${prefixCls}-${mode}-header-cancel`}
                            onClick={this.onCancel}
                        >
                            {Strings.cancel}
                        </Text>
                    </TouchFeedback>
                    <Text className={`${prefixCls}-${mode}-header-caption`}>
                        {caption}
                    </Text>
                    <TouchFeedback activeClassName={classnames({[`${prefixCls}-${mode}-header-done-active`]: !disableDone})}>
                        <Text 
                            className={classnames(`${prefixCls}-${mode}-header-done`, {
                                [`${prefixCls}-${mode}-header-done-disable`]: disableDone,
                            })}
                            onClick={disableDone ? undefined : this.onStop}
                        >
                            {Strings.done}
                        </Text>
                    </TouchFeedback>
                </View>
                <Timeline 
                    ref={el => this.timeline = el}
                    className={`${prefixCls}-${mode}-timeline`}
                    scrollable={this.state.status === 'paused'}
                    onSeek={this.onSeek}
                />
                <Text 
                    ref={el => this.time = el}
                    className={`${prefixCls}-${mode}-time`}
                    text='00:00:00'
                />
                <View className={`${prefixCls}-${mode}-control`}>
                    {renderResetKnob()}
                    <TouchFeedback activeClassName={`${prefixCls}-${mode}-control-knob-active`}>
                        <View
                            className={`${prefixCls}-${mode}-control-knob`}
                            onClick={this.state.status !== 'encoding' ? this.onTaggle : undefined}
                        >
                            {this.state.status === 'recording' ? <View className={`${prefixCls}-${mode}-control-knob-pause`} /> : <Icon type='recorder' color='#fff'/>}
                        </View>
                    </TouchFeedback>
                    {renderPlayer()}
                </View>
                {
                    this.state.status === 'encoding' ?
                    <Progress 
                        ref={el => this.encoder = el}
                        className={`${prefixCls}-${mode}-control-encoder`}
                        barClassName={`${prefixCls}-${mode}-control-encoder-bar`}
                        position='normal'
                    /> : null
                }
            </View>
        );
    }

    render() {
        const {
            mode,
            prefixCls,
        } =  this.props;

        return (
            <Modal
                popup
                className={`${prefixCls}-modal`}
                maskClosable={false}
                animationType="slide-up"
                visible={this.state.visible}
                // onClose={this.onClose}
            >
                { mode === 'simple' ? this.renderSimpleRecorder() : this.renderAdvanceRecorder()}
            </Modal>
        );
    }
}