import React from 'react'
import BgDiv from 'Components/BgDiv'
import { apiGetUserMatchRank } from 'src/Api'
import { parseUrl, buildUrl } from 'src/Utils'
import { PROMOTION_TYPE } from 'Constants'
import { Image } from 'thanos'
import dayjs from 'dayjs'
import './index.less'

const PREFIX_CLS = 'game-result'

class Result extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      result: undefined,
    }

    this.urlParam = parseUrl()

    const deadline = this.urlParam.get('deadline', 'Number')
    this.deadline = new Date(deadline).valueOf()
  }

  componentDidMount() {
    document.title = '海选赛晋级结果'
    this.init()
  }

  init = async () => {
    try {
      const matchId = this.urlParam.get('matchId', 'Number')
      const data = await apiGetUserMatchRank(matchId)

      let result
      if (!data) {
        result = 'unjoined'
      } else if (data.promotion === PROMOTION_TYPE.new) {
        result = 'covered'
      } else if (data.promotion === PROMOTION_TYPE.success) {
        result = 'success'
      } else {
        result = 'failed'
      }

      this.setState({ result: result })
    } catch (err) {
      console.log('eeeeeeeeeeeeeee', err.message)
    }
  }

  handleMyReport = () => {
    const url = buildUrl('/answer-report', {
      token: this.urlParam.token,
      matchId: this.urlParam.matchId,
      tableId: this.urlParam.tableId,
    })

    this.props.history.push(url)
  }

  handlePromotionList = () => {
    const url = buildUrl('/promotion-list', {
      token: this.urlParam.token,
      matchId: this.urlParam.matchId,
      tableId: this.urlParam.tableId,
    })

    this.props.history.push(url)
  }

  renderStatus() {
    const { result } = this.state
    // const result = 'covered'

    let label
    let src
    if (result === 'covered') {
      label = `将于${dayjs(this.deadline).format('YYYY年MM月DD日 HH:mm')}揭晓`
      src = require('Images/covered.png').default
    }

    if (result === 'success') {
      label = '恭喜您成功晋级复赛'
      src = require('Images/promoted.png').default
    }

    if (result === 'failed') {
      label = '距离晋级仅有一步之遥'
      src = require('Images/unpromoted.png').default
    }

    if (result === 'unjoined') {
      label = '海选答题阶段已结束'
      src = require('Images/unjoined.png').default

      return (
        <React.Fragment>
          <Image source={src} className={`${PREFIX_CLS}-status`}/>

          <p className={`${PREFIX_CLS}-label`}>
            {label}
          </p>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <p className={`${PREFIX_CLS}-label`}>
          {label}
        </p>

        <Image source={src} className={`${PREFIX_CLS}-status`}/>
      </React.Fragment>
    )
  }

  render() {
    const { result } = this.state

    return (
      <BgDiv
        className={PREFIX_CLS}
        style={{ height: '100%' }}
        src={require('Images/bg-mudan.png').default}
      >
        <div className={`${PREFIX_CLS}-spacer`}></div>

        {this.renderStatus()}

        <div className={`${PREFIX_CLS}-spacer2`}></div>

        {result && result !== 'unjoined' &&
          <p
            className={`${PREFIX_CLS}-report`}
            onClick={this.handleMyReport}
          >
            我的答题报告
          </p>
        }
        <p
          className={`${PREFIX_CLS}-list`}
          onClick={this.handlePromotionList}
        >
          复赛晋级名单
        </p>
      </BgDiv>
    )
  }
}

export default Result
