import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Platform } from '../util';

import View from '../view';

import './style/index.less';

export default class Dropdown extends React.PureComponent {

    static propTypes = {
        className: PropTypes.string,
        contentClassName: PropTypes.string,
        prefixCls: PropTypes.string,
        visible: PropTypes.bool,
        onVisibilityChanged: PropTypes.func,
        content: PropTypes.node,
    };

    static defaultProps = {
        prefixCls: 'tm-dropdown',
        visible: false,
    };

    constructor(props) {
        super(props);
        this.state ={
            visible: !!props.visible,
            animate: 'idle',
        };
    }

    componentDidMount() {
        document.addEventListener('click', this.onTrigger);
        this.contentElem.addEventListener('animationend', this.onAnimationEnd);
        this.contentElem.addEventListener('webkitAnimationEnd', this.onAnimationEnd);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.onTrigger);
        this.contentElem.removeEventListener('animationend', this.onAnimationEnd);
        this.contentElem.removeEventListener('webkitAnimationEnd', this.onAnimationEnd);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ('visible' in nextProps && nextProps.visible !== this.state.visible) {
            this.props.onVisibilityChanged && this.props.onVisibilityChanged(nextProps.visible);
            this.setState({
                animate: nextProps.visible ? 'slidein' : 'slideout',
            });
        }
    }

    matchDom(node, target) {
        if (node === target) return true;
        let parent = target.parentNode;
        while (parent) {
            if (parent === node) return true;
            parent = parent.parentNode;
        }

        return false;
    }

    onAnimationEnd = e => {
        let visible;
        if (e.animationName === 'TMDropDownSlideIn') {
            visible = true;
        } else if (e.animationName === 'TMDropDownSlideOut') {
            visible = false;
        } else {
            return;
        }
        this.setState({
            visible: visible,
            animate: 'idle',
        });
    }

    onTrigger = e => {
        if (!this.contentElem || !this.triggerElem) return;

        const target = e.target;
        let visible = this.state.visible;
        if (this.matchDom(this.contentElem, target)) {
            return;
        }
        
        if (this.matchDom(this.triggerElem, target)) {
            visible = !visible;
        } else if (!visible) {
            return;
        } else {
            visible = false;
        }

        e.stopPropagation();

        this.setState({
            visible: true,
            animate: visible ? 'slidein' : 'slideout',
        }, () => {
            this.props.onVisibilityChanged && this.props.onVisibilityChanged(visible);
        });
    }

    render() {
        const {
            className,
            contentClassName,
            prefixCls,
            content,
            children,
        } = this.props;
        
        const triggerElement = () => {
            const trigger = React.Children.only(children);
            return (
                <View ref={el => this.triggerElem = ReactDOM.findDOMNode(el)}>
                    {trigger}
                </View>
            )
        }

        const contentElement = () => {
            const wrapCls = classnames({
                [`${prefixCls}-content`]: this.state.visible,
                [`${prefixCls}-content-deactive`]: !this.state.visible && this.state.animate === 'idle',
                [`${prefixCls}-content-slidein`]: this.state.animate === 'slidein',
                [`${prefixCls}-content-slideout`]: this.state.animate === 'slideout',
                [`${prefixCls}-content-ios`]: Platform.isIPhone(),
            }, contentClassName);
    
            return (
                <View 
                    className={wrapCls}
                    ref={el => this.contentElem = ReactDOM.findDOMNode(el)}
                >
                    {content}
                </View>
            );
        }

        return (
            <View className={classnames(`${prefixCls}`, className)}>
                {triggerElement()}
                {contentElement()}
            </View>
        );
    }
}