import commandFactory from '../factory/command';
import consts from '../lib/consts';
import util from '../lib/util';

const { componentNames, rejectMessages, commandNames } = consts;
const { ICON } = componentNames;

/**
 * Chched data for undo
 * @type {Object}
 */
let chchedUndoDataForSilent = null;

/**
 * Make undoData
 * @param {object} styles - icon styles
 * @param {Component} targetObj - icon component
 * @returns {object} - undo data
 */
function makeUndoData(styles, targetObj) {
  const undoData = {
    object: targetObj,
    styles: {}
  };
  util.forEachOwnProperties(styles, (value, key) => {
    const undoValue = targetObj[key];
    undoData.styles[key] = undoValue;
  });

  return undoData;
}

const command = {
  name: commandNames.CHANGE_ICON_STYLE,

  /**
   * Change icon styles
   * @param {Graphics} graphics - Graphics instance
   * @param {number} id - object id
   * @param {Object} styles - icon styles
   *     @param {string} [styles.fill] Color
   *     @param {string} [styles.scale] Font type for icon
   * @param {boolean} isSilent - is silent execution or not
   * @returns {Promise}
   */
  execute(graphics, id, styles, isSilent) {
    const iconComp = graphics.getComponent(ICON);
    const targetObj = graphics.getObject(id);

    if (!targetObj) {
      return Promise.reject(rejectMessages.noObject);
    }
    if (!this.isRedo) {
      const undoData = makeUndoData(styles, targetObj);

      chchedUndoDataForSilent = this.setUndoData(undoData, chchedUndoDataForSilent, isSilent);
    }

    return iconComp.setStyle(targetObj, styles);
  },
  /**
   * @param {Graphics} graphics - Graphics instance
   * @returns {Promise}
   */
  undo(graphics) {
    const iconComp = graphics.getComponent(ICON);
    const { object: iconObj, styles } = this.undoData;

    return iconComp.setStyle(iconObj, styles);
  }
};

commandFactory.register(command);

export default command;
