import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import './index.less';

import View from '../../view';

const prefixCls = 'tm-picture-editor-colors';

export const defaultColors = [
  '#E12B28',
  '#1A1A1C',
  '#C1BA29',
  '#CA7C22',
  '#C13372',
  '#7B28C0',
  '#2935C1',
  '#28A1C0',
  '#26C137'
];

class Colors extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.value !== nextProps.value) {
      return {
        value: nextProps.value
      };
    }
    return null;
  }

  handleClick = color => {
    const { onChange } = this.props;
    this.setState({ color }, () => {
      onChange && onChange(color);
    });
  };

  render() {
    const { className } = this.props;
    return (
      <View className={classNames(prefixCls, className)}>
        {defaultColors.map(color => (
          <View
            key={color}
            className="color-item"
            style={{
              borderColor: color === this.state.value ? color : '#fff'
            }}
            onClick={() => this.handleClick(color)}
          >
            <span style={{ backgroundColor: color }} />
          </View>
        ))}
      </View>
    );
  }
}

Colors.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
};

Colors.defaultProps = {
  value: defaultColors[0]
};

export default Colors;
