import Modal from './Modal';
import progress from './progress';
import alert from './alert';
import operation from './operation';
import prompt from './prompt';

Modal.progress = progress;
Modal.alert = alert;
Modal.operation = operation;
Modal.prompt = prompt;

export default Modal;