import fabric from './lib/fabric';
import consts from './lib/consts';
import CustomEvents from './lib/CustomEvents';
import util from './lib/util';

import NormalMode from './drawingMode/normal';
import FreeDrawingMode from './drawingMode/freeDrawing';
import TextDrawingMode from './drawingMode/text';
import DeletionMode from './drawingMode/deletion';
import IconMode from './drawingMode/icon';
import PathMode from './drawingMode/path';

import Normal from './component/normal';
import ImageLoader from './component/imageLoader';
import FreeDrawing from './component/freeDrawing';
import Text from './component/text';
import Rotation from './component/rotation';
import Icon from './component/icon';
import Path from './component/path';
import Deletion from './component/deletion';

const { drawingModes, fObjectOptions } = consts;

const components = consts.componentNames;
const events = consts.eventNames;

const DEFAULT_CSS_MAX_WIDTH = 1000;
const DEFAULT_CSS_MAX_HEIGHT = 800;

class Graphics {
  constructor(element, { containerClass, cssMaxWidth, cssMaxHeight, useItext, useDragAddIcon } = {}) {
    // 画布背影图片
    this.canvasImage = null;

    this.containerClass = containerClass;

    // 最大范围
    this.cssMaxWidth = cssMaxWidth || DEFAULT_CSS_MAX_WIDTH;
    this.cssMaxHeight = cssMaxHeight || DEFAULT_CSS_MAX_HEIGHT;

    /**
     * Use Itext mode for text component
     * @type {boolean}
     */
    this.useItext = useItext;

    /**
     * Use add drag icon mode for icon component
     * @type {boolean}
     */
    this.useDragAddIcon = useDragAddIcon;

    /**
     * Image name
     * @type {string}
     */
    this.imageName = '';

    /**
     * Object Map
     * @type {Object}
     * @private
     */
    this._objects = {};

    /**
     * Fabric-Canvas instance
     * @type {fabric.Canvas}
     * @private
     */
    this._canvas = null;

    /**
     * Drawing mode
     * @type {string}
     * @private
     */
    this._drawingMode = drawingModes.NONE;

    /**
     * DrawingMode map
     * @type {Object.<string, DrawingMode>}
     * @private
     */
    this._drawingModeMap = {};

    /**
     * Component map
     * @type {Object.<string, Component>}
     * @private
     */
    this._componentMap = {};

    /**
     * fabric event handlers
     * @type {Object.<string, function>}
     * @private
     */
    this._handler = {
      onMouseDown: this._onMouseDown.bind(this),
      onMouseMove: this._onMouseMove.bind(this),
      onMouseUp: this._onMouseUp.bind(this),
      onObjectAdded: this._onObjectAdded.bind(this),
      onObjectRemoved: this._onObjectRemoved.bind(this),
      onObjectMoved: this._onObjectMoved.bind(this),
      onObjectScaled: this._onObjectScaled.bind(this),
      onObjectRotated: this._onObjectRotated.bind(this),
      onObjectSelected: this._onObjectSelected.bind(this),
      onPathCreated: this._onPathCreated.bind(this),
      onSelectionCleared: this._onSelectionCleared.bind(this),
      onSelectionCreated: this._onSelectionCreated.bind(this),
    };

    this._setObjectCachingToFalse();
    this._setCanvasElement(element);
    this._createDrawingModeInstances();
    this._createComponents();
    this._attachCanvasEvents();
    // this._attachCanvasContainerEvents();

    fabric.enableGLFiltering = false;
  }

  clear() {
    const canvas = this._canvas;
    let objects = this._canvas.getObjects();
    canvas.remove(...objects);

    function dispose() {
      // cancel eventually ongoing renders
      if (this.isRendering) {
        fabric.util.cancelAnimFrame(this.isRendering);
        this.isRendering = 0;
      }
      this.forEachObject(function (object) {
        object.dispose && object.dispose();
        this.remove(object);
      });
      this._objects = [];

      if (this.backgroundImage && this.backgroundImage.dispose) {
        this.backgroundImage.dispose();
      }
      this.backgroundImage = null;

      if (this.overlayImage && this.overlayImage.dispose) {
        this.overlayImage.dispose();
      }
      this.overlayImage = null;

      this.clearContext(this.contextContainer);
      this.clearContext(this.contextTop);
      this.clearContext(this.contextCache);

      this.setWidth(0);
      this.setHeight(0);

      this.clear();
    }

    dispose.apply(this._canvas);
  }

  /**
   * 销毁画布对象
   */
  destroy() {
    // const { wrapperEl } = this._canvas;

    this.removeAll();

    this._canvas.clear();
    this._canvas.dispose();

    // wrapperEl.parentNode.removeChild(wrapperEl);

    util.forEach(
      this,
      (value, key) => {
        this[key] = null;
      },
      this
    );
  }
  /**
   * 解除画布中所有对象活动
   * @returns {Graphics} this
   */
  deactivateAll() {
    this._canvas.discardActiveObject();
    return this;
  }

  /**
   * 绘制所有对象
   * @returns {Graphics} this
   */
  renderAll() {
    this._canvas.renderAll();
    return this;
  }

  /**
   *
   */
  loadFromJSON(...args) {
    return this._canvas.loadFromJSON(...args);
  }

  /**
   * 在画布上添加对象
   * @param {Object|Array} objects - objects
   */
  add(objects) {
    let theArgs = [];
    if (util.isArray(objects)) {
      theArgs = objects;
    } else {
      theArgs.push(objects);
    }
    this._canvas.add(...theArgs);
  }

  /**
   * 画布中包含对象
   * @param {Object} target - graphics object or group
   * @returns {boolean} true if contains or false
   */
  contains(target) {
    return this._canvas.contains(target);
  }

  /**
   * 获取所有对象
   * @returns {Array}
   */
  getObjects() {
    return this._canvas.getObjects();
  }

  /**
   * 通过id获取对象
   * @param {number} id - object id
   * @returns {fabric.Object} object corresponding id
   */
  getObject(id) {
    return this._objects[id];
  }

  /**
   * 移除对象
   * @param {Object} target - graphics object or group
   */
  remove(target) {
    this._canvas.remove(target);
  }

  /**
   * 移除所有对象
   */
  removeAll() {
    const canvas = this._canvas;
    let objects = this._canvas.getObjects();
    canvas.remove(...objects);
  }

  /**
   * 通过id移除对象
   * @param {number} id - object id
   * @returns {Array} removed objects
   */
  removeObjectById(id) {
    const objects = [];
    const canvas = this._canvas;
    const target = this.getObject(id);
    const isValidGroup = target && target.isType('group') && !target.isEmpty();

    if (isValidGroup) {
      canvas.discardActiveObject(); // restore states for each objects
      target.forEachObject((obj) => {
        objects.push(obj);
        canvas.remove(obj);
      });
    } else if (canvas.contains(target)) {
      objects.push(target);
      canvas.remove(target);
    }

    return objects;
  }

  /**
   * 获取对象id
   * @param {fabric.Object} object object
   * @returns {number} object id if it exists or null
   */
  getObjectId(object) {
    let key = null;
    for (key in this._objects) {
      // eslint-disable-next-line no-prototype-builtins
      if (this._objects.hasOwnProperty(key)) {
        if (object === this._objects[key]) {
          return key;
        }
      }
    }

    return null;
  }

  /**
   * 获取当前活动对象
   * @returns {Object} active object or group instance
   */
  getActiveObject() {
    return this._canvas._activeObject;
  }

  /**
   * 获取所有活动对象
   * @returns {Object} active group object instance
   */
  getActiveObjects() {
    const activeObject = this._canvas._activeObject;
    return activeObject && activeObject.type === 'activeSelection' ? activeObject : null;
  }

  /**
   * 设置活动对象
   * @param {Object} target - target object or group
   */
  setActiveObject(target) {
    this._canvas.setActiveObject(target);
  }

  /**
   * 获取组件
   * @param {string} name - Component name
   * @returns {Component}
   */
  getComponent(name) {
    return this._componentMap[name];
  }

  /**
   * 获取绘制模式
   * @returns {string}
   */
  getDrawingMode() {
    return this._drawingMode;
  }

  /**
   * 开始绘制
   */
  startDrawingMode(mode, option) {
    if (this._isSameDrawingMode(mode)) {
      return true;
    }

    // If the current mode is not 'NORMAL', 'stopDrawingMode()' will be called first.
    this.stopDrawingMode();

    const drawingModeInstance = this._getDrawingModeInstance(mode);
    if (drawingModeInstance && drawingModeInstance.start) {
      drawingModeInstance.start(this, option);

      this._drawingMode = mode;
      this.fire(events.DRAWING_MODE_CHANGED, mode);
    }
    return !!drawingModeInstance;
  }
  /**
   * 停止绘制
   */
  stopDrawingMode() {
    if (this._isSameDrawingMode(drawingModes.NONE)) {
      return;
    }

    const drawingModeInstance = this._getDrawingModeInstance(this.getDrawingMode());
    if (drawingModeInstance && drawingModeInstance.end) {
      drawingModeInstance.end(this);
    }
    this._drawingMode = drawingModes.NONE;
    return !!drawingModeInstance;
  }

  /**
   * To data url from canvas
   * @param {Object} options - options for toDataURL
   *   @param {String} [options.format=png] The format of the output image. Either "jpeg" or "png"
   *   @param {Number} [options.quality=1] Quality level (0..1). Only used for jpeg.
   *   @param {Number} [options.multiplier=1] Multiplier to scale by
   *   @param {Number} [options.left] Cropping left offset. Introduced in fabric v1.2.14
   *   @param {Number} [options.top] Cropping top offset. Introduced in fabric v1.2.14
   *   @param {Number} [options.width] Cropping width. Introduced in fabric v1.2.14
   *   @param {Number} [options.height] Cropping height. Introduced in fabric v1.2.14
   * @returns {string} A DOMString containing the requested data URI.
   */
  toDataURL(options) {
    return this._canvas.toDataURL(options);
  }

  /**
   * 设置画布的北影图片
   * @param {string} name - Name of image
   * @param {?fabric.Image} canvasImage - Fabric image instance
   */
  setCanvasImage(name, canvasImage) {
    if (canvasImage) {
      util.stamp(canvasImage);
    }
    this.imageName = name;
    this.canvasImage = canvasImage;
  }

  /**
   * 设置最大范围
   * @param {{width: number, height: number}} maxDimension - Max width & Max height
   */
  setCssMaxDimension(maxDimension) {
    this.cssMaxWidth = maxDimension.width || this.cssMaxWidth;
    this.cssMaxHeight = maxDimension.height || this.cssMaxHeight;
  }

  /**
   * 校正画布范围
   */
  adjustCanvasDimension() {
    const canvasImage = this.canvasImage.scale(1);
    const { width, height } = canvasImage.getBoundingRect();
    const maxDimension = this._calcMaxDimension(width, height);

    this.setCanvasCssDimension({
      width: `${maxDimension.width}px`,
      height: `${maxDimension.height}px`, // Set height '' for IE9
      // 'max-width': `${maxDimension.width}px`,
      // 'max-height': `${maxDimension.height}px`,
    });

    this.setCanvasBackstoreDimension({ width, height });
    this._canvas.centerObject(canvasImage);
  }

  /**
   * 设置画布范围 - css only
   * @param {Object} dimension - Canvas css dimension
   */
  setCanvasCssDimension(dimension) {
    this._canvas.setDimensions(dimension, {
      cssOnly: true,
    });
  }
  /**
   * 设置画布范围 - backstore only
   * @param {Object} dimension - Canvas backstore dimension
   */
  setCanvasBackstoreDimension(dimension) {
    this._canvas.setDimensions(dimension, {
      backstoreOnly: true,
    });
  }

  /**
   * 设置图片属性
   * @param {Object} setting - Image properties
   * @param {boolean} [withRendering] - If true, The changed image will be reflected in the canvas
   */
  setImageProperties(setting, withRendering) {
    const { canvasImage } = this;

    if (!canvasImage) {
      return;
    }

    canvasImage.set(setting).setCoords();
    if (withRendering) {
      this._canvas.renderAll();
    }
  }

  /**
   * Get canvas element
   */
  getCanvasElement() {
    return this._canvas.getElement();
  }

  /**
   * Get fabric.Canvas instance
   */
  getCanvas() {
    return this._canvas;
  }

  /**
   * Get canvasImage (fabric.Image instance)
   */
  getCanvasImage() {
    return this.canvasImage;
  }

  /**
   * Get image name
   */
  getImageName() {
    return this.imageName;
  }

  /**
   * Add image object on canvas
   */
  addImageObject(imgUrl) {
    const callback = this._callbackAfterLoadingImageObject.bind(this);

    return new Promise((resolve) => {
      fabric.Image.fromURL(
        imgUrl,
        (image) => {
          callback(image);
          resolve(this.createObjectProperties(image));
        },
        { crossOrigin: 'Anonymous' }
      );
    });
  }

  /**
   * Get center position of canvas
   */
  getCenter() {
    return this._canvas.getCenter();
  }

  /**
   * Get vp center position of canvas
   */
  getVpCenter() {
    return this._canvas.getVpCenter();
  }

  /**
   * Get vp center position of canvas
   */
  getViewportCenter() {
    const vpCenter = this._canvas.getVpCenter();
    return {
      top: vpCenter.y,
      left: vpCenter.x,
    };
  }

  /**
   * Get zoom of canvas
   */
  getZoom() {
    return this._canvas.getZoom();
  }

  /**
   * Set brush option
   * @param {Object} option brush option
   *  @param {Number} option.width width
   *  @param {String} option.color color like 'FFFFFF', 'rgba(0, 0, 0, 0.5)'
   */
  setBrush(option) {
    const drawingMode = this._drawingMode;
    let compName = components.FREE_DRAWING;

    if (drawingMode === drawingModes.LINE) {
      compName = drawingModes.LINE;
    }

    this.getComponent(compName).setBrush(option);
  }

  /**
   * Change cursor style
   * @param {string} cursorType - cursor type
   */
  changeCursor(cursorType) {
    const canvas = this.getCanvas();
    canvas.defaultCursor = cursorType;
    canvas.renderAll();
  }

  /**
   * Whether it has the filter or not
   * @param {string} type - Filter type
   * @returns {boolean} true if it has the filter
   */
  hasFilter(type) {
    return this.getComponent(components.FILTER).hasFilter(type);
  }

  /**
   * Set selection style of fabric object by init option
   * @param {Object} styles - Selection styles
   */
  setSelectionStyle(styles) {
    util.extend(fObjectOptions.SELECTION_STYLE, styles);
  }

  /**
   * Set Crop selection style
   */
  setCropSelectionStyle(style) {
    this.cropSelectionStyle = style;
  }

  /**
   * Set object properties
   * @param {number} id - object id
   * @param {Object} props - props
   *     @param {string} [props.fill] Color
   *     @param {string} [props.fontFamily] Font type for text
   *     @param {number} [props.fontSize] Size
   *     @param {string} [props.fontStyle] Type of inclination (normal / italic)
   *     @param {string} [props.fontWeight] Type of thicker or thinner looking (normal / bold)
   *     @param {string} [props.textAlign] Type of text align (left / center / right)
   *     @param {string} [props.textDecoration] Type of line (underline / line-through / overline)
   * @returns {Object} applied properties
   */
  setObjectProperties(id, props) {
    const object = this.getObject(id);
    const clone = util.extend({}, props);

    object.set(clone);

    object.setCoords();

    this.getCanvas().renderAll();

    return clone;
  }

  /**
   * Get object properties corresponding key
   * @param {number} id - object id
   * @param {Array<string>|ObjectProps|string} keys - property's key
   * @returns {Object} properties
   */
  getObjectProperties(id, keys) {
    const object = this.getObject(id);
    const props = {};

    if (util.isString(keys)) {
      props[keys] = object[keys];
    } else if (util.isArray(keys)) {
      util.forEachArray(keys, (value) => {
        props[value] = object[value];
      });
    } else {
      util.forEachOwnProperties(keys, (value, key) => {
        props[key] = object[key];
      });
    }

    return props;
  }

  /**
   * Get object position by originX, originY
   * @param {number} id - object id
   * @param {string} originX - can be 'left', 'center', 'right'
   * @param {string} originY - can be 'top', 'center', 'bottom'
   * @returns {Object} {{x:number, y: number}} position by origin if id is valid, or null
   */
  getObjectPosition(id, originX, originY) {
    const targetObj = this.getObject(id);
    if (!targetObj) {
      return null;
    }

    return targetObj.getPointByOrigin(originX, originY);
  }

  /**
   * Set object position  by originX, originY
   * @param {number} id - object id
   * @param {Object} posInfo - position object
   *  @param {number} posInfo.x - x position
   *  @param {number} posInfo.y - y position
   *  @param {string} posInfo.originX - can be 'left', 'center', 'right'
   *  @param {string} posInfo.originY - can be 'top', 'center', 'bottom'
   * @returns {boolean} true if target id is valid or false
   */
  setObjectPosition(id, posInfo) {
    const targetObj = this.getObject(id);
    const { x, y, originX, originY } = posInfo;
    if (!targetObj) {
      return false;
    }

    const targetOrigin = targetObj.getPointByOrigin(originX, originY);
    const centerOrigin = targetObj.getPointByOrigin('center', 'center');
    const diffX = centerOrigin.x - targetOrigin.x;
    const diffY = centerOrigin.y - targetOrigin.y;

    targetObj.set({
      left: x + diffX,
      top: y + diffY,
    });

    targetObj.setCoords();

    return true;
  }

  /**
   * Get the canvas size
   * @returns {Object} {{width: number, height: number}} image size
   */
  getCanvasSize() {
    const image = this.getCanvasImage();

    return {
      width: image ? image.width : 0,
      height: image ? image.height : 0,
    };
  }

  adjustCanvasViewport() {
    // 画布范围
    const { width, height } = this.getCanvasSize();
    // canvas transform
    const [zoom, , , , x, y] = this._canvas.viewportTransform;
    // 图片范围
    const [imageWidth, imageHeight] = [zoom * width, zoom * height];
    // 原点范围
    const [pointWidth, pointHeight] = [imageWidth - width, imageHeight - height];
    // 校正原点，图片范围不能超出画布范围
    const pointer = new fabric.Point(
      x > 0 ? 0 : -x > pointWidth ? pointWidth : -x,
      y > 0 ? 0 : -y > pointHeight ? pointHeight : -y
    );
    this._canvas.absolutePan(pointer);
  }

  /**
   * Get a DrawingMode instance
   * @param {string} modeName - DrawingMode Class Name
   * @returns {DrawingMode} DrawingMode instance
   * @private
   */
  _getDrawingModeInstance(modeName) {
    return this._drawingModeMap[modeName];
  }

  /**
   * Set object caching to false. This brought many bugs when draw Shape & cropzone
   * @private
   */
  _setObjectCachingToFalse() {
    fabric.Object.prototype.objectCaching = false;
  }

  /**
   * Set canvas element to fabric.Canvas
   * @param {Element|string} element - Wrapper or canvas element or selector
   * @private
   */
  _setCanvasElement(element) {
    let selectedElement = element;
    let canvasElement = document.createElement('canvas');

    selectedElement.appendChild(canvasElement);

    this._canvas = new fabric.Canvas(canvasElement, {
      containerClass: this.containerClass,
      enableRetinaScaling: false,
      selection: false,
      targetFindTolerance: 5,
      allowTouchScrolling: false,
      stopContextMenu: true,
    });

    this._canvas.wrapperEl.id = 'canvas-container';

    window._canvas = this._canvas;
  }

  /**
   * Creates DrawingMode instances
   * @private
   */
  _createDrawingModeInstances() {
    this._register(this._drawingModeMap, new NormalMode());
    this._register(this._drawingModeMap, new FreeDrawingMode());
    this._register(this._drawingModeMap, new TextDrawingMode());
    this._register(this._drawingModeMap, new DeletionMode());
    this._register(this._drawingModeMap, new IconMode());
    this._register(this._drawingModeMap, new PathMode());
  }

  /**
   * Create components
   * @private
   */
  _createComponents() {
    this._register(this._componentMap, new Normal(this));
    this._register(this._componentMap, new ImageLoader(this));
    this._register(this._componentMap, new FreeDrawing(this));
    this._register(this._componentMap, new Text(this));
    this._register(this._componentMap, new Rotation(this));
    this._register(this._componentMap, new Icon(this));
    this._register(this._componentMap, new Path(this));
    this._register(this._componentMap, new Deletion(this));
  }

  /**
   * Register component
   * @param {Object} map - map object
   * @param {Object} module - module which has getName method
   * @private
   */
  _register(map, module) {
    map[module.getName()] = module;
  }

  /**
   * Get the current drawing mode is same with given mode
   * @param {string} mode drawing mode
   * @returns {boolean} true if same or false
   */
  _isSameDrawingMode(mode) {
    return this.getDrawingMode() === mode;
  }

  /**
   * Calculate max dimension of canvas
   * The css-max dimension is dynamically decided with maintaining image ratio
   * The css-max dimension is lower than canvas dimension (attribute of canvas, not css)
   * @param {number} width - Canvas width
   * @param {number} height - Canvas height
   * @returns {{width: number, height: number}} - Max width & Max height
   * @private
   */
  _calcMaxDimension(width, height) {
    const wScaleFactor = this.cssMaxWidth / width;
    const hScaleFactor = this.cssMaxHeight / height;
    let cssMaxWidth = Math.min(width, this.cssMaxWidth);
    let cssMaxHeight = Math.min(height, this.cssMaxHeight);

    if (wScaleFactor < 1 && wScaleFactor < hScaleFactor) {
      cssMaxWidth = width * wScaleFactor;
      cssMaxHeight = height * wScaleFactor;
    } else if (hScaleFactor < 1 && hScaleFactor < wScaleFactor) {
      cssMaxWidth = width * hScaleFactor;
      cssMaxHeight = height * hScaleFactor;
    }

    return {
      width: Math.floor(cssMaxWidth),
      height: Math.floor(cssMaxHeight),
    };
  }

  /**
   * Attach canvas's events
   */
  _attachCanvasEvents() {
    const canvas = this._canvas;
    const handler = this._handler;
    canvas.on({
      'mouse:down': handler.onMouseDown,
      'mouse:move': handler.onMouseMove,
      'mouse:up': handler.onMouseUp,
      'object:added': handler.onObjectAdded,
      'object:removed': handler.onObjectRemoved,
      'object:moving': handler.onObjectMoved,
      'object:scaling': handler.onObjectScaled,
      'object:rotating': handler.onObjectRotated,
      'object:selected': handler.onObjectSelected,
      'path:created': handler.onPathCreated,
      'selection:cleared': handler.onSelectionCleared,
      'selection:created': handler.onSelectionCreated,
      'selection:updated': handler.onObjectSelected,
    });
  }

  /**
   * "mouse:down" canvas event handler
   */
  _onMouseDown(fEvent) {
    const originPointer = this._canvas.getPointer(fEvent.e);

    if (
      this._drawingMode !== drawingModes.FREE_DRAWING &&
      this._drawingMode !== drawingModes.DELETION &&
      !fEvent.target
    ) {
      this._isMouseDown = true;
      this._originPointer = originPointer;
    }

    this.fire(events.MOUSE_DOWN, fEvent, originPointer);
  }

  /**
   * "mouse:move" canvas event handler
   */
  _onMouseMove(fEvent) {
    const originPointer = this._canvas.getPointer(fEvent.e);

    if (this._isMouseDown) {
      const pointer = new fabric.Point(
        originPointer.x - this._originPointer.x,
        originPointer.y - this._originPointer.y
      );
      // console.log("_onMouseMove -> pointer", pointer)
      this.relativePan(pointer);
      this.adjustCanvasViewport();
    }

    this.fire(events.MOUSE_MOVE, fEvent, originPointer);
  }

  /**
   * "mouse:up" canvas event handler
   */
  _onMouseUp(fEvent) {
    const originPointer = this._canvas.getPointer(fEvent.e);

    if (this._isMouseDown) {
      this._isMouseDown = false;
      this._originPointer = null;
    }

    this.fire(events.MOUSE_UP, fEvent, originPointer);
  }

  restoreZoom() {
    const canvas = this.getCanvas();
    var zoom = 1;
    canvas.zoomToPoint({ x: 0, y: 0 }, zoom);
    this.adjustCanvasViewport();
    // this.fire(events.ZOOM, zoom);
  }

  /**
   * "object:added" canvas event handler
   * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
   * @private
   */
  _onObjectAdded(fEvent) {
    const obj = fEvent.target;
    if (obj.isType('cropzone')) {
      return;
    }

    this._addFabricObject(obj);
  }

  /**
   * "object:removed" canvas event handler
   * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
   * @private
   */
  _onObjectRemoved(fEvent) {
    const obj = fEvent.target;
    this._removeFabricObject(util.stamp(obj));
  }

  /**
   * "object:moving" canvas event handler
   * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
   * @private
   */
  _onObjectMoved(fEvent) {
    this._lazyFire(events.OBJECT_MOVED, (object) => this.createObjectProperties(object), fEvent.target);
  }

  /**
   * "object:scaling" canvas event handler
   * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
   * @private
   */
  _onObjectScaled(fEvent) {
    this._lazyFire(events.OBJECT_SCALED, (object) => this.createObjectProperties(object), fEvent.target);
  }

  /**
   * "object:rotating" canvas event handler
   * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
   * @private
   */
  _onObjectRotated(fEvent) {
    this._lazyFire(events.OBJECT_ROTATED, (object) => this.createObjectProperties(object), fEvent.target);
  }

  /**
   * Lazy event emitter
   * @param {string} eventName - event name
   * @param {Function} paramsMaker - make param function
   * @param {Object} [target] - Object of the event owner.
   * @private
   */
  _lazyFire(eventName, paramsMaker, target) {
    const existEventDelegation = target && target.canvasEventDelegation;
    const delegationState = existEventDelegation ? target.canvasEventDelegation(eventName) : 'none';

    if (delegationState === 'unregisted') {
      target.canvasEventRegister(eventName, (object) => {
        this.fire(eventName, paramsMaker(object));
      });
    }

    if (delegationState === 'none') {
      this.fire(eventName, paramsMaker(target));
    }
  }

  /**
   * "object:selected" canvas event handler
   * @param {{target: fabric.Object, e: MouseEvent}} fEvent - Fabric event
   * @private
   */
  _onObjectSelected(fEvent) {
    const { target } = fEvent;
    const params = this.createObjectProperties(target);

    this.fire(events.OBJECT_ACTIVATED, params);
  }

  /**
   * "path:created" canvas event handler
   */
  _onPathCreated(obj) {
    const { x: left, y: top } = obj.path.getCenterPoint();
    obj.path.set(
      util.extend(
        {
          left,
          top,
          // selectable: false,
          // evented: false,
          perPixelTargetFind: true,
        },
        consts.fObjectOptions.SELECTION_STYLE
      )
    );

    const params = this.createObjectProperties(obj.path);

    this.fire(events.ADD_OBJECT, params);
  }

  /**
   * "selction:cleared" canvas event handler
   */
  _onSelectionCleared() {
    this.fire(events.SELECTION_CLEARED);
  }

  /**
   * "selction:created" canvas event handler
   */
  _onSelectionCreated(fEvent) {
    this.fire(events.SELECTION_CREATED, fEvent.target);
  }

  /**
   * Canvas discard selection all
   */
  discardSelection() {
    this._canvas.discardActiveObject();
    this._canvas.renderAll();
  }

  /**
   * Canvas Selectable status change
   * @param {boolean} selectable - expect status
   */
  changeSelectableAll(selectable) {
    this._canvas.forEachObject((obj) => {
      obj.selectable = selectable;
      obj.hoverCursor = selectable ? 'move' : 'crosshair';
    });
  }

  /**
   * Return object's properties
   * @param {fabric.Object} obj - fabric object
   * @returns {Object} properties object
   */
  createObjectProperties(obj) {
    const predefinedKeys = ['left', 'top', 'width', 'height', 'fill', 'stroke', 'strokeWidth', 'opacity', 'angle'];
    const props = {
      id: util.stamp(obj),
      type: obj.type,
    };

    util.extend(props, util.getProperties(obj, predefinedKeys));

    if (['i-text', 'text'].indexOf(obj.type) > -1) {
      util.extend(props, this._createTextProperties(obj, props));
    }

    return props;
  }

  /**
   * Get text object's properties
   * @param {fabric.Object} obj - fabric text object
   * @param {Object} props - properties
   * @returns {Object} properties object
   */
  _createTextProperties(obj) {
    const predefinedKeys = ['text', 'fontFamily', 'fontSize', 'fontStyle', 'textAlign', 'textDecoration', 'fontWeight'];
    const props = {};
    util.extend(props, util.getProperties(obj, predefinedKeys));

    return props;
  }

  /**
   * Add object array by id
   * @param {fabric.Object} obj - fabric object
   * @returns {number} object id
   */
  _addFabricObject(obj) {
    const id = util.stamp(obj);
    this._objects[id] = obj;

    return id;
  }

  /**
   * Remove an object in array yb id
   * @param {number} id - object id
   */
  _removeFabricObject(id) {
    delete this._objects[id];
  }

  relativePan(pointer) {
    this._canvas.relativePan(pointer);
  }

  absolutePan(pointer) {
    this._canvas.absolutePan(pointer);
  }

  /**
   * 画线过程中途取消
   */
  cancelCurrentlyDrawing() {
    const canvas = this._canvas;
    const freeDrawingBrush = canvas.freeDrawingBrush;
    const ctx = canvas.contextTop;
    canvas._isCurrentlyDrawing = false;
    freeDrawingBrush._reset();
    freeDrawingBrush.oldEnd = undefined;
    ctx.closePath();
    canvas.clearContext(canvas.contextTop);
    canvas.requestRenderAll();
    freeDrawingBrush._resetShadow();
  }
}

CustomEvents.mixin(Graphics);
export default Graphics;
