import React from 'react'
import CountdownModal from './CountdownModal'
import Progress from 'Components/Progress'
import Question from 'Components/Question'
import BgDiv from 'Components/BgDiv'
import Scroll from 'Components/Scroll'
import { IMAGE_URL, TABLE_STATE } from 'Constants'
import { parseUrl, buildUrl, getQuestionTypeString } from 'src/Utils'
import {
  apiGetTableInfo,
  apiStart,
  apiSubmitAnswer,
  apiSyncTableInfo,
  apiGetUserMatchLog,
} from 'src/Api'
import _ from 'lodash'

import './InAnswer.less'

const PREFIX_CLS = 'in-answer'

class InAnswer extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showCountdownModal: false,
      duration: 10,
      elapse: 10,
      questionList: [],
      currentQuestionId: 0,
      currentAnswer: '',
    }

    this.timer = null
    this.urlParam = parseUrl()
    this.matchId = this.urlParam.get('matchId', 'Number') || 1
  }

  componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
    this.stopTimer()
  }

  init = async () => {
    try {
      this.tableId = await apiGetUserMatchLog(this.matchId)
      if (this.tableId) {
        this.restoreGame()
      } else {
        this.newGame()
      }
    } catch (err) {
      console.log('eeeeeeeeeeeeeee', err.message)
    }
  }

  restoreGame = async () => {
    try {
      const info = await apiGetTableInfo(this.tableId)
      const questionList = _.get(info, 'questionList') || []
      const currentQuestionId = _.get(info, 'baseInfo.currentQuestionId') || 0
      const currentQuestion = _.find(questionList, v => v.id === currentQuestionId)

      if (!currentQuestion) {
        return
      }

      this.setState({
        questionList: questionList,
        currentQuestionId: currentQuestionId,
        duration: currentQuestion.maxTime,
        elapse: currentQuestion.maxTime,
      }, () => {
        this.startTimer()
      })
    } catch (err) {
      console.log('eeeeeeeeeeeeeee', err.message)
    }
  }

  newGame = async () => {
    try {
      const info = await apiStart(this.matchId)

      this.tableId = _.get(info, 'baseInfo.id') || ''

      const questionList = _.get(info, 'questionList') || []
      const currentQuestionId = _.get(info, 'baseInfo.currentQuestionId') || 0
      const currentQuestion = _.find(questionList, v => v.id === currentQuestionId)

      if (!currentQuestion) {
        return
      }

      this.setState({
        questionList: questionList,
        currentQuestionId: currentQuestionId,
        duration: currentQuestion.maxTime,
        elapse: currentQuestion.maxTime,
        showCountdownModal: true,
      })
    } catch (err) {
      console.log('eeeeeeeeeeeeeee', err.message)
    }
  }

  startTimer = () => {
    if (this.timer) {
      this.stopTimer()
    }

    this.timer = setInterval(() => {
      // 同步服务器进度
      apiSyncTableInfo(this.tableId).then(info => {
        if (info.tableState === TABLE_STATE.over) {
          this.gotoResultPage()
          return
        }

        const currentQuestion = _.find(this.state.questionList, v => v.id === info.currentQuestionId)
        if (!currentQuestion) {
          return
        }

        this.setState({
          duration: currentQuestion.maxTime,
          elapse: info.remainSeconds,
          currentQuestionId: info.currentQuestionId,
          currentAnswer: info.currentQuestionId === this.state.currentQuestionId
            ? this.state.currentAnswer
            : '',
        })
      })
    }, 1000)
  }

  stopTimer = () => {
    clearInterval(this.timer)
    this.timer = null
  }

  submitAnswer = async () => {
    const { currentAnswer, currentQuestionId, questionList } = this.state

    if (questionList.length === 0) {
      return
    }

    // 提交答案
    try {
      await apiSubmitAnswer({
        matchTableID: this.tableId,
        questionId: currentQuestionId,
        submitAnswer: currentAnswer,
      })
    } catch (err) {
      console.log('eeeeeeeeeeeeeee', err.message)
    }

    // 如果是最后一题，跳转到结果页
    const index = this.getQuestionIndex()
    if (index === questionList.length - 1) {
      this.gotoResultPage()
      return
    }
  }

  getQuestionIndex = () => {
    const { currentQuestionId, questionList } = this.state
    const idx = _.findIndex(questionList, v => v.id === currentQuestionId)
    return idx
  }

  getCurrentQuestion = () => {
    const { currentQuestionId, questionList } = this.state

    const currentQuestion = _.find(questionList, v => v.id === currentQuestionId)
    return currentQuestion
  }

  handleModalCountdownEnd = () => {
    this.setState({ showCountdownModal: false })
    this.startTimer()
  }

  handleOptionClick = optionValue => {
    this.setState({ currentAnswer: optionValue })
  }

  gotoResultPage = () => {
    const url = buildUrl('/answer-report', {
      token: this.urlParam.token,
      matchId: this.urlParam.matchId,
      tableId: this.tableId,
    })
    this.props.history.replace(url)
  }

  render() {
    const { elapse, duration, currentAnswer, showCountdownModal, questionList } = this.state

    const currentQuestion = this.getCurrentQuestion()
    const currentQuestionIndex = this.getQuestionIndex()

    return (
      <BgDiv
        className={PREFIX_CLS}
        style={{ height: '100%' }}
        src={require('Images/bg-mudan.png').default}
      >
        <div className={`${PREFIX_CLS}-title`}>
          {currentQuestionIndex + 1}/{questionList.length}
        </div>

        <Progress
          className={`${PREFIX_CLS}-progress`}
          percent={Math.floor(elapse / duration * 100)}
          preText={getQuestionTypeString(_.get(currentQuestion, 'type', 0))}
          text={`${elapse}秒`}
        />

        <Scroll className={`${PREFIX_CLS}-main`}>
          <Question
            data={currentQuestion}
            activeOption={currentAnswer}
            onOptionClick={this.handleOptionClick}
          />
        </Scroll>

        <div className={`${PREFIX_CLS}-footer`}>
          <BgDiv
            src={IMAGE_URL.BG_BTN}
            className={`${PREFIX_CLS}-btn`}
            onClick={this.submitAnswer}
          >
            确定
          </BgDiv>
        </div>

        {showCountdownModal &&
          <CountdownModal
            visible={showCountdownModal}
            onEnd={this.handleModalCountdownEnd}
          />
        }
      </BgDiv>
    )
  }
}

export default InAnswer
