import Component from '../interface/component';
import consts from '../lib/consts';

const events = consts.eventNames;

/**
 * Deletion
 * @class Deletion
 * @param {Graphics} graphics - Graphics instance
 * @extends {Component}
 * @ignore
 */
class Deletion extends Component {
  constructor(graphics) {
    super(consts.componentNames.DELETION, graphics);

    /**
     * Selected objects
     * @type {Object}
     */
    this._selectedObjects = [];

    /**
     * Listeners for fabric event
     * @type {Object}
     */
    this._listeners = {
      mousedown: this._onFabricMouseDown.bind(this),
      mousemove: this._onFabricMouseMove.bind(this),
      mouseup: this._onFabricMouseUp.bind(this),
    };
  }

  /**
   * Start delection mode
   */
  start() {
    const canvas = this.getCanvas();

    // 取消画布选择对象
    canvas.discardActiveObject().renderAll();

    canvas.forEachObject(obj => {
      obj.set({
        selectable: false,
        hoverCursor: 'default',
      });
    });

    canvas.on({
      'mouse:down': this._listeners.mousedown,
      'mouse:move': this._listeners.mousemove,
      'mouse:up': this._listeners.mouseup,
    });
    canvas.renderAll();
  }

  /**
   * End delection mode
   */
  end() {
    const canvas = this.getCanvas();

    canvas.forEachObject(obj => {
      obj.set({
        selectable: true,
        hoverCursor: null,
      });
    });

    canvas.off({
      'mouse:down': this._listeners.mousedown,
      'mouse:move': this._listeners.mousemove,
      'mouse:up': this._listeners.mouseup,
    });
  }

  /**
   * Fabric 'mousedown' event handler
   */
  _onFabricMouseDown(fEvent) {
    this._isMouseDown = true;
    this._pushEventObj(fEvent);
  }

  /**
   * Fabric 'mousedown' event handler
   */
  _onFabricMouseMove(fEvent) {
    if (this._isMouseDown) {
      this._pushEventObj(fEvent);
    }
  }

  /**
   * Fabric mouseup event handler
   */
  _onFabricMouseUp(fEvent) {
    if (this._isMouseDown) {
      this._isMouseDown = false;
      this._pushEventObj(fEvent);
      this._fireRemoveObject();
    }
  }

  _pushEventObj(fEvent) {
    const obj = fEvent.target;
    if (obj && !this._selectedObjects.find(o => o.__fe_id === obj.__fe_id)) {
      this._selectedObjects.push(obj);
    }
  }

  _fireRemoveObject() {
    if (this._selectedObjects.length > 0) {
      this.fire(events.REMOVE_OBJECT, this._selectedObjects);
      this._selectedObjects = [];
    }
  }
}

export default Deletion;
