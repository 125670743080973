function hasOwnProperty(obj, prop) {
  return Object.prototype.hasOwnProperty.call(obj, prop);
}

export function parseUrl(href = window.location.href) {
  const url = decodeURIComponent(href)

  const obj = {}

  const searchIndex = url.indexOf('?')
  const search = url.substring(searchIndex + 1)
  const searchList = search.split('&')

  for (let i = 0; i < searchList.length; ++i) {
    const idx = searchList[i].indexOf('=')
    const k = searchList[i].substring(0, idx)
    const v = searchList[i].substring(idx + 1)

    if (!hasOwnProperty(obj, k)) {
      obj[k] = v
    } else if (Array.isArray(obj[k])) {
      obj[k].push(v)
    } else {
      obj[k] = [obj[k], v];
    }
  }

  function getParam(key, type = 'String') {
    let value = this[key]

    if (type === 'String') {
      return value
    }

    if (type === 'Number') {
      value = parseInt(value, 10)
      return value
    }

    return value
  }

  Object.defineProperty(obj, 'get', {
    value: getParam,
  })

  return obj
}

export function buildUrl(base, args) {
  let url = base

  if (!args || Object.prototype.toString.call(args) !== '[object Object]')  {
    return url
  }

  const keys = Object.keys(args)
  for (let i = 0; i < keys.length; ++i) {
    const key = keys[i]

    if (url.indexOf('?') === -1) {
      url += '?'
    } else {
      url += '&'
    }

    url += `${key}=${args[key]}`
  }

  return url
}

export function dealScorePrecision(score, percision = 1) {
  let s = Number(score)
  if (isNaN(s) || percision < 1) {
    return score
  }

  const base = Math.pow(10, percision)
  return Math.round(s * base) / base
}

export function getQuestionTypeString(type) {
  const types = {
    '1': '单选题',
    '2': '判断题',
    '3': '填空题',
  }

  return types[type] || ''
}
