import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view';
import { Platform } from '../util';

import './style/index.less';

// const [TOUCH_IDLE, TOUCH_BEGIN, TOUCH_ACCEPT, TOUCH_DENY] = [0, 1, 2, 3];

export default class ScrollView extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        orientation: PropTypes.oneOf(['horizontal', 'vertical', 'both']),
        onScroll: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-scrollview',
        orientation: 'vertical',
    };

    trackClickTimestamp = 0;
    trackClickStartPoint = {x: 0, y: 0};

    // touchType = TOUCH_IDLE;

    isMobile = Platform.isMobile();
    isAndroid = Platform.isAndroid();
    isIPhone = Platform.isIPhone();

    isHorizontal = () => this.props.orientation === 'horizontal' || this.props.orientation === 'both'
    isVertical = () => this.props.orientation === 'vertical' || this.props.orientation === 'both'
    isBoth = () => this.props.orientation === 'both'

    optionPassive = () => this.isAndroid ? {passive: true} : false;

    componentDidMount() {
        if (this.scroller) {
            this.scroller.addEventListener('scroll', this.onScroll);
            this.scroller.addEventListener('touchstart', this.onTouchStart, this.optionPassive());
            if (!this.isMobile) {
                this.scroller.addEventListener('mousedown', this.onTouchStart, this.optionPassive());
                this.scroller.addEventListener('wheel', this.onWheel);
                this.scroller.addEventListener('click', this.onClick, true);
            }
        }
    }

    componentWillUnmount() {
        if (this.scroller) {
            this.scroller.removeEventListener('scroll', this.handleScroll);

            this.scroller.removeEventListener('touchstart', this.onTouchStart);
            if (this.shouldClearEventListener) {
                this.scroller.removeEventListener('touchmove', this.onTouchMove);
                this.scroller.removeEventListener('touchend', this.onTouchEnd);
                this.scroller.removeEventListener('touchcancel', this.onTouchEnd);
                if (!this.isMobile) {
                    this.scroller.removeEventListener('mousemove', this.onTouchMove);
                    this.scroller.removeEventListener('mouseup', this.onTouchEnd);
                    this.scroller.removeEventListener('mouseleave', this.onTouchEnd);
                }

                this.shouldClearEventListener = false;
            }

            if (!this.isMobile) {
                this.scroller.removeEventListener('mousedown', this.onTouchStart);
                this.scroller.removeEventListener('wheel', this.onWheel);
                this.scroller.removeEventListener('click', this.onClick);
            }
        }
    }

    metrics = () => {
        return {
            viewSize: {
                width: this.scroller.offsetWidth,
                height: this.scroller.offsetHeight,
            },
            contentSize: {
                width: this.scroller.scrollWidth,
                height: this.scroller.scrollHeight,
            },
            offset: {
                x: this.scroller.scrollLeft,
                y: this.scroller.scrollTop,
            },
            // distance to bottom end
            distance: {
                x: this.scroller.scrollWidth - this.scroller.offsetWidth - this.scroller.scrollLeft,
                y: this.scroller.scrollHeight - this.scroller.offsetHeight - this.scroller.scrollTop,
            }
        };
    }

    scrollTo = (position, duration) => {
        if (position === undefined || (position.x === undefined && position.y === undefined) || !this.scroller || this.dragging) {
            return;
        }

        if (position.x === this.scroller.scrollLeft && position.y === this.scroller.scrollTop) {
            return;
        }

        if (position.x === undefined) position.x = 0;
        if (position.y === undefined) position.y = 0;
        if (!duration) duration = 300;

        let timeElapse = 0;
        const offset = {
            deltaX: this.scroller.scrollLeft - position.x, 
            deltaY: this.scroller.scrollTop - position.y
        };

        let lastTimestamp = null;
        const scroll = timestamp => {
            if (this.scroller == null) {
              return;
            }
            if (!lastTimestamp) lastTimestamp = timestamp;
            const deltaTime = timestamp - lastTimestamp;
            timeElapse += deltaTime;
            lastTimestamp = timestamp;
            
            const percent = duration === 0 ? 1 : deltaTime / duration;
            if (this.isHorizontal() && offset.deltaX !== 0) {
                this.scroller.scrollLeft -= offset.deltaX * percent;
            }
            if (this.isVertical() && offset.deltaY !== 0) {
                this.scroller.scrollTop -= offset.deltaY * percent;
            }
            
            if (timeElapse >= duration) {
                return;
            }

            requestAnimationFrame(scroll);
        }

        requestAnimationFrame(scroll);
    }

    onScroll = e => {
        const { onScroll } = this.props;

        onScroll && onScroll(e);
    }

    onClick = e => {
        if (this.isMobile) {
            return;
        }
        
        const timeElapse = e.timeStamp - this.trackClickTimestamp;
        const clickPoint = {
            x: e.clientX !== undefined ? e.clientX : e.changedTouches[0].clientX,
            y: e.clientY !== undefined ? e.clientY : e.changedTouches[0].clientY
        };
        const distance = Math.max(Math.abs(clickPoint.x - this.trackClickStartPoint.x), Math.abs(clickPoint.y - this.trackClickStartPoint.y));
        if (timeElapse > 300 || distance > 10) {
            e.stopPropagation();
        }
    }

    onWheel = e => {
        if (!this.scroller) {
            return;
        }

        if (this.isHorizontal()) {
            this.scroller.scrollLeft += e.deltaX;
        }

        if (this.isVertical()) {
            this.scroller.scrollTop += e.deltaY;
        }
    }

    onTouchStart = e => {
        if (!this.scroller) {
            return;
        }
        
        if (!this.isMobile) {
            this.trackClickTimestamp = e.timeStamp;
            this.trackClickStartPoint = {
                x: e.clientX,
                y: e.clientY,
            };
        }

        this.velocity = {x: 0, y: 0};
        this.lastTouch = {
            x: e.clientX !== undefined ? e.clientX : e.changedTouches[0].clientX,
            y: e.clientY !== undefined ? e.clientY : e.changedTouches[0].clientY
        };
        this.lastTouchTime = Date.now();
        this.stopInertiaMove = true;
        this.dragging = true;
        // this.touchType = TOUCH_BEGIN;

        this.scroller.addEventListener('touchmove', this.onTouchMove, this.optionPassive());
        this.scroller.addEventListener('touchend', this.onTouchEnd);
        this.scroller.addEventListener('touchcancel', this.onTouchEnd);
        if (!this.isMobile) {
            this.scroller.addEventListener('mousemove', this.onTouchMove, this.optionPassive());
            this.scroller.addEventListener('mouseup', this.onTouchEnd);
            this.scroller.addEventListener('mouseleave', this.onTouchEnd);
        }
        this.shouldClearEventListener = true;
    }

    onTouchMove = e => {
        if (this.dragging && this.scroller) {

            let touch =  {
                x: e.clientX !== undefined ? e.clientX : e.changedTouches[0].clientX,
                y: e.clientY !== undefined ? e.clientY : e.changedTouches[0].clientY
            };
            let offset = {
                x: touch.x - this.lastTouch.x,
                y: touch.y - this.lastTouch.y,
            };

            if (this.isAndroid || (this.isIPhone && this.isVertical()) || (this.isIPhone && this.isBoth())) {
                // e.preventDefault();
                return;
            }

            if (this.isHorizontal()) {
                this.scroller.scrollLeft -= offset.x;
            }

            if (this.isVertical()) {
                this.scroller.scrollTop -= offset.y;
            }

            let touchTime = Date.now();
            let duration = touchTime - this.lastTouchTime;

            this.velocity = {
                x: duration > 0 ? offset.x / duration : 0,
                y: duration > 0 ? offset.y / duration : 0,
            };

            this.lastTouch = touch;
            this.lastTouchTime = touchTime;
            this.stopInertiaMove = true;
        }
    }

    onTouchEnd = e => {
        if (!this.scroller) {
            return;
        }

        if (this.shouldClearEventListener) {
            this.scroller.removeEventListener('touchmove', this.onTouchMove);
            this.scroller.removeEventListener('touchend', this.onTouchEnd);
            this.scroller.removeEventListener('touchcancel', this.onTouchEnd);
            if (!this.isMobile) {
                this.scroller.removeEventListener('mousemove', this.onTouchMove);
                this.scroller.removeEventListener('mouseup', this.onTouchEnd);
                this.scroller.removeEventListener('mouseleave', this.onTouchEnd);
            }
            this.shouldClearEventListener = false;
        }

        if (!this.dragging) {
            return;
        }

        this.dragging = false;

        if (this.isAndroid || (this.isIPhone && this.isVertical()) || (this.isIPhone && this.isBoth())) {
            return;
        }

        let touch =  {
            x: e.clientX !== undefined ? e.clientX : e.changedTouches[0].clientX,
            y: e.clientY !== undefined ? e.clientY : e.changedTouches[0].clientY
        };
        let offset = {
            x: touch.x - this.lastTouch.x,
            y: touch.y - this.lastTouch.y,
        };
        
        if (this.isHorizontal()) {
            this.scroller.scrollLeft -= offset.x;
        }
        if (this.isVertical()) {
            this.scroller.scrollTop -= offset.y;
        }

        this.stopInertiaMove = false;

        if (offset.x !== 0 || offset.y !== 0) {
            let duration = Date.now() - this.lastTouchTime;
            this.velocity = {
                x: duration > 0 ? offset.x / duration : 0,
                y: duration > 0 ? offset.y / duration : 0
            };
        }
        
        offset = {
            x: this.scroller.scrollLeft,
            y: this.scroller.scrollTop,
        };
        const direction = {
            x: this.velocity.x > 0 ? 1 : -1,
            y: this.velocity.y > 0 ? 1 : -1,
        }

        const inertiaScroll = (offset, velocity, direction, vertical) => {
            if (this.stopInertiaMove) return;
            const deceleration = 0.005 * direction;
            const time = Date.now() - this.lastTouchTime;
            let v = velocity - time * deceleration;
            
            if (v * direction < 0 || !this.scroller) {
                return;
            }
    
            if (vertical) {
                this.scroller.scrollTop = offset - (velocity + v) / 2 * time;
            } else {
                this.scroller.scrollLeft = offset - (velocity + v) / 2 * time;
            }
            const metrics = this.metrics();
            const _distance = vertical ? metrics.distance.y : metrics.distance.x;
            const _offset = vertical ? metrics.offset.y : metrics.offset.x;
            if ((direction === -1 && _distance < 0.1) || (direction === 1 && _offset < 0.1)) return;
            setTimeout(() => inertiaScroll(offset, velocity, direction, vertical), 10);
        }

        if (this.isHorizontal()) {
            inertiaScroll(offset.x, this.velocity.x, direction.x, false);
        }

        if (this.isVertical()) {
            inertiaScroll(offset.y, this.velocity.y, direction.y, true);
        }
    }

    render() {
        const {
            prefixCls,
            className,
            style = {},
            children,
        } = this.props;
        const wrapCls = classnames({
            [`${prefixCls}-horizontal`]:this.isHorizontal(),
            [`${prefixCls}-vertical`]:this.isVertical(),
            [className]: !!className,
            [prefixCls]: true,
        });
        let wrapStyle = {
            ...style,
            // overflowX: this.isHorizontal() && this.isAndroid ? 'auto' : 'hidden',
            // overflowY: this.isVertical() && this.isMobile ? 'auto' : 'hidden',
        };

        if (this.isBoth() && this.isIPhone) {
            wrapStyle['overflow'] = 'auto';
        } else {
            wrapStyle['overflowX'] = this.isHorizontal() && this.isAndroid ? 'auto' : 'hidden';
            wrapStyle['overflowY'] = this.isVertical() && this.isMobile ? 'auto' : 'hidden';
        }

        const wrapContentCls = classnames(
        {
            [`${prefixCls}-content`]:true,
            [`${prefixCls}-horizontal`]:this.isHorizontal(),
            [`${prefixCls}-vertical`]:this.isVertical(),
        });
        return (
            <View 
                ref={el => this.scroller = ReactDOM.findDOMNode(el)}
                className={wrapCls} 
                style={wrapStyle}
            >
                <View className={wrapContentCls} >
                    {children}
                </View>
            </View>
        );
    }
}