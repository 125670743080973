import DrawingMode from '../interface/drawingMode';
import consts from '../lib/consts';

const { drawingModes } = consts;
const components = consts.componentNames;

/**
 * DeletionMode class
 * @class
 * @ignore
 */
class DeletionMode extends DrawingMode {
  constructor() {
    super(drawingModes.DELETION);
  }

  /**
   * start this deletion mode
   * @param {Graphics} graphics - Graphics instance
   * @override
   */
  start(graphics) {
    const deletion = graphics.getComponent(components.DELETION);
    deletion.start();
  }

  /**
   * stop this deletion mode
   * @param {Graphics} graphics - Graphics instance
   * @override
   */
  end(graphics) {
    const deletion = graphics.getComponent(components.DELETION);
    deletion.end();
  }
}

export default DeletionMode;
