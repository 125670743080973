import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Image from '../image';

import './style/index.less';

export default class CheckBox extends React.Component {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        name: PropTypes.string,
        defaultChecked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
        checked: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        value: PropTypes.any,
        checkedIcon: PropTypes.any,
        uncheckedIcon: PropTypes.any,
        disabledIcon: PropTypes.any,
    };

    static defaultProps = {
        prefixCls: 'tm-checkbox',
        className: '',
        style: {},
        defaultChecked: false,
        onChange: e => {},
    };

    constructor(props) {
        super(props);

        const checked = 'checked' in props ? props.checked : props.defaultChecked;

        this.state = {
            checked,
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ('checked' in nextProps) {
            this.setState({
                checked: nextProps.checked,
            });
        }
    }

    onChange = e => {
        const { props } = this;
        if (props.disabled) {
            return;
        }

        if (!('checked' in props)) {
            this.setState({
                checked: e.target.checked,
            });
        }
        props.onChange({
            target: {
                ...props,
                checked: e.target.checked,
            },
            stopPropagation() {
                e.stopPropagation();
            },
            preventDefault() {
                e.preventDefault();
            },
            nativeEvent: e.nativeEvent,
        });
    };

    onClick = e => {
        const { disabled, disabledIcon, onChange } = this.props;
        if (disabled && disabledIcon) {
            return;
        }

        let checked = this.state.checked;
        if (!('checked' in this.props)) {
            checked = !checked;
            this.setState({
                checked: checked,
            });
        }

        onChange({
            target: {
                ...this.props,
                checked: checked,
            },
            stopPropagation() {
                e.stopPropagation();
            },
            preventDefault() {
                e.preventDefault();
            },
            nativeEvent: e.nativeEvent,
        });
    }

    render() {
        const {
            prefixCls,
            className,
            style,
            name,
            type,
            disabled,
            value,
            checkedIcon,
            uncheckedIcon,
            disabledIcon,
            ...others
        } = this.props;

        if (checkedIcon && uncheckedIcon) {
            const wrapCls = classnames(prefixCls, className);
            const renderIcon = () => {
                if (disabled && disabledIcon) {
                    return <Image className={`${prefixCls}-image`} source={disabledIcon} />
                } else {
                    if (this.state.checked) {
                        return <Image className={`${prefixCls}-image`} source={checkedIcon} />
                    } else {
                        return <Image className={`${prefixCls}-image`} source={uncheckedIcon} />
                    }
                }
            };
            return (
                <label className={wrapCls} style={style} onClick={this.onClick} >
                    {renderIcon()}
                </label>
            );
        } else {
            const globalProps = Object.keys(others).reduce((prev, key) => {
                if (key.substr(0, 5) === 'aria-' || key.substr(0, 5) === 'data-' || key === 'role') {
                    prev[key] = others[key];
                }
                return prev;
            }, {});

            const { checked } = this.state;
            const classString = classnames(prefixCls, className, {
                [`${prefixCls}-checked`]: checked,
                [`${prefixCls}-disabled`]: disabled,
            });

            return (
                <label className={classString} style={style}>
                    <input
                        className={`${prefixCls}-input`}
                        name={name}
                        type='checkbox'
                        disabled={disabled}
                        checked={!!checked}
                        onChange={this.onChange}
                        value={value}
                        {...globalProps}
                    />
                    <span className={`${prefixCls}-inner`} />
                </label>
            );
        }
    }
}