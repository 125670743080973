import './style/index';

/* eslint-disable */
Date.prototype.format = function(fmt) {
    var o = {   
        "M+" : this.getMonth()+1,                 //月份   
        "d+" : this.getDate(),                    //日   
        "h+" : this.getHours(),                   //小时   
        "m+" : this.getMinutes(),                 //分   
        "s+" : this.getSeconds(),                 //秒   
        "q+" : Math.floor((this.getMonth()+3)/3), //季度   
        "S"  : this.getMilliseconds()             //毫秒 
    };

    if (/(y+)/.test(fmt)) {
        fmt = fmt.replace(RegExp.$1, (this.getFullYear() + "").substr(4 - RegExp.$1.length));
    }

    for (var k in o) {  
        if(new RegExp("("+ k +")").test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
        }
    }
    
    return fmt;
} 
/* eslint-disable */

export * from './util';

export { default as Accordion } from './accordion';
export { default as Audio } from './audio';
export { default as Badge } from './badge';
export { default as Button } from './button';
export { default as CheckBox } from './check-box';
export { default as ContextMenu } from './context-menu';
export { default as DatePicker } from './date-picker';
export { default as Dialog } from './dialog';
export { default as Dropdown } from './dropdown';
export { default as FileLoader } from './file-loader';
export { default as Gallery } from './gallery';
export { default as Hotspot } from './hotspot';
export { default as Icon } from './icon';
export { default as Image } from './image';
export { default as LineEdit } from './line-edit';
export { default as List } from './list';
export { default as ListView } from './list-view';
export { default as Modal } from './modal';
export { default as NavBar } from './nav-bar';
export { default as Pager } from './pager';
export { default as Picker } from './picker';
export { default as Progress } from './progress';
export { default as Popover } from './popover';
export { default as Recorder } from './recorder';
export { default as ScrollView } from './scroll-view';
export { default as Share } from './share';
export { default as Slider } from './slider';
export { default as Switch } from './switch';
export { default as Text } from './text';
export { default as TextEdit } from './text-edit';
export { default as TimePicker } from './time-picker';
export { default as TouchBoard } from './touch-board';
export { default as TouchFeedback } from './touch-feedback';
export { default as Toast } from './toast';
export { default as View } from './view';
export { default as Video } from './video';
export { default as WhiteSpace } from './white-space';
export { default as WingBlank } from './wing-blank';
export { default as Tabs } from './tabs';
export { default as PictureEditor } from './picture-editor';

export * from './indicator';
