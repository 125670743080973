import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Platform, Log } from '../../util';

import './index.less';

class Slider extends React.PureComponent {
  rail = null;
  track = null;
  handle = null;

  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
    };
  }

  onRailClick = e => {
    if (!this.rail) {
      return;
    }

    const { onChange, onAfterChange } = this.props;

    let value = this.calculateValue(e);
    if (null === value) return;

    onChange && onChange(value);
    onAfterChange && onAfterChange(value);
  };

  matchDOM = (node, target) => {
    let targetNode = target;
    while (targetNode) {
      if (targetNode === node) {
        return true;
      }

      targetNode = targetNode.parentNode;
    }
    return false;
  };

  onTouchBegin = e => {
    Log.verbose('onTouchBegin');
    if (!this.rail || !this.handle) {
      return;
    }

    if (this.matchDOM(this.handle, e.target)) {
      document.addEventListener(Platform.isMobile() ? 'touchmove' : 'mousemove', this.onTouchMove);
      document.addEventListener(Platform.isMobile() ? 'touchend' : 'mouseup', this.onTouchEnd);
      this.setState({
        dragging: true,
      });
    }
  };

  onTouchMove = e => {
    Log.verbose('onTouchMove');
    if (!this.state.dragging) {
      return;
    }

    const { onChange } = this.props;
    let value = this.calculateValue(e);
    if (null === value) return;
    onChange && onChange(value);
  };

  onTouchEnd = e => {
    Log.verbose('onTouchEnd');
    if (!this.state.dragging) {
      return;
    }

    document.removeEventListener(Platform.isMobile() ? 'touchmove' : 'mousemove', this.onTouchMove);
    document.removeEventListener(Platform.isMobile() ? 'touchend' : 'mouseup', this.onTouchEnd);

    this.setState({
      dragging: false,
    });

    const { onAfterChange, onChange } = this.props;
    let value = this.calculateValue(e);
    if (null === value) return;
    onChange && onChange(value);
    onAfterChange && onAfterChange(value);
  };

  calculateValue(e) {
    if (!this.rail) {
      return null;
    }

    const { min, max } = this.props;

    let rect = this.rail.getBoundingClientRect();
    let pos = rect.left;
    let currentPos = 0;
    if (e.type === 'touchstart') {
      currentPos = e.touches[0].clientX;
    } else if (e.type === 'touchmove' || e.type === 'touchend') {
      currentPos = e.changedTouches[0].clientX;
    } else {
      currentPos = e.clientX;
    }

    let offset = currentPos - pos;
    offset = offset > 0 ? offset : 0;

    let percent = Math.round((offset * 100) / this.rail.clientWidth) / 100;
    if (percent > 1) percent = 1;
    let value = (max - min) * percent + min;
    value = Math.min(Math.max(min, value), max);
    return value;
  }

  calculatePercentValue() {
    const { value, min, max } = this.props;

    let tmpVal = value - min;
    tmpVal = Math.min(Math.max(tmpVal, 0), max - min);
    let percent = (tmpVal * 100) / (max - min);
    return percent.toFixed(2);
  }

  render() {
    const {
      className,
      prefixCls,
      value,
      min,
      max,
      style = {},
      trackStyle = {},
      railStyle = {},
      handleStyle = {},
      handle,
      track,
      rail,
    } = this.props;

    const sliderCls = classnames(`${prefixCls}`, className);
    const trackCls = classnames(`${prefixCls}-track`);
    const railCls = classnames(`${prefixCls}-rail`);
    const handleCls = classnames(`${prefixCls}-handle`);
    const handlePointCls = classnames(`${prefixCls}-handle-point`);

    const percent = this.calculatePercentValue();
    const activeTrackStyle = {
      width: `${percent}%`,
    };
    const activeHandleStyle = {
      left: `calc(${percent}% - 8px)`,
    };

    const renderHandle = () => {
      if (handle) {
        const props = {
          ref: el => (this.handle = ReactDOM.findDOMNode(el)),
          style: { ...activeHandleStyle },
          onMouseDown: this.onTouchBegin,
          onTouchStart: this.onTouchBegin,
        };
        return React.cloneElement(handle, props);
      } else {
        return (
          <div
            ref={el => (this.handle = el)}
            className={handleCls}
            style={{ ...handleStyle, ...activeHandleStyle }}
            onMouseDown={this.onTouchBegin}
            onTouchStart={this.onTouchBegin}
          >
            <div className={handlePointCls}></div>
          </div>
        );
      }
    };

    const renderSlider = () => {
      if (rail && track) {
        const railProps = {
          ref: el => (this.rail = ReactDOM.findDOMNode(el)),
          onClick: e => this.onRailClick(e),
        };
        const trackProps = {
          style: { ...activeTrackStyle },
          key: 'track',
        };

        const trackElem = React.cloneElement(track, trackProps);

        return React.cloneElement(rail, railProps, [trackElem]);
      } else {
        return (
          <div ref={el => (this.rail = el)} className={railCls} style={railStyle} onClick={e => this.onRailClick(e)}>
            <div className={trackCls} style={{ ...trackStyle, ...activeTrackStyle }} />
          </div>
        );
      }
    };

    return (
      <div
        className={sliderCls}
        style={style}
        role="slider"
        aria-valuenow={value}
        aria-valuemin={min}
        aria-valuemax={max}
      >
        {renderSlider()}
        {renderHandle()}
      </div>
    );
  }
}

Slider.propTypes = {
  prefixCls: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.object,
  handleStyle: PropTypes.object,
  trackStyle: PropTypes.object,
  railStyle: PropTypes.object,
  handle: PropTypes.any,
  track: PropTypes.any,
  rail: PropTypes.any,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  onAfterChange: PropTypes.func,
};

Slider.defaultProps = {
  prefixCls: 'tm-picture-editor-slider',
  value: 0,
  min: 0,
  max: 100,
};

export default Slider;
