import React from 'react';
import Tooltip from 'rc-tooltip';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Item from './Item';
import View from '../view';

import './style/index.less';

function recursiveCloneChildren(children, cb = ch => ch) {
    return React.Children.map(children, (child, index) => {
        const newChild = cb(child, index);
        if (
            typeof newChild !== 'string' &&
            typeof newChild !== 'number' &&
            newChild &&
            newChild.props &&
            newChild.props.children
        ) {
            return React.cloneElement(
                newChild,
                {},
                recursiveCloneChildren(newChild.props.children, cb),
            );
        }
        return newChild;
    });
}

export default class Popover extends React.Component {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        visible: PropTypes.bool,
        disabled: PropTypes.bool,
        mask: PropTypes.bool,
        overlay: PropTypes.any,
        onSelect: PropTypes.func,
        onVisibleChange: PropTypes.func,
        placement: PropTypes.oneOf(['left', 'right', 'top', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight']),
      
    };

    static defaultProps = {
        prefixCls: 'tm-popover',
        placement: 'bottomRight',
        align: { overflow: { adjustY: 0, adjustX: 0 } },
        trigger: ['click'],
    };
    static Item = Item;
    
    onSelect = (item, index) => {
        const { onSelect, onVisibleChange } = this.props;
        onSelect && onSelect(item, index);
        onVisibleChange && onVisibleChange(false);
    }

    render() {
        const {
            className,
            overlay,
            ...restProps 
        } = this.props;

        const overlayNode = recursiveCloneChildren(overlay, (child, index) => {
            const extraProps = { firstItem: false };
            if (
                child &&
                typeof child !== 'string' &&
                typeof child !== 'number' &&
                child.type &&
                // Fixme: not sure where the `myName` came from.
                (child.type).myName === 'PopoverItem' &&
                !child.props.disabled
            ) {
                extraProps.onClick = () => this.onSelect(child, index);
                extraProps.firstItem = index === 0;
                return React.cloneElement(child, extraProps);
            }
            return child;
        });
        const wrapperNode = (
            <View className={classnames(`${this.props.prefixCls}-inner-wrapper`, className)}>
                {overlayNode}
            </View>
        );
        return <Tooltip {...restProps} overlay={wrapperNode} hideAction={['click']} action={['click']}/>;
    }
}
