const strings = {
    today: '今天',
    year: '年',
    month: '月',
    day: '日',
    hour: '时',
    minute: '分',
    second: '秒',
    week: '周',
    one: '一',
    two: '二',
    three: '三',
    four: '四',
    five: '五',
    six: '六',
    seven: '七',
    eight: '八',
    nine: '九',
    zero: '零',

    selectTime: '选择时间',

    beginDate: '开始时间',
    endDate: '结束时间',

    cancel: '取消',
    ok: '确定',
    done: '完成',

    record: '录音',

    load_finish: '加载完成',
    no_more_data: '没有更多数据',

    netaddr: '网址',
    bad_address: '请输入正确的网址',
    address_placeholder: '请输入网页地址',
    brief: '描述',
    address_brief_placeholder: '请在此输入该网页的描述',

    link_name: '超链接',
    link_brief_placeholder: '请输入超链接描述',
    link_url_placeholder: '请输入超链接地址',
    link_insert: '插入',
    link_set: '设置',

    error_unknown: '未知错误',

    // Share
    share: '分享到',
    share_wechat: '微信',
    share_timeline: '朋友圈',
    share_qq: 'QQ',
    share_qzone: 'QQ空间',
    share_weibo: '新浪微博',
    share_lechat: '乐信',
    share_moment: '乐信朋友圈',
    share_copylink: '复制链接',

    media_msg_abort: '获取媒体文件被中断',
    media_msg_network: '获取媒体文件错误，请检查网络链接',
    media_msg_decode: '媒体文件解码错误，可能系统当前不支持该编码格式',
    media_msg_transcode: '转码中，请稍侯...',
    media_msg_src_not_supported: '系统当前不支持该媒体文件',
    media_msg_unknown: '媒体文件损坏，暂时不能播放',

    image_editor_clip: '裁剪',
    image_editor_rotate: '旋转',
    image_editor_clip_confirm: '确认裁剪',
    image_editor_reset: '重置',

    image_load_error: '图片加载失败',
};

export default strings;