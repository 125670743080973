import React from 'react';
import _ from 'lodash';
import {_px2rem} from '../util'

export default class TabContent extends React.Component {
    getContent = ()=> {
        let tabs = this.props.tabs;
        let children = this.props.children;
        let len = tabs.length;
        let nIndex = -1;
        if(this.props.cache) {
            let contents = []
            for (nIndex = 0; nIndex < len; nIndex++){
                let style = {display:'none'}
                let key = tabs[nIndex].key
                if (key === this.props.activeKey){
                    style.display = 'block'
                }
                contents.push(<div key={key} style={style}>{children[nIndex]}</div>)
            }
            return contents
        } else {
            for (nIndex = 0; nIndex < len; nIndex++){
                if (tabs[nIndex].key === this.props.activeKey){
                    break;
                }
            };
            if ((nIndex >= 0) && (nIndex < len))
            { 
                let res = (React.cloneElement(children[nIndex], {
                    getTabContentRef: this.props.refParent.saveContentChildViewRef(tabs[nIndex].key),
                }));
                return res;
            }
            return <div/>;
        }
    }

    render() {
        const {
            prefixCls,
            options,
        } = this.props;    
        let contentStyle = {};
        if (options && options.contentHeight){
            if(typeof(options.contentHeight) === 'string' && _.endsWith(options.contentHeight,'px')) {
                contentStyle.height = options.contentHeight;
            } else {
                contentStyle.height=`${_px2rem(options.contentHeight)}rem`;
            }
        }
        return (
            <div  className={`${prefixCls}-container`}
                style={contentStyle}
            >
                {this.getContent()}
            </div>
        );
    }
}