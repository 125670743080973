import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import TouchFeedback from '../touch-feedback/index';
import Icon from '../icon/index';

import './style/index.less';

const rxTwoCNChar = /^[\u4e00-\u9fa5]{2}$/;
const isTwoCNChar = rxTwoCNChar.test.bind(rxTwoCNChar);
function isString(str) {
    return typeof str === 'string';
}

// Insert one space between two chinese characters automatically.
function insertSpace(child) {
    if (isString(child.type) && isTwoCNChar(child.props.children)) {
        return React.cloneElement(
            child,
            {},
            child.props.children.split('').join(' '),
        );
    }
    if (isString(child)) {
        if (isTwoCNChar(child)) {
            child = child.split('').join(' ');
        }
        return <span>{child}</span>;
    }
    return child;
}

export default class Button extends React.PureComponent {
    static propTypes = {
        type: PropTypes.oneOf(['primary', 'warning', 'ghost']),
        size: PropTypes.oneOf(['large', 'small']),
        disabled: PropTypes.bool,
        loading: PropTypes.bool,

        prefixCls: PropTypes.string,
        className: PropTypes.string,
        role: PropTypes.string,
        inline: PropTypes.bool,
        icon: PropTypes.string,
        activeClassName: PropTypes.string,
        activeStyle: PropTypes.any,
        style: PropTypes.object,
        onClick: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-button',
        size: 'large',
        inline: false,
        disabled: false,
        loading: false,
        activeStyle: {},
    };

    render() {
        const {
            children,
            className,
            prefixCls,
            type,
            size,
            inline,
            disabled,
            icon,
            loading,
            activeStyle,
            activeClassName,
            onClick,
            ...restProps
        } = this.props;

        const iconType = loading ? 'loading' : icon;
        const wrapCls = classnames(prefixCls, {
            [`${prefixCls}-primary`]: type === 'primary',
            [`${prefixCls}-ghost`]: type === 'ghost',
            [`${prefixCls}-warning`]: type === 'warning',
            [`${prefixCls}-small`]: size === 'small',
            [`${prefixCls}-inline`]: inline,
            [`${prefixCls}-disabled`]: disabled,
            [`${prefixCls}-icon`]: !!iconType,
        }, className);

        const kids = React.Children.map(children, insertSpace);

        let iconEl;
        if (typeof iconType === 'string') {
            iconEl = (
                <Icon
                    aria-hidden="true"
                    type={iconType}
                    size={size === 'small' ? 'xxs' : 'md'}
                    className={`${prefixCls}-icon`}
                />
            );
        } else if (iconType) {
            const rawCls = iconType.props && iconType.props.className;
            const cls = classnames(
                'tm-icon',
                `${prefixCls}-icon`,
                size === 'small' ? 'tm-icon-xxs' : 'tm-icon-md',
            );
            iconEl = React.cloneElement(iconType, {
                className: rawCls ? `${rawCls} ${cls}` : cls,
            });
        }
        // use div, button native is buggy @yiminghe
        return (
            <TouchFeedback
                // tslint:disable-next-line:jsx-no-multiline-js
                activeClassName={
                activeClassName || (activeStyle ? `${prefixCls}-active` : undefined)}
                disabled={disabled}
                activeStyle={activeStyle}
            >
                <a
                    role="button"
                    className={wrapCls}
                    {...restProps}
                    onClick={disabled ? undefined : onClick}
                    aria-disabled={disabled}
                >
                    {iconEl}
                    {kids}
                </a>
            </TouchFeedback>
        );
    }
}

