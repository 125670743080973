import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import View from '../view';
import TouchFeedback from '../touch-feedback';
import Strings from '../strings';

class Link extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        onConfirm: PropTypes.func,
        showBrief: PropTypes.bool,
    };

    static defaultProps = {
        prefixCls: 'tm-textedit-link',
        showBrief: true,
    };

    componentDidMount() {
        if (this.brief) {
            this.brief.focus();
        } else if (this.url) {
            this.url.focus();
        }
    }

    onClick = e => {
        const { onConfirm } = this.props;
        const brief = this.brief ? this.brief.value : '';
        const url = this.url ? this.url.value : '';

        onConfirm && onConfirm({
            brief,
            url
        });
    }

    render() {
        const {
            prefixCls,
            className,
            showBrief,
        } = this.props;

        return (
            <View className={classnames(`${prefixCls}`, className)}>
                <View className={`${prefixCls}-content`}>
                    <View className={`${prefixCls}-content-header`}>{Strings.link_insert}{Strings.link_name}</View>
                    {!!showBrief ?
                        <View className={classnames(`${prefixCls}-content-input`, `${prefixCls}-content-input-border`)}>
                            {/* <View className={`${prefixCls}-content-input-splitter`} /> */}
                            <input ref={el => this.brief = el} type='text' placeholder={Strings.link_brief_placeholder} spellCheck={false} />
                        </View>
                        : null
                    }
                    <View className={`${prefixCls}-content-input`}>
                        {/* <View className={`${prefixCls}-content-input-splitter`} /> */}
                        <input ref={el => this.url = el} type='text' placeholder={Strings.link_url_placeholder} spellCheck={false} />
                    </View>
                    <View className={`${prefixCls}-content-action`} >
                        <TouchFeedback activeClassName={`${prefixCls}-content-action-button-active`} >
                            <View 
                                className={`${prefixCls}-content-action-button`}
                                onClick={this.onClick}
                            >
                                {Strings.ok}
                            </View>
                        </TouchFeedback>
                    </View>
                </View>
            </View>
        );
    }
}

function show(onChange, showBrief) {
    const linkEl = document.createElement('div');
    document.body.appendChild(linkEl);

    const confirm = value => {
        ReactDOM.unmountComponentAtNode(linkEl);
        if (linkEl && linkEl.parentNode) {
            linkEl.parentNode.removeChild(linkEl);
        }
        onChange && onChange('link', value);
    }

    ReactDOM.render(<Link onConfirm={confirm} showBrief={showBrief} />, linkEl);
}

export default { show };
