import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Platform } from '../util';

const [TOUCH_IDLE, TOUCH_BEGIN, TOUCH_ACCEPT, TOUCH_DENY] = [0, 1, 2, 3];

function throttle(fn, delay) {
    let delayFlag = true;
    let firstInvoke = true;
    return function _throttle(e) {
        if (delayFlag) {
            delayFlag = false;
            setTimeout(() => {
                delayFlag = true;
                fn(e);
            }, delay);

            if (firstInvoke) {
                fn(e);
                firstInvoke = false;
            }
        }
    };
}

export default class ScrollView extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        style: PropTypes.object,
        scrollable: PropTypes.bool,
        useCustomEvent: PropTypes.bool,
        onScroll: PropTypes.func,
        horizontalTouchable: PropTypes.bool,
    };

    static defaultProps = {
        prefixCls: 'tm-listview',
        scrollable: true,
        useCustomEvent: false,
        horizontalTouchable: true,
    };

    velocity = 0;
    lastTouch = 0;
    lastTouchTime = 0;
    stopInertiaMove = false;
    dragging = false;
    trackClickTimestamp = 0;
    trackClickStartPoint = {x: 0, y: 0};

    touchType = TOUCH_IDLE;

    isMobile = Platform.isMobile();
    isAndroid = Platform.isAndroid();

    optionPassive = () => this.isAndroid ? {passive: true} : false;

    _refresher = null;
    _loader = null;

    get refresher() {
        return this._refresher;
    }
    set refresher(refresher) {
        this._refresher = refresher;
    }

    get loader() {
        return this._loader;
    }
    set loader(loader) {
        this._loader = loader;
    }

    UNSAFE_componentWillUpdate(nextProps) {
        // https://github.com/ant-design/ant-design-mobile/issues/1480
        // https://stackoverflow.com/questions/1386696/make-scrollleft-scrolltop-changes-not-trigger-scroll-event
        // 问题情景：用户滚动内容后，改变 dataSource 触发 ListView componentWillReceiveProps
        // 内容变化后 scrollTop 如果改变、会自动触发 scroll 事件，而此事件应该避免被执行
        if ((this.props.dataSource !== nextProps.dataSource ||
            this.props.initialListSize !== nextProps.initialListSize) && this.handleScroll) {
            this.haveScrollEvent = false;
            this.scrollview.removeEventListener('scroll', this.handleScroll);
        }
    }

    componentDidUpdate(prevProps) {
        // handle componentWillUpdate accordingly
        if ((this.props.dataSource !== prevProps.dataSource ||
            this.props.initialListSize !== prevProps.initialListSize) && this.handleScroll) {
            setTimeout(() => {
                this.haveScrollEvent = true;
                this.scrollview.addEventListener('scroll', this.handleScroll);
            }, 0);
        }
    }

    componentDidMount() {
        if (this.isScrollable()) {
            let handleScroll = e => this.props.onScroll && this.props.onScroll(e);
            if (this.props.scrollEventThrottle) {
                handleScroll = throttle(handleScroll, this.props.scrollEventThrottle);
            }
            this.handleScroll = handleScroll;
            this.haveScrollEvent = true;
            this.scrollview.addEventListener('scroll', this.handleScroll);
            if (!this.isMobile) {
                this.scrollview.addEventListener('mousedown', this.onTouchStart, this.optionPassive());
            }
            this.scrollview.addEventListener('touchstart', this.onTouchStart, this.optionPassive());

            if (!this.isMobile) {
                this.scrollview.addEventListener('wheel', this.onWheel);
                this.scrollview.addEventListener('click', this.onClick, true);
            }
        }
    }

    componentWillUnmount() {
        if (this.isScrollable()) {
            this.scrollview.removeEventListener('scroll', this.handleScroll);

            if (!this.isMobile) {
                this.scrollview.removeEventListener('mousedown', this.onTouchStart);
            }
            this.scrollview.removeEventListener('touchstart', this.onTouchStart);

            if (!this.isMobile) {
                this.scrollview.removeEventListener('wheel', this.onWheel);
                this.scrollview.removeEventListener('click', this.onClick);
            }
        }
    }

    isScrollable = () => !!this.props.scrollable

    metrics = () => {
        return {
            visibleLength: this.scrollview.offsetHeight,
            contentLength: this.scrollview.scrollHeight,
            offset: this.scrollview.scrollTop,
            distanceFromEnd: this.scrollview.scrollHeight - this.scrollview.offsetHeight - this.scrollview.scrollTop,
        };
    }

    scrollTo = (position, duration, loadMore) => {
        if (position === undefined) return;

        const distance = position - this.scrollview.scrollTop;
        this.scrollBy(distance, duration, loadMore);
    }

    scrollBy = (distance, duration, loadMore) => {
        if (distance === undefined) return;

        if (duration === undefined || duration < 0) duration = 300;
        this.shouldLoadMore = loadMore === undefined ? true : loadMore;
        let delta = distance;
        if (duration > 0) {
            delta = distance / duration;
        };
        const direction = distance > 0 ? 1 : -1;
        let lastTimestamp = -1;
        let scrollDistance = 0;
        const scroll = timestamp => {
            let keepAlive = true;
            if (lastTimestamp === -1) {
                lastTimestamp = timestamp;
            } else {
                let deltaTime = timestamp - lastTimestamp;
                let deltaDistance = delta * deltaTime;
                if (direction === 1 && (scrollDistance + deltaDistance) > distance) {
                    deltaDistance = distance - scrollDistance;
                    keepAlive = false;
                } else if (direction === -1 && (scrollDistance + deltaDistance) < distance) {
                    deltaDistance = distance - scrollDistance;
                    keepAlive = false;
                }
                scrollDistance += deltaDistance;
                this.scrollview.scrollTop += deltaDistance;
            }

            if (keepAlive) {
                requestAnimationFrame(scroll);
            }
        };
        requestAnimationFrame(scroll);
    }

    loadMore = () => {
        if (!this.shouldLoadMore) {
            return;
        }

        if (this.loader && !this.loader.loading && (!this.refresher || !this.refresher.refreshing)) {
            this.loader.loading = true;
            this.shouldLoadMore = false;
        }
    }

    onClick = e => {
        if (this.isMobile) {
            return;
        }

        const timeElapse = e.timeStamp - this.trackClickTimestamp;
        const clickPoint = {
            x: e.clientX,
            y: e.clientY,
        };
        const distance = Math.max(Math.abs(clickPoint.x - this.trackClickStartPoint.x), Math.abs(clickPoint.y - this.trackClickStartPoint.y));
        if (timeElapse > 300 || distance > 10) {
            e.stopPropagation();
        }
    }

    onWheel = e => {
        this.shouldLoadMore = true;
        this.scrollview.scrollTop += e.deltaY;
    }

    onTouchStart = e => {
        if (!this.isMobile) {
            this.trackClickTimestamp = e.timeStamp;
            this.trackClickStartPoint = {
                x: e.clientX,
                y: e.clientY,
            };
        }

        this.touchType = TOUCH_BEGIN;
        const touches = e.changedTouches ? e.changedTouches[0] : e;

        this.velocity = 0;
        this.lastTouch = {
            x: touches.clientX,
            y: touches.clientY
        };
        this.lastTouchTime = Date.now();
        this.stopInertiaMove = true;
        this.dragging = true;
        this.shouldLoadMore = true;

        this.scrollview.addEventListener('touchmove', this.onTouchMove, this.optionPassive());
        this.scrollview.addEventListener('touchend', this.onTouchEnd);
        this.scrollview.addEventListener('touchcancel', this.onTouchEnd);
        if (!this.isMobile) {
            this.scrollview.addEventListener('mousemove', this.onTouchMove, this.optionPassive());
            this.scrollview.addEventListener('mouseup', this.onTouchEnd);
            this.scrollview.addEventListener('mouseleave', this.onTouchEnd);
        }
    }

    onTouchMove = e => {
        if (this.dragging) {
            const touches = e.changedTouches ? e.changedTouches[0] : e;
            let touch =  {
                x: touches.clientX,
                y: touches.clientY
            };

            let touchOffset = {
                x: touch.x - this.lastTouch.x,
                y: touch.y - this.lastTouch.y
            };

            if (this.touchType !== TOUCH_ACCEPT) {
                if (Math.max(Math.abs(touchOffset.x), Math.abs(touchOffset.y)) < 10) {
                    return;
                }
            }

            if (this.touchType === TOUCH_BEGIN) {
                if (this.props.horizontalTouchable) {
                    this.touchType = Math.abs(touchOffset.x) < Math.abs(touchOffset.y) ? this.touchType = TOUCH_ACCEPT : this.touchType = TOUCH_DENY;
                } else {
                    this.touchType = TOUCH_ACCEPT;
                }
            }

            if (this.touchType !== TOUCH_ACCEPT) {
                this.dragging = false;
                e.preventDefault();
                return;
            }

            let offset = touchOffset.y;
            if (this.refresher) {
                let scrollTop = this.scrollview.scrollTop - touchOffset.y;
                if (this.props.refreshable && this.props.refreshable() && scrollTop < 0 && !this.refresher.refreshing) {
                    this.refresher.height -= scrollTop;
                    offset = 0;
                    if (this.haveScrollEvent && this.isMobile) {
                        this.haveScrollEvent = false;
                        this.scrollview.removeEventListener('scroll', this.handleScroll);
                    }
                }
            }

            this.lastTouch = touch;

            if (this.isMobile && !this.props.useCustomEvent) {
                if (this.refresher && this.refresher.height > 0) {
                    this.scrollview.scrollTop -= offset;
                }
                return;
            }

            this.scrollview.scrollTop -= offset;

            let touchTime = Date.now();
            let duration = touchTime - this.lastTouchTime;

            this.velocity = duration > 0 ? touchOffset.y / duration : 0;
            this.lastTouchTime = touchTime;
            this.stopInertiaMove = true;
        }
    }

    onTouchEnd = e => {
        this.scrollview.removeEventListener('touchmove', this.onTouchMove);
        this.scrollview.removeEventListener('touchend', this.onTouchEnd);
        this.scrollview.removeEventListener('touchcancel', this.onTouchEnd);
        if (!this.isMobile) {
            this.scrollview.removeEventListener('mousemove', this.onTouchMove, this.optionPassive());
            this.scrollview.removeEventListener('mouseup', this.onTouchEnd);
            this.scrollview.removeEventListener('mouseleave', this.onTouchEnd);
        }

        if (!this.dragging) return;

        this.dragging = false;

        if (this.touchType !== TOUCH_ACCEPT) {
            return;
        }

        this.touchType = TOUCH_IDLE;

        if (this.refresher && this.refresher.height > 0) {
            this.refresher.activate();
            if (!this.haveScrollEvent && this.isMobile) {
                this.haveScrollEvent = true;
                this.scrollview.addEventListener('scroll', this.handleScroll);
            }
        }

        const metrics = this.metrics();
        if (metrics.distanceFromEnd === 0) {
            this.loadMore();
        }

        if (this.isMobile && !this.props.useCustomEvent) {
            return;
        }
        
        let touch =  typeof(e.clientY) === 'number'  ? e.clientY : e.changedTouches[0].clientY;
        let touchOffset = touch - this.lastTouch.y;

        this.scrollview.scrollTop -= touchOffset;
        this.stopInertiaMove = false;

        if (touchOffset !== 0) {
            let duration = Date.now() - this.lastTouchTime;
            this.velocity = duration > 0 ? touchOffset / duration : 0;
        }

        let scrollOffset = this.scrollview.scrollTop;
        const direction = this.velocity > 0 ? 1 : -1;
        const deceleration = 0.005 * direction;

        const inertiaScroll = () => {
            if (this.stopInertiaMove) return;
            const time = Date.now() - this.lastTouchTime;
            let v = this.velocity - time * deceleration;

            if (v * direction < 0) {
                return;
            }

            let offset = (this.velocity + v) / 2 * time;
            this.scrollview.scrollTop = scrollOffset - offset;
            const metrics = this.metrics();
            if ((direction === -1 && metrics.distanceFromEnd < 0.1) || (direction === 1 && metrics.offset < 0.1)) return;
            setTimeout(inertiaScroll, 10);
        }

        inertiaScroll();
    }

    render() {
        const {
            children,
            className,
            prefixCls,
            useCustomEvent,
            style = {},
        } = this.props;

        const styleBase = {
            position: 'relative',
            overflow: this.isMobile && !useCustomEvent ? 'auto' : 'hidden',
        };

        const containerProps = {
            ref: el => this.scrollview = el || this.scrollview,
            style: {  ...style, ...styleBase },
            className: classnames(className, `${prefixCls}`),
        };

        const contentContainerProps = {
            style: { position: this.isScrollable() ? 'absolute' : 'relative', minWidth: '100%' },
            className: classnames(`${prefixCls}-content`),
        };

        return (
            <div {...containerProps} >
                <div {...contentContainerProps}>
                    {children}
                </div>
            </div>
        );
    }
}