import React from 'react';
import ReactDOM from 'react-dom';
import closest from './closest';
import Modal from './Modal';

export default function operation(actions = [{ text: '确定' }],  platform = 'ios') {
    let closed = false;

    const prefixCls = 'tm-modal';
    const div = document.createElement('div');
    document.body.appendChild(div);

    function close() {
        ReactDOM.unmountComponentAtNode(div);
        if (div && div.parentNode) {
            div.parentNode.removeChild(div);
        }
    }

    const footer = actions.map((button) => {
        // tslint:disable-next-line:only-arrow-functions
        const orginPress = button.onPress || function() {};
        button.onPress = () => {
        if (closed) {
            return;
        }

        const res = orginPress();
        if (res && res.then) {
            res
            .then(() => {
                closed = true;
                close();
            })
            .catch(() => {});
        } else {
            closed = true;
            close();
        }
        };
        return button;
    });

    function onWrapTouchStart(e) {
        if (!/iPhone|iPod|iPad/i.test(navigator.userAgent)) {
            return;
        }
        const pNode = closest(e.target, `.${prefixCls}-footer`);
        if (!pNode) {
            e.preventDefault();
        }
    }

    ReactDOM.render(
        <Modal
            visible
            operation
            transparent
            prefixCls={prefixCls}
            transitionName="tm-zoom"
            closable={false}
            maskClosable
            onClose={close}
            footer={footer}
            maskTransitionName="tm-fade"
            className="tm-modal-operation"
            platform={platform}
            wrapProps={{ onTouchStart: onWrapTouchStart }}
        />,
        div,
    );

    return {
        close,
    };
}
