import React, { useState } from 'react';
import { Picker } from 'antd-mobile';
import { Image } from 'thanos';
import _ from 'lodash';

const CSS = 'register';

/* 输入框 */
export const Input = (props) => {
  return (
    <div className={`${CSS}-input-box`}>
      <div className={`${CSS}-input-label`}>
        <span className='must-be'>* </span>
        {props.label}
      </div>
      <div className={`${CSS}-input`}>
        <input
          type='text'
          placeholder={props.placeholder}
          onChange={(v) => props.onchange(props.type, v.target.value)}
        />
      </div>
    </div>
  );
};

/* picker */
export const MyPicker = (props) => {
  const [name, setName] = useState('');
  const [value, setValue] = useState(0);

  const chose = (v) => {
    let lab = _.find(props.data, { value: v[0] });
    setName(lab.label);
    setValue(lab.value);
    props.onchange(props.type, v);
  };

  return (
    <div className={`${CSS}-input-box`}>
      <div className={`${CSS}-input-label`}>
        <span className='must-be'>* </span>
        {props.label}
      </div>
      <Picker
        className='forss'
        cols={1}
        data={props.data}
        value={value}
        onChange={(v) => chose(v)}
      >
        <div className={`${CSS}-input`}>
          <div className='picker'>
            {name === '' ? props.placeholder : name}
            <Image className='sign' source={require('Images/down.svg')} />
          </div>
        </div>
      </Picker>
    </div>
  );
};

/* 性别 */
export const Sex = (props) => {
  const [sex, setSex] = useState(1);

  const Opt = [
    { label: '男', value: 1 },
    { label: '女', value: 0 },
  ];

  const onChose = (v) => {
    setSex(v.value);
    props.onchange(props.type, v.value);
  };

  return (
    <div className={`${CSS}-input-box`}>
      <div className={`${CSS}-input-label`}>
        <span className='must-be'>* </span>
        {props.label}
      </div>
      <div className={`${CSS}-input`}>
        <div className='sex'>
          {Opt.map((v) => (
            <div
              key={`sex-${v.value}`}
              onClick={() => onChose(v)}
              className={`sex-opt ${sex === v.value ? 'active' : 'normal'}`}
            >
              {v.label}
              {sex === v.value && (
                <Image
                  className='chose'
                  source={require('Images/chose.png').default}
                />
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

/* 地区 */
export const AreaPicker = (props) => {
  const [name, setName] = useState('');
  const [value, setValue] = useState([]);

  const chose = (v) => {
    let province = _.find(props.data, { value: v[0] });
    let city = v[1] ? _.find(province.children, { value: v[1] }) : '';
    let county = v[2] ? _.find(city.children, { value: v[2] }) : '';
    let lab = province.label + '/' + city.label + '/' + county.label;
    setValue(v);
    setName(lab);
    props.onchange(v);
  };

  return (
    <div className={`${CSS}-input-box`}>
      <div className={`${CSS}-input-label`}>
        <span className='must-be'>* </span>
        {props.label}
      </div>
      <Picker
        title='选择地区'
        data={props.data}
        value={value}
        onChange={(v) => chose(v)}
        // onOk={v => chose(v)}
        // onClick={() => { console.log('xx')}}
      >
        <div className={`${CSS}-input`}>
          <div className='picker'>
            <span className='area-name'>{name === '' ? props.placeholder : name}</span>
            <Image className='sign' source={require('Images/down.svg')} />
          </div>
        </div>
      </Picker>
    </div>
  );
};
