import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import View from '../view';
import { Time, Platform, Log } from '../util';

export default class Timeline extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        className: PropTypes.string,
        scrollable: PropTypes.bool,
        onSeek: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-recorder-timeline',
        scrollable: false,
    };

    state = {
        metrics: {
            width: 0,
            height: 0,
        },
        scrollable: true,
    };

    scaleInitialX = 2.5;         //Initial x position
    intervalOfScales = 8;        //Length between each scale
    scalesOfSecond = 5;          //Total scales of one second
    lengthOfScale = 12;
    lengthOfSubScale = 4;
    scaleHeight = 24;
    originOffset = 0;
    finishPosition = 0;
    originScales = 0;

    minAmplitude = 4;
    maxAmplitude = 40;
    amplitudePadding = 4;

    isMobile = Platform.isMobile();
    dragging = false;
    touchStart = 0;
    timestamp = 0;
    velocity = 0;
    position = 0;
    stopInertiaMove = false;

    audioData = []
    canvas = null;
    osr = null; // offscreen canvas
    secondOfOsr = 0;
    offsetOfOsr = 0;
    originSecondOfOsr = 0;
    originOffsetOfOsr = 0;

    currentTime = 0;

    set scrollable(scrollable) {
        if (scrollable === this.state.scrollable) {
            return;
        }
        this.setState({
            scrollable: scrollable,
        });
    }
    get scrollable() {
        return this.state.scrollable;
    }

    componentDidMount() {
        if (this.scroller && this.canvas) {
            const metrics = {
                width: this.scroller.clientWidth,
                height: this.scroller.clientHeight,
            };

            this.canvas.width = metrics.width;
            this.canvas.height = metrics.height;

            this.maxAmplitude = metrics.height - this.scaleHeight - this.amplitudePadding * 2;
            if (this.indicator) {
                this.indicator.style.left = (this.scaleInitialX - 0.5) + 'px';
            }

            this.setState({metrics}, () => {
                this.originSeconds = Math.ceil((this.scroller.clientWidth / 2 - this.scaleInitialX) / this.intervalOfScales / this.scalesOfSecond);
                this.originScales = this.originSeconds * this.scalesOfSecond;
                this.originOffset = this.originScales * this.intervalOfScales + 0.5;
                this.secondOfScreen = Math.floor(this.scroller.clientWidth / this.intervalOfScales / this.scalesOfSecond);
                this.adjustOffset({amplitude: 0, timestamp: 0});
                this.loadOSRCanvas(0);
                this.drawFromOsr();
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if ('scrollable' in nextProps && this.state.scrollable !== nextProps.scrollable) {
            this.setState({
                scrollable: nextProps.scrollable,
            });
        }
    }

    update = (amplitude, timestamp) => {
        const data = {amplitude, timestamp};
        this.currentTime = timestamp;
        this.audioData.push(data);
        Log.verbose('Update timestamp: ' + timestamp);
        this.adjustOffset(data);
        this.drawFromOsr();
        this.drawAudioWave(data);
        this.loadOSRCanvas(timestamp);
    }

    // Update and scroll timeline while seeking
    relocate = (timestamp, reset) => {
        if (reset) {
            this.isIndicatorFixed = false;
            this.originSecondOfOsr = 0;
            this.originOffsetOfOsr = 0;
            this.audioData = [];
            this.adjustOffset({amplitude: 0, timestamp: 0});
            this.drawTimeline();
            this.loadOSRCanvas(0);
            this.drawFromOsr();
            return;
        }

        let index = Math.floor(timestamp * 10) - 1;
        if (index < 0) index = 0;
        for (; index < this.audioData.length; index++) {
            const data = this.audioData[index];
            if (data.timestamp >= timestamp) {
                break;
            }
        }

        this.audioData = this.audioData.slice(0, index);

        this.originSecondOfOsr = Math.floor(timestamp - this.secondOfScreen / 2) - 1;
        if (this.originSecondOfOsr < 0) this.originSecondOfOsr = 0;
        if (this.originSecondOfOsr === 0) {
            this.originOffsetOfOsr = 0;
        } else {
            this.originOffsetOfOsr = (timestamp - this.originSecondOfOsr) * this.scalesOfSecond * this.intervalOfScales - this.scroller.clientWidth / 2;// + this.scaleInitialX;
        }

        this.drawTimeline();

        this.audioData.forEach(data => {
            if (data.timestamp >= this.originSecondOfOsr) {
                this.drawAudioWave(data);
            }
        });

        this.adjustOffset({amplitude: 0, timestamp: timestamp});
        this.drawFromOsr();
    }

    // Update and scroll timeline while playing recorded data
    locate = timestamp => {
        if (!this.scroller || this.audioData.length === 0) {
            return;
        }

        const width = this.scroller.clientWidth;
        const originOffset = (timestamp - this.originSecondOfOsr) * this.scalesOfSecond * this.intervalOfScales + this.scaleInitialX + this.originOffsetOfOsr;
        const shouldReloadOsr = timestamp < this.originSecondOfOsr || timestamp > (this.originSecondOfOsr + this.secondOfScreen) || (this.widthOfOsr - originOffset) < width;
        if (shouldReloadOsr) {
            this.originSecondOfOsr = Math.floor(timestamp - this.secondOfScreen / 2) - 1;
            if (this.originSecondOfOsr < 0) this.originSecondOfOsr = 0;

            this.drawTimeline();

            this.audioData.forEach(data => {
                if (data.timestamp >= this.originSecondOfOsr) {
                    this.drawAudioWave(data);
                }
            });

            this.originOffsetOfOsr = (timestamp - this.originSecondOfOsr) * this.scalesOfSecond * this.intervalOfScales - width / 2;
        }
        const lastTimestamp = this.audioData[this.audioData.length - 1].timestamp;
        // Log.verbose('timestamp - ' + timestamp + ' lastTimestamp - ' + lastTimestamp);
        if (lastTimestamp - timestamp < -0.05) {
            return;
        }

        this.adjustOffset({amplitude: 0, timestamp: timestamp});
        this.drawFromOsr();
        this.props.onSeek && this.props.onSeek(timestamp, lastTimestamp);
    }

    onPlaybackFinish = () => {
        if (this.audioData.length === 0) {
            return;
        }

        this.locate(this.audioData[this.audioData.length - 1].timestamp);
    }

    drawLine = (context, x0, y0, x1, y1) => {
        context.beginPath();
        context.moveTo(x0, y0);
        context.lineTo(x1, y1);
        context.stroke();
        context.closePath();
    }

    drawTimeline = () => {
        if (!this.osr) {
            return;
        }

        Log.verbose('Draw timeline');

        const metrics = {
            width: this.osr.width,
            height: this.osr.height
        };

        let context = this.osr.getContext('2d');
        context.clearRect(0, 0, metrics.width, metrics.height);

        context.save();
        context.strokeStyle = '#ccc';
        context.lineWidth = 1;

        context.translate(0.5, 0.5);

        this.drawLine(context, 0, this.scaleHeight, metrics.width, this.scaleHeight);

        context.strokeStyle = '#eee';
        context.translate(-0.5, -0.5);
        const y = (metrics.height - this.scaleHeight) / 2 + this.scaleHeight;
        this.drawLine(context, 0, y, metrics.width, y);

        context.strokeStyle = '#ccc';
        context.translate(0.5, 0.5);
        this.drawLine(context, 0, metrics.height - 1, metrics.width, metrics.height - 1);

        context.font = '"Microsoft YaHei"';
        context.fillStyle = '#888';

        context.translate(0, 0);
        let start = this.scaleInitialX;
        let amountOfDrawScales = 0;
        const originScales = this.originSecondOfOsr * this.scalesOfSecond;
        Log.verbose('Origin second of offscreen: ' + this.originSecondOfOsr);
        while (start < metrics.width) {
            const isSubScale = (amountOfDrawScales % this.scalesOfSecond) !== 0;
            let lengthOfScale = (this.scaleHeight - (isSubScale ? this.lengthOfSubScale : this.lengthOfScale)) + 0.5;
            this.drawLine(context, start, lengthOfScale, start, this.scaleHeight);

            if (!isSubScale) {
                if ((start > this.originOffset) || this.originSecondOfOsr > 0) {
                    const scales = this.originSecondOfOsr === 0 ? originScales + amountOfDrawScales - this.originScales : originScales + amountOfDrawScales;
                    const seconds = Math.floor(scales / this.scalesOfSecond);
                    const timeText = Time.format(seconds, 'mm:ss');
                    // Log.verbose(timeText);
                    context.fillText(timeText, start + 2.5, lengthOfScale);
                }
            }

            amountOfDrawScales += 1;
            start = this.scaleInitialX + amountOfDrawScales * this.intervalOfScales;
        }

        context.restore();
    }

    drawAudioWave = data => {
        if (!this.osr) {
            return;
        }

        const metrics = this.state.metrics;

        let amplitude = data.amplitude / 100;
        if (amplitude < this.minAmplitude) {
            amplitude = this.minAmplitude;
        } else if (amplitude > this.maxAmplitude) {
            amplitude = this.maxAmplitude;
        }

        const offsetOfOsr = (data.timestamp - this.originSecondOfOsr) * this.scalesOfSecond * this.intervalOfScales + this.scaleInitialX;
        let x = this.originSecondOfOsr > 0 ? offsetOfOsr : offsetOfOsr + this.originOffset;
        let y = (metrics.height - this.scaleHeight) / 2 + this.scaleHeight;

        let context = this.osr.getContext('2d');
        this.drawLine(context, x, y - amplitude / 2, x, y + amplitude / 2);
    }

    adjustOffset = data => {
        if (!this.scroller || !this.indicator) {
            return;
        }

        const center = this.scroller.clientWidth / 2 + this.scaleInitialX - 0.5;
        let left = (data.timestamp - this.originSecondOfOsr) * this.scalesOfSecond * this.intervalOfScales + this.scaleInitialX - 0.5;
        if (!this.isIndicatorFixed && left <= center) {
            this.indicator.style.left = left + 'px';
            this.isIndicatorFixed = false;
            this.offsetOfOsr = this.originOffset;
        } else {
            this.isIndicatorFixed = true;
            this.offsetOfOsr = left - center;// + this.originOffsetOfOsr;
            if (this.originSecondOfOsr === 0) {
                this.offsetOfOsr += this.originOffset;
            }
        }
    }

    drawFromOsr = () => {
        const metrics = this.state.metrics;
        let context = this.canvas.getContext('2d');
        let width = this.osr.width - this.offsetOfOsr;
        width = width > metrics.width ? metrics.width : width;
        context.clearRect(0, 0, metrics.width, metrics.height);
        context.drawImage(this.osr, this.offsetOfOsr, 0, width, metrics.height, 0, 0, width, metrics.height);
    }

    loadOSRCanvas = timestamp => {
        const metrics = this.state.metrics;
        if (!this.osr) {
            this.osr = document.createElement('canvas');
            this.osr.width = metrics.width << 1;
            this.osr.height = metrics.height;

            this.secondOfOsr = Math.floor((this.osr.width - this.scaleInitialX) / this.intervalOfScales / this.scalesOfSecond);
            this.widthOfOsr = this.secondOfOsr * this.scalesOfSecond * this.intervalOfScales + this.scaleInitialX;

            let context = this.osr.getContext('2d');
            context.strokeStyle = '#03c0ab';
            context.lineWidth = 1.5;
            context.lineCap = 'round';

            this.originSecondOfOsr = 0;
            this.drawTimeline();

            return;
        }

        if (timestamp > 0) {
            let widthOfDrawOsr = (timestamp - this.originSecondOfOsr) * this.scalesOfSecond * this.intervalOfScales + this.scaleInitialX + this.originOffsetOfOsr;
            widthOfDrawOsr = this.originSecondOfOsr > 0 ? widthOfDrawOsr : widthOfDrawOsr + this.originOffset;
            const width = this.scroller.clientWidth;
            if ((this.widthOfOsr - widthOfDrawOsr) > width) {
                return;
            }

            this.originSecondOfOsr = Math.floor(timestamp - this.secondOfScreen / 2) - 1;
            if (this.originSecondOfOsr < 0) this.originSecondOfOsr = 0;

            this.drawTimeline();

            this.audioData.forEach(data => {
                if (data.timestamp >= this.originSecondOfOsr) {
                    this.drawAudioWave(data);
                }
            });

            this.originOffsetOfOsr = (timestamp - this.originSecondOfOsr) * this.scalesOfSecond * this.intervalOfScales - width / 2;
            this.adjustOffset({amplitude: 0, timestamp: timestamp});
            this.drawFromOsr();
        }
    }

    locateOSRCanvas = delta => {
        if (this.audioData.length === 0 || !this.scroller) {
            return;
        }

        let offsetOfOsr = this.offsetOfOsr - delta;
        const lastTimestamp = this.audioData[this.audioData.length - 1].timestamp;
        const width = this.scroller.clientWidth;
        const indicatorOffset = this.indicator.offsetLeft;
        let offset = offsetOfOsr + indicatorOffset;
        if (this.originSecondOfOsr === 0) {
            offset -= this.originOffset;
        }
        const timestamp = offset / this.intervalOfScales / this.scalesOfSecond + this.originSecondOfOsr;

        if (delta > 0) {
            if (this.originSecondOfOsr === 0) {
                if (offsetOfOsr < (this.originOffset - indicatorOffset)) {
                    offsetOfOsr = this.originOffset - indicatorOffset + this.scaleInitialX - 0.5;
                }
            } else {
                if (offsetOfOsr < 0) {
                    this.originSecondOfOsr -= this.secondOfScreen;
                    if (this.originSecondOfOsr < 0) this.originSecondOfOsr = 0;
                    this.originOffsetOfOsr = 0;
                    this.drawTimeline();
                    for (let index = 0; index < this.audioData.length; index++) {
                        const data = this.audioData[index];
                        this.drawAudioWave(data);

                        if (data.timestamp > (this.originSecondOfOsr + this.secondOfOsr)) break;
                    }

                    this.adjustOffset({amplitude: 0, timestamp: timestamp});
                    offsetOfOsr = this.offsetOfOsr;
                }
            }
        } else {
            if (timestamp >= lastTimestamp) {
                offsetOfOsr = (lastTimestamp - this.originSecondOfOsr) * this.scalesOfSecond * this.intervalOfScales - indicatorOffset + this.scaleInitialX - 0.5;
                if (this.originSecondOfOsr === 0) {
                    offsetOfOsr += this.originOffset;
                }
            } else {
                if ((this.widthOfOsr - offsetOfOsr - width) < 0) {
                    this.originSecondOfOsr = Math.floor(timestamp - this.secondOfScreen / 2);
                    this.originOffsetOfOsr = (timestamp - this.originSecondOfOsr) * this.scalesOfSecond * this.intervalOfScales - width / 2;
                    this.drawTimeline();
                    for (let index = this.originSecondOfOsr * 10; index < this.audioData.length; index++) {
                        const data = this.audioData[index];
                        if (data.timestamp >= this.originSecondOfOsr) {
                            this.drawAudioWave(data);
                        }
                    }

                    this.adjustOffset({amplitude: 0, timestamp: timestamp});
                    offsetOfOsr = this.offsetOfOsr;
                }
            }
        }

        return offsetOfOsr;
    }

    onTouchBegin = e => {
        if (!this.scrollable || this.audioData.length === 0) {
            return;
        }

        if (!this.isMobile) {
            e.preventDefault();
        }
        
        this.dragging = true;
        this.stopInertiaMove = true;
        const touches = this.isMobile ? e.changedTouches[0] : e;
        this.touchStart = touches.clientX;
        this.velocity = 0;
        this.timestamp = Date.now();

        this.scroller.addEventListener(this.isMobile ? 'touchmove' : 'mousemove', this.onTouchMove);
        this.scroller.addEventListener(this.isMobile ? 'touchend' : 'mouseup', this.onTouchEnd);
        if (!this.isMobile) {
            this.scroller.addEventListener('mouseleave', this.onTouchEnd);
        }
    }

    onTouchMove = e => {
        if (this.dragging && !this.moving) {
            this.moving = true;
            this.stopInertiaMove = true;
            const touches = this.isMobile ? e.changedTouches[0] : e;
            let delta = touches.clientX - this.touchStart;

            this.offsetOfOsr = this.locateOSRCanvas(delta);
            this.drawFromOsr();

            this.touchStart = touches.clientX;
            const timestamp = Date.now();
            const duration = timestamp - this.timestamp;
            this.timestamp = timestamp;
            if (duration > 0) {
                this.velocity = delta / duration;
            } else {
                this.velocity = 0;
            }
            this.moving = false;
            this.onSeek();
        }
    }

    onTouchEnd = e => {
        if (this.dragging) {
            this.dragging = false;

            const decelerator = 0.05;
            this.stopInertiaMove = false;
            Log.verbose('velocity: ' + this.velocity);

            const direction = this.velocity > 0 ? 1 : -1;
            const deceleration = decelerator * direction;
            this.velocity = Math.abs(this.velocity) > 3 ? 3 * direction : this.velocity;

            const inertiaScroll = () => {
                if (this.stopInertiaMove) {
                    this.onSeek();
                    return;
                }
                let time = Date.now() - this.timestamp;
                let v = this.velocity - time * deceleration;
                
                if (v * direction < 0) {
                    this.onSeek();
                    return;
                }
    
                let offset = (this.velocity + v) / 2 * time;
                let offsetOfOsr = this.offsetOfOsr - offset;
                this.offsetOfOsr = this.locateOSRCanvas(offset);
                this.drawFromOsr();
                if (offsetOfOsr !== this.offsetOfOsr) {
                    this.onSeek();
                    return;
                }
                setTimeout(inertiaScroll, 50);
            }
    
            inertiaScroll();  
        }

        this.scroller.removeEventListener(this.isMobile ? 'touchmove' : 'mousemove', this.onTouchMove);
        this.scroller.removeEventListener(this.isMobile ? 'touchend' : 'mouseup', this.onTouchEnd);
        if (!this.isMobile) {
            this.scroller.removeEventListener('mouseleave', this.onTouchEnd);
        }
    }

    onSeek = () => {
        const { onSeek } = this.props;
        if (!onSeek || !this.indicator || this.audioData.length === 0) return;

        let offset = this.offsetOfOsr + this.indicator.offsetLeft;
        if (this.originSecondOfOsr === 0) {
            offset -= this.originOffset;
        }
        const lastTimestamp = this.audioData[this.audioData.length - 1].timestamp;
        let timestamp = offset / this.intervalOfScales / this.scalesOfSecond + this.originSecondOfOsr;
        if (timestamp < 0.1) {
            timestamp = 0;
        } else if (timestamp > lastTimestamp) {
            timestamp = lastTimestamp;
        }

        this.currentTime = timestamp;
        onSeek(timestamp, lastTimestamp);
    }

    render() {
        const {
            prefixCls,
            className,
        } = this.props;

        const wrapCls = classnames(`${prefixCls}`, className);
        const canvasStyle = {
            width: this.state.metrics.width + 'px',
            height: this.state.metrics.height + 'px',
        };

        const indicatorStyle = {
            height: (this.state.metrics.height - this.scaleHeight) + 'px',
            top: this.scaleHeight + 'px',
        };

        return (
            <View 
                ref={el => this.scroller = ReactDOM.findDOMNode(el)}
                className={wrapCls}
                onMouseDown={this.onTouchBegin}
                onTouchStart={this.onTouchBegin}
            >
                <canvas 
                    ref={el => this.canvas = el}
                    style={canvasStyle}
                />
                <View 
                    ref={el => this.indicator = ReactDOM.findDOMNode(el)}
                    className={`${prefixCls}-indicator`}
                    style={indicatorStyle}
                />
            </View>
        );
    }
}