import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import invariant from 'fbjs/lib/invariant';
import View from '../view';
import Text from '../text';
import Picker from '../picker';
import TouchFeedback from '../touch-feedback';
import Strings from '../strings';

import './style/index.less';

export default class DatePicker extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        // className: PropTypes.string,
        max: PropTypes.string,
        min: PropTypes.string,
        date: PropTypes.string,
        time: PropTypes.string,
        mode: PropTypes.oneOf(['datetime', 'date']),
        range: PropTypes.bool,
        fromDate: PropTypes.string,
        toDate: PropTypes.string,
        header: PropTypes.string,
        onChange: PropTypes.func,
        onConfirm: PropTypes.func,
        onCancel: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-date-picker',
        max: '2099/1/1',
        min: '1900/1/1',
        mode: 'datetime',
        range: false,
    };

    dates = {source: [], index: 0};
    hours = {source: [], index: 0};
    minutes = {source: [], index: 0};
    months = {source: [], index: 0};
    days = {source: [], index: 0};

    selectedDate = null;
    lastContext = {date: '', hour: -1, minute: -1};
    maxDate = null;
    minDate = null;
    reachMaxDate = false;
    reachMinDate = false;
    reachMaxYear = false;
    reachMinYear = false;
    maxDays = 0;

    constructor(props) {
        super(props);
        this.state = {
            dataSource: new Picker.DataSource({
                getData: this.getData,
                getCount: () => 3,
                hasMore: this.hasMore,
                shouldSyncColumn: this.shouldSyncColumn,
            }),
            header: '',
            fromDateText: '',
            toDateText: '',
            activeTab: 'from',
        };

        if (props.mode ===  'datetime') {
            this.generate(this.hours.source, 0, 23, Strings.hour);
            this.generate(this.minutes.source, 0, 59, Strings.minute);
        }

        this.maxDate = new Date(props.max);
        this.minDate = new Date(props.min);
        this.maxDate.setHours(0, 0, 0, 0);
        this.minDate.setHours(0, 0, 0, 0);

        let date;
        if (props.mode === 'date' && props.range) {
            if (!!props.fromDate && props.fromDate !== '' && /^\d{4}\/\d{1,2}\/\d{1,2}$/.test(props.fromDate)) {
                date = new Date(props.fromDate);
                this.lastFromContext = { year: date.getFullYear(), month: date.getMonth(), day: date.getDate() };
            }

            if (!!props.toDate && props.toDate !== '' && /^\d{4}\/\d{1,2}\/\d{1,2}$/.test(props.toDate)) {
                date = new Date(props.toDate);
                this.lastToContext = { year: date.getFullYear(), month: date.getMonth(), day: date.getDate() };
            }
        }

        if (!!props.date && props.date !== '' && /^\d{4}\/\d{1,2}\/\d{1,2}$/.test(props.date)) {
            if (props.mode === 'datetime') {
                this.lastContext.date = props.date;
            } else if (props.mode === 'date' && !props.range) {
                date = new Date(props.date);
                this.lastContext = { year: date.getFullYear(), month: date.getMonth(), day: date.getDate() };
            }
        }

        if (!!props.time && props.time !== '' && /^\d{1,2}:\d{1,2}/.test(props.time)) {
            let times = props.time.split(':');
            if (times.length === 2) {
                this.lastContext.hour = parseInt(times[0], 10);
                this.lastContext.minute = parseInt(times[1], 10);
            }
        }

        this.maxDays = Math.floor((this.maxDate - this.minDate) / 1000 / 60 / 60 / 24);

        invariant(this.maxDays > 0, 'Maximum date MUST great than minimum date');
    }

    getData = (column, index) => {
        const { mode } = this.props;
        if (mode === 'datetime') {
            return this.getDateTimeData(column, index);
        } else if (mode === 'date') {
            return this.getDateData(column, index);
        }
    }

    getDateTimeData = (column, index) => {
        if (column === 1) {
            return this.generateHour();
        } else if (column === 2) {
            return this.generateMinute();
        } else {
            let initialId = '';
            if (this.lastDate !== '') {
                this.dates = {source: [], index: 0};
                index = 5;
                let date = new Date();
                let date1 = new Date(this.lastDate)
                date.setHours(0, 0, 0, 0);
                date1.setHours(0, 0, 0, 0);
                let days = (date1.getTime() - date.getTime()) / (1000 * 60 * 60 * 24);
                if (days < 5) {
                    index = days;
                    date.setDate(date.getDate() - 1);
                    date1 = date;
                } else {
                    date1.setDate(date1.getDate() - 6);
                    this.reachMinDate = false;
                }
                
                this.lastDate = '';
                if (index >= 0) {
                    let dates = this.generateDate(date1.toString(), 20, true);
                    this.dates = {
                        source: this.dates.source.concat(dates),
                        index: index,
                    };
                    return this.dates;
                }
            }

            if (index < 0) {
                let date = new Date();
                let count = Math.min(this.maxDays, 20);
                let activeIndex = Math.floor(count / 2) - 1;

                if ((date - this.minDate) > 0) {
                    let tmpIndex = Math.min(Math.floor((date - this.minDate) / 1000 / 60 / 60 / 24), activeIndex);
                    if (tmpIndex < activeIndex) {
                        this.reachMinDate = true;
                        activeIndex = tmpIndex;
                    }
                }

                let activeDate = date.getDate() - activeIndex - 1;
                if ((this.maxDate - date) < 0) {
                    date = this.maxDate;
                } else if ((this.minDate - date) > 0) {
                    date = this.minDate;
                    activeDate = date.getDate() - 1;
                    activeIndex = 0;
                    this.reachMinDate = true;
                }

                date.setDate(activeDate);
                this.dates = {
                    source: this.generateDate(date.toString(), count, true),
                    index: activeIndex,
                };
            } else {
                let dates = [];
                if (index < Picker.Threshold) {
                    // console.log('New index - ' + this.dates.index);
                    if (initialId === '') {
                        initialId = this.dates.source[0].id;
                    }
                    dates = this.generateDate(initialId, 20, false);
                    this.dates = {
                        source: dates.concat(this.dates.source),
                        index: index + 5,
                    };
                } else {
                    if (initialId === '') {
                        initialId = this.dates.source[this.dates.source.length - 1].id;
                    }
                    dates = this.generateDate(initialId, 20, true);
                    this.dates = {
                        source: this.dates.source.concat(dates),
                        index: index,
                    };
                }
            }
            return this.dates;
        }
    }

    getDateData = (column, index) => {
        if (column === 0) {
            return this.generateYear();
        } else if (column === 1) {
            return this.generateMonth();
        } else if (column === 2) {
            return this.generateDay(index);
        }
    }

    shouldSyncColumn = (column, sibling) => {
        const { mode } = this.props;
        if (mode === 'date') {
            return (column === 0 || column === 1) && sibling === 2;
        }

        return false;
    }

    hasMore = (column, forward) => {
        const { mode } = this.props;
        if (mode === 'datetime') {
            if (column === 0) {
                if (forward) {
                    return !this.reachMaxDate;
                } else {
                    return !this.reachMinDate;
                }
            }
        } else {
            if (column === 0) {
                if (forward) {
                    return !this.reachMaxYear;
                } else {
                    return !this.reachMinYear;
                }
            }
        }

        return true;
    }

    daysOfMonth = (year, month) => {
        let date = new Date(year, month, 0);
        return date.getDate();
    }

    generate = (data, start, end, suffix) => {
        for (let i = start; i <= end; i++) {
            let text = i + suffix;
            if (i < 10) text = '0' + text;
            data.push({id: i, label: text});
        }
    }

    generateYear = () => {
        const { dataSource } = this.state;
        const { mode } = this.props;
        let activeData = dataSource.data(0);
        let year, date;
        if (activeData) {
            year = activeData.id;
        } else {
            if (mode === 'date' && this.selectedDate) {
                date = this.selectedDate
            } else {
                date = new Date();
            }
            year = date.getFullYear();
        }

        let startYear = year - 10;
        let endYear = year + 10;
        this.reachMinYear = false;
        this.reachMaxYear = false;
        if (startYear < this.minDate.getFullYear()) {
            startYear = this.minDate.getFullYear();
            this.reachMinYear = true;
        }

        if (endYear < this.maxDate.getFullYear()) {
            endYear = this.maxDate.getFullYear();
            this.reachMaxYear = true;
        }

        let index = 10;
        let source = [];
        for (let i = startYear; i < endYear; i++) {
            if (i === year) index = i - startYear;
            source.push({id: i, label: i + ''});
        }

        return { source, index };
    }

    generateMonth = () => {
        const { dataSource } = this.state;
        const { mode } = this.props;
        let activeData = dataSource.data(1);
        let month, date;
        if (activeData) {
            month = activeData.id;
        } else {
            if (mode === 'date' && this.selectedDate) {
                date = this.selectedDate
            } else {
                date = new Date();
            }
            month = date.getMonth();
        }

        console.log('month: ' + month);
        const startMonth = month - 10;
        const endMonth = month + 10;
        let source = [];
        for (let i = startMonth; i <= endMonth; i++) {
            let _month = (i + 12) % 12;
            source.push({id: _month, label: (_month + 1) + ''});
        }

        console.log('month 1: ' + source[10].id);

        return { source: source, index: 10 };
    }

    generateDay = () => {
        const { dataSource } = this.state;
        const { mode } = this.props;
        let activeYear = dataSource.data(0);
        let activeMonth = dataSource.data(1);
        let activeDay = dataSource.data(2);
        let year, month, day;
        let date;
        if (activeDay) {
            day = activeDay.id;
        } else {
            if (mode === 'date' && this.selectedDate) {
                date = this.selectedDate
            } else {
                date = new Date();
            }
            day = date.getDate();
        }

        if (activeYear) {
            year = activeYear.id
        } else {
            if (date) {
                year = date.getFullYear();
            } else if (this.selectedDate) {
                year = this.selectedDate.getFullYear();
            } else {
                date = new Date();
                year = date.getFullYear();
            }
        }

        if (activeMonth) {
            month = activeMonth.id
        } else {
            if (date) {
                month = date.getMonth();
            } else if (this.selectedDate) {
                month = this.selectedDate.getMonth();
            } else {
                date = new Date();
                month = date.getMonth();
            }
        }
        
        const startIndex = day - 10;
        const endIndex = day + 10;
        const days = this.daysOfMonth(year, month + 1);
        let source = [];
        for (let i = startIndex; i <= endIndex; i++) {
            let _day = (i + days) % days;
            if (_day === 0) _day = days;
            source.push({id: _day, label: _day + ''});
        }

        return { source, index: 10 };
    }

    generateMinute = () => {
        const { dataSource } = this.state;
        let activeData = dataSource.data(2);
        let index = this.lastMinute;
        if (index < 0) {
            if (activeData) {
                index = activeData.id;
            } else {
                const date = new Date();
                index = date.getMinutes();
            }
        } else {
            this.lastMinute = -1;
        }
    
        const startIndex = index - 10;
        const endIndex = index + 10;
        let source = [];
        for (let i = startIndex; i <= endIndex; i++) {
            let tmpIndex = (i + 60) % 60;
            source.push(this.minutes.source[tmpIndex]);
        }

        return { source: source, index: 10 };
    }

    generateHour = () => {
        const { dataSource } = this.state;
        let activeData = dataSource.data(1);
        let index = this.lastHour;
        if (index < 0) {
            if (activeData) {
                index = activeData.id;
            } else {
                const date = new Date();
                index = date.getHours();
            }
        } else {
            this.lastHour = -1;
        }

        const startIndex = index - 10;
        const endIndex = index + 10;
        let source = [];
        for (let i = startIndex; i <= endIndex; i++) {
            let tmpIndex = (i + 24) % 24;
            source.push(this.hours.source[tmpIndex]);
        }

        return { source: source, index: 10 };
    }

    generateDate = (dateString, count, forward) => {
        let date = new Date(dateString);

        let days = 0;
        if (forward) {
            days = Math.floor((this.maxDate - date) / 1000 / 60 / 60 / 24);
        } else {
            days = Math.floor((date - this.minDate) / 1000 / 60 / 60 / 24);
        }
        if (days < 0) {
            count = 0;
        } else {
            count = days > count ? count : days;
        }

        if (count === 0) {
            if (forward) this.reachMaxDate = true;
            else this.reachMinDate = true;
        }

        let source = [];
        for (let i = 0; i < count; i++) {
            let newDay = date.getDate();
            if (forward) {
                newDay += 1;
            } else {
                newDay -= 1;
            }
            date.setDate(newDay);
            if (forward) {
                source.push({id: date.toString(), label: this.generateLabel(date)});
            } else {
                source = [{id: date.toString(), label: this.generateLabel(date)}, ...source];
            }
        }

        return source;
    }

    generateLabel = date => {
        const today = new Date();
        if (today.getFullYear() === date.getFullYear() && 
            today.getMonth() === date.getMonth() && 
            today.getDate() === date.getDate()) {
                return Strings.today;
        }
        const weekday = [
            Strings.week + Strings.day,
            Strings.week + Strings.one,
            Strings.week + Strings.two,
            Strings.week + Strings.three,
            Strings.week + Strings.four,
            Strings.week + Strings.five,
            Strings.week + Strings.six,
        ];

        let label = date.getMonth() + 1 + Strings.month;
        if (date.getMonth() < 9) label = '0' + label;
        if (date.getDate() < 10) label += '0';
        label = label + date.getDate() + Strings.day + ' ' + weekday[date.getDay()];

        return label;
    }

    activate() {
        const { mode, range, header } = this.props;

        if (mode === 'datetime') {
            if (this.lastContext.date !== '') {
                this.selectedDate = new Date(this.lastContext.date);
            }

            if (!this.selectedDate) {
                this.selectedDate = new Date();
            }
        } else {
            if (range) {
                if (this.lastFromContext) {
                    this.selectedFromDate = new Date(this.lastFromContext.year, this.lastFromContext.month, this.lastFromContext.day);
                } else {
                    this.selectedFromDate = new Date();
                } 

                if (this.lastToContext) {
                    this.selectedToDate = new Date(this.lastToContext.year, this.lastToContext.month, this.lastToContext.day);
                } else {
                    this.selectedToDate = new Date(this.selectedFromDate.getFullYear(), this.selectedFromDate.getMonth(), this.selectedFromDate.getDate() + 1);
                }
                
                this.selectedDate = this.selectedFromDate;
                this.setState({
                    activeTab: 'from',
                }, () => {
                    this.fromDateText.text = this.selectedFromDate.format('yyyy.M.d');
                    this.toDateText.text = this.selectedToDate.format('yyyy.M.d');
                    this.state.dataSource.reset();
                    this.picker.reset();
                });
            } else {
                if (this.lastContext) {
                    this.selectedDate = new Date(this.lastContext.year, this.lastContext.month, this.lastContext.day);
                } else {
                    this.selectedDate = new Date();
                }
            }
        }

        if (mode === 'datetime') {
            this.lastDate = this.lastContext.date;
            this.lastHour = this.lastContext.hour;
            this.lastMinute = this.lastContext.minute;
        }

        this.cancelled = false;
        this.state.dataSource.reset();
        
        if (this.picker) {
            this.picker.activate();
        }

        if (!header && this.selectedDate) {
            this.setState({
                header: this.selectedDate.getFullYear() + Strings.year,
            });
        }

        if (mode === 'datetime') {
            this.props.onChange && this.props.onChange(this.selectedDate.toString());
        } else {
            if (range) {
                this.props.onChange && this.props.onChange(this.selectedFromDate.format('yyyy-MM-dd'), this.selectedToDate.format('yyyy-MM-dd'));
            } else {
                this.props.onChange && this.props.onChange(this.selectedDate.format('yyyy-MM-dd'));
            }
        }
    }

    onChange = data => {
        const { mode, range } = this.props;
        if (data.length !== 3) return;

        let date;
        if (mode === 'datetime') {
            date = new Date(data[0].id);
            date.setHours(data[1].id, data[2].id, 0, 0);

            console.log(date);
            this.props.onChange && this.props.onChange(date.toString());
            if (date.getFullYear() !== this.selectedDate.getFullYear()) {
                this.setState({
                    header: date.getFullYear() + Strings.year,
                });
            }
        } else {
            if (range) {
                if (this.state.activeTab === 'from') {
                    this.selectedFromDate = new Date(data[0].id, data[1].id, data[2].id);
                    // if (this.selectedFromDate > this.selectedToDate) {
                    //     this.selectedToDate.setFullYear(this.selectedFromDate.getFullYear());
                    //     this.selectedToDate.setMonth(this.selectedFromDate.getMonth());
                    //     this.selectedToDate.setDate(this.selectedFromDate.getDate() + 1);
                    // }
                } else {
                    this.selectedToDate = new Date(data[0].id, data[1].id, data[2].id);
                    // if (this.selectedToDate < this.selectedFromDate) {
                    //     this.selectedFromDate.setFullYear(this.selectedToDate.getFullYear());
                    //     this.selectedFromDate.setMonth(this.selectedToDate.getMonth());
                    //     this.selectedFromDate.setDate(this.selectedToDate.getDate() - 1);
                    // }
                }
                console.log(this.selectedFromDate);
                console.log(this.selectedToDate);
                this.fromDateText.text = this.selectedFromDate.format('yyyy.M.d');
                this.toDateText.text = this.selectedToDate.format('yyyy.M.d');
                this.props.onChange && this.props.onChange(this.selectedFromDate.format('yyyy-MM-dd'), this.selectedToDate.format('yyyy-MM-dd'));
                return;    
            }
            date = new Date(data[0].id, data[1].id, data[2].id);
            this.props.onChange && this.props.onChange(date.format('yyyy-MM-dd'));
        }
        this.selectedDate = date;
    }

    onConfirm = () => {
        const { mode, range } = this.props;
        this.picker.dismiss();
        if (mode === 'datetime') {
            this.lastContext = {
                date: this.state.dataSource.data(0).id,
                hour: this.state.dataSource.data(1).id,
                minute: this.state.dataSource.data(2).id,
            }
            this.props.onConfirm && this.props.onConfirm(this.selectedDate.toString());
        } else {
            if (range) {
                this.lastFromContext = {
                    year: this.state.dataSource.data(0).id,
                    month: this.state.dataSource.data(1).id,
                    day: this.state.dataSource.data(2).id,
                }
            
                this.lastToContext = {
                    year: this.state.dataSource.data(0).id,
                    month: this.state.dataSource.data(1).id,
                    day: this.state.dataSource.data(2).id,
                }

                this.props.onConfirm && this.props.onConfirm(this.selectedFromDate.format('yyyy-MM-dd'), this.selectedToDate.format('yyyy-MM-dd'));
            } else {
                this.lastContext = {
                    year: this.state.dataSource.data(0).id,
                    month: this.state.dataSource.data(1).id,
                    day: this.state.dataSource.data(2).id,
                }
                this.props.onConfirm && this.props.onConfirm(this.selectedDate.format('yyyy-MM-dd'));
            }
        }
    }

    onCancel = () => {
        this.picker.dismiss();
        this.cancelled = true;
        this.props.onCancel && this.props.onCancel();
    }

    onFromClicked = e => {
        if (this.state.activeTab === 'from') return;

        this.selectedDate = this.selectedFromDate;
        this.setState({
            activeTab: 'from'
        }, () => {
            this.state.dataSource.reset();
            this.picker.reset();
        });
    }

    onToClicked = e => {
        if (this.state.activeTab === 'to') return;

        this.selectedDate = this.selectedToDate;
        this.setState({
            activeTab: 'to'
        }, () => {
            this.state.dataSource.reset();
            this.picker.reset();
        });
    }

    renderHeader = () => {
        const { prefixCls, header } = this.props;

        return (
            <View className={`${prefixCls}-container`}>
                <View className={`${prefixCls}-header`}>
                    <TouchFeedback activeClassName={`${prefixCls}-header-left-active`}>
                        <Text className={`${prefixCls}-header-left`} onClick={this.onCancel}>{Strings.cancel}</Text>
                    </TouchFeedback>
                    <View className={`${prefixCls}-header-text`}>{header}</View>
                    <TouchFeedback activeClassName={`${prefixCls}-header-right-active`}>
                        <Text className={`${prefixCls}-header-right`} onClick={this.onConfirm}>{Strings.ok}</Text>
                    </TouchFeedback>
                </View>
                <View className={`${prefixCls}-menu`}>
                    <TouchFeedback activeClassName={`${prefixCls}-menu-touched`}>
                        <View className={`${prefixCls}-menu-left`} onClick={this.onFromClicked}>
                            <View className={classnames(`${prefixCls}-menu-left-text`, {[`${prefixCls}-menu-left-text-active`]: this.state.activeTab === 'from'})}>
                                <Text>{Strings.beginDate}</Text>
                                <Text ref={el => this.fromDateText = el}></Text>
                                {
                                    this.state.activeTab !== 'from' ? null :
                                    <View className={`${prefixCls}-menu-left-text-activebar`} />
                                }
                            </View>
                        </View>
                    </TouchFeedback>
                    <TouchFeedback activeClassName={`${prefixCls}-menu-touched`}>
                        <View className={`${prefixCls}-menu-right`}  onClick={this.onToClicked}>
                            <View className={classnames(`${prefixCls}-menu-right-text`, {[`${prefixCls}-menu-right-text-active`]: this.state.activeTab === 'to'})}>
                                <Text>{Strings.endDate}</Text>
                                <Text ref={el => this.toDateText = el} />
                                {
                                    this.state.activeTab !== 'to' ? null :
                                    <View className={`${prefixCls}-menu-right-text-activebar`} />
                                }
                            </View>
                        </View>
                    </TouchFeedback>
                </View>
            </View>
        );
    }

    render() {
        const {
            prefixCls,
            header,
            mode,
            range,
        } = this.props;

        let itemStyles = undefined;
        if (mode === 'datetime') {
            itemStyles = [];
            itemStyles.push({width: '40%'});
            itemStyles.push({width: '30%'});
            itemStyles.push({width: '30%'});
        }

        return (
            <Picker
                ref={el => this.picker = el}
                modalClassName={`${prefixCls}-modal`}
                itemClassName={`${prefixCls}-modal-item`}
                itemStyles={itemStyles}
                onChange={this.onChange}
                dataSource={this.state.dataSource}
                header={header || this.state.header}
                renderHeader={mode === 'date' && range ? this.renderHeader : undefined}
                onConfirm={this.onConfirm}
                onCancel={this.onCancel}
            />
        )
    }
}