import React from 'react';
import ReactDOM from 'react-dom';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import View from '../view';

import './style/index.less';

function recursiveCloneChildren(children, cb = ch => ch) {
    return React.Children.map(children, (child, index) => {
        const newChild = cb(child, index);
        if (
            typeof newChild !== 'string' &&
            typeof newChild !== 'number' &&
            newChild &&
            newChild.props &&
            newChild.props.children
        ) {
            return React.cloneElement(
                newChild,
                {},
                recursiveCloneChildren(newChild.props.children, cb),
            );
        }
        return newChild;
    });
}

export default class ContextMenu extends React.PureComponent {
    static propTypes = {
        prefixCls: PropTypes.string,
        menus: PropTypes.array,
        point: PropTypes.object,
        offset: PropTypes.object,
        onSelect: PropTypes.func,
        onClose: PropTypes.func,
    };

    static defaultProps = {
        prefixCls: 'tm-context-menu',
        offset: { x: 0, y: 0 },
    };

    componentDidMount() {
        const { point, offset } = this.props;
        if (this.mask && this.menu) {
            const x = point.x + offset.x;
            const y = point.y + offset.y;
            if (x + this.menu.clientWidth >= this.mask.clientWidth) {
                this.menu.style.left = x - this.menu.clientWidth + 'px';
            }
            if (y + this.menu.clientHeight >= this.mask.clientHeight) {
                this.menu.style.top = y - this.menu.clientHeight + 'px';
            }
        }
    }

    onOutsideTouch = e => {
        let node = e.target;
        while (node) {
            if (node === this.menu) return;
            node = node.parentNode;
        }
        
        this.props.onClose && this.props.onClose();
    }

    onSelect = (menu, index) => {
        const { onSelect, onClose } = this.props;

        onSelect && onSelect(menu, index);
        onClose && onClose();
    }

    render() {
        const { prefixCls, menus, point, offset } = this.props;
        
        const menuNode = recursiveCloneChildren(menus, (menu, index) => {
            const extraProps = { firstItem: false };
            if (
                menu &&
                typeof menu !== 'string' &&
                typeof menu !== 'number' &&
                menu.type &&
                (menu.type).myName === 'ContextMenuItem' &&
                !menu.props.disabled
            ) {
                extraProps.onClick = () => this.onSelect(menu, index);
                extraProps.firstItem = index === 0;
                return React.cloneElement(menu, extraProps);
            }
            return menu;
        });

        const style = {
            left: point.x + offset.x + 'px',
            top: point.y + offset.y + 'px',
        };

        const wrapperNode = (
            <View
                ref={el => this.menu = ReactDOM.findDOMNode(el)}
                className={classnames(`${prefixCls}-menu`)} 
                style={style}
            >
                {menuNode}
            </View>
        );

        return (
            <View 
                ref={el => this.mask = ReactDOM.findDOMNode(el)}
                className={`${prefixCls}`}
                onClick={this.onOutsideTouch}
            >
                {wrapperNode}
            </View>
        )
    }
}